import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import _ from 'lodash';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { FacilityContract } from 'src/app/shared/models/facility-contract';
import { AssignedContractsService } from '../admin-facility-assigned-contracts/services/assigned-contracts.service';

@Component({
  selector: 'app-view-facility-contracts',
  templateUrl: './view-facility-contracts.component.html',
  styleUrls: ['./view-facility-contracts.component.scss']
})
export class ViewFacilityContractsComponent implements OnInit, OnDestroy {


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() facilityIdInput: number;

  private userSessionSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<FacilityContract[]>;
  public totalRows$: Observable<number>;
  public sortedData = [];
  public facilityContractList: Array<FacilityContract> = [];


  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private assignedContractsService: AssignedContractsService,
    public dialog: MatDialog
  ) { }


  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.getFacilityContractList(this.facilityIdInput);
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  getFacilityContractList(facilityId: number): void {
    this.assignedContractsService.getFacilityContracts(facilityId).subscribe(
      (response) => {
        this.facilityContractList = response;
        this.setDisplayRows(this.facilityContractList);
        this.typeOfSpinner='none';
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      });
  }

  setDisplayRows(facilityContractList: FacilityContract[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(facilityContractList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }
}
