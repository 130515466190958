import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, Subscription, of } from 'rxjs';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { SnackBarModule } from '../../../shared/modules/snackbar/snack-bar.module';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import _ from 'lodash';
import { SecurityGroupService } from '../services/security-group.service';
import { UserSecurityGroup } from '../models/user-security-group';
import { UserConfirmDialogModel, UserDetailsDialogComponent } from '../../user-details-dialog/user-details-dialog.component';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';

@Component({
  selector: 'app-security-group-list',
  templateUrl: './security-group-list.component.html',
  styleUrls: ['./security-group-list.component.scss']
})

export class SecurityGroupListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() filterByAppRoleId = '';
  @Input() filterByAppRoleName = '';
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<UserSecurityGroup[]>;
  public totalRows$: Observable<number>;
  public appId: number;
  private securityGroupUsers : UserSecurityGroup[];
  public pageName: string;
  private unsubscribeList: any[] = [];
  public userSession: UserSession = new UserSession();
  public appRoleName = '';

  private userDetailsDialogSubscription : Subscription;
  displayedColumns: string[] = ['securityGroupName', 'securityGroupId', 'securityGroupDescription', 'abbreviation', 'usersCount',
    'action'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private b2cService: B2cService,
    private securityGroupService :SecurityGroupService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private dialog: MatDialog,
    private snackBar: SnackBarModule,
   // public parentDialogRef: MatDialogRef<ApprolesSecuritygroupDialogComponent>
     ) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    const selectedTitle = this.filterByAppRoleName
    this.pageName = !_.isNil(this.activatedRoute.snapshot.data.pageDetails) ? this.activatedRoute.snapshot.data.pageDetails.title : selectedTitle + ' Security Groups';

        const routeParamSubscription = this.activatedRoute.params.subscribe(
        (params: Params) => {
        this.appId = params.id;
        }
      );
        this.unsubscribeList.push(routeParamSubscription);
        if (this.filterByAppRoleId) {
          this.getAppRoleSecurityGroup(this.filterByAppRoleId);
          this.appRoleName = this.getSelectedAppRoleName();
        } else {
          this.getAllSecurityGroupwithUsers();
        }
  }
  getAppRoleSecurityGroup(appRoleId: string): void {
    this.securityGroupService.getAppRoleSecurityGroups(appRoleId)
    .subscribe({
      next: (response) => {
        this.securityGroupUsers = response;
        this.setDisplayRows(this.securityGroupUsers);
      },
      error:(error: HttpErrorResponse) => {
        this.showSnackBarMessageError(error);
      }
  });

  }

  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  getAllSecurityGroupwithUsers(): void {
    this.securityGroupService.getSecurityGroupWithUsers()
    .subscribe({
      next: (response) => {
        this.securityGroupUsers = response;
        this.setDisplayRows(this.securityGroupUsers);
        this.typeOfSpinner = 'none';
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
  });
  }

  getSelectedAppRoleName(): string {
    this.typeOfSpinner = 'none';
    return this.appSecurityManagementService.getAppRoleName();
  }

  setDisplayRows(securityGroupList: UserSecurityGroup[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(securityGroupList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  closeDiaglog():void{
    //this.parentDialogRef.close();
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    const filteredSecGroups = this.securityGroupUsers.filter((securityGroup) => {
      return (
        securityGroup.securityGroupDescription && securityGroup.securityGroupDescription.toLowerCase().indexOf(filterValue) !== -1 ||
        securityGroup.securityGroupName && securityGroup.securityGroupName.toLowerCase().indexOf(filterValue) !== -1 ||
        securityGroup.securityGroupId && securityGroup.securityGroupId.toString().indexOf(filterValue) !== -1
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredSecGroups);
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  openUserDetailsDialog(rowData: UserSecurityGroup): void {
    const dialogData = new UserConfirmDialogModel(
      'View User',rowData.securityGroupId, rowData.securityGroupName);
    const exportDialogRef = this.dialog.open(UserDetailsDialogComponent, {
      // width: '1000px',
      data: dialogData,
      backdropClass: 'md_panel-dialog'
    });

    this.userDetailsDialogSubscription = exportDialogRef.afterClosed().subscribe(result => {
      console.log(result);
      return;
    });
  }

}
