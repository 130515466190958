import { Component, OnInit, AfterViewInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { B2cService, User, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { ActivatedRoute, Params } from '@angular/router';
import _ from 'lodash';
import { CossaService } from '../../shared/services/cossa.service';
import { Facility } from '../../shared/models/facility.model';
import { UserSecurityFacility } from '../../shared/models/user-security-facility.model';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { OrgAdminFacility } from '../../shared/models/org-admin-facility.model';
import { Users } from '../../shared/models/users.model';
import { successMsgs } from '../../shared/constants/constants';
import { errorMsgs } from '../../shared/constants/constants';
import { Organization } from 'src/app/shared/models/organization.model';
import { UserEntitySecurityGroup } from 'src/app/shared/models/user-entity-security-group.model';

@Component({
  selector: 'app-facility-assign-user',
  templateUrl: './facility-assign-user.component.html',
  styleUrls: ['./facility-assign-user.component.scss']
})

export class FacilityAssignUserComponent implements OnInit, OnDestroy{
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private userId: string;
  private successMessage: string;
  private userSessionSubscription: Subscription;
  private routeParamSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<Users[]>;
  public totalRows$: Observable<number>;
  public facilityDetail: Facility;
  public userNotInFacility: Users[];
  private applicationsSubscription: Subscription;
  public sortedData = [];
  private unsubscribeList: any[] = [];
  private organization: Organization[];
  public selectedOrganization: Organization;
  public organizationUserNotInEntity: Users[];
  public selectuserUserEntitySecurityGroupList: Users[];
  public entityId: number;

  public isSuperAdminPage = false;
  lineOfBusinessData: any[];
  UserEntitySecurityGroup: UserEntitySecurityGroup[];
  showOrganizations = true;
  showLOB = true;
  doNavigation= false;
  isOrgAdminView = true;
  displayedColumns: string[] = ['logo', 'userId', 'firstName', 'lastName', 'email', 'securityGroupName',
    'assignedEntityName', 'lineOfBusiness', 'action'];

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private activatedRoute: ActivatedRoute,
    private cossaService: CossaService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FacilityAssignUserComponent>
  ) {
      this.showOrganizations = this.data?.showOrganizations ?? true;
      this.showLOB = this.data?.showLOB ?? true;
      this.doNavigation = this.data?.doNavigation ?? false;
      this.isOrgAdminView = this.data?.isOrgAdminView ?? true;
   }

  ngOnInit(): void {
    const pagename = this.activatedRoute.snapshot['_routerState'].url;
    this.isSuperAdminPage = (pagename.indexOf("detailedadminfacility") !== -1 || pagename.indexOf("detailedadminfacilityuserassignment") !== -1) ? true : false;
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    if(this.isSuperAdminPage){
      if(this.data.dataKey && this.data.dataKey != '0'){
      this.getUserEntitySecurityGroupList();
      }else{
      this.getllAAssignUsers();
      }
      return;
    }
    this.organization = this.appSecurityManagementService.getOrganization();
    this.organization.forEach(organization => {
      if (organization.selected === true) {
        this.selectedOrganization = organization;
      }
    });


    if (this.selectedOrganization) {
      this.getAssignUsers(this.selectedOrganization.entityId, this.data.dataKey);
    }
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  getFacilityDetails(facilityId): void {
    this.cossaService.getFacilityDetails(facilityId.toString()).subscribe(
      (facilityDetail) => {
        this.facilityDetail = facilityDetail;
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  insertUser(userId,lineOfBusinessId): void {
    this.entityId = Number(this.data.dataKey);
    this.appSecurityManagementService.addUserToFacility(userId, this.entityId,lineOfBusinessId).subscribe(
      (entityUser) => {
        if (!_.isNil(entityUser)) {
          this.snackBar.successMessage(successMsgs.user_entity_insert);
          if (this.selectedOrganization) {
            this.dialogRef.close(true);
          }
          else {
            if(this.data.dataKey && this.data.dataKey != '0'){
              this.getCPSInternalUser(this.data.dataKey);
              }else{
              this.getllAAssignUsers();
              }
          }
        }
        else {
          this.snackBar.errMessage(errorMsgs.user_entity_record_not_inserted);
        }
      }
      ,
      (error: HttpErrorResponse) => {
        this.customErrorHandler.handleError(error);
        this.typeOfSpinner = 'none';
      }
    );
  }

  selectFacility(userUserEntitySecurityGroup: Users){
    if(!this.selectuserUserEntitySecurityGroupList){
      this.selectuserUserEntitySecurityGroupList = [];
    }
    let selectUserFacilityList = [];
    if(this.selectuserUserEntitySecurityGroupList && this.selectuserUserEntitySecurityGroupList.length > 0){
     selectUserFacilityList = this.selectuserUserEntitySecurityGroupList.filter(i=> i.userId === userUserEntitySecurityGroup.userId);
    }
    if(selectUserFacilityList.length == 0){
    this.selectuserUserEntitySecurityGroupList.push(userUserEntitySecurityGroup);
    }
    this.dialogRef.close(this.selectuserUserEntitySecurityGroupList);
  }

  getAssignUsers(entityRootId, entityId): void {
    this.appSecurityManagementService.getOrganizationUseNotInEntity(entityRootId.toString(), entityId).subscribe(
      (organizationUserNotinEntity) => {
        this.organizationUserNotInEntity = organizationUserNotinEntity;
        this.setDisplayRows(this.organizationUserNotInEntity);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);

      });
  }

    getllAAssignUsers(): void {
      this.appSecurityManagementService.getAllAssignedUsers().subscribe(
        (usersNotinEntity) => {
          this.organizationUserNotInEntity = usersNotinEntity;
          this.setDisplayRows(this.organizationUserNotInEntity);
          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.customErrorHandler.handleError(error);

        });
    }

  setDisplayRows(userSecurityFacilityList: Users[]): void {
    this.sort.active = 'lastName'
    this.sort.direction = 'asc';
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(userSecurityFacilityList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.toLowerCase().trim();
    const filteredUserNotInFacility = this.organizationUserNotInEntity.filter((userNotInEntity) => {
      return (
        (userNotInEntity.firstName && userNotInEntity.firstName.toLowerCase().indexOf(filterValue) !==
          -1) ||
        (userNotInEntity.lastName && userNotInEntity.lastName
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
        (userNotInEntity.email && userNotInEntity.email
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
        (userNotInEntity.jobTitle && userNotInEntity.jobTitle
          .toLowerCase()
          .indexOf(filterValue) !== -1)
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredUserNotInFacility);
  }

  getUserEntitySecurityGroupList(): void{
    this.appSecurityManagementService.getUserEntitySecurityGroupList().subscribe(
      (UserEntitySecurityGroup) => {
        this.UserEntitySecurityGroup = UserEntitySecurityGroup;
        this.getLineOfBusinessDetails();
      });
  }
  getLineOfBusinessDetails(): void {
    this.appSecurityManagementService.getLineOfBusinessDetails().subscribe(
      (lineOfBusiness) => {
        this.lineOfBusinessData = lineOfBusiness;
        this.getCPSInternalUser(this.data.dataKey);
      });
  }

  getCPSInternalUser(entityId): void {
    this.appSecurityManagementService.getCPSInternalUser().subscribe(
      (usersNotinEntity) => {
        if(this.isSuperAdminPage){
          this.organizationUserNotInEntity=usersNotinEntity;
          if(this.data.selectedAssignedUser)
          {
          const selectedSecurityGroup =  (this.data.selectedAssignedUser) ? this.data.selectedAssignedUser: null;
          this.organizationUserNotInEntity=this.organizationUserNotInEntity.filter(item => selectedSecurityGroup.map(a => a.userId).indexOf(item.userId) === -1);
          }
        }
        else{
        this.organizationUserNotInEntity = usersNotinEntity;
        }
        this.organizationUserNotInEntity.forEach(element => {
          element.isOrgUser=false;
          const userEntitySecurityGroup =  this.UserEntitySecurityGroup.filter(e => e.userId === element.userId && e.entityId === parseInt(entityId));
          const selectedSecurityGroup =  (this.data.selectedAssignedUser) ? this.data.selectedAssignedUser.filter(e => e.userId === element.userId) : null;
          if(selectedSecurityGroup && selectedSecurityGroup.length > 0){
            element.lineOfBusinessData = this.lineOfBusinessData.filter(item => selectedSecurityGroup.map(a => a.lineOfBusinessId).indexOf(item.lineOfBusinessId) === -1);
            element.lineOfBusinessId = element.lineOfBusinessData[0].lineOfBusinessId;
          }
          else if(userEntitySecurityGroup && userEntitySecurityGroup.length > 0){
            element.lineOfBusinessData = this.lineOfBusinessData.filter(item => userEntitySecurityGroup.map(a => a.lineOfBusinessId).indexOf(item.lineOfBusinessId) === -1);
            element.lineOfBusinessId = element.lineOfBusinessData[0].lineOfBusinessId;
          }else{
              element.lineOfBusinessData = this.lineOfBusinessData;
              element.lineOfBusinessId = element.lineOfBusinessData[0].lineOfBusinessId;
          }
        });
        this.setDisplayRows(this.organizationUserNotInEntity);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);

      });
    }

}
