import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError, tap } from "rxjs";
import { App } from "src/app/shared/models/app.model";
import { environment } from "src/environments/environment";
import { FacilityContractApplication } from "../models/facility-contract-application";
import { FacilityContractAssessmentType } from "../models/facility-contract-assessment-type";

@Injectable({
  providedIn: 'root'
})
export class AdminContractService {

  constructor(private http: HttpClient) { }

  getFacilityLOBApplications(): Observable<App[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}FacilityLOBApp`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<App[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'admin-contract.service.service.ts- getFacilityLOBApplications- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getAssignedApplicationByFacilityContractId(facilityContractId: number): Observable<FacilityContractApplication[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityContractApp/${facilityContractId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<FacilityContractApplication[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'admin-contract.service.service.ts- getAssignedApplicationByFacilityContractId- ThrowingError To Caller...',
            err
          );
          }),
        tap((data) => JSON.stringify(data))
      );
  }

  getAssessmentTypeByFacilityContractId(facilityContractId: number): Observable<FacilityContractAssessmentType[]> {
  const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityContractAssessmentType/${facilityContractId}`;
  const headers = {
    'Content-Type': 'application/json',
  };

  return this.http.get<FacilityContractAssessmentType[]>(apiUrl, { headers })
    .pipe(
      catchError((err) => {
        return throwError(
          'admin-contract.service.service.ts- getAssessmentTypeByFacilityContractId- ThrowingError To Caller...',
          err
        );
        }),
      tap((data) => JSON.stringify(data))
    );
}
}
