import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { UserAppSelectionSession } from '../../shared/models/user-app-selection-session.model';

@Injectable({
  providedIn: 'root',
})
export class SharedUserAppDetailService {

  public subject = new Subject<UserAppSelectionSession>();
  private selectedAppDetails = new BehaviorSubject(new UserAppSelectionSession());
  currentSelectedApp = this.selectedAppDetails.asObservable();

  updateAppSelection(message: UserAppSelectionSession) {
    this.selectedAppDetails.next(message);
  }

}
