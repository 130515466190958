import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import _ from 'lodash';
import { of } from 'rxjs';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { AdminFacilityService } from '../view-admin-facility-lob/services/admin-facility.service';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';
import { FacilityLOBList } from 'src/app/shared/models/facility-lob-list-model';

@Component({
  selector: 'app-child-org-admin-facility-lob',
  templateUrl: './child-org-admin-facility-lob.component.html',
  styleUrls: ['./child-org-admin-facility-lob.component.scss']
})
export class ChildOrgAdminFacilityLOBComponent implements OnInit {
  displayedColumns: string[] = ['indent', 'facilityName', 'city', 'state', 'serviceProvided',
    'startDate', 'endDate', 'action'];

  constructor(
    private adminFacilityService: AdminFacilityService,
    private customErrorHandler: CustomErrorHandler){ }

  @Input() parentEntityIdInput: number;
  @Input() selectedOption: boolean;
  @Output()
  openChildFacility = new EventEmitter<FacilityLOBList>();


  childAdminFacilitiesList: FacilityLOBList[] = [];

  public displayedRows$: Observable<FacilityLOBList[]>;
  public totalRows$: Observable<number>;

  dataSource = new MatTableDataSource<FacilityLOBList>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  typeOfSpinner: string;

  ngOnInit() {
    if (!_.isNil(this.parentEntityIdInput))
    {
      this.typeOfSpinner ='loading';
      this.getChildFacilities(this.parentEntityIdInput);
    }
  }

  onEdit(item): void{
    this.openChildFacility.emit(item);
  }

  getChildFacilities(parentEntityId: number): void {
    this.adminFacilityService.getOrganizationFacilityLOBList(parentEntityId).subscribe(
      (response) => {
        this.childAdminFacilitiesList = response;
        this.childAdminFacilitiesList = this.childAdminFacilitiesList.sort((a, b) => {
          return (a.facilityName && b.facilityName) ? (a.facilityName.trim().toLocaleLowerCase() < b.facilityName.trim().toLocaleLowerCase()) ? -1 : 1: null;
        });
        this.childAdminFacilitiesList.forEach(element => {
          element.isParentEntityString= String(element.isParentEntity);
        });
        this.childAdminFacilitiesList = this.childAdminFacilitiesList.filter((x) => x.isActive == true);
        this.setDisplayRows(this.childAdminFacilitiesList);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  setDisplayRows(childAdminFacilitiesList: FacilityLOBList[]): void {
    // const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const rows$ = of(childAdminFacilitiesList);
    this.displayedRows$ = rows$.pipe(
      // sortRows(sortEvents$)
    );
  }

}
