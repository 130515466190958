import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { of } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs';
import { ActionMessage, DisplayMessage, DisplayString } from '../../shared/components/message';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';

import { fromMatSort, sortRows } from '../../shared/utils/utils';
import { ApplicationRoles } from '../../shared/models/application-roles';
import { UserApproleFacility } from '../../shared/models/user-approle-facility';
import { ModifyUserService } from '../../shared/services/modify-user.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assign-user-permissions',
  templateUrl: './assign-user-permissions.component.html',
  styleUrls: ['./assign-user-permissions.component.scss']
})

export class AssignUserPermissionsComponent implements OnInit {
  displayedColumns: string[] = ['appName', 'appRoleName','appRoleDescription'];
  dataSource: any;

  selectedRole: ApplicationRoles;
  AppRoleForUserList: Array<ApplicationRoles> = [];
  selectedAppRoleList: Array<ApplicationRoles> = [];
  updatedUserApproleFacility: UserApproleFacility;
  loadAppRolesSubscription: Subscription;
  updateAppRolesSubscription: Subscription;

  public displayedRows$: Observable<ApplicationRoles[]>;
  public totalRows$: Observable<number>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  isNonEmpty: boolean;
  @Input() userIdInput: string;
  userId: number;

  typeOfSpinner = "loading";
  appRolesCount: number;
  isButtonEnable: boolean;

  constructor(private userRoleService: ModifyUserService,
    private snackBar: SnackBarModule,
    private router: Router) { }

  ngOnInit() {
    this.userId = Number(this.userIdInput);
    this.isNonEmpty = true;
    this.getApplicationRoles();
  }

  groupingRolesByApp(row: ApplicationRoles, index: number): string {
    const currentIndex = this.AppRoleForUserList.indexOf(row);
    const previousMetric = this.AppRoleForUserList[currentIndex - 1];
    if (previousMetric !== undefined) {
      if (index === 0) {
        return 'showSection';
      } else {
        if (row.appName === previousMetric.appName) {
          return 'hideSection';
        }
      }
      return 'showSection';
    }

    return 'showSection';
  }


  selectedAppRole(event: MatCheckboxChange, applicationRole: ApplicationRoles): void {
    this.selectedRole = new ApplicationRoles();
    this.selectedRole.appId = applicationRole.appId;
    this.selectedRole.appName = applicationRole.appName;
    this.selectedRole.appRoleId = applicationRole.appRoleId;
    this.selectedRole.appRoleName = applicationRole.appRoleName;
    this.selectedRole.appRoleDescription = applicationRole.appRoleDescription;
    if (event.source.checked) {
      this.selectedAppRoleList.push(this.selectedRole);
    } else {
      this.selectedAppRoleList = this.selectedAppRoleList.filter(({ appRoleId }) => appRoleId !== this.selectedRole.appRoleId);
    }
    this.appRolesCount = this.selectedAppRoleList.length;
    this.isButtonEnable = this.appRolesCount > 0 ? true : false;
  }

  updateUserRoles(): void {
    if (_.isNil(this.selectedAppRoleList)) {
      this.selectedAppRoleList = this.AppRoleForUserList.filter(x => x.isSelected == true);
    }

    this.updatedUserApproleFacility = new UserApproleFacility();
    this.updatedUserApproleFacility.appRoleSecurityGroupUsers = new Array<ApplicationRoles>();
    this.updatedUserApproleFacility.appRoleSecurityGroupUsers = this.selectedAppRoleList;

    this.updateAppRolesSubscription = this.userRoleService.updateUserRolesAndFacility(this.userId, this.updatedUserApproleFacility).subscribe(data => {
      this.snackBar.successMessage(DisplayMessage.updateMessage, ActionMessage.okAction);
      this.router.navigate(['allusers']);
    },
      (error: HttpErrorResponse) => {
        this.snackBar.exceptionMessage(DisplayMessage.updateFailureMessage(DisplayString.userWithAppRole), ActionMessage.okAction);
      });
  }

  getApplicationRoles(): void {
    this.loadAppRolesSubscription = this.userRoleService.GetUserAppRole(this.userId).subscribe(data => {
      if (data) {
        this.AppRoleForUserList = data;
        this.selectedAppRoleList = this.AppRoleForUserList.filter(x => x.isSelected == true);
        this.setDisplayRows(this.AppRoleForUserList);
        this.typeOfSpinner = "none";
        this.isNonEmpty = false;
      }
    },
      (error: HttpErrorResponse) => {
        this.snackBar.exceptionMessage(DisplayMessage.createFailureMessage(DisplayString.approles), ActionMessage.okAction);
      });
  }


  setDisplayRows(AppRoleForUserList: ApplicationRoles[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const rows$ = of(AppRoleForUserList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
    );
  }

}
