export enum RouteGuardConst {
  AccessDeniedURL = '/access-denied',
  UserPermissions = 'userPermissions',
  RoutedUrl = 'routedUrl',
  NoOrganizationURL = '/noorganization',
}


export enum SettingMenus {
  FacilityURL = '/viewadminfacilitylist',
  OrganizationURL = '/viewadminorganizations',
  UserURL = '/allusers'
}
