import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent{

  title: string;
  message: string;
  domain = false;
  buttonText ="Ok";

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogModel) {
      // Update view with given values
      this.title = data.title;
      this.message = data.message;
      this.buttonText = data.buttonText
      if(this.message ==='Are you sure want to remove the domain from Allowed Domain list.'){
       this.domain = true;
      }else{
        this.domain = false;
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export class ErrorDialogModel {
  constructor(public title: string, public message: string, public buttonText: string="Ok") {
  }

}
