import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiurl = `${environment.appConfig.apiServer.cpsOptimizer}`;

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})


export class ThankyouComponent {
  public thisUrl= apiurl;

 onSubmit(){
  location.reload();
  location.href=apiurl;
 }

}
