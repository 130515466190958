import {
  Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild,
  OnDestroy, OnChanges, SimpleChanges
} from '@angular/core';
import { startWith, map } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import * as _ from 'lodash';
import { MatOption } from '@angular/material/core';
import { SearchAutoCompleteDropDown } from '../../models/search-auto-filters';

@Component({
  selector: 'app-search-auto-complete-filters',
  templateUrl: './search-auto-complete-filters.component.html',
  styleUrls: ['./search-auto-complete-filters.component.scss']
})
export class SearchAutoCompleteFiltersComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectProperties: SearchAutoCompleteDropDown;
  @Output() selectionChange = new EventEmitter<{ value: any[], name: string, isInitialize: boolean }>();

  @Input() parentSubject: Subject<void>;
  @ViewChild('allSelected', { static: true }) private allSelected: MatOption;

  filterOptions: Observable<string[]>;
  formControlSearch: FormControl = new FormControl();
  formControlMultiCtrl: FormControl = new FormControl();
  @ViewChild('search', { static: true }) search: ElementRef;
  parentSubjectSubcription: Subscription;
  formControlMultiCtrlSubcription: Subscription;
  defaultValue: Array<string | number> = [];
  isInitialized = true;
  isEntityControl = false;
  isDashBoardContol = false;
  selectedSearchItems: Array<string> = [];
  finalFilteredItem: Array<string> = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.TableAutoFilters();
    }
  }

  ngOnInit(): void {
    this.isEntityControl = this.selectProperties.controlName === 'entityName'
      || this.selectProperties.placeholder === 'Metric Section'|| this.selectProperties.placeholder === 'Metric' ? true : false;
    if (!_.isNil(this.selectProperties) && !_.isNil(this.selectProperties.defaultValue) && this.selectProperties.defaultValue.length > 0) {
      this.defaultValue = this.selectProperties.defaultValue;
    }
    this.TableAutoFilters();
    this.isInitialized = true;
    this.formControlMultiCtrlSubcription = this.formControlMultiCtrl.valueChanges.subscribe(sub => {
      this.selectedSearchItems = [];
      sub.forEach(element => {
        if (!this.selectedSearchItems.includes(element)) {
          this.selectedSearchItems.push(element);
        }
      });
      this.selectionChange.emit({ value: sub, name: this.selectProperties.controlName, isInitialize: this.isInitialized });
      this.isInitialized = false;
    });
    // isInitialized = false;
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.parentSubjectSubcription)) {
      this.parentSubjectSubcription.unsubscribe();
    }
    if (!_.isNil(this.formControlMultiCtrlSubcription)) {
      this.formControlMultiCtrlSubcription.unsubscribe();
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Space') {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.preventDefault();
      return false;
    }
  }

  private TableAutoFilters(): void {
    if (!_.isNil(this.selectProperties) && !_.isNil(this.selectProperties.defaultValue) && this.selectProperties.defaultValue.length > 0) {
      this.defaultValue = this.selectProperties.defaultValue;
    }

    this.filterOptions = this.formControlSearch.valueChanges.pipe(startWith(''),
      map(value => (Array.from(new Set(this.selectProperties.items
        .map(x => x[this.selectProperties.controlName]).filter(x => !_.isNil(x) ? x.toString() : ''))).sort())
        .filter(x => x.toString()).sort().filter(f => f.toString().toLowerCase()
          .includes(value.toString().toLowerCase()))));

    this.filterOptions.subscribe(x => {
      this.finalFilteredItem = this.selectedSearchItems.filter(si => !x.includes(si));
    });

    this.parentSubjectSubcription = this.parentSubject.subscribe(() => {
      this.formControlSearch = new FormControl();
      this.formControlMultiCtrl = new FormControl();
      this.ngOnInit();
      this.TableAutoFilters();
    });
  }

  openedChange(e: boolean): void {
    this.formControlSearch.patchValue('');
    if (e === true) {
      this.search.nativeElement.focus();
    }
  }

  clearSearch(event: MouseEvent): void {
    event.stopPropagation();
    this.formControlSearch.patchValue('');
  }

  toggleAllSelection(): void {
    if (this.allSelected.selected) {
      this.formControlMultiCtrl
        .patchValue([...this.selectProperties.items.map(item => item[this.selectProperties.controlName]), 'All']);
    } else {
      this.formControlMultiCtrl.patchValue([]);
    }
  }

  tosslePerOne(): boolean {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.formControlMultiCtrl.value.length === this.selectProperties.items.length) {
      this.allSelected.select();
    }
  }

}
