import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-description-dialog',
  templateUrl: './description-dialog.component.html',
  styleUrls: ['./description-dialog.component.scss']
})
export class DescriptionDialogComponent {
  title: string;
  message: string;
  domain = false;

  constructor(
    public dialogRef: MatDialogRef<DescriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DescriptionDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    if (this.message === 'Are you sure want to remove the domain from Allowed Domain list.') {
      this.domain = true;
    } else {
      this.domain = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export class DescriptionDialogModel {
  constructor(public title: string, public message: string) {
  }

}
