import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import _ from 'lodash';


@Component({
  selector: 'app-organization-orgadmin-user-dialog',
  templateUrl: './organization-orgadmin-user-dialog.component.html',
  styleUrls: ['./organization-orgadmin-user-dialog.component.scss']
})

export class OrganizationOrgadminUserDialogComponent implements OnInit {
  public orgId: number;
  public orgName: string;
  private routeParamSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<OrganizationOrgadminUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public rowData: OrgAdminUserConfirmDialogModel,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.orgId = this.rowData.orgId;
    this.orgName = this.rowData.orgName;
    this.routeParamSubscription = this.activatedRoute.params.subscribe((params) => {
       if (params.appRoleId) {
        this.orgId = params.orgId;
      }
    });
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.routeParamSubscription)) {
      this.routeParamSubscription.unsubscribe();
    }
  }
}
export class OrgAdminUserConfirmDialogModel {
  constructor(public title: string, public orgId: number, public orgName: string) { }
}
