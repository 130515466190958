import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AppRoleAll } from '../../shared/models/app-role-all.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AppRoleService } from '../../shared/services/app-role.service';
import { SharedUserAppDetailService } from '../../shared/services/shared-user-app-detail.service';
import { UserAppSelectionSession } from '../../shared/models/user-app-selection-session.model';
import { AppRoleAllAdmin } from '../../shared/models/app-role-all-admin.model';
import _ from 'lodash';
import { UserDetail } from 'src/app/shared/models/user-detail.model';

@Component({
  selector: 'app-role-panel',
  templateUrl: './app-role-panel.component.html',
  styleUrls: ['./app-role-panel.component.scss'],
})
export class AppRolePanelComponent implements OnInit, OnDestroy {
  public userAppRoles: Array<string> = [];
  public appRoleAll: Array<AppRoleAll> = [];
  public appRoleAllForAdmin: AppRoleAllAdmin[];
  public appRoleBySecGroup: Array<string> = [];
  public deniedRole: Array<number> = [];
  private appId: number;
  private securityGroupId: number;
  private userId: string;
  private successMessage: string;
  private userSessionSubscription: Subscription;
  private routeParamSubscription: Subscription;
  private userSession: UserSession = new UserSession();
  private typeOfSpinner = 'loading';
  private userAppDetail: UserAppSelectionSession = new UserAppSelectionSession();
  public isAdmin = false;
  @Input() userIdInput: string;
  @Input() orgAdminSelectedAppId: number;

  constructor(
    private snackBar: SnackBarModule,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private activatedRoute: ActivatedRoute,
    private appRoleService: AppRoleService,
    private sharedUserAppDetailService: SharedUserAppDetailService
  ) {}

  ngOnInit(): void {
    this.userId = this.userIdInput;
    this.routeParamSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.appId = params.id;
      }
    );

    if( this.orgAdminSelectedAppId){
      this.appId = this.orgAdminSelectedAppId;
    }

    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();

    this.sharedUserAppDetailService.currentSelectedApp.subscribe(
      (appDetail) => {
        this.userAppDetail = appDetail;
        if (this.userAppDetail !== undefined && this.userAppDetail.isAdmin === true) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      }
    );
    this.getUserDetails();
  }


  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  eventCheckChanged(event: MatCheckboxChange, appRoleId: string): void {
    if (event.checked === true) {
      this.addAppRoleToUser(appRoleId, this.userId);
    }
    if (event.checked === false) {
      this.removeAppRoleToUser(appRoleId, this.userId);
    }
  }

  getUserDetails(): void {
    //const thisComponent = this;
    this.userAppRoles = [];
    this.appRoleService.getUserDetail(
      this.isAdmin,
      this.userId,
      this.appId,
      (userDetails: UserDetail) => {
        this.typeOfSpinner = 'none';
        this.securityGroupId = userDetails.securityGroupId;
        if (this.isAdmin === false) {
          if (userDetails.appRolesAll !== undefined) {
            this.appRoleAll = userDetails.appRolesAll;
          }
        }
        if (this.isAdmin === true) {
          if (userDetails.appRolesAllForAdmin !== undefined) {
            this.appRoleAllForAdmin = userDetails.appRolesAllForAdmin;
          }
        }
        if (userDetails.appRoleBySecGroup !== undefined) {
          userDetails.appRoleBySecGroup.forEach((securityGroupRole) => {
            this.appRoleBySecGroup.push(securityGroupRole.appRoleName);
          });
        }

        if (userDetails.userAppRoles !== undefined) {
         userDetails.userAppRoles.forEach((userRole) => {
          this.userAppRoles.push(userRole.appRoleName);
          });

        }
        if (userDetails.deniedRole !== undefined) {
          this.deniedRole = userDetails.deniedRole;
        }
      }
    );
  }

  addAppRoleToUser(appRoleId: string, userId: string): void {
    this.appSecurityManagementService
      .addAppRoleToUser(appRoleId, userId)
      .subscribe((response) => {
        if (response !== null) {
          this.getUserDetails();
        }
      });
  }

  removeAppRoleToUser(appRoleId: string, userId: string): void {
    this.appSecurityManagementService
      .removeAppRoleToUser(appRoleId, userId)
      .subscribe((response) => {
        if (response !== null) {
          this.getUserDetails();
        }
      });
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
    if (!_.isNil(this.routeParamSubscription)) {
      this.routeParamSubscription.unsubscribe();
    }
  }
}
