import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Users } from 'src/app/shared/models/users.model';
import { UserSecurityGroup } from '../security-group/models/user-security-group';

@Component({
  selector: 'app-user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  styleUrls: ['./user-details-dialog.component.scss']
})
export class UserDetailsDialogComponent implements OnInit {
  securityGroupId: number;
  securityGroupName : string;
  private allUsers: Users[];
  dataSource: MatTableDataSource<Users>;
  private routeParamSubscription: Subscription;
  public pageName: string;

  constructor(
    public dialogRef: MatDialogRef<UserDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public rowData: UserConfirmDialogModel,
    private activatedRoute: ActivatedRoute
  ) {
    this.dataSource = new MatTableDataSource<Users>(this.allUsers);
  }

  ngOnInit() {
    this.securityGroupId = this.rowData.securityGroupId;
    this.securityGroupName = this.rowData.securityGroupName;
    this.routeParamSubscription = this.activatedRoute.params.subscribe((params) => {
      if (params.securityGroupId) {
        this.securityGroupId = params.securityGroupId;
      }
    });
  }
}
export class UserConfirmDialogModel {
  constructor(public title: string, public securityGroupId: number, public securityGroupName: string) { }
}

