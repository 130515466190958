import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private sessionStorageModel: { [key: string]: string } = {};

  setUserEmail(key: string, value: string) {
    this.sessionStorageModel[key] = value;
  }

  getUserEmail(key: string): string {
    return this.sessionStorageModel[key];
  }
}
