import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { App } from 'src/app/shared/models/app.model';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { Facility } from 'src/app/shared/models/facility.model';
import { PropertyDefinition } from 'src/app/shared/models/property-definition';
import { ActionMessage, DisplayMessage } from 'src/app/shared/components/message';
import { Users } from 'src/app/shared/models/users.model';
import { ConfirmationDialogComponent, ConfirmDialogModel } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent, ErrorDialogModel } from 'src/app/shared/dialog/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { OrganizationFacility } from 'src/app/shared/models/organization-facility.model';
import { FiscalYearStartMonth } from 'src/app/shared/models/fiscal-year-start-month.model';
import { Subject, Subscription } from 'rxjs';
import { FacilityLOBService } from '../detailed-admin-facility/services/facility-lob.service';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { FacilityLOB } from 'src/app/shared/models/facility-lob.model';
import { FacilityDEANumber} from 'src/app/shared/models/facility-lob.model';
import { ValidateDuplicateFacilityLOBRequest } from 'src/app/shared/models/validate-duplicate-facility-lob.model';
import { FacilityLOBDropdowns,Dropdown } from 'src/app/shared/models/facility-lob-dropdowns.model';
import { AssessmentType } from './models/assessment-type';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';
import { AdminContractService } from './services/admin-contract.service';
import { FacilityContractAssessmentType } from './models/facility-contract-assessment-type';
import { FacilityContractApplication } from './models/facility-contract-application';
import { FacilityContractTeamMemberModel,FacilityContractTeamMember } from 'src/app/shared/models/facility-contract-team-member.models';
import { ChangeParentEntityComponent } from '../change-parent-entity/change-parent-entity.component';
import _ from 'lodash';
import { SuperAdminOrgCreateService } from '../super-admin-org-create/services/super-admin-org-create.service';
import { Organization } from '../super-admin-org-create/models/organization';
import { State } from 'src/app/shared/models/state.model';
import { ServiceProvidedIDs } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-add-edit-facility-lob',
  templateUrl: './add-edit-facility-lob.component.html',
  styleUrls: ['./add-edit-facility-lob.component.scss']
})
export class AddEditFacilityLOBComponent implements OnInit {

  facilityApplications: App[];
  facilityOverviewForm: FormGroup;
  facilityName: string;
  propertyDefinitionList: Array<PropertyDefinition> = [];
  public typeOfSpinner = 'loading';
  formControlList: string[];
  isViewFacility = false;
  isEdit = false;
  isOrg = false;
  isViewOnly = false;
  selectedFacility: any;
  selectedParentFacility: any;
  isFromParentFacility: boolean;
  isFromOrg: boolean;

  entityId: number;
  facilityId: number;
  facilityContractId: number;
  parentEntityId: number;
  parentFacilityContractId: number;
  isChildOfFacility: boolean;
  allowDelete : boolean;
  assignedDEANumber = false;
  deaNumberList: FacilityDEANumber[] = [];
  isEditDisable=false;
  selectable = false;
  removable = true;

  selectedTabIndex = 0;
  validationMessage: string;
  selectedOrganizations: string;
  orgId: number;
  orgName: string;
  isSaasFacility: boolean;
  allowEditOnServiceCategoryChange: boolean;
  displayNotation: boolean;

  routeParamSubscription: Subscription;
  public assignedUserInEntity: Users[];
  public usersCount: number;
  facilityForm: FormGroup;
  entityFacility: OrganizationFacility[];
  searchControl = new FormControl();
  @ViewChild('search', { static: false }) search: ElementRef;
  isEnabledApp: boolean;
  facilityContractApplications: App[];
  assignedFacilityContractApplications: FacilityContractApplication[];
  assignedApplicationList: any = [];
  assignedAssessmentTypeList: any = [];
  facilityContractAssessmentTypes: FacilityContractAssessmentType[];
  assessmentTypes: AssessmentType[];
  validateDuplicateFacility: ValidateDuplicateFacilityLOBRequest;
  updateFacilityLOB: FacilityLOB;
  objFacilityContractTeamMember: FacilityContractTeamMemberModel[];
  public usersInEntity: Users[];
  defaultValue: Array<string | number> = [];
  public organizationDetail: Organization;
  fiscalYearStartMonthList: FiscalYearStartMonth[] = [
    { monthId: -1, monthName: 'Unknown' },
    { monthId: 1, monthName: 'January' },
    { monthId: 2, monthName: 'February' },
    { monthId: 3, monthName: 'March' },
    { monthId: 4, monthName: 'April' },
    { monthId: 5, monthName: 'May' },
    { monthId: 6, monthName: 'June' },
    { monthId: 7, monthName: 'July' },
    { monthId: 8, monthName: 'August' },
    { monthId: 9, monthName: 'September' },
    { monthId: 10, monthName: 'October' },
    { monthId: 11, monthName: 'November' },
    { monthId: 12, monthName: 'December' },
  ];
  selectedMonth: number;
  stateList:State[] = [];
  facilityLOBDropdowns: FacilityLOBDropdowns;
  isAdmin = false;
  isLOBManager = false;
  isOrgAdmin = false;
  isUserManagement=false;
  minDateToFinishEndDate = new Subject<string>();
  minDateEndDate;
  minDateToFinishBaselineEndDate = new Subject<string>();
  minDateBaselineEndDate;

  maxDateToFinishLastDateOfService = new Subject<string>();
  maxDateLastDateOfService;
  anyTabDataChanged = false;
  public formResetter: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private snackBar: SnackBarModule,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private superAdminOrgCreateService: SuperAdminOrgCreateService,
    private facilityLOBService: FacilityLOBService,
    private adminContractService: AdminContractService,
    private router: Router,
    private dialog: MatDialog,
    private customErrorHandler: CustomErrorHandler,

  ) {
    this.minDateToFinishEndDate.subscribe(r => {
      this.minDateEndDate = new Date(r);
    });
    this.minDateToFinishBaselineEndDate.subscribe(r => {
      this.minDateBaselineEndDate = new Date(r);
    })
    this.maxDateToFinishLastDateOfService.subscribe(r => {
      this.maxDateLastDateOfService = new Date(r);
    });
   }

  ngOnInit() {
    this.isEdit = false;
    this.validationMessage = "";
    this.isFromParentFacility = false;
    this.isFromOrg = false;
    this.parentEntityId = 0;
    this.parentFacilityContractId = 0;
    this.typeOfSpinner = 'loading';
    this.allowEditOnServiceCategoryChange = true;
    this.displayNotation = false;
    this.isSaasFacility = false;

    this.facilityLOBDropdowns = new FacilityLOBDropdowns();
    this.isAdmin=this.appSecurityManagementService.getAdminMode();
    this.isLOBManager=this.appSecurityManagementService.getLOBManagerMode();
    this.isOrgAdmin=this.appSecurityManagementService.getOrganizationAdminMode();
    this.isUserManagement=this.appSecurityManagementService.getUserManagementMode();
    this.getAllStateList();

    this.routeParamSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.selectedTabIndex = 0;
        this.parentFacilityContractId = 0;
        this.selectedFacility = new Facility();
        this.isEdit =  JSON.parse(this.activatedRoute.queryParams["value"].isEdit);
        this.isFromOrg = JSON.parse(this.activatedRoute.queryParams["value"].isFromOrg);
        this.orgId = this.activatedRoute.queryParams["value"].orgId;
        this.orgName = this.activatedRoute.queryParams["value"].orgName;
        this.isFromParentFacility = this.activatedRoute.queryParams["value"].isFromParentFacility != undefined ? JSON.parse(this.activatedRoute.queryParams["value"].isFromParentFacility) : false;
        this.entityId = params.id;

        if(this.isEdit &&
          ((this.isOrgAdmin == true && this.isAdmin == false && this.isLOBManager == false && this.isUserManagement==false) ||
           (this.isOrgAdmin == false && this.isAdmin == false && this.isLOBManager == false && this.isUserManagement==true))
          ){
              this.initViewOnlyFormGroup();
          }
        else{
          this.initFormGroup();
        }

        this.populateDropdowns();

        if (this.isEdit) {
          this.facilityContractId = this.activatedRoute.queryParams["value"].facilityContractId;
          this.facilityId =this.activatedRoute.fragment["value"];
          this.getFacilityDetails();
        } else {
          this.getOrganizationDetail();
          this.facilityContractId = 0;
          this.facilityId = 0;
        }
      }

    );

    this.getFacilityContractApplications();
    this.getAssessmentDetails();
    this.typeOfSpinner = 'none';
  }

  dateChangeEffectiveDate(e) {
    this.minDateToFinishEndDate.next(e.value.toString());
  }
  dateChangeEndDate(e) {
    this.maxDateToFinishLastDateOfService.next(e.value.toString());
  }
  dateChangeBaselineStartDate(e) {
    this.minDateToFinishBaselineEndDate.next(e.value.toString());
  }
  getOrganizationDetail(): void {
    if (this.orgId != 0) {
      this.superAdminOrgCreateService
        .getOrganizationDetail(this.orgId).subscribe((organizationDetail) => {
          this.organizationDetail = organizationDetail;
          const selectedEntity = organizationDetail.entity;
          this.selectedFacility = new FacilityLOB();
          this.selectedFacility.facilityName = "";
          this.selectedFacility.address1 = this.organizationDetail.customer.address1;
          this.selectedFacility.city = this.organizationDetail.customer.city;
          this.selectedFacility.state = this.organizationDetail.customer.state?.trim();
          this.selectedFacility.zip = this.organizationDetail.customer.zip;
          this.selectedFacility.fiscalYearStartMonth = selectedEntity.fiscalYearStartMonth ?? -1;
          this.selectedFacility.isRestricted = this.organizationDetail.entity.isRestricted;

          this.facilityOverviewForm.patchValue(this.selectedFacility);

          this.typeOfSpinner = 'none';
        },
          (error: HttpErrorResponse) => {
            this.typeOfSpinner = 'none';
            this.customErrorHandler.handleError(error);
          });
    }

  }

  populateDropdowns(): void {
    this.facilityLOBService.getFacilityLOBDropdowns()
    .subscribe({
      next: facilityLOBDropdowns => {
        if (facilityLOBDropdowns != null) {
          this.facilityLOBDropdowns = facilityLOBDropdowns;
          this.facilityLOBDropdowns.lineOfBusinessTypeList.sort((a, b) => (a.dropDownId > b.dropDownId) ? 1 : -1);
          if(this.facilityLOBDropdowns.lineOfBusinessTypeList.length > 0 && !this.isEdit) {
            this.facilityOverviewForm.controls['lineOfBusinessId'].setValue(1);
          }

          if(this.isFromParentFacility) {
            this.isChildOfFacility = true;
            this.facilityOverviewForm.controls['lineOfBusinessId'].disable();
            this.parentFacilityContractId = this.activatedRoute.queryParams["value"].parentFacilityContractId != undefined ?  this.activatedRoute.queryParams["value"].parentFacilityContractId : 0;
            if(this.parentFacilityContractId  > 0) {
              this.getParentFacilityDetails();
            }
          } else {
            this.getServiceCategoryByLineOfBusiness(false);
            this.getServiceCategoryServiceProvided(false);
            this.getServiceCategoryServicesubCategory(false);
            this.getServiceCategoryServiceSubcategoryDivision(false);
          }
        }
      },
      error: error => {
        console.log(error);
      }
    });
  }

  getFacilityContractApplications(): void {
    this.typeOfSpinner = 'loading';
    this.adminContractService.getFacilityLOBApplications().subscribe(
      (facilityContractApplications) => {
        this.facilityContractApplications = facilityContractApplications.filter(i => i.isActive == true);
        this.getAssignedFacilityContractApplications();
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
      });
  }

  getAssignedFacilityContractApplications() {
    this.typeOfSpinner = 'loading';
    if(this.facilityContractId){
    this.adminContractService.getAssignedApplicationByFacilityContractId(this.facilityContractId)
      .subscribe(
        (data) => {
          this.assignedFacilityContractApplications = data;
          this.assignedFacilityContractApplications.forEach(element => {
            this.assignedApplicationList.push(element);
            if(this.facilityContractApplications.find(i => i.appId === element.appId)!=undefined)
            {
            this.facilityContractApplications.find(i => i.appId === element.appId).isSelected = true;
            }
          });

          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.customErrorHandler.handleError(error);
        });
    }
  }

  getAssessmentDetails(): void {
    this.typeOfSpinner = 'loading';
    this.appSecurityManagementService.getAssessmentType().subscribe(
      (data) => {
        this.assessmentTypes = data;
        if (this.facilityContractId && this.facilityContractId != 0) {
          this.getAssessmentTypeByFacilityContractId();
        }
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });
  }

  getAssessmentTypeByFacilityContractId() {
    this.typeOfSpinner = 'loading';
    this.adminContractService.getAssessmentTypeByFacilityContractId(this.facilityContractId)
      .subscribe(
        (data) => {
          this.facilityContractAssessmentTypes = data;
          this.facilityContractAssessmentTypes.forEach(element => {
            this.assignedAssessmentTypeList.push(element);
            if(this.assessmentTypes.find(i => i.assessmentTypeId === element.assessmentTypeId)!=undefined)
            {
            this.assessmentTypes.find(i => i.assessmentTypeId === element.assessmentTypeId).isSelected = true;
            }
          });

          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.customErrorHandler.handleError(error);
        });
  }

  getFacilityDetails(): void {
    this.facilityLOBService.getFacilityLOBDetails(this.facilityContractId)
    .subscribe({
      next: facilityDetails => {
        if (facilityDetails != null) {
          this.selectedFacility = facilityDetails;
          this.selectedFacility.facilityId = facilityDetails.facilityId;
          this.facilityId = facilityDetails.facilityId;
          this.selectedFacility.fiscalYearStartMonth = facilityDetails.fiscalYearStartMonth ?? -1;
          this.facilityOverviewForm.patchValue(this.selectedFacility);
          this.facilityName = facilityDetails.facilityName;
          this.isChildOfFacility = JSON.parse(facilityDetails.isChildOfFacility);

          this.deaNumberList = facilityDetails.facilityDEANumbers

          if(this.isChildOfFacility) {
            this.facilityOverviewForm.controls['lineOfBusinessId'].disable();
          }
          if(this.facilityOverviewForm.controls.startDate.value != "" ) {
            this.minDateEndDate = this.facilityOverviewForm.controls.startDate.value;
          }
          if(this.facilityOverviewForm.controls.endDate.value != "" ) {
            this.maxDateLastDateOfService = this.facilityOverviewForm.controls.endDate.value;
          }

          if(this.facilityOverviewForm.controls.baselineStartDate.value != "" ) {
            this.minDateBaselineEndDate = this.facilityOverviewForm.controls.baselineStartDate.value;
          }
          this.facilityOverviewForm.controls.purchasingVendorIDPrimary.patchValue(facilityDetails.purchasingVendorIDPrimary ?? 0);
          this.facilityOverviewForm.controls.purchasingVendorIDSecondary.patchValue(facilityDetails.purchasingVendorIDSecondary ?? 0);
          this.facilityOverviewForm.controls.groupPurchasingOrganizationID.patchValue(facilityDetails.groupPurchasingOrganizationID ?? "");
          this.facilityOverviewForm.controls.DEANumber.patchValue(facilityDetails.deaNumber);

          if (this.deaNumberList) {
            this.facilityOverviewForm.patchValue(this.deaNumberList);
            this.facilityOverviewForm.controls["deanumber"].clearValidators();
            this.facilityOverviewForm.get("deanumber").updateValueAndValidity();
          }
          this.facilityLOBService.allowDeleteFacilityLOBAsync(this.selectedFacility.entityId).subscribe(
            (response) => {
              if (response) {
                this.allowDelete = true;
              } else {
                this.allowDelete = false;
              }
            });
        }
      },
      error: error => {
        console.log(error);
      }
    });
  }

  getParentFacilityDetails(): void {
    this.facilityLOBService.getFacilityLOBDetails(this.parentFacilityContractId)
    .subscribe({
      next: facilityDetails => {
        if (facilityDetails != null) {
          this.selectedParentFacility = facilityDetails;
          this.parentEntityId = facilityDetails.entityId;
          if (!this.isEdit) {
            this.isChildOfFacility = true;
            this.selectedFacility = new FacilityLOB();
            this.selectedFacility.facilityName = "";
            this.selectedFacility.lineOfBusinessId = facilityDetails.lineOfBusinessId;
            this.selectedFacility.address1 = facilityDetails.address1;
            this.selectedFacility.city = facilityDetails.city;
            this.selectedFacility.state = facilityDetails.state.trim();
            this.selectedFacility.zip = facilityDetails.zip;
            this.selectedFacility.country = facilityDetails.country;
            this.selectedFacility.defaultPurchasingMetricId = facilityDetails.defaultPurchasingMetricId;
            this.selectedFacility.fiscalYearStartMonth = facilityDetails.fiscalYearStartMonth ?? -1;

            this.facilityOverviewForm.patchValue(this.selectedFacility);
            this.getServiceCategoryByLineOfBusiness(false);
            this.getServiceCategoryServiceProvided(false);
            this.getServiceCategoryServicesubCategory(false);
            this.getServiceCategoryServiceSubcategoryDivision(false);
          }
        }
      },
      error: error => {
        console.log(error);
      }
    });
  }

  navigateToParentPage() {
    if(this.isFromOrg && !this.isFromParentFacility) {
      this.router.navigate(['/', this.orgId ,'superadminorgcreate'], { fragment: this.orgName, queryParams:  {isEdit: true } });
    } else if(this.isFromParentFacility) {
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/', this.selectedParentFacility.entityId ,'addeditfacilitylob'], { fragment: this.selectedParentFacility.facilityId, queryParams:  {isEdit: true, isFromOrg : this.isFromOrg, orgName: this.orgName, orgId: this.orgId, isFromParentFacility: false, facilityContractId: this.selectedParentFacility.facilityContractId } });
    } else {
      this.router.navigate(['/viewadminfacilityLOBlist']);
    }
  }

  onPaste(event: ClipboardEvent) {
    this.facilityOverviewForm.controls['phone'].setValue('');
    const clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    pastedText = pastedText.replace(/-/g, '');
    const regexpNumber = new RegExp('^[+ 0-9]{10}$');
    if (regexpNumber.test(pastedText)) {
      this.facilityOverviewForm.controls['phone'].setValue(pastedText.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    } else {
      this.facilityOverviewForm.controls['phone'].setValue('');
      this.facilityOverviewForm.controls['phone'].markAsDirty();
      this.facilityOverviewForm.controls['phone'].markAsTouched();
    }
  }

  initFormGroup(): void {
    this.facilityOverviewForm = this.formBuilder.group({
      facilityName: ["", Validators.required],
      organization: [this.orgName],
      organizationId: [this.orgId],
      lineOfBusinessId: ["", Validators.required],
      unitNumber: ["", Validators.required],
      address1: ["", Validators.required],
      city: ["", Validators.required],
      country: [""],
      state: ["", Validators.required],
      zip: [""],
      phone: ["", Validators.pattern( /^\d{3}-\d{3}-\d{4}/)],
      entityTypeId: ["", Validators.required],
      defaultPurchasingMetricId: ["", Validators.required],
      fiscalYearStartMonth: [-1, Validators.required],
      salesforceLineofBusiness: [""],
      purchasingVendorIDPrimary: [""],
      purchasingVendorIDSecondary: [""],
      groupPurchasingOrganizationID: [""],
      DEANumber: [""],
      deaNumber: [""],
      deaNumberList: [new FormControl([])],
      serviceProvidedId: ["", Validators.required],
      serviceSubCategoryId: ["", Validators.required],
      serviceSubcategoryDivisionId: [""],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      baselineStartDate: [""],
      baselineEndDate: [""],
      autoRenewMonths: ["0"],
      lastDayOfService: [""],
      isActive: [true, Validators.required],
      isRestricted: [false],
      isExclusionDrugManaged: [false],
      serviceCategoryId: ["", Validators.required],
      exclusionDrugThreshold: ["0"],
      facilitySettingTypeId: ["", Validators.required],
      earlyAccess: [false],
    });
  }

  initViewOnlyFormGroup():void{
    this.facilityOverviewForm = this.formBuilder.group({
      facilityName: [{ value: "", disabled: true },Validators.required],
      organization: [{ value:this.orgName, disabled: true}],
      organizationId: [{ value:this.orgId, disabled: true}],
      lineOfBusinessId: [{ value:"", disabled: true}, Validators.required],
      unitNumber: [{ value:"", disabled: true}, Validators.required],
      address1: [{ value:"", disabled: true}, Validators.required],
      city: [{ value:"", disabled: true}, Validators.required],
      country: [{ value:"", disabled: true}],
      state: [{ value:"", disabled: true}, Validators.required],
      zip: [{ value:"", disabled: true}, Validators.required],
      phone: [{ value:"", disabled: true}],
      entityTypeId: [{ value:"", disabled: true}, Validators.required],
      defaultPurchasingMetricId: [{ value:"", disabled: true}, Validators.required],
      fiscalYearStartMonth: [{ value:-1, disabled: true}, Validators.required],
      salesforceLineofBusiness: [{ value:"", disabled: true}],
      purchasingVendorIDPrimary: [{ value:"", disabled: true}],
      purchasingVendorIDSecondary: [{ value:"", disabled: true}],
      groupPurchasingOrganizationID: [{ value:"", disabled: true}],
      DEANumber: [{ value:"", disabled: true}],
      deaNumber: [{ value:"", disabled: true}],
      deaNumberList: [new FormControl([])],
      serviceProvidedId: [{ value:"", disabled: true}, Validators.required],
      serviceSubCategoryId: [{ value:"", disabled: true}, Validators.required],
      serviceSubcategoryDivisionId: [{ value:"", disabled: true}],
      startDate: [{ value:"", disabled: true}, Validators.required],
      endDate: [{ value:"", disabled: true}, Validators.required],
      baselineStartDate: [{ value:"", disabled: true}],
      baselineEndDate: [{ value:"", disabled: true}],
      autoRenewMonths: [{ value:"0", disabled: true}],
      lastDayOfService: [{ value:"", disabled: true}],
      isActive: [{ value:true, disabled: true}, Validators.required],
      isRestricted: [{ value:false, disabled: true}],
      isExclusionDrugManaged: [{ value:false, disabled: true}],
      serviceCategoryId: [{ value:"", disabled: true}, Validators.required],
      exclusionDrugThreshold: [{ value:"0", disabled: true}],
      facilitySettingTypeId: [{ value:"", disabled: true}, Validators.required],
      earlyAccess: [{ value:false, disabled: true}],
  });
  this.facilityOverviewForm.disable();
  this.isViewOnly = true;
}

addDEANumber () {
  if (this.deaNumberList.length < 3)
  {
    const deanumber: any = this.facilityOverviewForm.controls['deaNumber'].value;
    const regularExpression = /^([a-zA-Z]{2}[0-9]{7})$/;
    if (!regularExpression.test(String(deanumber).toLowerCase())) {
      this.snackBar.exceptionMessage("Please enter valid DEA #.", ActionMessage.okAction);
      return false;
    }
      const isExistRecord = this.deaNumberList.find(i => i.deaNumber.toLowerCase() === deanumber.toLowerCase());
      if (isExistRecord == undefined || isExistRecord == null) {
      if (deanumber != undefined && deanumber != null && deanumber != '') {
      const objDEANumber = new FacilityDEANumber();
      objDEANumber.facilityDEANumberId = 0;
      objDEANumber.facilityId = 0;
      objDEANumber.deaNumber = deanumber;
      this.deaNumberList.push(objDEANumber);
      this.facilityOverviewForm.get('deaNumberList').setValue(this.deaNumberList);
      this.facilityOverviewForm.get('deaNumber').setValue('');
      this.assignedDEANumber = true;
      }
    } else {
      this.snackBar.exceptionMessage(DisplayMessage.duplicateRecordMessage, ActionMessage.okAction);
      this.facilityOverviewForm.get('deaNumber').setValue('');
      return false;
    }
  } else {
    this.snackBar.exceptionMessage('Max 3 DEA# allowed.', ActionMessage.okAction);
    this.facilityOverviewForm.get('deaNumber').setValue('');
    return false;
  }
}

showHideDEANumbers() {
  this.assignedDEANumber = true;
}

onRemoveClick(index: number) {
  this.openConfirmPopup(index);
}

 openConfirmPopup(index){
  const message = `Are you sure want to remove the DEA # from Assigned DEA list.`;
  const dialogData = new ConfirmDialogModel('Confirm Action', message);
  const dialogRef = this.dialog.open(ConfirmationDialogComponent, {

    data: dialogData,
    backdropClass: 'sm_panel-dialog'
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.deaNumberList.splice(index, 1);
      this.facilityOverviewForm.get('deaNumberList').setValue(this.deaNumberList);
    }
  });
}

openErrorDialog(deaNumber): void {
  const message = `Unable to Delete DEA # of [${deaNumber}] as a facility purchase history is assigned to the deaNumber.`;
  const dialogData = new ErrorDialogModel('DEA # Delete Alert' , message, "Cancel");
  const dialogRef = this.dialog.open(ErrorDialogComponent, {

    data: dialogData,
    backdropClass: 'sm_panel-dialog'
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      console.log(result);
    }
  });
}
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onSave(): void {
    const ASSESSMENT_APP_ID = 142
    this.facilityOverviewForm.markAllAsTouched();
    this.anyTabDataChanged = false;
    this.validationMessage = "";

    if (this.facilityOverviewForm.valid)
    {
      this.validateDuplicateFacility = new ValidateDuplicateFacilityLOBRequest();
      this.validateDuplicateFacility.facilityContractId = 0;
      this.validateDuplicateFacility.lineOfBusinessId = this.facilityOverviewForm.controls.lineOfBusinessId.value;
      this.validateDuplicateFacility.serviceCategoryId = this.facilityOverviewForm.controls.serviceCategoryId.value;
      this.validateDuplicateFacility.serviceSubCategoryId = this.facilityOverviewForm.controls.serviceSubCategoryId.value;
      this.validateDuplicateFacility.serviceProvidedId = this.facilityOverviewForm.controls.serviceProvidedId.value;
      this.validateDuplicateFacility.facilityName = this.facilityOverviewForm.controls.facilityName.value;
      this.validateDuplicateFacility.endDate = this.facilityOverviewForm.controls.endDate.value;

      this.facilityLOBService.isFacilityLOBExistsAsync(this.validateDuplicateFacility).subscribe(
        (validateResponse) => {
          if (validateResponse) {
              this.snackBar.exceptionMessage(DisplayMessage.facilityLOBAlreadyExistsMessage, ActionMessage.okAction);
              return false;
          } else {
            this.typeOfSpinner = 'loading';
            if(this.assignedApplicationList.find(function(l){ return l.appId === ASSESSMENT_APP_ID}) === undefined && this.assignedAssessmentTypeList.length !== 0){
              this.displayeConfirmationOnAssessmentsChange(false);
              this.typeOfSpinner = 'none';
            }else{
            this.saveFacilityLOBDetails();
            }
           }
        });
    } else {
      this.selectedTabIndex = 0;
      this.validationMessage = "Please enter the required fields."

      this.addValidationMessage();
    }
    this.typeOfSpinner = 'none';
  }

  onUpdate(): void {
    const ASSESSMENT_APP_ID = 142
    this.facilityOverviewForm.markAllAsTouched();
    this.anyTabDataChanged = false;
    this.validationMessage = "";
    if (this.facilityOverviewForm.valid || this.facilityOverviewForm.disabled == true)
    {
      this.validateDuplicateFacility = new ValidateDuplicateFacilityLOBRequest();
      this.validateDuplicateFacility.facilityContractId = this.facilityContractId;
      this.validateDuplicateFacility.lineOfBusinessId = this.facilityOverviewForm.controls.lineOfBusinessId.value;
      this.validateDuplicateFacility.serviceCategoryId = this.facilityOverviewForm.controls.serviceCategoryId.value;
      this.validateDuplicateFacility.serviceSubCategoryId = this.facilityOverviewForm.controls.serviceSubCategoryId.value;
      this.validateDuplicateFacility.serviceProvidedId = this.facilityOverviewForm.controls.serviceProvidedId.value;
      this.validateDuplicateFacility.facilityName = this.facilityOverviewForm.controls.facilityName.value;
      this.validateDuplicateFacility.endDate = this.facilityOverviewForm.controls.endDate.value;

      this.facilityLOBService.isFacilityLOBExistsAsync(this.validateDuplicateFacility).subscribe(
        (validateResponse) => {
          if (validateResponse) {
              this.snackBar.exceptionMessage(DisplayMessage.facilityLOBAlreadyExistsMessage, ActionMessage.okAction);
              return false;
          } else {
            this.typeOfSpinner = 'loading';
            if(!this.allowEditOnServiceCategoryChange && ((this.facilityOverviewForm.controls.serviceCategoryId.value != this.selectedFacility.serviceCategoryId) ||
              (this.facilityOverviewForm.controls.serviceSubCategoryId.value != this.selectedFacility.serviceSubCategoryId))) {
              this.displayeConfirmationOnServiceCaretoryChange();
              this.typeOfSpinner = 'none';
            } else {
              if(this.assignedApplicationList.find(function(l){ return l.appId === ASSESSMENT_APP_ID}) === undefined && this.assignedAssessmentTypeList.length !== 0){
                this.displayeConfirmationOnAssessmentsChange(true);
                this.typeOfSpinner = 'none';
              }else{
              this.updateFacilityLOBDetails();
            }
            this.allowEditOnServiceCategoryChange = true;
          }
    }});
    } else {
      this.selectedTabIndex = 0;
      this.validationMessage = "Please enter the required fields."
      this.addValidationMessage();

    }
    this.typeOfSpinner = 'none';
      return;
  }

  addValidationMessage(): void {
    const todayDate = new Date();
    todayDate.setHours(0,0,0,0);

    const startDate = (this.facilityOverviewForm.controls.startDate.value == null) ?  todayDate : new Date(this.facilityOverviewForm.controls.startDate.value);
    const endDate = (this.facilityOverviewForm.controls.endDate.value == null) ? todayDate : new Date(this.facilityOverviewForm.controls.endDate.value);
    const lastServiceDate = (this.facilityOverviewForm.controls.lastDayOfService.value == null) ?  todayDate : new Date(this.facilityOverviewForm.controls.lastDayOfService.value);

    if (startDate > lastServiceDate || endDate < lastServiceDate) {
      this.validationMessage = this.validationMessage + " The Last Day of Service must be between Effective Date and End Date."
    }
  }

  openDeleteDialog(): void {
    if (this.allowDelete) {
      const message = 'Are you sure you want to delete ' + this.selectedFacility.facilityName + ' ?  ';
      const dialogData = new ConfirmDialogModel('Delete' , message);

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        // width: '450px',
        data: dialogData,
        backdropClass: 'sm_panel-dialog'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
            this.deleteFacilityLOB();
        }
      });
    }
  }

  deleteFacilityLOB(): void {
    this.typeOfSpinner = 'loading';
    this.facilityLOBService.deleteFacilityContract(this.facilityContractId).subscribe(
      (updatedRows) => {
        if (updatedRows > 0) {
          this.typeOfSpinner = 'none';
          this.snackBar.successMessage(DisplayMessage.deleteMessage, ActionMessage.okAction);
          this.router.navigate(['/viewadminfacilityLOBlist']);
        }
      },
      (error: HttpErrorResponse) => {
        this.snackBar.errorMessage(error, ActionMessage.okAction);
      }
    );
  }

  saveFacilityLOBDetails(): void{

    this.fillUpdateFacilityLOB();

    const isValidDEANumber = this.isValidDEANumber();
    if(isValidDEANumber === false ) {
      this.snackBar.exceptionMessage(DisplayMessage.invalidDEANumberMessage, ActionMessage.okAction);
      this.facilityOverviewForm.controls.DEANumber.setErrors({'invalid':true});
      this.typeOfSpinner = 'none';
      return;
    } else {
      this.facilityOverviewForm.controls.DEANumber.setErrors({'invalid':false});
    }


    this.updateFacilityLOB.entityId =0;
    this.updateFacilityLOB.facilityContractId = 0;
    this.updateFacilityLOB.organizationId = this.orgId;
    this.updateFacilityLOB.parentEntityId = this.isFromParentFacility ? this.parentEntityId : this.orgId;
    this.updateFacilityLOB.facilityContractTeamMemberUser = this.objFacilityContractTeamMember;

    this.facilityLOBService.post(this.updateFacilityLOB).subscribe(
      (facilityContractId) => {
        if (!_.isNil(facilityContractId)) {
          this.typeOfSpinner = 'none';
          this.snackBar.successMessage(DisplayMessage.successMessage, ActionMessage.okAction);
          this.facilityContractId=parseInt(facilityContractId);
          this.isEdit=true;
          this.getFacilityDetails();
          this.formResetter.next(true);
          }
      }
    ), (error: HttpErrorResponse) => {
      this.snackBar.exceptionMessage(DisplayMessage.updateFacilityFailureMessage, ActionMessage.okAction);
    }
  }


  updateFacilityLOBDetails(): void {

    this.fillUpdateFacilityLOB();

    const isValidDEANumber = this.isValidDEANumber();
    if(isValidDEANumber === false ) {
      this.snackBar.exceptionMessage(DisplayMessage.invalidDEANumberMessage, ActionMessage.okAction);
      this.facilityOverviewForm.controls.DEANumber.setErrors({'invalid':true});
      this.typeOfSpinner = 'none';
      return;
    } else {
      this.facilityOverviewForm.controls.DEANumber.setErrors({'invalid':false});
    }

    this.updateFacilityLOB.entityId =0;
    this.updateFacilityLOB.organizationId = this.orgId;
    this.updateFacilityLOB.parentEntityId = this.selectedFacility.parentEntityId;
    this.updateFacilityLOB.facilityContractId = this.facilityContractId;

    this.facilityLOBService.put(this.facilityId, this.updateFacilityLOB)
    .subscribe({
      next: result => {
        this.typeOfSpinner = 'none';
        this.snackBar.successMessage(DisplayMessage.successMessage, ActionMessage.okAction);
        this.loadSavedFacilityLOB();
        this.formResetter.next(true);
      },
      error: error => {
        this.typeOfSpinner = 'none';
        this.snackBar.exceptionMessage(DisplayMessage.updateFacilityFailureMessage, ActionMessage.okAction);
      }
    });
  }

  isValidDEANumber(): boolean {
    const deaNumber = this.updateFacilityLOB.DEANumber;
    let isValid = true;

   const deaNumberValidString = /^([a-zA-Z]{2}[0-9]{7})$/;
    if(deaNumber == null || deaNumber.length === 0) {
      isValid = true;
    } else if(deaNumber.length < 9) {
      isValid = false;
    } else if(deaNumber.length === 9) {
      isValid = deaNumberValidString.test(deaNumber);
    } else if(deaNumber.length > 9) {
      const deaNumbers =  deaNumber.split(/[ ,]+/);
      deaNumbers.forEach(x => {
        if (isValid) {
          isValid = deaNumberValidString.test(x);
        }
      });
    }


    return isValid;
  }

  loadSavedFacilityLOB(){
    this.displayNotation = false;
    this.selectedTabIndex = 0;
    this.getFacilityDetails();
    this.getFacilityContractApplications();
    this.getAssessmentDetails();
  }

  displayeConfirmationOnServiceCaretoryChange(): any {
    const message = `You have made changes that could affect the default Assigned Applications and / or Assigned Assessment Types.
    <br/><br/>Select <b>Cancel</b> to review and adjust your Applications and/or Assessment Types. Select <b>Continue</b> to accept system default changes made. `;
    const dialogData = new ConfirmDialogModel('Confirmation', message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {

      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
       this.updateFacilityLOBDetails();
      }
    });
  }

  displayeConfirmationOnAssessmentsChange(UpdateFacility: boolean): any {
    const message = `The Assessments Application has not been selected, but Assessment Type have been assigned.
    <br/><br/>Click <b>Continue</b> to proceed, which will remove selected Assessment Types or <b>Cancel</b> to continue editing.`;
    const dialogData = new ConfirmDialogModel('Assessments Error', message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {

      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let index = null;
        this.assessmentTypes.forEach(element=>{
        if(this.assignedAssessmentTypeList.find(i => i.assessmentTypeId === element.assessmentTypeId)){
          this.assessmentTypes.find(i => i.assessmentTypeId === element.assessmentTypeId).isSelected = false;
        }
         index = this.assignedAssessmentTypeList.findIndex(i => i.assessmentTypeId === element.assessmentTypeId);
        this.assignedAssessmentTypeList.splice(index, 1);
        });

        if(UpdateFacility){
          this.updateFacilityLOBDetails();
          }else{
            this.saveFacilityLOBDetails();
          }
      }
    });
  }

  fillUpdateFacilityLOB(): void {
    this.updateFacilityLOB = new FacilityLOB();

    this.updateFacilityLOB.facilityName = this.facilityOverviewForm.controls.facilityName.value;
    this.updateFacilityLOB.organizationId =this.facilityOverviewForm.controls.organizationId.value;

    this.updateFacilityLOB.lineOfBusinessId =this.facilityOverviewForm.controls.lineOfBusinessId.value;
    this.updateFacilityLOB.unitNumber = this.facilityOverviewForm.controls.unitNumber.value;
    this.updateFacilityLOB.address1 = this.facilityOverviewForm.controls.address1.value;
    this.updateFacilityLOB.city = this.facilityOverviewForm.controls.city.value;
    this.updateFacilityLOB.state = this.facilityOverviewForm.controls.state.value;
    this.updateFacilityLOB.zip = this.facilityOverviewForm.controls.zip.value;
    this.updateFacilityLOB.country = this.facilityOverviewForm.controls.country.value;

    this.updateFacilityLOB.phone = this.facilityOverviewForm.controls.phone.value;
    this.updateFacilityLOB.entityTypeId =this.facilityOverviewForm.controls.entityTypeId.value;
    this.updateFacilityLOB.facilitySettingTypeId = this.facilityOverviewForm.controls.facilitySettingTypeId.value;
    this.updateFacilityLOB.defaultPurchasingMetricId =this.facilityOverviewForm.controls.defaultPurchasingMetricId.value;
    this.updateFacilityLOB.fiscalYearStartMonth = !this.facilityOverviewForm.controls.fiscalYearStartMonth.value  ? -1 : this.facilityOverviewForm.controls.fiscalYearStartMonth.value;
    this.updateFacilityLOB.salesforceLineofBusiness =this.facilityOverviewForm.controls.salesforceLineofBusiness.value;
    this.updateFacilityLOB.purchasingVendorIDPrimary = !this.facilityOverviewForm.controls.purchasingVendorIDPrimary.value  ? 0 : this.facilityOverviewForm.controls.purchasingVendorIDPrimary.value;
    this.updateFacilityLOB.purchasingVendorIDSecondary = !this.facilityOverviewForm.controls.purchasingVendorIDSecondary.value ? 0 : this.facilityOverviewForm.controls.purchasingVendorIDSecondary.value;
    this.updateFacilityLOB.DEANumber = this.facilityOverviewForm.controls.DEANumber.value == "" ? null : this.facilityOverviewForm.controls.DEANumber.value;
    this.updateFacilityLOB.groupPurchasingOrganizationID =  this.facilityOverviewForm.controls.groupPurchasingOrganizationID.value == "" ? null : this.facilityOverviewForm.controls.groupPurchasingOrganizationID.value;
    this.updateFacilityLOB.serviceProvidedId =this.facilityOverviewForm.controls.serviceProvidedId.value;
    this.updateFacilityLOB.serviceSubcategoryDivisionId =this.facilityOverviewForm.controls.serviceSubcategoryDivisionId.value == "" ? 0 : this.facilityOverviewForm.controls.serviceSubcategoryDivisionId.value;
    this.updateFacilityLOB.serviceSubCategoryId =this.facilityOverviewForm.controls.serviceSubCategoryId.value == "" ? 0 : this.facilityOverviewForm.controls.serviceSubCategoryId.value;
    this.updateFacilityLOB.serviceCategoryId =this.facilityOverviewForm.controls.serviceCategoryId.value == "" ? 0 : this.facilityOverviewForm.controls.serviceCategoryId.value;
    this.updateFacilityLOB.startDate = this.facilityOverviewForm.controls.startDate.value;
    this.updateFacilityLOB.endDate = this.facilityOverviewForm.controls.endDate.value;
    this.updateFacilityLOB.baselineStartDate = this.facilityOverviewForm.controls.baselineStartDate.value == "" ? null : this.facilityOverviewForm.controls.baselineStartDate.value;
    this.updateFacilityLOB.baselineEndDate = this.facilityOverviewForm.controls.baselineEndDate.value == "" ? null : this.facilityOverviewForm.controls.baselineEndDate.value;
    this.updateFacilityLOB.autoRenewMonths = this.facilityOverviewForm.controls.autoRenewMonths.value;
    this.updateFacilityLOB.lastDayOfService = this.facilityOverviewForm.controls.lastDayOfService.value == "" ? null : this.facilityOverviewForm.controls.lastDayOfService.value;

    this.updateFacilityLOB.isExclusionDrugManaged = this.facilityOverviewForm.controls.isExclusionDrugManaged.value;
    this.updateFacilityLOB.earlyAccess = this.facilityOverviewForm.controls.earlyAccess.value;
    this.updateFacilityLOB.isActive = this.facilityOverviewForm.controls.isActive.value;
    this.updateFacilityLOB.isRestricted = this.facilityOverviewForm.controls.isRestricted.value;
    this.updateFacilityLOB.exclusionDrugThreshold = this.facilityOverviewForm.controls.exclusionDrugThreshold.value;
    this.updateFacilityLOB.facilityContractApps = this.assignedApplicationList;
    this.updateFacilityLOB.facilityContractAssessmentTypes = this.assignedAssessmentTypeList;
    this.updateFacilityLOB.facilityContractTeamMemberUser = this.objFacilityContractTeamMember;
    this.updateFacilityLOB.facilityDEANumbers = this.deaNumberList;
  }

    selectedAssignApplication(event: MatCheckboxChange, application: any): void {
      this.anyTabDataChanged = true;
    if (event.source.checked) {
      const obj = {
        appId: application.appId,
        facilityContractId: this.facilityContractId,
        facilityContractAppId: 0,
      }
      if (this.assignedApplicationList) {
        if (this.assignedApplicationList.filter(i => i.appId == obj.appId).length == 0) {
          this.assignedApplicationList.push(obj);
        }
      } else {
        this.assignedApplicationList.push(obj);
      }
    } else {
      this.assignedApplicationList = this.assignedApplicationList.filter(i => i.appId != application.appId);
    }
  }

  selectedAssessmentType(event: MatCheckboxChange, assessmentType: any): void {
    this.anyTabDataChanged = true;
    if (event.source.checked) {
      const obj = {
        assessmentTypeId: assessmentType.assessmentTypeId,
        facilityContractId: this.facilityContractId,
        facilityContractAssessmentTypeId: 0,
      }
      if (this.assignedAssessmentTypeList) {
        if (this.assignedAssessmentTypeList.filter(i => i.assessmentTypeId == assessmentType.assessmentTypeId).length == 0) {
          this.assignedAssessmentTypeList.push(obj);
        }
      } else {
        this.assignedAssessmentTypeList.push(obj);
      }
    } else {
      this.assignedAssessmentTypeList = this.assignedAssessmentTypeList.filter(i => i.assessmentTypeId != assessmentType.assessmentTypeId);
    }
  }

  onCancelClick(): void {
    const message = DisplayMessage.facilityCancelWarningMessage;
    const dialogData = new ConfirmDialogModel('Facility', message);
    if(!this.facilityOverviewForm.touched && !this.anyTabDataChanged) {
      this.navigateToParentPage();
    } else{
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {

      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(this.isEdit && this.selectedTabIndex > 0 && this.selectedTabIndex !== 4) {
          this.facilityOverviewForm.markAsUntouched();
          this.navigateDefaultPage();
        } else {
          this.navigateToParentPage();
        }
      }
    });
  }
}

  navigateDefaultPage() {
    this.selectedTabIndex = 0;
    this.populateDropdowns();
    this.getFacilityDetails();
    this.getFacilityContractApplications();
    this.getAssessmentDetails();
   }

  changeParent() {
    const lineOfBusiness = this.facilityLOBDropdowns.lineOfBusinessTypeList.filter(i => i.dropDownId == this.selectedFacility.lineOfBusinessId);

    const dialogRef = this.dialog.open(ChangeParentEntityComponent, {
      data: {
        orgId: this.orgId,
        orgName: this.orgName,
        lineOfBusinessId:  this.selectedFacility.lineOfBusinessId,
        lineOfBusiness:  lineOfBusiness[0].dropDownName,
        parentEntityId: this.selectedFacility.parentEntityId,
        facilityContractId: this.facilityContractId,
        facilityId: this.facilityId,
        facilityName: this.facilityName,
        parentEntityName: this.selectedFacility.parentEntityTypeId == 1 ? "" : this.selectedFacility.parentEntityName
      },
        backdropClass: 'md_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
     if(result){
        if(result != null) {
          this.selectedFacility.parentEntityId = result;
        }
      }
    });
  }

  teamMember(teamMember: FacilityContractTeamMember[]) {
    this.anyTabDataChanged = true;
    this.objFacilityContractTeamMember =
      [] as FacilityContractTeamMemberModel[];
    teamMember.forEach((element) => {
      const objEntity = {} as FacilityContractTeamMemberModel;
      objEntity.facilityContractID = 0;
      objEntity.userID = element.userID;
      objEntity.salesforceCustomTeamRole = element.salesforceCustomTeamRole;
      this.objFacilityContractTeamMember.push(objEntity);
    });
  }

  onLineOfBusinessChange() {
    this.getServiceCategoryByLineOfBusiness(true);
  }

  onServiceCategoryChange(onChange:boolean) {
    this.allowEditOnServiceCategoryChange = false;
    this.displayNotation = true;
    this.getServiceCategoryServiceProvided(onChange);
    this.getServiceCategoryServicesubCategory(onChange);
    this.getServiceCategoryServiceSubcategoryDivision(onChange);
    if(!this.isEdit || onChange)
    {
    this.getServiceCategoryApplications();
    }
  }
  onServiceProvidedChange() {
      const serviceProvidedId=this.facilityOverviewForm.controls.serviceProvidedId.value;
      this.isSaasFacility = serviceProvidedId == ServiceProvidedIDs.Saas? true : false;
    }

  onServiceSubCategoryChange() {
    this.allowEditOnServiceCategoryChange = false;
    this.displayNotation = true;
    this.getServiceCategoryAssessmentTypes();
  }

  getServiceCategoryApplications() {
    const serviceCategoryId=this.facilityOverviewForm.controls.serviceCategoryId.value;
    this.appSecurityManagementService.getServiceCategoryAppList(serviceCategoryId)
      .subscribe(
        (data) => {
          this.assignedApplicationList = [];
          const appList = data;
          this.facilityContractApplications.forEach(element=>{
            if(appList.filter((x) => x.appID == element.appId).length > 0)
            {
              element.isSelected=true;
              const obj = {
                appId: element.appId,
                facilityContractId: this.facilityContractId,
                facilityContractAppId: 0,
              }
              this.assignedApplicationList.push(obj);
            }
            else{
              element.isSelected=false;
            }
            if(this.assignedFacilityContractApplications) {
              if(this.assignedFacilityContractApplications.filter((x) => x.appId == element.appId).length > 0)
              {
                element.isSavedInDB=true;
              } else {
                element.isSavedInDB=false;
              }
            }
          });
        },
        (error: HttpErrorResponse) => {
          this.customErrorHandler.handleError(error);
        });
  }

  getServiceCategoryAssessmentTypes() {
    const serviceCategoryId=this.facilityOverviewForm.controls.serviceCategoryId.value;
    const serviceSubCategoryId=this.facilityOverviewForm.controls.serviceSubCategoryId.value;
    this.appSecurityManagementService.getServiceCategoryAssessmentTypeList(serviceCategoryId,serviceSubCategoryId)
      .subscribe(
        (data) => {
          this.assignedAssessmentTypeList=[];
          const assessmentTypesList = data;
          this.assessmentTypes.forEach(element=>{
            if(assessmentTypesList.filter((x) => x.assessmentTypeID == element.assessmentTypeId).length > 0)
            {
              element.isSelected=true;
              const obj = {
                assessmentTypeId: element.assessmentTypeId,
                facilityContractId: this.facilityContractId,
                facilityContractAssessmentTypeId: 0,
              }
              this.assignedAssessmentTypeList.push(obj);
            }
            else{
              element.isSelected=false;
            }

            if(this.facilityContractAssessmentTypes){
              if(this.facilityContractAssessmentTypes.filter((x) => x.assessmentTypeId == element.assessmentTypeId).length > 0)
              {
                element.isSavedInDB=true;
              } else {
                element.isSavedInDB=false;
              }
            }
          });
        },
        (error: HttpErrorResponse) => {
          this.customErrorHandler.handleError(error);
        });
  }

  getServiceCategoryByLineOfBusiness(onChange:boolean) {
    const lineOfBusinessId=this.facilityOverviewForm.controls.lineOfBusinessId.value;

    if(lineOfBusinessId) {
      this.appSecurityManagementService.getServiceCategoryListByLineOfBusinessTypeId(lineOfBusinessId)
        .subscribe(
          (data) => {
            const serviceCategoryList=[] as Dropdown[];
            data.forEach(element=>{
            const obj = {
              dropDownId: element.serviceCategoryID,
              dropDownName: element.serviceCategoryName
            }
            serviceCategoryList.push(obj);

          });
            this.facilityLOBDropdowns.serviceCategoryList=serviceCategoryList;
            if(this.facilityLOBDropdowns.serviceCategoryList.length > 0 && (!this.isEdit || onChange)) {
              this.facilityOverviewForm.controls['serviceCategoryId'].setValue(this.facilityLOBDropdowns.serviceCategoryList[0].dropDownId);
              this.onServiceCategoryChange(onChange);
            }
            else if(this.isEdit)
            {
              const isExist=this.facilityLOBDropdowns.serviceCategoryList.filter(x=>x.dropDownId==this.facilityOverviewForm.controls.serviceCategoryId.value)
              if(isExist && isExist.length==0)
              {
              this.facilityOverviewForm.controls['serviceCategoryId'].setValue("");
              }
            }

          },
          (error: HttpErrorResponse) => {
            this.customErrorHandler.handleError(error);
          });
      }
  }


  getServiceCategoryServiceProvided(onChange:boolean) {
    const serviceCategoryId=this.facilityOverviewForm.controls.serviceCategoryId.value;
    if(serviceCategoryId) {
      this.appSecurityManagementService.getServiceCategoryServiceProvidedList(serviceCategoryId)
        .subscribe(
          (data) => {
            const serviceProvidedList=[] as Dropdown[];
            data.forEach(element=>{
            const obj = {
              dropDownId: element.serviceProvidedID,
              dropDownName: element.serviceProvidedName
            }
            serviceProvidedList.push(obj);
          });
          this.facilityLOBDropdowns.serviceProvidedList=serviceProvidedList;
          if((!this.isEdit|| onChange) && (this.facilityLOBDropdowns.serviceProvidedList.length > 0)) {
              this.facilityOverviewForm.controls['serviceProvidedId'].setValue(this.facilityLOBDropdowns.serviceProvidedList[0].dropDownId);
          }
          else if(this.isEdit)
          {
             const isExist=this.facilityLOBDropdowns.serviceProvidedList.filter(x=>x.dropDownId==this.facilityOverviewForm.controls.serviceProvidedId.value)
             if(isExist && isExist.length==0)
             {
             this.facilityOverviewForm.controls['serviceProvidedId'].setValue("");
             }
           }
           this.onServiceProvidedChange();
          },
          (error: HttpErrorResponse) => {
            this.customErrorHandler.handleError(error);
          });
      }
  }

  getServiceCategoryServicesubCategory(onChange:boolean) {
    const serviceCategoryId=this.facilityOverviewForm.controls.serviceCategoryId.value;
    if(serviceCategoryId) {
    this.appSecurityManagementService.getServiceCategoryServicesubCategoryList(serviceCategoryId)
      .subscribe(
        (data) => {
          const serviceSubCategoryList=[] as Dropdown[];
          data.forEach(element=>{
            const obj = {
              dropDownId: element.serviceSubcategoryID,
              dropDownName: element.serviceSubcategoryName
            }
            serviceSubCategoryList.push(obj);
          });
          this.facilityLOBDropdowns.serviceSubCategoryList=serviceSubCategoryList;
          if(!this.isEdit || onChange) {
             if(this.facilityLOBDropdowns.serviceSubCategoryList.length > 0) {
               this.facilityOverviewForm.controls['serviceSubCategoryId'].setValue(this.facilityLOBDropdowns.serviceSubCategoryList[0].dropDownId);
             }
             this.getServiceCategoryAssessmentTypes();
           }
           else if(this.isEdit)
           {
             const isExist=this.facilityLOBDropdowns.serviceSubCategoryList.filter(x=>x.dropDownId==this.facilityOverviewForm.controls.serviceSubCategoryId.value)
             if(isExist && isExist.length==0)
             {
             this.facilityOverviewForm.controls['serviceSubCategoryId'].setValue("");
             }
           }

        },
        (error: HttpErrorResponse) => {
          this.customErrorHandler.handleError(error);
        });
      }
  }

  getServiceCategoryServiceSubcategoryDivision(onChange:boolean) {
    const serviceCategoryId=this.facilityOverviewForm.controls.serviceCategoryId.value;
    if(serviceCategoryId) {
    this.appSecurityManagementService.getServiceCategoryServiceSubcategoryDivisionList(serviceCategoryId)
      .subscribe(
        (data) => {
          const serviceSubcategoryDivisionList=[] as Dropdown[];

          data.forEach(element=>{
            const obj = {
              dropDownId: element.serviceSubcategoryDivisionID,
              dropDownName: element.serviceSubcategoryDivisionName
            }
            serviceSubcategoryDivisionList.push(obj);
          });
          this.facilityLOBDropdowns.serviceSubcategoryDivisionList=serviceSubcategoryDivisionList;
          if(!this.isEdit || onChange) {
            if(this.facilityLOBDropdowns.serviceSubcategoryDivisionList.length > 0) {
              this.facilityOverviewForm.controls['serviceSubcategoryDivisionId'].setValue(this.facilityLOBDropdowns.serviceSubcategoryDivisionList[0].dropDownId);
            }
          }
        },
        (error: HttpErrorResponse) => {
          this.customErrorHandler.handleError(error);
        });
      }
  }

  getAllStateList() {
    this.appSecurityManagementService.getState().subscribe(data => {
      if(data) {
         this.stateList = data;
      }
    })
  }

  clearStartBaselineDate(event){
    event.stopPropagation();
    this.facilityOverviewForm.controls.baselineStartDate.setValue('');
  }

  clearEndBaselineDate(event){
    event.stopPropagation();
    this.facilityOverviewForm.controls.baselineEndDate.setValue('');
  }

  clearLastDayService(event){
    event.stopPropagation();
    this.facilityOverviewForm.controls.lastDayOfService.setValue('');
  }
}

