import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FacilityContract } from '../models/facility-contract';

@Injectable({
  providedIn: 'root'
})
export class ManageContactService {

  constructor(public httpClient: HttpClient) { }

  public getOrganizationContracts(customerId: number): Observable<Array<FacilityContract>> {
    return this.httpClient.get<Array<FacilityContract>>(`${environment.appConfig.apiServer.appSecService}OrganizationContractFacilities/${customerId}`)
      .pipe(
        map((data) => {
          data.sort((a, b) => {
            if (a.contractName === null) {
              return 1;
            }

            if (b.contractName === null) {
              return -1;
            }

            if (a.contractName === b.contractName) {
              return 0;
            }
            return a.contractName.trim().toLocaleLowerCase() < b.contractName.trim().toLocaleLowerCase() ? -1 : 1;
          });
          return data;
        })
      );
  }
}
