// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-logo {
  border: none;
  height: 150px;
}

.mat-mdc-card-header {
  background-color: #f8dac4 !important;
  padding-left: 0px !important;
}

.mat-mdc-card {
  padding: 0 !important;
}

.help-card-title {
  font-size: 16px;
  margin-bottom: 0px !important;
  padding: 10px 0;
}

.help-card-subtitle {
  font-size: 15px;
  padding-left: 15px;
}

.content-sec-wrap {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.page-name {
  color: #1f5488;
  font-weight: 500;
  font-size: 18px;
}

.help-docs-container {
  padding: 7px 30px 30px;
  position: relative;
  margin-left: 2px;
  max-width: 99%;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/help/help.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAAY,aAAA;AAEd;;AACA;EACE,oCAAA;EACA,4BAAA;AAEF;;AACA;EACE,qBAAA;AAEF;;AACA;EACE,eAAA;EACA,6BAAA;EACA,eAAA;AAEF;;AACA;EACE,eAAA;EACA,kBAAA;AAEF;;AACA;EACE,iBAAA;EACA,kBAAA;EACA,mBAAA;AAEF;;AACA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;AAEF;;AACA;EACE,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AAEF","sourcesContent":[".help-logo {\r\n  border:none;height:150px;\r\n}\r\n\r\n.mat-mdc-card-header {\r\n  background-color: #f8dac4 !important;\r\n  padding-left: 0px !important;\r\n}\r\n\r\n.mat-mdc-card {\r\n  padding:0 !important;\r\n}\r\n\r\n.help-card-title {\r\n  font-size: 16px;\r\n  margin-bottom: 0px !important;\r\n  padding: 10px 0;\r\n}\r\n\r\n.help-card-subtitle {\r\n  font-size: 15px;\r\n  padding-left: 15px;\r\n}\r\n\r\n.content-sec-wrap {\r\n  padding-top: 30px;\r\n  padding-left: 30px;\r\n  padding-right:30px;\r\n}\r\n\r\n.page-name {\r\n  color: #1f5488;\r\n  font-weight: 500;\r\n  font-size: 18px;\r\n}\r\n\r\n.help-docs-container{\r\n  padding: 7px 30px 30px;\r\n  position: relative;\r\n  margin-left: 2px;\r\n  max-width: 99%;\r\n  overflow-y: auto;\r\n}\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
