import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AutoCompleteDropDown } from '../auto-complete/models/auto-complete';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit, OnChanges {
  @Input() selectProperties: AutoCompleteDropDown;
  @Output() selectionChange = new EventEmitter<any>();

  keyUp = false;
  filterList = [];
  value: any;



  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.value = this.selectProperties.selectFormGroup.controls[this.selectProperties.formControlName].value;
      this.setItems('');
      setTimeout(() => {
        this.selectProperties.selectFormGroup.controls[this.selectProperties.formControlName].setValue(this.value);
      }, 0);
    }
  }

  ngOnInit(): void {
    if (this.selectProperties.items) {
      this.setItems('');
    }
  }

  keyUpFunction(event, value): boolean {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      return false;
    } else {
      if (value) {
        const data = this.filterList.filter(e =>
          e[this.selectProperties.controlName].toLowerCase() === value.toLowerCase());
        if (data.length > 0) {
          this.keyUp = true;
        } else {
          this.keyUp = true;
        }
      } else {
        this.keyUp = false;
      }
    }
  }

  get displayDataFn(): any {
    const dataNew = (data) => {
      if (data == null || data === undefined || data === '') {
        return null;
      } else {
        if (this.filterList && this.filterList.length > 0) {
          data = this.selectProperties.items.find(x => x[this.selectProperties.controlId] === data);
          if (data) {
            return data[this.selectProperties.controlName];
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    };
    return dataNew;
  }

  setItems(value): void {
    if (!value) { this.assignResourceCopy(); }
    if (value) {
      this.filterList = Object.assign([], this.selectProperties.items).filter(
        item => ((item[this.selectProperties.controlName].toLowerCase().indexOf(value.toLowerCase()) > -1)));
    }

  }

  assignResourceCopy(): void {
    this.filterList = Object.assign([], this.selectProperties.items);
  }

  checkValidValue(): void {
    const value = this.selectProperties.selectFormGroup.controls[this.selectProperties.formControlName].value;
    if (value === '' || value === null) {
      console.log(value);
    } else if (this.keyUp) {
      this.selectProperties.selectFormGroup.controls[this.selectProperties.formControlName].setErrors({ incorrect: true });
    } else {
      this.selectProperties.selectFormGroup.controls[this.selectProperties.formControlName].setErrors(null);
    }
  }

  checVal(value): void {
    const data: any = this.selectProperties.items.filter(e => e[this.selectProperties.controlName].toLowerCase().trim() ===
      value.toLowerCase().trim());
    if (data.length > 0) {
      const datax = data[0];
      this.selectProperties.selectFormGroup.controls[this.selectProperties.formControlName]
        .setValue(datax[this.selectProperties.controlId]);
      this.keyUp = false;
    }
    this.checkValidValue();
  }

  getId(event): void {
    this.selectionChange.emit(event.option);
  }
}
