/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  menuEnvironment:any

  setMenuEnvironment(data:any){
    this.menuEnvironment = data;
  }
}
