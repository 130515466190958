import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddFacility, Facility } from 'src/app/shared/models/facility.model';
import { environment } from 'src/environments/environment';
import { Assessment } from '../models/assessment';

@Injectable({
  providedIn: 'root'
})
export class FacilityDetailedService {

  constructor(private http: HttpClient) { }

  UpdateFacilityUserAssignment(facilityId: number, facility: AddFacility): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}facilityUserAssignment/${facilityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http.put<string>(apiurl,facility);
  }

}
