import { NgModule } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { environment} from 'src/environments/environment';

@NgModule({})

export class SnackBarModule {

  public readonly facilityWarning = 'Please choose a valid facility';

  constructor(public snackBar: MatSnackBar) {
  }

  successMessage(message: string, action = 'OK'): void {
    this.snackBar.open(message, action, {
      duration: environment.appConfig.snackBar.successDuration,
      panelClass: ['successsnackbar']
    });
  }

  warningMessage(message: string, action = 'OK'): void {
    this.snackBar.open(message, action, {
      duration: environment.appConfig.snackBar.warningDuration,
      panelClass: ['warningsnackbar']
    });
  }

  errorMessage(error: HttpErrorResponse, action = 'OK', customErrorMsg = ''): void {
    const errorMsg = customErrorMsg === '' ? this.getErrorMessage(error) : customErrorMsg;
    this.snackBar.open(errorMsg, action, {
      duration: environment.appConfig.snackBar.errorDuration,
      panelClass: ['errorsnackbar']
    });
  }

  errMessage(error: string, action = 'OK') {
    this.snackBar.open(error, action, {
       duration: environment.appConfig.snackBar.errorDuration,
       panelClass: ['errorsnackbar']
    });
  }

  showErrorMessage(error: HttpErrorResponse, action = 'OK', customErrorMsg = ''): void {
    const errorMsg = customErrorMsg === '' ? this.getErrorMessage(error) : customErrorMsg;
    this.snackBar.open(errorMsg, action, {
      duration: environment.appConfig.snackBar.errorDuration,
      panelClass: ['errorsnackbar']
    });
  }

  getErrorMessage(error): string {
    let errorMessage = '';
    if (error.errors !== undefined) {
      Object.keys(error.errors).forEach(key => {
        errorMessage = errorMessage.concat((error.errors[key][0]), '\n');
      });
    } else {
      errorMessage = error.message;
    }
    return errorMessage;
  }

  exceptionMessage(message: string, action = 'OK') {
    this.snackBar.open(message, action, {
    duration: environment.appConfig.snackBar.successDuration,
    panelClass: ['redsnackbar']
    });
    }

}
