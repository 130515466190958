// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-header {
  width: 50%;
  font-size: 10px;
}

.display-entity-header {
  width: 100%;
  font-size: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
:host::ng-deep .mat-select-placeholder {
  color: black;
}

.termdefinitionfontSize {
  font-size: small !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/search-auto-complete-filters/search-auto-complete-filters.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,eAAA;AACF;;AACA;EACE,WAAA;EACA,eAAA;AAEF;;AACA,6HAAA;AACA;EACE,YAAA;AAEF;;AACA;EACE,2BAAA;AAEF","sourcesContent":[".display-header {\r\n  width: 50%;\r\n  font-size: 10px;\r\n}\r\n.display-entity-header {\r\n  width: 100%;\r\n  font-size: 10px;\r\n}\r\n\r\n/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */\r\n:host::ng-deep .mat-select-placeholder {\r\n  color: black;\r\n}\r\n\r\n.termdefinitionfontSize{\r\n  font-size: small !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
