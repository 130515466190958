
<div id="divTermsAndCondition" style="padding-top: 20px;">
  <h2 class="page-title">Terms and Conditions</h2>
<div id="TermsAndConditionsContent" class="content">
<p>This Agreement is between Comprehensive Pharmacy Services, Inc. (hereinafter called "CPS") and you, the website user.</p>
<p>WHEREAS, CPS may disclose certain information to a Non-CPS Hospital Employee (CPS is not the hospital employee’s employer), which is of a proprietary and confidential nature, NOW, THEREFORE, in consideration of the foregoing and of the mutual covenants set forth herein, the parties agree as follows:</p>
<ol>
    <li><p>As used in this Agreement, the term "Confidential Information" shall mean all information disclosed by CPS to a Non-CPS Hospital Employee in connection with their duties, including, but not limited to, any information about prototype products, beta products, or any other products not commercially available such as information concerning construction, operation, or workflow.</p></li>
    <li><p>Confidential Information does not include information that: (i) is approved for release by the written authorization of CPS; (ii) Non-CPS Hospital Employee can show was already in its possession at the time of disclosure; (iii) is or becomes publicly available by other than unauthorized disclosure by Non-CPS Hospital Employee in violation hereof; (iv) is received by Non-CPS Hospital Employee from a third party who is rightfully in possession of such information free of any obligation to maintain its confidentiality; or (v) is independently developed by Non-CPS Hospital Employee without access to the Confidential Information.</p></li>
    <li><p>Non-CPS Hospital Employee agrees that, except as required by judicial order or governmental laws or regulations, Non-CPS Hospital Employee shall use the Confidential Information solely for the purpose of their duties as defined by CPS.  Non-CPS Hospital Employee agrees to hold the Confidential Information in confidence by security measures, devices and procedures equal to those used by it in securing its own confidential documents.  Non-CPS Hospital Employee further agrees that the Confidential Information shall be disclosed only to those individuals and agents of Non-CPS Hospital Employee (each a “Representative”) who need to know such Confidential Information for the sole purpose of their employment responsibilities and who have agreed in writing to keep such information confidential pursuant to an agreement substantially identical to this Agreement.  In any event, Non-CPS Hospital Employee shall be responsible for any breach of this Agreement by any of Non-CPS Hospital Employee] Representatives and Non-CPS Hospital Employee agrees, at its sole expense, to take all reasonable measures (including, but not limited to, court proceedings) to restrain its Representatives from prohibited or unauthorized disclosure or use of the Confidential Information.</p></li>
    <li><p>If Non-CPS Hospital Employee or any of its Representatives, or anyone to whom Non-CPS Hospital Employee transmits the Confidential Information, becomes legally obligated (by oral questions, interrogations, requests for information or documents, subpoena, investigative demand or similar process) to disclose any of the Confidential Information, Non-CPS Hospital Employee shall use its best efforts to provide CPS with prompt written notice so that CPS may seek a protective order or other appropriate remedy and/or waive compliance with the provisions of this Agreement.  If such protective order or other remedy is not obtained, or if CPS waives compliance with the provisions of this Agreement, Non-CPS Hospital Employee or its Representatives will furnish only that portion of the Confidential Information which it is legally required to disclose and will exercise its best efforts to obtain reliable assurance, to the extent that such assurance can be obtained, that confidential treatment will be accorded the Confidential Information.</p></li>
    <li><p>Non-CPS Hospital Employee acknowledges that any remedy at law for the breach or threatened breach of the provisions of this Agreement may be inadequate to fully and properly protect CPS and, therefore, the parties agree that CPS shall be entitled to injunctive relief in addition to other available remedies; provided, however, that nothing contained herein shall be construed as prohibiting CPS from pursuing any other remedies available in law or in equity for such breach or threatened breach.</p></li>
    <li><p>In the event that the work agreement is not consummated or upon CPS’ written request, Non-CPS Hospital Employee shall promptly return to CPS any and all Confidential Information in Non-CPS Hospital Employee’s possession, including all copies thereof or extracts therefrom held by Non-CPS Hospital Employee.</p></li>
    <li><p>This Agreement sets forth all of the promises, agreements, conditions and understandings between the parties respecting the subject matter hereof and supersedes all prior or contemporaneous negotiations, conversations, discussions and agreements, whether written or oral, between the parties concerning such subject matter.  This Agreement may not be amended, changed, modified or altered, except in written document signed by both parties.</p></li>
    <li><p>This Agreement shall be governed by the laws of the State of you are in without regard to its rules regarding conflicts of law.</p></li>
</ol>
<br />
<p>The Rx ResourceSolutions platform is support on the following browsers: Internet Explorer 7.0 and higher, Firefox 3.5.0 and higher.</p>
<a id="HIPAA_Cert_Link" href="{{termsAndConditions}}" target="HIPAA_Cert"><img src="../../../assets/images/HIPAA_Seal.jpg" alt="HIPAA Certified" class="terms-logo" /></a>
</div>
</div>
