export const WebPortalCode = 'RXRS';

export const successMsgs = {
  user_entiy_delete: 'User unassigned successfully.',
  user_entity_insert: 'User assigned successfully.',
  orgadmin_entity_insert: 'Org Admin assigned successfully.',
  orgadmin_entiy_delete: 'Org Admin unassigned successfully.',

  user_entiy_unassign: 'Entity unassigned successfully.',
  user_entity_assign: 'Entity assigned successfully.',
  contract_facility_assign: 'Facility assigned successfully.',
  contract_assign_module: 'Module assigned successfully.',
  securityGroup_assign: 'Security Group assigned successfully.',
};

export const errorMsgs = {
  user_in_securitygroup: 'User is part of Security Group.',
  user_entity_record_not_inserted: 'Record not inserted.',
  orgadmin_entity_record_not_inserted: 'Record not inserted.',
  something_went_wrong: 'something went wrong, please try again.',
  team_member_role_map: 'Select a role to assign.'
};

export const roleNames = {
  security_manager: 'SecurityManager',
  admin: 'Admin',
  organization_admin: 'OrganizationAdmin',
  manager: 'Manager',
  app_message_admin:'AppMessageAdmin',
  report_admin: 'CorporateReports',
  report_viewer: 'SettingsReports',
  can_print: 'CanPrint',
  can_export: 'CanExport',
  graphiteRx_DataMgmt: 'GraphiteRx DataMgmt',
  lob_manager: 'LOBMgr',
  left_menu_manager: 'LeftMenuMgr'
}

export const appRoleIDs = {
  admin: 234,
  manager: 235,
  organization_admin: 260,
  app_message_admin:261,
  security_manager: 259,
  user_management: 368,
  report_admin: 371,
  report_viewer: 372,
  can_print: 373,
  can_export: 374,
  graphiteRx_DataMgmt: 376,
  lob_mgr:380,
  lobmgrold:383,
  left_menu_mgr:379
}

export const Contract = {
  entityTypeID: 3
};

export const UserFilter = {
  active: 'Active',
  terminated: 'Terminated',
  all :'All'
}

export const ActiveFilter = {
  active: 'Active',
  inactive: 'Inactive',
  all: 'All'
}

export const organization = {
  userOrgLength: 6,
}

export const ServiceProvidedIDs = {
  Saas: 19,
}