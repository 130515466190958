import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { Customer, Organization, Entity, EntityDomain, OrgAdminUser, BusinessUser, RestrictedDomain } from './models/organization';
import { SuperAdminOrgCreateService } from './services/super-admin-org-create.service';
import _ from 'lodash';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DisplayMessage } from 'src/app/shared/components/message/display-message';
import { ActionMessage } from 'src/app/shared/components/message/action-message';
import { ConfirmationDialogComponent, ConfirmDialogModel } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Users } from 'src/app/shared/models/users.model';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { FiscalYearStartMonth } from 'src/app/shared/models/fiscal-year-start-month.model';
import { ValidationRequest } from 'src/app/components/super-admin-org-create/models/ValidationRequest';
import { ErrorDialogComponent, ErrorDialogModel } from 'src/app/shared/dialog/error-dialog/error-dialog.component';
import {  Subject } from "rxjs";
import { State } from 'src/app/shared/models/state.model';

@Component({
  selector: 'app-super-admin-org-create',
  templateUrl: './super-admin-org-create.component.html',
  styleUrls: ['./super-admin-org-create.component.scss']
})
export class SuperAdminOrgCreateComponent implements OnInit {
  addOrganizationForm: FormGroup;
  organization: Organization;
  allEntityDomainList: EntityDomain[] = [];
  restrictedDomainList: RestrictedDomain[] = [];
  private cpsDomainList: any = [];
  allowedDomains = false;
  public userSession: UserSession = new UserSession();
  domainList: EntityDomain[] = [];
  arrayOfDomain: any = [];
  selectable = false;
  removable = true;
  disableDropDown = false;
  public orgAdminUserList: OrgAdminUser[] = [];
  organizationName = '';
  salesforceAccountId = '';
  zip = '';
  city = '';
  address1 = '';
  entityID = '';
  selectedTabIndex = 0;
  validationMessage: string;
  isEditOrganization: boolean;
  allowDelete = false;
  public organizationDetail: Organization;
  public selectedEntity: Entity;
  public selectedCustomer: Customer;
  public selectedEntityDomains: EntityDomain[];
  public typeOfSpinner = 'loading';
  public orgId: number;
  public activeURLId: number;
  selectedOrganizationName: string;
  fiscalYearStartMonthList: FiscalYearStartMonth[] = [
    { monthId: -1, monthName: 'Unknown' },
    { monthId: 1, monthName: 'January' },
    { monthId: 2, monthName: 'February' },
    { monthId: 3, monthName: 'March' },
    { monthId: 4, monthName: 'April' },
    { monthId: 5, monthName: 'May' },
    { monthId: 6, monthName: 'June' },
    { monthId: 7, monthName: 'July' },
    { monthId: 8, monthName: 'August' },
    { monthId: 9, monthName: 'September' },
    { monthId: 10, monthName: 'October' },
    { monthId: 11, monthName: 'November' },
    { monthId: 12, monthName: 'December' },
  ];
  selectedMonth: number;
  fiscalYearStartMonth: number;
  validate = true;
  validationRequest:ValidationRequest;

  isAdmin=false;
  isLOBManager=false;
  isOrgAdmin=false;
  isEditDisable=false;
  organizationAdmins:Users[] = [];
  stateList:State[] = [];
  public formResetter: Subject<boolean> = new Subject<boolean>();
  isInternalUserOrgAdminView=false;

  constructor(private formBuilder: FormBuilder,
    private superAdminOrgCreateService: SuperAdminOrgCreateService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private snackBar: SnackBarModule,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private b2cService: B2cService,
    private customErrorHandler: CustomErrorHandler
  ) { }

  ngOnInit() {
    this.isEditOrganization = false;
    this.activeURLId = 0;
    this.getAlldomainList();
    this.getRestrictedDomainList();
    this.getAllStateList();
    this.getCpsDomainList();
    this.isAdmin=this.appSecurityManagementService.getAdminMode();
    this.isLOBManager=this.appSecurityManagementService.getLOBManagerMode();
    this.isOrgAdmin=this.appSecurityManagementService.getOrganizationAdminMode();
    this.isInternalUserOrgAdminView=this.appSecurityManagementService.getIsInternalUserOrgAdminView();
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.activeURLId = !_.isNil(this.activatedRoute.queryParams["value"].activeURLId) ? JSON.parse(this.activatedRoute.queryParams["value"].activeURLId) : null;
        this.orgId = params.id;
        this.organizationName = this.activatedRoute.fragment["value"];

        this.isEditOrganization = JSON.parse(this.activatedRoute.queryParams["value"].isEdit);
        if (this.isEditOrganization) {
          this.isEditDisable=this.appSecurityManagementService.checkOrganizationLOBEditStatus();
          if (this.isEditDisable==true || this.isInternalUserOrgAdminView === true) {
            this.initEditForm();
          } else {
            this.initForm();
            this.templateReset();
            this.superAdminOrgCreateService.validateOrgLOBDelete(this.orgId).subscribe((allowDel :boolean) => {
                if (allowDel) {
                  this.allowDelete = allowDel;
                }
              });
          }
          this.getOrganizationDetail();
          this.getOrganisationAdmins();
        }
        else{
          this.initForm();
        }
        this.selectedTabIndex = JSON.parse(this.activatedRoute.queryParams["value"].selectedTab);
      }
    );

  }

  initForm(): void {
    this.addOrganizationForm = this.formBuilder.group({
      entityName: ["", Validators.required],
      address1: [""],
      city: ["", Validators.required],
      state: ["", Validators.required],
      zip: [""],
      fiscalYearStartMonth: [new FormControl([]), Validators.required],
      domain: ["", Validators.required,],
      domainList: [new FormControl([])],
      isActive: [true],
      isRestricted: [false],
      salesforceAccountId: [""]
    });
    this.validationMessage = "";
    this.selectedMonth = 4;
    this.addOrganizationForm.get('state').enable();
    this.addOrganizationForm.get('fiscalYearStartMonth').enable();
  }

  initEditForm(): void {
    let domainDisable=true;
    if(this.isInternalUserOrgAdminView === true)
    {
      domainDisable=false;
    }
    this.addOrganizationForm = this.formBuilder.group({
      entityName: [{ value: "", disabled: true },Validators.required],
      address1: [{ value: "", disabled: true }],
      city: [{ value: "", disabled: true }, Validators.required],
      state: [new FormControl([{ disabled: true }]), Validators.required],
      zip: [{ value: "", disabled: true }],
      fiscalYearStartMonth: [new FormControl([{ disabled: true }]), Validators.required],
      domain: [{ value: "", disabled: domainDisable }, Validators.required,],
      domainList: [new FormControl([])],
      isActive: [{ value: true, disabled: true }],
      isRestricted: [{ value: false, disabled: true }],
      salesforceAccountId: [{ value: "", disabled: true }]
    });
    this.validationMessage = "";
    this.selectedMonth = 4;
    this.disableDropDown = true;
    this.addOrganizationForm.get('state').disable();
    this.addOrganizationForm.get('fiscalYearStartMonth').disable();
  }

  templateReset() {
    this.salesforceAccountId = "NA";
    this.zip = "NA";
    this.city = "NA";
    this.address1 = "NA";
    this.zip = "NA";
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  getOrganizationDetail(): void {
    if (this.orgId != 0) {
      this.superAdminOrgCreateService
        .getOrganizationDetail(this.orgId).subscribe((organizationDetail) => {
          this.organizationDetail = organizationDetail;
          this.selectedEntity = organizationDetail.entity;
          this.selectedCustomer = organizationDetail.customer;
          this.entityID = organizationDetail.entity.entityId.toString();
          this.selectedCustomer.state = organizationDetail.customer.state == null? organizationDetail.customer.state : organizationDetail.customer.state.trimEnd();
          this.selectedEntity.fiscalYearStartMonth = organizationDetail.entity.fiscalYearStartMonth??-1;
          this.domainList = organizationDetail.domain;
          this.selectedOrganizationName = organizationDetail.entity.entityName;
          this.addOrganizationForm.patchValue(this.selectedEntity);
          this.addOrganizationForm.patchValue(this.selectedCustomer);
          this.orgAdminUserList = organizationDetail.orgAdminList;
          if (this.domainList) {
            this.addOrganizationForm.patchValue(this.domainList);
            this.addOrganizationForm.controls["domain"].clearValidators();
            this.addOrganizationForm.get("domain").updateValueAndValidity();
          }
          this.typeOfSpinner = 'none';
        },
          (error: HttpErrorResponse) => {
            this.typeOfSpinner = 'none';
            this.customErrorHandler.handleError(error);
          });
    }

  }

  getAlldomainList() {
    this.superAdminOrgCreateService.GetDomainList().subscribe(data => {
      if (data) {
        this.allEntityDomainList = data;
      }
    },
      (error: HttpErrorResponse) => {
        this.snackBar.exceptionMessage(DisplayMessage.createFailureMessage("Something went wrong!."), ActionMessage.okAction);
      });
  }



  fillOrganizationSet(): void {
    this.organization = new Organization();

    const entity = new Entity();
    entity.entityName = this.addOrganizationForm.controls.entityName.value;
    entity.isActive = this.addOrganizationForm.controls.isActive.value;
    entity.isRestricted = this.addOrganizationForm.controls.isRestricted.value;
    entity.fiscalYearStartMonth = this.addOrganizationForm.controls.fiscalYearStartMonth.value;
    entity.salesforceAccountId = this.addOrganizationForm.controls.salesforceAccountId.value;
    entity.entityTypeId = 1;
    entity.parentEntityId = 502;
    this.organization.entity = entity;

    const customer = new Customer();
    customer.address1 = this.addOrganizationForm.controls.address1.value;
    customer.city = this.addOrganizationForm.controls.city.value;
    customer.state = this.addOrganizationForm.controls.state.value;
    customer.zip = this.addOrganizationForm.controls.zip.value;
    this.organization.customer = customer;
    this.organization.domain = this.domainList;
    this.organization.orgAdminList = this.orgAdminUserList;

    this.validationRequest = new ValidationRequest();
    this.validationRequest.entityId = 0;
    this.validationRequest.entityName = this.organization.entity.entityName;
    this.validationRequest.salesforceAccountId = this.organization.entity.salesforceAccountId;
  }


  fillEditOrganizationSet(): void {
    this.organization = new Organization();

    const entity = new Entity();
    entity.entityId = this.orgId;
    entity.entityName = this.addOrganizationForm.controls.entityName.value;
    entity.isActive = this.addOrganizationForm.controls.isActive.value;
    entity.isRestricted = this.addOrganizationForm.controls.isRestricted.value;
    entity.fiscalYearStartMonth = this.addOrganizationForm.controls.fiscalYearStartMonth.value;
    entity.salesforceAccountId = this.addOrganizationForm.controls.salesforceAccountId.value;
    this.organization.entity = entity;

    const customer = new Customer();
    customer.address1 = this.addOrganizationForm.controls.address1.value;
    customer.city = this.addOrganizationForm.controls.city.value;
    customer.state = this.addOrganizationForm.controls.state.value;
    customer.zip = this.addOrganizationForm.controls.zip.value;
    this.organization.customer = customer;
    this.organization.domain = this.domainList;
    this.organization.orgAdminList = this.orgAdminUserList;

    this.validationRequest = new ValidationRequest();
    this.validationRequest.entityId = this.orgId;
    this.validationRequest.entityName = this.organization.entity.entityName;
    this.validationRequest.salesforceAccountId = this.organization.entity.salesforceAccountId;
  }

  domainValidators() {
    if (this.domainList.length <= 0) {
      this.addOrganizationForm.markAllAsTouched();
      this.addOrganizationForm.controls['domain'].setValidators([Validators.required]);
    }
    else {
      this.addOrganizationForm.controls["domain"].clearValidators();
    }
    this.addOrganizationForm.get("domain").updateValueAndValidity();
  }

  orgAdminUserEntitySecurityGroup(userEntitySecurityGroup: Users[]) {
    this.orgAdminUserList = [];
    userEntitySecurityGroup.forEach(element => {
      const objEntity = {
        userId: element.userId,
        entityId: 0,
        onPremisesSecurityIdentifier: element.onPremisesSecurityIdentifier
      };
      this.orgAdminUserList.push(objEntity);
    });

  }

  findEvent() {
    this.isEditOrganization ? this.updtateOrganization() : this.addOrganization();
  }

  updtateOrganization() {
    this.typeOfSpinner = 'loading';
    this.addOrganizationForm.markAllAsTouched();
    if (this.addOrganizationForm.valid) {
      this.fillEditOrganizationSet();
      this.superAdminOrgCreateService.validateOrganization(this.validationRequest).subscribe(
        (validateResponse) => {
          if (!validateResponse.isValid){
              this.snackBar.exceptionMessage(DisplayMessage.alreadyExists(validateResponse.message), ActionMessage.okAction);
              return false;
            } else {
            this.superAdminOrgCreateService.updateOrganization(this.orgId, this.organization).subscribe(
              (entityid) => {
                if (!_.isNil(entityid)) {
                  this.typeOfSpinner = 'none';
                  this.selectedTabIndex = 0;
                  this.snackBar.successMessage('The record has been updated');
                }
              }), (error: HttpErrorResponse) => {
                this.showSnackBarMessageError(error);
              }
          }
        }), (error: HttpErrorResponse) => {
          this.showSnackBarMessageError(error);
        }
    } else {
      this.selectedTabIndex = 0;
      this.validationMessage = "Please enter the required fields."
    }
  }

  addOrganization(): void {
    this.typeOfSpinner = 'loading';
    this.addOrganizationForm.markAllAsTouched();
    if (this.addOrganizationForm.valid) {
      this.fillOrganizationSet();
      this.superAdminOrgCreateService.validateOrganization(this.validationRequest).subscribe(
        (validateResponse) => {
          if (!validateResponse.isValid){
            let message = 'Organization';
            if(this.validationRequest.salesforceAccountId ==validateResponse.message){
              message = 'Salesforce Account Number';
            }
            this.snackBar.exceptionMessage(`${message} ${validateResponse.message} already exists on our site`, ActionMessage.okAction);
            return false;
            }
          else {
            this.superAdminOrgCreateService.addOrganization(this.organization).subscribe(
              (entityid) => {
                if (!_.isNil(entityid)) {
                  this.typeOfSpinner = 'none';
                  this.snackBar.successMessage('The record has been saved');
                   this.router.navigate(['/', entityid ,'superadminorgcreate'],
                  { fragment: this.organization.entity.entityName, queryParams:  {isEdit: true } });
                }
              }
            ), (error: HttpErrorResponse) => {
              this.showSnackBarMessageError(error);
            }
          }
        }
      ), (error: HttpErrorResponse) => {
        this.showSnackBarMessageError(error);
      }
    } else {
      this.selectedTabIndex = 0;
      this.validationMessage = "Please enter the required fields."
    }
  }

  cancelClick(): void {
    if(!this.isEditOrganization){
      this.domainList = [];
    }

    if (this.selectedTabIndex == 0) {

      this.router.navigate(['viewadminorganizationsLOB']);
    } else {
      this.selectedTabIndex = 0;
      this.ngOnInit();
      this.resetChildForm();
    }
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  resetForm(): void {
    if (!_.isNil(this.addOrganizationForm)) {
      this.initForm();
    }
  }

  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  addDomain() {
    const domain: any = this.addOrganizationForm.controls['domain'].value;
    const regularExpression = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regularExpression.test(String(domain).toLowerCase())) {
      this.snackBar.exceptionMessage("Please enter valid domain.", ActionMessage.okAction);
      return false;
    }
    const isExistRestrictedDomain = this.restrictedDomainList.find(i => i.domain.toLowerCase() === domain.toLowerCase());
    const isCpsDomain = this.cpsDomainList.find(i => i.domainName.toLowerCase() === domain.toLowerCase());
    let isExistAllDomain = this.allEntityDomainList.find(i => i.domain.toLowerCase() === domain.toLowerCase());
    const isExistRecord = this.domainList.find(i => i.domain.toLowerCase() === domain.toLowerCase());
    if (isCpsDomain){
      isExistAllDomain = undefined;
    }
    if (isExistRestrictedDomain == undefined || isExistRestrictedDomain == null) {
      if (isExistAllDomain == undefined || isExistAllDomain == null) {
        if (isExistRecord == undefined || isExistRecord == null) {
          if (domain != undefined && domain != null && domain != '') {
            const objDomain = new EntityDomain();
            objDomain.entityDomainId = 0;
            objDomain.entityId = 0;
            objDomain.domain = domain;
            this.domainList.push(objDomain);
            this.addOrganizationForm.get('domainList').setValue(this.domainList);
            this.addOrganizationForm.get('domain').setValue('');
            this.allowedDomains = true;
          }
        } else {
          this.snackBar.exceptionMessage(DisplayMessage.duplicateRecordMessage, ActionMessage.okAction);
          this.addOrganizationForm.get('domain').setValue('');
          return false;
        }
      } else {
        this.snackBar.exceptionMessage(DisplayMessage.domainExistMessage(isExistAllDomain.entityName), ActionMessage.okAction);
        return false;
      }
    } else {
      this.snackBar.exceptionMessage(DisplayMessage.restrictedDomainMessage(isExistRestrictedDomain.domain), ActionMessage.okAction);
      return false;
    }
    this.addOrganizationForm.controls["domain"].clearValidators();
    this.addOrganizationForm.get("domain").updateValueAndValidity();
  }

  createItem(domain: any) {
    return this.formBuilder.group({
      domain: [domain.domain],
      entityDomainId: [domain.entityDomainId],
      entityId: [domain.entityId]
    });
  }

  showHideDomains() {
    this.allowedDomains = true;
  }
  onRemoveClick(index: number) {
     let goodToClose = true;
     const selectedDomain = this.domainList[index];
    if(selectedDomain &&  this.organizationAdmins?.filter( admin => admin?.email?.toLowerCase()?.endsWith(selectedDomain.domain?.toLowerCase()))?.length > 0){
      goodToClose = false;
      this.openErrorDialog(selectedDomain.domain);
    }
    if(goodToClose){
       this.openConfirmPopup(index);
    }
   }

  getRestrictedDomainList() {
    this.superAdminOrgCreateService.GetRestrictedDomainList().subscribe(data => {
      if (data) {
        this.restrictedDomainList = data;
      }
    });
  }

  openConfirmPopup(index){
    const message = `Are you sure want to remove the domain from Allowed Domain list.`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // width: '450px',
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.domainList.splice(index, 1);
        this.addOrganizationForm.get('domainList').setValue(this.domainList);
      }
      if (this.domainList.length <= 0) {
        this.addOrganizationForm.controls['domain'].setValidators([Validators.required]);
        this.addOrganizationForm.markAllAsTouched();
        this.addOrganizationForm.get("domain").updateValueAndValidity();
      }
    });
  }

  openErrorDialog(domain): void {
    const message = `Unable to Delete Domain of [${domain}] as there are users assigned to the domain.`;
    const dialogData = new ErrorDialogModel('Domain Delete Alert' , message, "Cancel");
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      // width: '450px',
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
      }
    });
  }

  getOrganisationAdmins(){
    this.appSecurityManagementService.getOrganizationAdminsWithFacility(this.orgId.toString()).subscribe(
      (organizationAdmins) => {
       this.organizationAdmins = organizationAdmins;
      },
      (error: HttpErrorResponse) => {
        this.customErrorHandler.handleError(error);
      });
  }

  getAllStateList() {
    this.appSecurityManagementService.getState().subscribe(data => {
      if(data) {
         this.stateList = data;
      }
    })
  }

  resetChildForm() {
    this.formResetter.next(true);
 }

 getCpsDomainList(){
  this.superAdminOrgCreateService.GetCpsDomainList().subscribe(data => {
    if(data) {
      this.cpsDomainList = data;
    }
  });
}

openDeleteDialog(): void {
  if (this.allowDelete) {
    const message = 'Are you sure you want to delete ' + this.organizationName + ' ?  ';
    const dialogData = new ConfirmDialogModel('Delete' , message);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // width: '450px',
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          this.deleteOrganizationLOB();
      }
    });
  }
}

deleteOrganizationLOB(){
  this.typeOfSpinner = 'loading';
  this.superAdminOrgCreateService.deleteOrganization(this.orgId).subscribe(updatedRows => {
    if (updatedRows > 0) {
      this.typeOfSpinner = 'none';
      this.snackBar.successMessage('The Organization has been deleted');
      this.router.navigate(['viewadminorganizationsLOB']);
    }
  }), (error: HttpErrorResponse) => {
    this.showSnackBarMessageError(error);
  }
}

onFacilityLOBDeleteEvent(isDeleted: boolean){
  if(isDeleted)
  {
    this.superAdminOrgCreateService.validateOrgLOBDelete(this.orgId).subscribe((allowDel :boolean) => {
      if (allowDel) {
        this.allowDelete = allowDel;
      }
    });
  }
}

}
