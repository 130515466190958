import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './shared/modules/material/material.module';
import { Error404PageComponent } from './shared/components/error404-page/error404-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { ConfirmationDialogComponent } from './shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { ReadMoreComponent } from './shared/components/read-more/read-more.component';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { environment } from '../environments/environment';
import { SnackBarModule } from './shared/modules/snackbar/snack-bar.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './components/home/home.component';
import { SharedUserAppDetailService } from './shared/services/shared-user-app-detail.service';
import { UserDetailsDialogComponent } from './components/user-details-dialog/user-details-dialog.component';
import { ApprolesUserDialogComponent } from './components/approles-user-dialog/approles-user-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import { LogoutComponent } from './components/logout/logout.component';
import { HelpComponent } from './shared/components/help/help.component';
import { OverviewComponent } from './components/overview/overview.component';
import { AppRolePanelComponent } from './components/app-role-panel/app-role-panel.component';
import { AppUsersListComponent } from './components/app-users-list/app-users-list.component';
import { AppRolesComponent } from './components/app-roles/app-roles.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import {
  CPSLibModule,
  TestLibService,
  CPSLibAppComponent,
  LocalApplicationStorageService
} from 'cps-b2clibrary';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { OrgManagerHomeComponent } from './components/org-manager-home/org-manager-home.component';
import { ViewFacilityComponent } from './components/view-facility/view-facility.component';
import { AssignApplicationComponent } from './components/assign-application/assign-application.component';
import { ChangeOrgComponent } from './components/change-org/change-org.component';
import { VerifyUserComponent } from './components/verify-user/verify-user.component';
import { CustomErrorHandler } from './shared/utils/custom-error-handler';
import { OrgAdminUserApplicationsComponent } from './components/org-admin-user-applications/org-admin-user-applications.component';
import { ViewTokenComponent } from './components/view-accesstoken/view-accesstoken.component';
import { SearchAutoCompleteFiltersComponent } from './shared/components/search-auto-complete-filters/search-auto-complete-filters.component';
import { AutoCompleteComponent } from './shared/components/auto-complete/auto-complete.component';
import { NoOrganizationComponent } from './components/no-organization/no-organization.component';
import { TerminateUserDialogComponent } from './components/terminate-user-dialog/terminate-user-dialog.component';
import { InstructionsDialogComponent } from './components/app-message-instruction-dialog/app-message-instruction-dialog.component';
import { ContactDetailsDialogComponent } from './components/contact-details-dialog/contact-details-dialog.component';
import { OrganizationContractComponent } from './components/organization-contract/organization-contract.component';
import { OrganizationContractListComponent } from './components/organization-contract-list/organization-contract-list.component';
import { AddUpdateContractComponent } from './components/add-update-contract/add-update-contract.component';
import { OrganizationContractDialogComponent } from './components/organization-contract-dialog/organization-contract-dialog.component';
import { OrganizationOrgadminDeleteDialogComponent } from './components/organization-orgadmin-delete-dialog/organization-orgadmin-delete-dialog.component';
import { OrganizationAssignComponent } from './components/organization-assign/organization-assign.component';
import { OrganizationDomainListDialogComponent } from './components/organization-domain-list-dialog/organization-domain-list-dialog.component';
import { AssignUserPermissionsComponent } from './components/assign-user-permissions/assign-user-permissions.component';
import { ErrorDialogComponent } from './shared/dialog/error-dialog/error-dialog.component';
import { UserUnassignEntityDialogComponent } from './components/user-unassign-entity-dialog/user-unassign-entity-dialog.component';
import { OrgadminUserComponent } from './components/orgadmin-user/orgadmin-user.component';
import { ContractFacilityAssignComponent } from './components/view-admin-contracts/contract-facility-assign/contract-facility-assign.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChildEntityComponent } from './components/child-entity/child-entity.component';
import { ContractAssignedModulesComponent } from './components/view-admin-contracts/contract-assigned-modules/contract-assigned-modules.component';
import { ApproleModuleAppRolesComponent } from './components/approle-module-app-roles/approle-module-app-roles.component';
import { InheritedEntityComponent } from './components/inherited-entity/inherited-entity.component';
import { AppUserRoleAssignComponent } from './components/app-user-role-assign/app-user-role-assign.component';
import { DescriptionDialogComponent } from './shared/dialog/description-dialog/description-dialog.component';
import { FacilityContractComponent } from './components/facility-contract/facility-contract.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { FacilityDetailsDialogComponent } from './components/facility-details-dialog/facility-details-dialog.component';
import { FacilityAssignedUsersComponent } from './components/facility-assigned-users/facility-assigned-users.component';
import { SettingsComponent } from './components/settings/settings.component';
import { FacilityAssignUserComponent } from './components/facility-assign-user/facility-assign-user.component';
import { FacilityUserDeleteDialogComponent } from './components/facility-user-delete-dialog/facility-user-delete-dialog.component';
import { MenuModule } from 'cps-menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserAssignSecuityGroupDialogComponent } from './components/user-assign-security-group-dialog/user-assign-security-group-dialog.component';
import { ViewFacilityContractsComponent } from './components/view-facility-contracts/view-facility-contracts.component';
import { ApprolesSecuritygroupDialogComponent } from './components/approles-securitygroup-dialog/approles-securitygroup-dialog.component';
import { SecurityGroupListComponent } from './components/security-group/security-group-list/security-group-list.component';
import { CdkDetailRowDirective } from './cdk-detail-row.directive';
import { PhoneMaskDirective } from './shared/directives/phone-mask.directive';
import { GraphiterxComponent } from './graphiterx/graphiterx.component';
import { GrxvendorreconcilDialogComponent } from './shared/dialog/grxvendorreconcil-dialog/grxvendorreconcil-dialog.component';
import { GrxentityreconcilDialogComponent } from './shared/dialog/grxentityreconcil-dialog/grxentityreconcil-dialog.component';
import { NgxPermissionsModule, NgxPermissionsService} from 'ngx-permissions';
import { RolePermissionsService } from './shared/services/role-permissions.service';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { ViewPermissionMapEntitiesDialogComponent } from './components/view-permission-map-entities-dialog/view-permission-map-entities-dialog.component';
import { OrganizationOrgadminUserDialogComponent } from './components/organization-orgadmin-user-dialog/organization-orgadmin-user-dialog.component'
import { FacilityLOBAssignTeamMemberComponent } from './components/facility-lob-assign-team-member/facility-lob-assign-team-member.component';
import { UserDeniedEntityDialogComponent } from './components/user-denied-entity-dialog/user-denied-entity-dialog.component';
import { SearchDropDownComponent } from './components/search-drop-down/search-drop-down.component';
import { ViewAdminFacilityLobDialogComponent } from './components/view-admin-facility-lob-dialog/view-admin-facility-lob-dialog.component';
import { ViewLeftMenuLinkItemsComponent} from './components/view-leftmenu-linkitems/view-leftmenu-linkitems.component';
import { AddEditLeftMenuLinkItemsComponent } from "./components/add-edit-leftmenu-linkItems/add-edit-leftmenu-linkItems.component";
import { ChildFacilityLobComponent } from './components/child-facility-lob/child-facility-lob.component';
import { ChangeParentEntityComponent } from './components/change-parent-entity/change-parent-entity.component';
import { ViewAdminFacilityUserAssignmentComponent } from './components/view-admin-facility-user-assignment/view-admin-facility-user-assignment.component';
import { DetailedAdminFacilityUserAssignmentComponent } from './components/detailed-admin-facility-user-assignment/detailed-admin-facility-user-assignment.component';
import { ViewAdminFacilityLOBComponent } from './components/view-admin-facility-lob/view-admin-facility-lob.component';
import { AddEditFacilityLOBComponent } from './components/add-edit-facility-lob/add-edit-facility-lob.component';
import { AdminFacilityLOBAssignedTeamMembersComponent } from './components/admin-facility-lob-assigned-team-members/admin-facility-lob-assigned-team-members.component';
import { AssignOrgFacilityComponent } from './components/assign-org-facility/assign-org-facility.component';
import { OrganizationsLOBComponent } from './components/organizations-lob/organizations-lob.component';
import { AdminFacilityAssignedContractsComponent } from './components/admin-facility-assigned-contracts/admin-facility-assigned-contracts.component';
import { AdminFacilityAssignedUsersComponent } from './components/admin-facility-assigned-users/admin-facility-assigned-users.component';
import { SuperAdminOrgCreateComponent } from './components/super-admin-org-create/super-admin-org-create.component';
import { AddOrganizationOrgadminUserComponent } from './components/add-organization-orgadmin-user/add-organization-orgadmin-user.component';
import { FacilityLOBListComponent } from './components/facility-loblist/facility-loblist.component';
import { ViewOrgAdminFacilityLOBComponent } from './components/view-org-admin-facility-lob/view-org-admin-facility-lob.component';
import { ChildOrgAdminFacilityLOBComponent } from './components/child-org-admin-facility-lob/child-org-admin-facility-lob.component';
import { ChildTreeOrgAdminFacilityLOBComponent } from './components/child-tree-org-admin-facility-lob/child-tree-org-admin-facility-lob.component';

export const permissionsFactory =(rolePermissions:RolePermissionsService,ngxPermissions:NgxPermissionsService) =>{
  return ()=>{
    ngxPermissions.flushPermissions()
    ngxPermissions.loadPermissions(rolePermissions.loadPermissionsRole())
    return true;
  }
}
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({ declarations: [
        AppComponent,
        AddUpdateContractComponent,
        ApproleModuleAppRolesComponent,
        AppRolePanelComponent,
        AppRolesComponent,
        ApprolesUserDialogComponent,
        AppUsersListComponent,
        AssignApplicationComponent,
        AssignUserPermissionsComponent,
        AutoCompleteComponent,
        ChangeOrgComponent,
        ChildEntityComponent,
        ConfirmationDialogComponent,
        ContactDetailsDialogComponent,
        ContractAssignedModulesComponent,
        ContractFacilityAssignComponent,
        Error404PageComponent,
        ErrorDialogComponent,
        FacilityDetailsDialogComponent,
        DescriptionDialogComponent,
        FooterComponent,
        HeaderComponent,
        HelpComponent,
        HomeComponent,
        InheritedEntityComponent,
        InstructionsDialogComponent,
        LogoutComponent,
        NoOrganizationComponent,
        NoOrganizationComponent,
        OrgAdminUserApplicationsComponent,
        OrgadminUserComponent,
        OrganizationAssignComponent,
        OrganizationContractComponent,
        OrganizationContractDialogComponent,
        OrganizationContractListComponent,
        OrganizationDomainListDialogComponent,
        OrganizationOrgadminDeleteDialogComponent,
        OrgManagerHomeComponent,
        OverviewComponent,
        ReadMoreComponent,
        SearchAutoCompleteFiltersComponent,
        TerminateUserDialogComponent,
        TerminateUserDialogComponent,
        TermsAndConditionsComponent,
        UserDetailsDialogComponent,
        UserUnassignEntityDialogComponent,
        VerifyUserComponent,
        ViewFacilityComponent,
        OrganizationOrgadminDeleteDialogComponent,
        OrganizationAssignComponent,
        OrganizationDomainListDialogComponent,
        AssignUserPermissionsComponent,
        ErrorDialogComponent,
        UserDeniedEntityDialogComponent,
        UserAssignSecuityGroupDialogComponent,
        OrgadminUserComponent,
        ContractFacilityAssignComponent,
        ChildEntityComponent,
        ContractAssignedModulesComponent,
        ApproleModuleAppRolesComponent,
        ViewTokenComponent,
        AppUserRoleAssignComponent,
        DescriptionDialogComponent,
        FacilityContractComponent,
        ThankyouComponent,
        FacilityAssignedUsersComponent,
        FacilityAssignUserComponent,
        FacilityUserDeleteDialogComponent,
        SettingsComponent,
        ViewFacilityContractsComponent,
        ApprolesSecuritygroupDialogComponent,
        SecurityGroupListComponent,
        CdkDetailRowDirective,
        PhoneMaskDirective,
        GrxvendorreconcilDialogComponent,
        GrxentityreconcilDialogComponent,
        ViewPermissionMapEntitiesDialogComponent,
        OrganizationOrgadminUserDialogComponent,
        FacilityLOBAssignTeamMemberComponent,
        SearchDropDownComponent,
        ViewAdminFacilityLobDialogComponent,
        ViewLeftMenuLinkItemsComponent,
        AddEditLeftMenuLinkItemsComponent,
        ChildFacilityLobComponent,
        ChangeParentEntityComponent,
        ViewAdminFacilityLOBComponent,
        AddEditFacilityLOBComponent,
        AdminFacilityLOBAssignedTeamMembersComponent,
        ViewAdminFacilityUserAssignmentComponent,
        DetailedAdminFacilityUserAssignmentComponent,
        AssignOrgFacilityComponent,
        OrganizationsLOBComponent,
        AdminFacilityAssignedContractsComponent,
        AdminFacilityAssignedUsersComponent,
        SuperAdminOrgCreateComponent,
        AddOrganizationOrgadminUserComponent,
        FacilityLOBListComponent,
        ViewOrgAdminFacilityLOBComponent,
        ChildOrgAdminFacilityLOBComponent,
        ChildTreeOrgAdminFacilityLOBComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [MatCheckboxModule, MaterialModule, HeaderComponent, SnackBarModule, CustomErrorHandler, SearchAutoCompleteFiltersComponent, AutoCompleteComponent, ThankyouComponent],
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        FontAwesomeModule,
        CPSLibModule.forRoot({
            environment,
        }),
        MenuModule,
        MenuModule.forRoot({
            environment,
        }),
        NgxPermissionsModule.forRoot(),
        CustomErrorHandler,
        FlexLayoutModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MaterialModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MatTooltipModule,
        NgxMaterialTimepickerModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, { useHash: true }),
        RouterModule.forRoot(routes, { useHash: true }),
        ScrollingModule,
        SnackBarModule], providers: [
        TestLibService,
        CPSLibAppComponent,
        SharedUserAppDetailService,
        {
            provide: 'ApplicationStorageService',
            useClass: LocalApplicationStorageService,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: permissionsFactory,
            deps: [RolePermissionsService, NgxPermissionsService],
            multi: true
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
