export const DisplayString = {
  metric: 'Metric',
  category: 'Category',
  reportType : 'column',
  yearBy : 1,
  fiscalYearBy : 2,
  monthBy : 3,
  quarterBy : 4,
  securitygroup : 'Security Group',
  approles : 'App Roles',
  jobtitles : 'Job Titles',
  applicationWithAppRole : 'Application With App Role',
  user : 'User',
  userWithAppRole : 'User With App Role',
  assignPermissions : 'permission',
  assignFacilities : 'facility',
  email : 'email',
  app : 'application',
  modulemaster: 'Module',
  securityGroup: 'Security Group',
  lnk : 'Link Menu Items'
};
