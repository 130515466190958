import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable, of, map } from 'rxjs';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import _ from 'lodash';
import { Facility } from '../../shared/models/facility.model';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { Users } from '../../shared/models/users.model';
import { DeleteConfirmDialogModel } from 'src/app/components/organization-orgadmin-delete-dialog/organization-orgadmin-delete-dialog.component';
import { OrganizationOrgadminDeleteDialogComponent } from 'src/app/components/organization-orgadmin-delete-dialog/organization-orgadmin-delete-dialog.component';
import { Organization } from 'src/app/shared/models/organization.model';
import { OrganizationAssignComponent } from '../organization-assign/organization-assign.component';
import { FacilityUserDeleteDialogComponent } from 'src/app/components/facility-user-delete-dialog/facility-user-delete-dialog.component';
import { MatRadioChange, MatRadioButton } from '@angular/material/radio';
import { UserFilter } from '../../shared/constants/constants';
import { OrganizationUser } from 'src/app/shared/models/organization-user.model';
import { UserEntitySecurityGroup } from 'src/app/shared/models/user-entity-security-group.model';
import { ConfirmationDialogComponent, ConfirmDialogModel } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-add-organization-orgadmin-user',
  templateUrl: './add-organization-orgadmin-user.component.html',
  styleUrls: ['./add-organization-orgadmin-user.component.scss']
})
export class AddOrganizationOrgadminUserComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  selectedCondition: string;
  conditions: string[] = ['Active', 'Terminated', 'All'];
  @Output() assignOrgAdminUsersList: EventEmitter<Users[]> = new EventEmitter();

  @Input() orgIdInput: number;
  @Input() orgNameInput: string;
  @Input() resetForm: Observable<boolean>;
  isEditDisable=false;

  private userSessionSubscription: Subscription;

  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedUserRows$: Observable<Users[]>;
  public displayedOrgAdminRows$: Observable<Users[]>;
  public totalRows$: Observable<number>;
  public facilityDetail: Facility;
  public sortedData = [];
  private organization: Organization[];
  public selectedOrganization: Organization;
  public organizationAdmins: Users[] = [];
  public listedOrganizationAdmins: Users[];
  displayedColumns: string[] = ['logo', 'firstName', 'lastName', 'userId', 'email', 'terminationDate', 'securityGroupName', 'action'];

  constructor(
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    public dialog: MatDialog
  ) {
    this.selectedCondition = UserFilter.active;
  }

  assignOrgAdmin() {
    const dialogRef = this.dialog.open(OrganizationAssignComponent, {
      data: {
        orgId: this.orgIdInput == 0 ? 1 : this.orgIdInput,
        isForOrgAdmin: true
      },
      backdropClass: 'md_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const user = new Users();
        user.userId = result.userId;
        user.isOrgUser = false;
        user.isExternal = result.isExternal;
        user.onPremisesSecurityIdentifier = result.onPremisesSecurityIdentifier;
        user.firstName = result.firstName;
        user.lastName = result.lastName;
        user.email = result.email;
        user.terminationDate = result.terminationDate;
        user.securityGroupName = result.securityGroupName;
        user.firstNameLastName = result.firstNameLastName;
        this.organizationAdmins.push(user);
      }
      this.radioSelectionFilter(UserFilter.active);
      this.assignOrgAdminUsersList.emit(this.organizationAdmins);
    });
  }

  getOrgAdmins(orgId: number): void {
    this.appSecurityManagementService.getOrganizationAdminsWithFacility(orgId.toString()).subscribe(
      (organizationAdmins) => {
        this.organizationAdmins = organizationAdmins;
        this.radioSelectionFilter(UserFilter.active);
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });
  }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.isEditDisable=this.appSecurityManagementService.checkOrganizationLOBEditStatus();
    if (this.orgIdInput.toString() !== "0") {
      this.getOrgAdmins(this.orgIdInput);
    } else {
      this.typeOfSpinner = 'none';
    }
    if(this.resetForm){
      this.resetForm.subscribe(response => {
        if(response){
          if (this.orgIdInput.toString() !== "0") {
            this.getOrgAdmins(this.orgIdInput);
          } else {
            this.organizationAdmins =[];
            this.setOrgAdminDisplayRows(this.organizationAdmins);
            this.typeOfSpinner = 'none';
          }
        }
      });
    }

  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  onDeleteClick(userId: number, entityId: number, userName: string): void {
    this.deleteConfirmDialog(userId, entityId, userName);
  }

  onConditionChange(conditionChange: MatRadioChange) {
    this.selectedCondition = conditionChange.value;
    this.radioSelectionFilter(this.selectedCondition);
  }

  deleteConfirmDialog(userId: number, entityId: number, userName: string): void {
    const message = 'Are you sure you want to unassign ' + userName + ' from Org Admin Users list?';
    const dialogData = new ConfirmDialogModel('Delete User', message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // width: '450px',
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const filteredUsers = this.organizationAdmins.filter((user) => {
          return (
            (user.userId && user.userId.toString().indexOf(userId.toString()) === -1)
          );
        });
        this.organizationAdmins = filteredUsers;
        this.radioSelectionFilter(UserFilter.active);
        this.assignOrgAdminUsersList.emit(this.organizationAdmins);
      }
    });
  }

  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  setOrgAdminDisplayRows(orgAdminSecurityFacilityCountList: Users[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(orgAdminSecurityFacilityCountList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedOrgAdminRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }


  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    const filteredOrgAdminSecurityFacility = this.listedOrganizationAdmins.filter((orgAdminSecurityFacility) => {
      return (
        (orgAdminSecurityFacility.firstNameLastName && orgAdminSecurityFacility.firstNameLastName.toLowerCase().indexOf(filterValue) !== -1) ||
        (orgAdminSecurityFacility.createDate && orgAdminSecurityFacility.createDate.indexOf(filterValue) !== -1) ||
        (orgAdminSecurityFacility.userId && orgAdminSecurityFacility.userId.toString().indexOf(filterValue) !== -1) ||
        (orgAdminSecurityFacility.email && orgAdminSecurityFacility.email.toLowerCase().indexOf(filterValue) !== -1) ||
        (orgAdminSecurityFacility.securityGroupName && orgAdminSecurityFacility.securityGroupName.toLowerCase().indexOf(filterValue) !== -1) ||
        (orgAdminSecurityFacility.facilities && orgAdminSecurityFacility.facilities.toString().indexOf(filterValue) !== -1) ||
        (orgAdminSecurityFacility.terminationDate && orgAdminSecurityFacility.terminationDate.indexOf(filterValue) !== -1)
      );
    });
    this.setOrgAdminDisplayRows(filteredOrgAdminSecurityFacility);
  }

  radioSelectionFilter(filterValue: string): void {
    const filteredOrgAdminSecurityFacility = this.organizationAdmins.filter((orgAdminSecurityFacility) => {
      if (filterValue == UserFilter.active) { return ((orgAdminSecurityFacility.terminationDate == null)); }
      else if (filterValue == UserFilter.terminated) { return ((orgAdminSecurityFacility.terminationDate != null)); }
      else { return ((orgAdminSecurityFacility)); }
    });
    this.listedOrganizationAdmins = filteredOrgAdminSecurityFacility;
    this.setOrgAdminDisplayRows(filteredOrgAdminSecurityFacility);
    this.typeOfSpinner = 'none';
  }

}
