import { Injectable } from '@angular/core';
import { Observable, throwError} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs';
import { AdminContract } from '../models/admin-contract';
import { environment} from 'src/environments/environment';
import { ContractAssessmentType, FacilityContractAssessmentType, ContractAssignedApplication, FacilityContractAssignedApplication, ContractFacility, ContractModule, FacilityContract, Module, OrganizationContract } from 'src/app/shared/models/organization-contract.model';

@Injectable({
  providedIn: 'root'
})
export class AdminContractService {

  constructor(private http: HttpClient) { }

  public getAdminContractList(entityTypeID: number): Observable<Array<AdminContract>> {
    return this.http.get<Array<AdminContract>>(`${environment.appConfig.apiServer.appSecService}OrganizationContract/${entityTypeID}`)
      .pipe(
        map((data) => {
          data.sort((a, b) => {
            return (a.contractName && b.contractName) ? (a.contractName.trim().toLocaleLowerCase() < b.contractName.trim().toLocaleLowerCase()) ? -1 : 1: null;
          });
          return data;
        })
      );
  }

  getOrganizationContract(): Observable<Array<AdminContract>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}OrganizationContract`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<Array<AdminContract>>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'application-security-management.service.service.ts- getOrganizationContract- ThrowingError To Caller...',
            err
          );
          }),
        tap((data) => JSON.stringify(data))
      );
        }

  getOrganizationContractDetails(contractId: number): Observable<any> {
  const apiUrl = `${environment.appConfig.apiServer.appSecService}ViewContract/${contractId}`;
        const headers = {
        'Content-Type': 'application/json',
        };
          return this.http.get<any>(apiUrl, { headers })
            .pipe(
              catchError((err) => {
                return throwError(
                  'admin-contract.service.service.ts- getOrganizationContractDetails- ThrowingError To Caller...',
                  err
                );
                }),
              tap((data) => JSON.stringify(data))
            );
        }

        getAssignedApplicationByContractId(contractId: number): Observable<ContractAssignedApplication[]> {
          const apiUrl = `${environment.appConfig.apiServer.appSecService}ContractAssignedApplication/${contractId}`;
          const headers = {
            'Content-Type': 'application/json',
          };

          return this.http.get<ContractAssignedApplication[]>(apiUrl, { headers })
            .pipe(
              catchError((err) => {
                return throwError(
                  'admin-contract.service.service.ts- getAssignedApplicationByContractId- ThrowingError To Caller...',
                  err
                );
                }),
              tap((data) => JSON.stringify(data))
            );
        }

        getFacilityContract(): Observable<ContractFacility[]> {
          const apiUrl = `${environment.appConfig.apiServer.appSecService}ContractFacility/`;
          const headers = {
            'Content-Type': 'application/json',
          };

          return this.http.get<ContractFacility[]>(apiUrl, { headers })
            .pipe(
              catchError((err) => {
                return throwError(
                  'admin-contract.service.service.ts- getAssignedApplicationByContractId- ThrowingError To Caller...',
                  err
                );
                }),
              tap((data) => JSON.stringify(data))
            );
        }

        getAssignedApplicationByFacilityContractId(facilityContractId: number): Observable<FacilityContractAssignedApplication[]> {
          const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityContractApp/${facilityContractId}`;
          const headers = {
            'Content-Type': 'application/json',
          };

          return this.http.get<FacilityContractAssignedApplication[]>(apiUrl, { headers })
            .pipe(
              catchError((err) => {
                return throwError(
                  'admin-contract.service.service.ts- getAssignedApplicationByFacilityContractId- ThrowingError To Caller...',
                  err
                );
                }),
              tap((data) => JSON.stringify(data))
            );
        }

        public getOrganizationContractByOrgId(orgId: number): Observable<Array<AdminContract>> {
          const headers = {
            'Content-Type': 'application/json',
          };
          return this.http.get<AdminContract[]>(`${environment.appConfig.apiServer.appSecService}OrganizationContractByOrgID/${orgId}`, { headers})
          .pipe(
            catchError((err) => {
              return throwError(
                'admin-contract.service.service.ts- getOrganizationContractByOrgId- ThrowingError To Caller...',
                err
              );
              }),
            tap((data) => JSON.stringify(data))
          );
      }

      getFacilityByContractId(contractId: number): Observable<FacilityContract[]> {
        const apiUrl = `${environment.appConfig.apiServer.appSecService}ContractFacility/${contractId}`;
        const headers = {
          'Content-Type': 'application/json',
        };

        return this.http.get<FacilityContract[]>(apiUrl, { headers })
          .pipe(
            catchError((err) => {
              return throwError(
                'admin-contract.service.service.ts- getAssignedApplicationByContractId- ThrowingError To Caller...',
                err
              );
              }),
            tap((data) => JSON.stringify(data))
          );
      }

      getAssignedFacilities(contractId): Observable<ContractFacility[]> {
        const apiUrl = `${environment.appConfig.apiServer.appSecService}ContractAssignedFacility/${contractId}`;
        const headers = {
          'Content-Type': 'application/json',
        };

        return this.http.get<ContractFacility[]>(apiUrl, { headers })
          .pipe(
            catchError((err) => {
              return throwError(
                'admin-contract.service.service.ts- getAssignedApplicationByContractId- ThrowingError To Caller...',
                err
              );
              }),
            tap((data) => JSON.stringify(data))
          );
      }

      getAssessmentTypeByContractId(contractId: number): Observable<ContractAssessmentType[]> {
        const apiUrl = `${environment.appConfig.apiServer.appSecService}ContractAssessmentType/${contractId}`;
        const headers = {
          'Content-Type': 'application/json',
        };

        return this.http.get<ContractAssessmentType[]>(apiUrl, { headers })
          .pipe(
            catchError((err) => {
              return throwError(
                'admin-contract.service.service.ts- getAssessmentTypeByContractId- ThrowingError To Caller...',
                err
              );
              }),
            tap((data) => JSON.stringify(data))
          );
      }

      getAssessmentTypeByFacilityContractId(facilityContractId: number): Observable<FacilityContractAssessmentType[]> {
        const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityContractAssessmentType/${facilityContractId}`;
        const headers = {
          'Content-Type': 'application/json',
        };

        return this.http.get<FacilityContractAssessmentType[]>(apiUrl, { headers })
          .pipe(
            catchError((err) => {
              return throwError(
                'admin-contract.service.service.ts- getAssessmentTypeByFacilityContractId- ThrowingError To Caller...',
                err
              );
              }),
            tap((data) => JSON.stringify(data))
          );
      }

      getAssignedModules(): Observable<Module[]> {
        const apiUrl = `${environment.appConfig.apiServer.appSecService}ContractModule/`;
        const headers = {
          'Content-Type': 'application/json',
        };

        return this.http.get<Module[]>(apiUrl, { headers })
          .pipe(
            catchError((err) => {
              return throwError(
                'admin-contract.service.service.ts- getAssignedApplicationByContractId- ThrowingError To Caller...',
                err
              );
              }),
            tap((data) => JSON.stringify(data))
          );
      }

      getAssignedModulesByContractId(contractId: number): Observable<ContractModule[]> {
        const apiUrl = `${environment.appConfig.apiServer.appSecService}ContractModule/${contractId}`;
        const headers = {
          'Content-Type': 'application/json',
        };

        return this.http.get<ContractModule[]>(apiUrl, { headers })
          .pipe(
            catchError((err) => {
              return throwError(
                'admin-contract.service.service.ts- getAssignedModulesByContractId - ThrowingError To Caller...',
                err
              );
              }),
            tap((data) => JSON.stringify(data))
          );
      }
}
