import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { ApplicationRoles } from '../models/application-roles';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs';
import { UserApproleFacility } from '../models/user-approle-facility';

@Injectable({
  providedIn: 'root'
})
export class ModifyUserService {

  constructor(private http: HttpClient) { }


  GetUserAppRole(userId: number): Observable<Array<ApplicationRoles>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AdminUserAction/${userId}`; const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<ApplicationRoles>>(apiurl, { headers }) //, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'modify user.service.ts- GetSecurityGroupAppRole- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  updateUserRolesAndFacility(userId: number, userApproleFacility: UserApproleFacility): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}ModifyUser/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      userApproleFacility
    };

    return this.http.put<number>(
      apiurl,
      userApproleFacility
    );
  }
}
