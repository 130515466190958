import { Component, OnInit } from '@angular/core';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { Router } from '@angular/router';
import { RouteGuardConst, SettingMenus } from '../enum/routeguard-const.enum';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})

export class AccessDeniedComponent implements OnInit {

  private userSession: UserSession = new UserSession();
  private userRoleExpected: string[] = [];

  constructor(
    public router: Router,
    private b2cService: B2cService) {
  }

  ngOnInit(): void {
      if (this.hasUserRoleExpected()) {

        combineLatest([this.b2cService.userSession$, this.b2cService.userAppRoles$])
        .subscribe(([userSession, userAppRoles]) => {
          let canActivateRoute = false;
          const currentRoute = sessionStorage.getItem(RouteGuardConst.RoutedUrl);
          //if (this.userSession.onPremisesSecurityIdentifier) {

            this.userRoleExpected.forEach(roleExpected => {
              if (userAppRoles.length > 0) {
                let isSaas  = false;
                userAppRoles.forEach(
                  (userRole: any) => {
                    if (userRole.appNameForClinicalModule.toLowerCase() == 'settings') {
                      isSaas = userRole?.isSaas || false;
                      return;
                    }
                  });

                userAppRoles.forEach(
                  (userRole: any) => {

                    if (userRole.appRoleName === roleExpected) {
                      if(((currentRoute == SettingMenus.FacilityURL || currentRoute == SettingMenus.OrganizationURL || currentRoute == SettingMenus.UserURL) && isSaas == false) || (currentRoute != SettingMenus.FacilityURL && currentRoute != SettingMenus.OrganizationURL && currentRoute != SettingMenus.UserURL))
                      {
                        canActivateRoute = true;
                        return;
                      }
                    }
                  });
                }
              });
          //}

          if (canActivateRoute) {
            this.router.navigateByUrl(this.getRoutedUrl());
          }

        });

        this.b2cService.getUserSesssion();
      }
  }

  private hasUserRoleExpected(): boolean {
    const roleExpected = sessionStorage.getItem(RouteGuardConst.UserPermissions);

    if (roleExpected) {
      this.userRoleExpected = JSON.parse(roleExpected) as string[];
      sessionStorage.removeItem(RouteGuardConst.UserPermissions);
      return true;
    } else {
      return false;
    }
  }

  private getRoutedUrl(): string {
    let url = '/';
    const routedUrl = sessionStorage.getItem(RouteGuardConst.RoutedUrl);

    if (routedUrl) {
      url = routedUrl;
    }
    return url;
  }
}
