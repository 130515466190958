// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-button {
  float: right;
}

.close-icon {
  transition: 1s ease-in-out;
}

.close-icon:hover {
  transform: rotate(180deg);
}

::ng-deep .icon-outside .close-button {
  float: right;
}

::ng-deep .icon-outside .mat-mdc-dialog-container {
  overflow: unset;
}`, "",{"version":3,"sources":["webpack://./src/app/components/user-details-dialog/user-details-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAGA;EACC,eAAA;AAAD","sourcesContent":[".close-button{\r\n  float: right;\r\n}\r\n\r\n.close-icon {\r\n  transition: 1s ease-in-out;\r\n}\r\n\r\n.close-icon:hover {\r\n  transform: rotate(180deg);\r\n}\r\n\r\n::ng-deep .icon-outside .close-button{\r\n  float: right;\r\n\r\n}\r\n\r\n::ng-deep .icon-outside .mat-mdc-dialog-container {\r\n overflow: unset\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
