import { Component, Input, OnChanges} from '@angular/core';
@Component({
    selector: 'app-read-more',
    template: `
        <div [innerHTML]="currentText"></div>
        <span  *ngIf="showToggleButton && currentText!==null && currentText.length > maxLength" >
            <a (click)="toggleView()" class="readmore">Read {{isCollapsed? 'more':'less'}}</a>
        </span>`,
   styleUrls: ['./read-more.component.scss']
})

export class ReadMoreComponent implements OnChanges {

  @Input() text: string;
  @Input() maxLength = 0;
  @Input()showToggleButton: boolean;

    currentText: string;

    public isCollapsed = true;

    toggleView(): void {
        this.isCollapsed = !this.isCollapsed;
        this.determineView();
    }
    determineView(): void {

        if (this.text.length <= this.maxLength) {
            this.currentText = this.text;
            this.isCollapsed = false;
            return;
        }

        if (this.isCollapsed === true ) {
            this.currentText = this.text.substring(0, this.maxLength) + '...';
        } else if (this.isCollapsed === false)  {
            this.currentText = this.text;
        }

    }

    ngOnChanges(): void {
        if (!this.validateSource(this.text)) {
            console.error('Source must be a string.');
        } else {
            this.determineView();
        }
    }

    validateSource(inputValue: string): boolean {
        if (typeof inputValue !== 'string') {
            return false;
        } else {
            return true;
        }
    }
}
