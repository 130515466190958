import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SecurityManagerApplist } from '../../shared/models/security-manager-applist.model';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import * as _ from 'lodash';
import { environment} from 'src/environments/environment';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  public typeOfSpinner = 'loading';
  private params: Params;
  private securityManagerAppsSubscription: Subscription;
  private routeParamSubscription: Subscription;
  private secManagerAppList: SecurityManagerApplist[];
  private userSessionSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  private appId: number;
  private appCode: string;
  public appDescription: string;
  public pageName: string;

  constructor(
    private  b2cService: B2cService,
    private customErrorHandler: CustomErrorHandler,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private snackBar: SnackBarModule,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const element: HTMLInputElement = document.querySelector('#roleMode') as HTMLInputElement;
    if(element!=null && element.value !== 'Admin'){
      element.value = 'Admin';
      element.dispatchEvent(new Event('change'));
    }
    this.pageName = this.activatedRoute.snapshot.data.pageDetails.title;
    this.appDescription = '';
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();

      this.getApplicationDescription(
        this.userSession.onPremisesSecurityIdentifier
      );
  }

  getApplicationDescription(onPremisesSecurityIdentifier: string): void {
    this.securityManagerAppsSubscription = this.appSecurityManagementService
      .getSecurityManagerApps(
        `${environment.appConfig.apiServer.appSecService}App/${onPremisesSecurityIdentifier}`,
        onPremisesSecurityIdentifier
      )
      .subscribe((securityManagerApps) => {
        this.secManagerAppList = securityManagerApps;
        this.routeParamSubscription = this.activatedRoute.params.subscribe(
          (params: Params) => {
            this.params = params;
            this.appId = params.id;
            this.secManagerAppList.forEach((appList) => {
              if (appList.appId === Number(this.appId)) {
                if (appList.description != null) {
                  this.appDescription = appList.description;
                }
              }
            });
          }
        );
      });
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
    if (!_.isNil(this.securityManagerAppsSubscription)) {
      this.securityManagerAppsSubscription.unsubscribe();
    }
    if (!_.isNil(this.routeParamSubscription)) {
      this.routeParamSubscription.unsubscribe();
    }
  }
}
