import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { B2cService, User, UserSession } from 'cps-b2clibrary';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FacilityContract } from '../../shared/models/organization-contract.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort} from '@angular/material/sort';
import { of, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { fromMatPaginator, fromMatSort, paginateRows, sortRows } from 'src/app/shared/utils/utils';
import { App } from 'src/app/shared/models/app.model';
import { map } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ManagePropertyDefinitionService } from '../../shared/services/manage-property-definition.service';
import { PropertyDefinition } from '../../shared/models/property-definition';
import { Users } from 'src/app/shared/models/users.model';
import { FacilityContractTeamMember } from 'src/app/shared/models/facility-contract-team-member.models';
import { AdminContractService } from '../view-admin-contracts/services/admin-contract.service';
import { FacilityContractAssignedApplication  } from 'src/app/shared/models/organization-contract.model';
import { AdminContract, AssessmentType, FacilityContractApp, FacilityContractAssessmentType, ContractAssignedApplication, ContractFacility, ContractModule, OrganizationContract } from 'src/app/shared/models/organization-contract.model';
import { MatCheckboxChange } from '@angular/material/checkbox';

const category = 'FacilityContract';
@Component({
  selector: 'app-facility-contract',
  templateUrl: './facility-contract.component.html',
  styleUrls: ['./facility-contract.component.scss']
})
export class FacilityContractComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public userSession: UserSession = new UserSession();
  contractId: number;
  facilityId: number;
  public typeOfSpinner = 'loading';
  facilityContract: any[];
  public displayedRows$: Observable<any[]>;
  public totalRows$: Observable<number>;
  public displayedFacilityRows$: Observable<any[]>;
  public displayedFacilityContractTeamMemberRows$: Observable<FacilityContractTeamMember[]>;
  public facilityContractTeamMembers: FacilityContractTeamMember[];
  public totalTeamMemberRows$: Observable<number>;
  public totalFacilityRows$: Observable<number>;
  validationMessage: string;
  facilityContractForm: FormGroup;
  propertyDefinitionList: Array<PropertyDefinition>;
  searchControl = new FormControl();
  startDate: any;
  endDate: any;
  facilityContractId: number;
  objfacilityContract: any;
  facilityContractApplications: App[];
  assignedFacilityContractApplications: FacilityContractAssignedApplication[];
  selectedUser: Users[];
  selectedAssignedApplication: FacilityContractApp;
  assessmentType: AssessmentType[];
  facilityContractAssessmentType: FacilityContractAssessmentType[];
  formControlMultiCtrl: FormControl = new FormControl();
  formControlMultiCtrlSubcription: Subscription;
  formControlSearch: FormControl = new FormControl();
  defaultValue: Array<string | number> = [];
  facilityName: string;
  displayedColumns: string[] = ['userID', 'salesforceCustomTeamRole', 'userDisplayName', 'userEmail'];

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private managePropertyDefinitionService: ManagePropertyDefinitionService,
    private formBuilder: FormBuilder,
    private adminContractService: AdminContractService,
  ) { }

  ngOnInit() {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.contractId = this.data.contractId;
    this.facilityId = this.data.facilityId;
    this.startDate = this.data.startDate;
    this.endDate = this.data.endDate;
    this.objfacilityContract = this.data.facilityContract;
    this.facilityName = this.data.facilityName;
    if(this.contractId && this.contractId !== 0 && this.facilityId && this.facilityId !==0){
    this.getFacilityContract();
    }else{
      this.bindcontrols();
    }
  }

  getFacilityContract(): void {
    this.appSecurityManagementService.getFacilityContract(this.facilityId, this.contractId).subscribe(
      (facilityContract) => {
        if (facilityContract && facilityContract.length > 0) {
          this.facilityContract = facilityContract;
          this.facilityContractId = this.facilityContract[0].facilityContractID;
          this.bindFacilityContractDetails();
          this.getFacilityContractApplications();
          this.getFacilityContractTeamMembers();
          this.getAssessmentDetails();
        } else {
          this.bindcontrols();
        }
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
      });
  }

  bindFacilityContractDetails(): void {
    const lstpropertyDefinition: any = [];
    this.managePropertyDefinitionService.getPropertyDefinitionValues(category).subscribe(
      (propertyList) => {
        this.propertyDefinitionList = propertyList;
        this.loadSelectedFiltedOptions();
        propertyList.forEach(element => {
          let propVal = null;
          if(element.dataTypeID.toLocaleLowerCase() === 'multiselect'){
            this.defaultValue = (this.facilityContract[0][element.propertyName.trim().charAt(0).toLowerCase() + element.propertyName.trim().slice(1)]) ? this.facilityContract[0][element.propertyName.trim().charAt(0).toLowerCase() + element.propertyName.trim().slice(1)].replace(/;/g,',').split(",") : null;
            this.facilityContract[0][element.propertyName.trim().charAt(0).toLowerCase() + element.propertyName.trim().slice(1)] = this.defaultValue;
          }
          if (element.propertyName.trim().includes('UserID')) {
            if (element.propertyName === "CDOPUserID") {
              propVal = this.facilityContract[0][element.propertyName.trim().substring(0, 4).toLowerCase() + element.propertyName.trim().slice(4)];
            } else {
              propVal = this.facilityContract[0][element.propertyName.trim().substring(0, 3).toLowerCase() + element.propertyName.trim().slice(3)];
            }
            if (propVal) {
              const activeUser = element.dropdownData.find(i => i.key == propVal);
              if (!activeUser) {
                this.appSecurityManagementService
                  .getAllSecurityGroupUsers(propVal, "false")
                  .subscribe(
                    (allUsers) => {
                      if (allUsers && allUsers.length > 0) {
                        const inActiveUser = {
                          key: allUsers[0].userId.toString(),
                          value: (allUsers[0].jobTitle && allUsers[0].jobTitle != '') ? allUsers[0].displayName + ',' + allUsers[0].jobTitle : allUsers[0].displayName
                        };
                        element.dropdownData.push(inActiveUser);
                        lstpropertyDefinition.push(element);
                        lstpropertyDefinition[lstpropertyDefinition.length - 1].defaultValue = propVal.toString();
                        if (lstpropertyDefinition.length === this.propertyDefinitionList.length) {
                          this.propertyDefinitionList = lstpropertyDefinition.sort((a, b) => (a.propertyDefinitionID > b.propertyDefinitionID) ? 1 : -1);
                          this.updateFormGroup();
                          this.typeOfSpinner = 'none';
                        }
                      }
                    },
                    (error: HttpErrorResponse) => {
                      console.log(error);
                    }
                  );

              } else {
                lstpropertyDefinition.push(element);
                lstpropertyDefinition[lstpropertyDefinition.length - 1].defaultValue = propVal;
              }
            } else {
              lstpropertyDefinition.push(element);
              lstpropertyDefinition[lstpropertyDefinition.length - 1].defaultValue = propVal;
            }
          } else if(element.propertyName.trim() === 'DOPEmail' || element.propertyName.trim() === 'LEKPrimaryContactName'){
            propVal = this.facilityContract[0][element.propertyName.trim().substring(0, 3).toLowerCase() + element.propertyName.trim().slice(3)];
            lstpropertyDefinition.push(element);
            lstpropertyDefinition[lstpropertyDefinition.length - 1].defaultValue = propVal;
          } else if(element.propertyName.trim() === 'CPSTClientInteractionNotes' || element.propertyName.trim() === 'CPSTClientReports' || element.propertyName.trim() === 'CPSTCoverageHoursSummary'){
            propVal = this.facilityContract[0][element.propertyName.trim().substring(0, 4).toLowerCase() + element.propertyName.trim().slice(4)];
            lstpropertyDefinition.push(element);
            lstpropertyDefinition[lstpropertyDefinition.length - 1].defaultValue = propVal;
          }else if((element.propertyName.trim() === 'PharmacyRegulatoryAccreditation' || element.propertyName.trim() === 'ThirdPartyAdministrator') && element.dataTypeID.toLocaleLowerCase() == 'multiselect' ){
            this.defaultValue = this.facilityContract[0][element.propertyName.trim().charAt(0).toLowerCase() + element.propertyName.trim().slice(1)];
            lstpropertyDefinition.push(element);
            lstpropertyDefinition[lstpropertyDefinition.length - 1].defaultValue = this.defaultValue;
          }
          else {
            propVal = this.facilityContract[0][element.propertyName.trim().charAt(0).toLowerCase() + element.propertyName.trim().slice(1)];
            lstpropertyDefinition.push(element);
            lstpropertyDefinition[lstpropertyDefinition.length - 1].defaultValue = propVal;
          }

          if (lstpropertyDefinition.length === this.propertyDefinitionList.length) {
            this.propertyDefinitionList = lstpropertyDefinition;
            (this.facilityContractId && this.facilityContractId !== 0) ? this.updateFormGroup() : this.initFormGroup();
            this.typeOfSpinner = 'none';
          }
        });

      },
      (error: HttpErrorResponse) => {
        console.log(error);
      });
  }

  setDisplayRows(facilityContract: any[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(facilityContract);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  bindcontrols(): void {
    this.typeOfSpinner = 'loading';
    const lstpropertyDefinition: any = [];
    this.managePropertyDefinitionService.getPropertyDefinitionValues(category).subscribe(
      (propertyList) => {
        this.propertyDefinitionList = [];
        const facilityContract = (this.objfacilityContract) ? this.objfacilityContract[0] : null;
        propertyList.forEach(item => {
          if(item.propertyName.trim() == "FacilityID"){
            item.defaultValue = (this.facilityId) ? this.facilityId.toString() : "0";
          }
          else if(item.propertyName.trim() == "StartDate" && facilityContract != null && facilityContract[item.propertyName.trim()]){
            item.defaultValue = this.startDate;
          }
          else if(item.propertyName.trim() == "EndDate" && facilityContract != null && facilityContract[item.propertyName.trim()]){
            item.defaultValue = this.endDate;
          }
          else{
            item.defaultValue = (facilityContract) ? (facilityContract[item.propertyName.trim()]) ? facilityContract[item.propertyName.trim()] : null : null;
          }

          this.propertyDefinitionList.push(item);
        });
        this.initFormGroup();
        this.typeOfSpinner = "none";
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = "none";
      });
  }

  initFormGroup(): void {
    this.facilityContractForm = this.formBuilder.group({})
    this.loadSelectedFiltedOptions();
    this.propertyDefinitionList.forEach(item => {
      if (item.dataTypeID === 'date') {
        let date = new Date();
        if (this.contractId == 0 || this.contractId == null || this.contractId == undefined || item.defaultValue == null) {
          date = (item.propertyName.trim() === 'StartDate') ? new Date(this.startDate) : (item.propertyName.trim() === 'EndDate') ? new Date(this.endDate) : null;
        } else {
          date = new Date(item.defaultValue);
        }
        this.facilityContractForm.addControl(item.propertyName.trim(), new FormControl({ value: date, disabled: true }));
      } else {
        this.facilityContractForm.addControl(item.propertyName.trim(), new FormControl({ value: item.defaultValue, disabled: true }));
      }
    });

  }

  updateFormGroup(): void {
    this.facilityContractForm = this.formBuilder.group({})
    this.loadSelectedFiltedOptions();
    this.propertyDefinitionList.forEach(item => {
      if (item.dataTypeID === 'date') {
        let date = new Date();
        if (this.contractId == 0 || this.contractId == null || this.contractId == undefined || item.defaultValue == null) {
          date = (item.propertyName.trim() === 'StartDate') ? new Date(this.startDate) : (item.propertyName.trim() === 'EndDate') ? new Date(this.endDate) : null;
        } else {
          date = new Date(item.defaultValue);
        }
        this.facilityContractForm.addControl(item.propertyName.trim(), new FormControl({ value: date, disabled: true }));
      } else {
        this.facilityContractForm.addControl(item.propertyName.trim(), new FormControl({ value: item.defaultValue, disabled: true }));
      }
    });

  }

  loadSelectedFiltedOptions(): void {
    this.propertyDefinitionList.forEach(element => {
      if (element.dataTypeID === 'dropdown') {
        element.selectedFilterOptions = Object.assign([], element.dropdownData);
      }
      if (element.dataTypeID === 'multiselect') {
        element.selectedFilterOptions = Object.assign([], element.dropdownData);
      }
    });
  }


  filterSearch(value: string, displayName: string): void {
    this.propertyDefinitionList.forEach(element => {
      if (element.dataTypeID === 'dropdown' && element.displayName === displayName) {
        if (!value) {
          element.selectedFilterOptions = Object.assign([], element.dropdownData);
        } else {
          element.selectedFilterOptions = Object.assign([], this.propertyDefinitionList).filter(f => f.displayName === displayName)[0].dropdownData
            .filter(f => f.value.toLowerCase().includes(value.toLowerCase()));
        }
      }
      if (element.dataTypeID === 'multiselect' && element.displayName === displayName) {
        if (!value) {
          element.selectedFilterOptions = Object.assign([], element.dropdownData);
        } else {
          element.selectedFilterOptions = Object.assign([], this.propertyDefinitionList).filter(f => f.displayName === displayName)[0].dropdownData
            .filter(f => f.value.toLowerCase().includes(value.toLowerCase()));
        }
      }
    });
  }

  getFacilityContractTeamMembers()
  {
    this.typeOfSpinner = 'loading';
    this.appSecurityManagementService
    .getFacilityContractTeamMember(this.facilityContractId)
      .subscribe(
        (teamMembers) => {
          this.facilityContractTeamMembers = teamMembers;
          this.setTeamMembersDisplayRows(this.facilityContractTeamMembers);
          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.customErrorHandler.handleError(error);
        });
  }

  setTeamMembersDisplayRows(facilityContractTeamMemberList: FacilityContractTeamMember[]): void {
    facilityContractTeamMemberList.sort((a, b) => (a.userDisplayName > b.userDisplayName) ? 1 : -1)
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(facilityContractTeamMemberList);
    this.totalTeamMemberRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedFacilityContractTeamMemberRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  getFacilityContractApplications(): void {
    this.typeOfSpinner = 'loading';
    this.appSecurityManagementService.getAssignedApplications().subscribe(
      (facilityContractApplications) => {
        this.facilityContractApplications = facilityContractApplications.filter(i => i.isActive == true);
        this.getAssignedApplicationByFacilityContractId();
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
      });
  }

  getAssessmentDetails(): void {
    this.typeOfSpinner = 'loading';
    this.appSecurityManagementService.getAssessmentType().subscribe(
      (data) => {
        this.assessmentType = data;
        if (this.contractId && this.contractId != 0) {
          this.getAssessmentTypeByFacilityContractId();
        }
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
      });
  }

  getAssessmentTypeByFacilityContractId() {
    this.typeOfSpinner = 'loading';
    this.adminContractService.getAssessmentTypeByFacilityContractId(this.facilityContractId)
      .subscribe(
        (data) => {
          this.facilityContractAssessmentType = data;
          this.facilityContractAssessmentType.forEach(element => {
            this.assignedAssessmentTypeList.push(element);
            this.assessmentType.find(i => i.assessmentTypeId === element.assessmentTypeId).isSelected = true;
          });

          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.customErrorHandler.handleError(error);
        });
  }

  assignedAssessmentTypeList: any = [];
  selectedAssessmentType(event: MatCheckboxChange, entityDefinitive: any): void {
    if (event.source.checked) {
      const obj = {
        assessmentTypeId: entityDefinitive.assessmentTypeId,
        contractId: this.contractId,
        contractAssessmentTypeId: 0,
      }
      if (this.assignedAssessmentTypeList) {
        if (this.assignedAssessmentTypeList.filter(i => i.assessmentTypeId == entityDefinitive.assessmentTypeId).length == 0) {
          this.assignedAssessmentTypeList.push(obj);
        }
      } else {
        this.assignedAssessmentTypeList.push(obj);
      }
    } else {
      this.assignedAssessmentTypeList = this.assignedAssessmentTypeList.filter(i => i.assessmentTypeId != entityDefinitive.assessmentTypeId);
    }

  }

  getAssignedApplicationByFacilityContractId() {
    this.typeOfSpinner = 'loading';
    if(this.contractId){
    this.adminContractService.getAssignedApplicationByFacilityContractId(this.facilityContractId)
      .subscribe(
        (data) => {
          this.assignedFacilityContractApplications = data;
          this.assignedFacilityContractApplications.forEach(element => {
            const facilityContractApp = new FacilityContractApp();
            facilityContractApp.facilityContractAppId = element.facilityContractAppId;
            facilityContractApp.facilityContractId = element.facilityContractId;
            facilityContractApp.AppId = element.appId;
            this.assignedApplicationList.push(facilityContractApp);
            this.facilityContractApplications.find(i => i.appId === element.appId).isSelected = true;
          });

          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.customErrorHandler.handleError(error);
        });
    }
  }

  assignedApplicationList: any = [];
  selectedAssignApplication(event: MatCheckboxChange, entityDefinitive: any): void {
    this.selectedAssignedApplication = new FacilityContractApp();
    if (event.source.checked) {
      if (this.assignedApplicationList) {
        if (this.assignedApplicationList.filter(i => i.AppId == entityDefinitive.appId).length == 0) {
          this.assignedApplicationList.push(entityDefinitive);
        }
      } else {
        this.assignedApplicationList.push(entityDefinitive);
      }
    } else {
      this.assignedApplicationList = this.assignedApplicationList.filter(i => i.AppId != entityDefinitive.appId);
    }
  }

}
