import { Injectable } from '@angular/core';
import { Observable, throwError} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs';
import { UserSecurityGroup } from '../models/user-security-group';
import { environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SecurityGroupService {

  constructor(private http: HttpClient) { }

  public getSecurityGroupWithUsers(): Observable<Array<UserSecurityGroup>> {
    return this.http.get<Array<UserSecurityGroup>>(`${environment.appConfig.apiServer.appSecService}UserSecurityGroup`)
      .pipe(
        map((data) => {
          data.sort((a, b) => {
            return a.securityGroupName.trim().toLocaleLowerCase() < b.securityGroupName.trim().toLocaleLowerCase() ? -1 : 1;
          });
          return data;
        })
      );
  }

  public getAppRoleSecurityGroups(appRoleID: string): Observable<Array<UserSecurityGroup>> {
    return this.http.get<Array<UserSecurityGroup>>(`${environment.appConfig.apiServer.appSecService}UserSecurityGroup/${appRoleID}`)
      .pipe(
        map((data) => {
          data.sort((a, b) => {
            return a.securityGroupName.trim().toLocaleLowerCase() < b.securityGroupName.trim().toLocaleLowerCase() ? -1 : 1;
          });
          return data;
        })
      );
  }

}
