import { DOCUMENT } from '@angular/common';
import { Renderer2, Inject, Injectable, RendererFactory2 } from '@angular/core';
import { NewRelicConfig } from '../models/new-relic-config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let newrelic: any;

@Injectable({
  providedIn: 'root',
})
export class NewRelicService {
  renderer: Renderer2;
  constructor(
    rendererFactory: RendererFactory2,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(DOCUMENT) private document: any
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  Initialize(newRelicConfig: NewRelicConfig): void {
    const isNewRelicSetup = !(
      newRelicConfig?.accountID &&
      newRelicConfig?.agentID &&
      newRelicConfig?.applicationID &&
      newRelicConfig?.tagName
    );
    if (!isNewRelicSetup) {
      this.handleError('New relic config parameter mismatch');
      throw new Error('New relic config parameter mismatch');
    }
    const newRelicKeys = JSON.stringify({ ...newRelicConfig });
    const newRelic = this.renderer.createElement('script');
    newRelic.type = 'text/javascript';
    newRelic.innerText = `{ ;window.NREUM||(NREUM={});NREUM.init={distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}}; ;NREUM.loader_config = ${newRelicKeys}; ;NREUM.info={beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:'${newRelicConfig.licenseKey}',applicationID:'${newRelicConfig.applicationID}',sa:1};}`;
    this.renderer.appendChild(this.document.body, newRelic);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any): void {
    if (typeof newrelic !== 'undefined') {
      newrelic.noticeError(error);
    }
  }
}
