import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment} from 'src/environments/environment';
import { FacilityLOBList } from 'src/app/shared/models/facility-lob-list-model';

@Injectable({
  providedIn: 'root'
})
export class AdminFacilityService {

  constructor(private http: HttpClient) { }

  public getFacilityLOBListByOnPermId(onPremisesSecurityIdentifier: string): Observable<Array<FacilityLOBList>> {
    return this.http.get<Array<FacilityLOBList>>(`${environment.appConfig.apiServer.appSecService}FacilityLOBList/${onPremisesSecurityIdentifier}/orgadminfacilities`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
