import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import _ from 'lodash';
import { Facility } from '../../shared/models/facility.model';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { FacilityLOBAssignTeamMemberComponent } from '../facility-lob-assign-team-member/facility-lob-assign-team-member.component';
import { Users } from 'src/app/shared/models/users.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FacilityContractTeamMember } from 'src/app/shared/models/facility-contract-team-member.models';
import { TeamMemberRole } from '../../shared/models/team-member-role.models';

@Component({
  selector: 'app-admin-facility-lob-assigned-team-members',
  templateUrl: './admin-facility-lob-assigned-team-members.component.html',
  styleUrls: ['./admin-facility-lob-assigned-team-members.component.scss']
})
export class AdminFacilityLOBAssignedTeamMembersComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() facilityContractIdInput: number;
  @Input() orgIdInput: number;
  @Input() isSaasFacilityInput: boolean;
  @Input() isViewOnly = false;
  @Output() teamMember: EventEmitter<FacilityContractTeamMember[]> = new EventEmitter();

  private userSessionSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<FacilityContractTeamMember[]>;
  public totalRows$: Observable<number>;
  public facilityDetail: Facility;
  public assignedTeamMember: FacilityContractTeamMember[];
  public sortedData = [];
  userSecurityFacilityList: Users[];
  selectedOption = true;
  filterValue = "";
  public facilityContractTeamMembers: FacilityContractTeamMember[];
  teamMemberRoleData: TeamMemberRole[];
  @Input() resetForm: Observable<boolean>;
  private sub: any;
  displayedColumns: string[] = ['logo', 'firstName', 'lastName', 'email', 'roleDisplay', 'action'];


  constructor(
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    public dialog: MatDialog
  ) { }

  assignUser(user:FacilityContractTeamMember,isEditMode:number) {
    let isSaasFacility = false;
    if(this.isSaasFacilityInput) {
    const teamMemberDOPdata=this.assignedTeamMember.filter(x=>x.salesforceCustomTeamRole=='DOP');
      if(teamMemberDOPdata && teamMemberDOPdata.length == 0)
      {
        isSaasFacility = true;
      }
    }

    const dialogRef = this.dialog.open(FacilityLOBAssignTeamMemberComponent, {
      data: {
        dataKey: this.facilityContractIdInput,
        selectedAssignedUser: this.assignedTeamMember,
        editUser:user,
        isEditMode:isEditMode,
        orgId: this.orgIdInput,
        isSaasFacility: isSaasFacility
      },
      backdropClass: 'md_panel-dialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      result.forEach((element) => {
        const teamMemberdata=this.teamMemberRoleData.filter(x=>x.teamMemberRoleCode==element.salesforceCustomTeamRole)
        if(teamMemberdata && teamMemberdata.length>0)
        {
        element.roleDisplay=teamMemberdata[0].teamMemberRoleDescription;
        }
        let matchRecord = 0;
        this.assignedTeamMember.forEach((ele,index) => {
          if (ele.userID === element.userID) {
            ele.salesforceCustomTeamRole = element.salesforceCustomTeamRole;
            if(teamMemberdata && teamMemberdata.length>0 && (ele.facilityContractTeamMemberID>0 || element.salesforceCustomTeamRole!="None"))
            {
            ele.roleDisplay=element.salesforceCustomTeamRole!="None"?teamMemberdata[0].teamMemberRoleDescription:element.salesforceCustomTeamRole;
            }
            else if(ele.facilityContractTeamMemberID===0 && element.salesforceCustomTeamRole==="None")
            {
              this.assignedTeamMember.splice(index, 1);
            }
            matchRecord = 1;
          }
        });
        if (matchRecord == 0) {
          this.assignedTeamMember.push(element);
        }
      });

      this.setTeamMembersDisplayRows(this.assignedTeamMember);

      if (result) {
        this.teamMember.emit(this.assignedTeamMember);
      }
    });
  }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.getTeamMemberRoleDetails();
    this.typeOfSpinner = 'none';
    if(this.resetForm){
      this.sub = this.resetForm.subscribe(response => {
        if(response){
         this.getTeamMemberRoleDetails();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  onConditionChange(conditionChange: MatCheckboxChange){
    this.selectedOption = conditionChange.checked;
    if(this.filterValue == "") {
      this.getFilteredAssignedTeamMember(conditionChange.checked);
    } else {
      this.applyFilter(this.filterValue);
    }
  }

  getFilteredAssignedTeamMember(condition?: boolean){
    const filteredUsers=this.assignedTeamMember.filter((user)=>condition == true);
    this.paginator.pageIndex = 0;
    this.setTeamMembersDisplayRows(filteredUsers);
  }

  applyFilter(filterValue: string): void {
    this.filterValue = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    const filteredUserdata = this.assignedTeamMember.filter((userdata) => {
      return (
        (userdata.userID && userdata.userID.toString().indexOf(filterValue) !==
          -1) ||
        (userdata.firstName && userdata.firstName.toLowerCase().indexOf(filterValue) !==
          -1) ||
          (userdata.roleDisplay && userdata.roleDisplay.toLowerCase().indexOf(filterValue) !==
          -1) ||
        (userdata.lastName && userdata.lastName
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
        (userdata.userEmail
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
        (userdata.userEmail
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
          (userdata.firstName && userdata.lastName && (userdata.firstName + ' ' + userdata.lastName).toLowerCase().indexOf(filterValue) !==
          -1)
      );
    });
    const filteredUsers=filteredUserdata.filter((user)=>this.selectedOption == true );
    this.paginator.pageIndex = 0;
    this.setTeamMembersDisplayRows(filteredUsers);
  }

  getFacilityContractTeamMembers(facilityContractID: number)
  {
    this.typeOfSpinner = 'loading';
    this.appSecurityManagementService
    .getFacilityContractTeamMember(facilityContractID)
      .subscribe(
        (teamMembers) => {
          teamMembers.forEach(element => {
            const teamMemberdata=this.teamMemberRoleData.filter(x=>x.teamMemberRoleCode==element.salesforceCustomTeamRole)
            if(teamMemberdata && teamMemberdata.length>0)
            {
            element.roleDisplay=teamMemberdata[0].teamMemberRoleDescription;
            }
          });

          this.facilityContractTeamMembers = teamMembers;
          this.assignedTeamMember=teamMembers;
          this.setTeamMembersDisplayRows(this.facilityContractTeamMembers);
          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.customErrorHandler.handleError(error);
        });
  }

  setTeamMembersDisplayRows(facilityContractTeamMemberList: FacilityContractTeamMember[]): void {
    const facilityContractTeamMemberFilterList= facilityContractTeamMemberList.filter(x=>x.salesforceCustomTeamRole!="None")
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(facilityContractTeamMemberFilterList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  getTeamMemberRoleDetails(): void {
    this.appSecurityManagementService.getTeamMemberRoleDetails().subscribe(
      (teamMemberRoles:TeamMemberRole[]) => {
        const teamMemberData = {} as TeamMemberRole;
        this.teamMemberRoleData = [] as TeamMemberRole[];
        teamMemberData.teamMemberRoleId=0;
        teamMemberData.teamMemberRoleCode="None";
        teamMemberData.teamMemberRoleDescription="None";
        this.teamMemberRoleData.push(teamMemberData);
        teamMemberRoles.forEach(element => {
          this.teamMemberRoleData.push(element);
        });
        this.getFacilityContractTeamMembers(this.facilityContractIdInput);
      });
  }

}
