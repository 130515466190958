// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verify-user-panel {
  width: 45vw;
}

.verify-user-form {
  width: 50%;
  margin: 20px auto;
}

.verify-user-form .mat-mdc-dialog-content {
  padding-top: 15px;
}

.verify-user-form .btn {
  width: 200px;
}

@media (max-width: 1023px) {
  .verify-user-form {
    width: 80%;
  }
}
@media (max-width: 767px) {
  .verify-user-panel {
    width: 65vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/verify-user/verify-user.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,UAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;AACF;AAEA;EACE;IACE,WAAA;EAAF;AACF","sourcesContent":[".verify-user-panel {\r\n  width: 45vw;\r\n}\r\n\r\n.verify-user-form {\r\n  width: 50%;\r\n  margin: 20px auto;\r\n}\r\n\r\n.verify-user-form  .mat-mdc-dialog-content {\r\n  padding-top: 15px;\r\n}\r\n\r\n.verify-user-form .btn {\r\n  width: 200px;\r\n}\r\n\r\n@media(max-width:1023px) {\r\n  .verify-user-form  {\r\n    width: 80%;\r\n  }\r\n}\r\n\r\n@media(max-width:767px) {\r\n  .verify-user-panel {\r\n    width: 65vw;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
