// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-overview {
  width: 100%;
}

.dialog-overview th,
.dialog-overview td {
  padding: 10px;
  text-align: left;
}

.dialog-modal {
  position: relative;
}

h2.mat-mdc-dialog-title {
  color: #22365b;
  font-size: 1.45rem;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 25px;
  text-align: center;
}

.closedialog {
  position: absolute;
  right: -20px;
  min-width: 20px;
  margin-top: -10px;
  margin-right: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.mat-accent-darker {
  background: #22365b;
  color: white;
  margin-top: 10px;
}

/** Media query **/
@media (max-width: 768px) {
  .dialog-overview th,
  .dialog-overview td {
    padding: 5px;
    font-size: 13px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-message-instruction-dialog/app-message-instruction-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AACA;;EAEE,aAAA;EACA,gBAAA;AAEF;;AACA;EAEE,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EAAoB,YAAA;EACpB,gBAAA;AAEF;;AAAA,kBAAA;AACA;EACE;;IAEE,YAAA;IACA,eAAA;EAGF;AACF","sourcesContent":[".dialog-overview {\r\n  width: 100%;\r\n}\r\n.dialog-overview th,\r\n.dialog-overview td {\r\n  padding: 10px;\r\n  text-align: left;\r\n}\r\n\r\n.dialog-modal\r\n{\r\n  position: relative;\r\n}\r\n\r\nh2.mat-mdc-dialog-title {\r\n  color: #22365b;\r\n  font-size: 1.45rem;\r\n  font-weight: 400;\r\n  margin-bottom: 0;\r\n  margin-top:25px;\r\n  text-align: center;\r\n}\r\n\r\n.closedialog {\r\n  position: absolute;\r\n  right: -20px;\r\n  min-width: 20px;\r\n  margin-top: -10px;\r\n  margin-right: 15px;\r\n  padding-left: 10px;\r\n  padding-right: 10px;\r\n}\r\n\r\n.mat-accent-darker {\r\n  background: #22365b;color: white;\r\n  margin-top: 10px;\r\n}\r\n/** Media query **/\r\n@media(max-width:768px) {\r\n  .dialog-overview th,\r\n  .dialog-overview td {\r\n    padding: 5px;\r\n    font-size: 13px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
