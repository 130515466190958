import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Lookup, PropertyDefinition } from '../models/property-definition';

@Injectable({
  providedIn: 'root'
})
export class ManagePropertyDefinitionService {

  propertyDefinionList: Array<PropertyDefinition> = [];

  constructor(private httpClient: HttpClient) { }

  getPropertyDefinitionValues(category: string): Observable<Array<PropertyDefinition>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}PropertyDefinition/${category}`;
    console.log('pd'+apiUrl);
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.httpClient
      .get<Array<PropertyDefinition>>(apiUrl, { headers })
      .pipe(map((data) => data));
  }

  geLookUpValues(lookupEndPoint: string): Observable<Array<Lookup>> {
    const apiUrl = lookupEndPoint;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.httpClient
      .get<Array<Lookup>>(apiUrl, { headers })
      .pipe(map((data) => data));
  }
}
