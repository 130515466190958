import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { OrgAdminFacility } from '../../shared/models/org-admin-facility.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Organization } from 'src/app/shared/models/organization.model';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { organization } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-change-org',
  templateUrl: './change-org.component.html',
  styleUrls: ['./change-org.component.scss']
})

export class ChangeOrgComponent implements OnInit {

  public availableOrganizations: Organization[];
  public userOrganizations: Organization[];
  public userSession: UserSession = new UserSession();
  userOrgLength: number;

  constructor(
    public dialog: MatDialog, public dialogRef: MatDialogRef<ChangeOrgComponent>,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private snackBar: SnackBarModule,
    private b2cService: B2cService,
    )
    { }

  ngOnInit(): void {
    this.userOrgLength = organization.userOrgLength;
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();

    this.availableOrganizations = this.getOrganization();
    if (this.availableOrganizations == undefined) {
       this.getOrganizationList();
    }

    this.availableOrganizations = this.availableOrganizations.sort((sourceData, comparisionData) => (sourceData.cpsEntityName > comparisionData.cpsEntityName) ? 1 : -1);
    this.userOrganizations = this.availableOrganizations;
    if(this.availableOrganizations.length === 1)
    {
      this.changeOrganization(this.availableOrganizations[0].entityId);
    }
  }
  getOrganization(): Organization[] {
    return this.appSecurityManagementService.getOrganization();
  }

  changeOrganization(entityID): void {
    this.appSecurityManagementService.setOrganization(this.availableOrganizations,entityID);
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  getOrganizationList(): void {
    this.appSecurityManagementService
      .getOrganizationList(this.userSession.onPremisesSecurityIdentifier)
      .subscribe({
        next: response => {
          this.availableOrganizations = response;
          this.userOrganizations = this.availableOrganizations;

        },
        error: error => {
          this.showSnackBarMessageError(error);
        }
      });
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.toLowerCase().trim();
    this.userOrganizations = this.availableOrganizations.filter((module) => {
       return (
         (module.cpsEntityName && module.cpsEntityName.toString().toLowerCase().indexOf(filterValue) !== -1)
       );
     });
  }

}
