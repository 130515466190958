import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';

import { ActionMessage, DisplayMessage, DisplayString } from 'src/app/shared/components/message';
import { Subscription } from 'rxjs';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { LeftMenuLinkItem } from 'src/app/shared/models/left-menu-linkitem.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';
import _ from 'lodash';
import {newGuid } from '@microsoft/applicationinsights-core-js';

@Component({
  selector: 'app-add-edit-leftmenu-linkitems',
  templateUrl: './add-edit-leftmenu-linkitems.component.html',
  styleUrls: ['./add-edit-leftmenu-linkitems.component.scss']
})
export class AddEditLeftMenuLinkItemsComponent implements OnInit {
  addEditLeftMenuLinkItemForm: FormGroup;
  isEdit: boolean;
  show: boolean;
  selectedLinkItemGuid = "0";
  lnkKey: string;
  pageTitle: string;
  typeOfSpinner = "loading";
  selectedTabIndex = 0;
  validationMessage: string;
  routeParamSubscription: Subscription;
  leftMenuLinkItem : LeftMenuLinkItem
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private snackBar: SnackBarModule,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private router: Router,
    private customErrorHandler: CustomErrorHandler,

  ) { }

  ngOnInit() {
    this.isEdit = false;
    this.show = false;
    this.initForm();
    this.routeParamSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.selectedLinkItemGuid = params.id;
        this.isEdit = this.selectedLinkItemGuid != "0" && !_.isNil(this.selectedLinkItemGuid) ? true : false;
        this.lnkKey = this.activatedRoute.fragment["value"];

        if (this.isEdit) {
          this.pageTitle = 'Edit Left Menu Link Item';
        } else {
          this.pageTitle = 'Add Left Menu Link Item';
        }
        this.getSelectedLeftMenuLinkItemDetails();
      }
    );
  }

  initForm(): void {
    this.addEditLeftMenuLinkItemForm = this.formBuilder.group({
      clinicalModuleLinkItemGuid : [{ value: newGuid(), disabled: this.isAdminReadOnly()}],
      linkType : [{ value: "Navigation", disabled: this.isAdminReadOnly() }, Validators.required],
      linkKey : [{ value: "", disabled: this.isAdminReadOnly() }, Validators.required],
      displayText : [{ value: "", disabled: this.isAdminReadOnly() }, Validators.required],
      linkUrl : [{ value: "", disabled: this.isAdminReadOnly() }],
      marketingLinkUrl : [{ value: "https://www.cpssaassolutions.com", disabled: this.isAdminReadOnly() }],
      thumbnailImageType : [{ value: "FontAwesome", disabled: this.isAdminReadOnly() }],
      thumbnailImageName : [{ value: "", disabled: this.isAdminReadOnly() }],
      appId : [{ value: null, disabled: this.isAdminReadOnly() }],
      appRoleId : [{ value: null, disabled: this.isAdminReadOnly() }],
      sortNumber : [{ value: 0, disabled: this.isAdminReadOnly() }],
      parentClinicalModuleLinkItemGuid : [{ value: null, disabled: this.isAdminReadOnly() }],
      hasSubLinks : [{ value: false, disabled: this.isAdminReadOnly() }],
      isAppMenu : [{ value: false, disabled: this.isAdminReadOnly() }],
      isActive: [{ value: true, disabled: this.isAdminReadOnly() }],
      coreLinkUrl : [{ value: "", disabled: this.isAdminReadOnly() }],
      createBy : [{ value: "system", disabled: this.isAdminReadOnly() }],
      createDate: [{ value: "", disabled: this.isAdminReadOnly() }],
      updateDate: [{ value: "", disabled: this.isAdminReadOnly() }],
      updateBy: [{ value: "system", disabled: this.isAdminReadOnly() }],

    });
    this.validationMessage = "";
  }
  findEvent() {
    this.isEdit ? this.UpdateLeftMenuLinkItem() : this.AddLeftMenuLinkItem();
  }

  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  AddLeftMenuLinkItem(): void {
    if (this.addEditLeftMenuLinkItemForm.valid) {
      this.typeOfSpinner = "loading";
      this.leftMenuLinkItem = this.addEditLeftMenuLinkItemForm.value;
      if(!this.isEdit) {
        delete this.leftMenuLinkItem.createDate;
        delete this.leftMenuLinkItem.updateDate;
      }
      this.appSecurityManagementService.AddLeftMenuLinkItem(this.leftMenuLinkItem).subscribe(
        (id) => {
          if (!_.isNil(id)) {
            this.typeOfSpinner = "none";
            this.snackBar.successMessage(DisplayMessage.updateMessage, ActionMessage.okAction);
            this.router.navigate(['viewleftmenulinkitems']);
          }
        }
      ), (error: HttpErrorResponse) => {
        this.showSnackBarMessageError(error);
      }
    }
    else {
      if (this.addEditLeftMenuLinkItemForm.invalid) {
        this.selectedTabIndex = 0;
        this.validationMessage = "Please enter the required fields."
        this.addEditLeftMenuLinkItemForm.get('linkType').markAsTouched();
        this.addEditLeftMenuLinkItemForm.get('linkKey').markAsTouched();
        this.addEditLeftMenuLinkItemForm.get('displayText').markAsTouched();
        return;
      }
    }
  }

  UpdateLeftMenuLinkItem(): void {
    if (this.addEditLeftMenuLinkItemForm.valid) {
      this.typeOfSpinner = "loading";
      this.leftMenuLinkItem = this.addEditLeftMenuLinkItemForm.value;
      if(this.addEditLeftMenuLinkItemForm.controls.appRoleId.value == ""){
        this.leftMenuLinkItem.appRoleId = null;
      }
      if(this.addEditLeftMenuLinkItemForm.controls.parentClinicalModuleLinkItemGuid.value == ""){
        this.leftMenuLinkItem.parentClinicalModuleLinkItemGuid = null;
      }
      this.appSecurityManagementService.UpdateLeftMenuLinkItem(this.selectedLinkItemGuid, this.leftMenuLinkItem).subscribe(
        (id) => {
          if (!_.isNil(id)) {
            this.typeOfSpinner = "none";
            this.snackBar.successMessage(DisplayMessage.updateMessage, ActionMessage.okAction);
            this.router.navigate(['viewleftmenulinkitems']);
          }
        }
      ), (error: HttpErrorResponse) => {
        this.showSnackBarMessageError(error);
      }
    }
    else {
      if (this.addEditLeftMenuLinkItemForm.invalid) {
        this.selectedTabIndex = 0;
        this.validationMessage = "Please enter the required fields."
        this.addEditLeftMenuLinkItemForm.get('linkType').markAsTouched();
        this.addEditLeftMenuLinkItemForm.get('linkKey').markAsTouched();
        this.addEditLeftMenuLinkItemForm.get('displayText').markAsTouched();
        return;
      }
    }
  }

  getSelectedLeftMenuLinkItemDetails(): void {
    if (this.selectedLinkItemGuid != "0") {
      this.typeOfSpinner = "loading";
      this.appSecurityManagementService
      .getSelectedLeftMenuLinkItemDetails(this.selectedLinkItemGuid)
      .subscribe(
        (lnk) => {
          if (!_.isNil(lnk)) {
            this.addEditLeftMenuLinkItemForm.patchValue(lnk);
          }
          else {
            this.snackBar.exceptionMessage(DisplayMessage.noDataMessage(DisplayString.lnk), ActionMessage.okAction);
          }
        }),

        (error: HttpErrorResponse) => {
            this.typeOfSpinner = 'none';
            this.customErrorHandler.handleError(error);
          }

      }
      this.typeOfSpinner = "none";
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

}
