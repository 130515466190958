import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, Subscription, of } from 'rxjs';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs/operators';
import _ from 'lodash';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { FacilityDetailsDialogComponent } from '../facility-details-dialog/facility-details-dialog.component';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { ContactDetailsDialogComponent } from '../contact-details-dialog/contact-details-dialog.component';
import { OrgAdminUserConfirmDialogModel, OrganizationOrgadminUserDialogComponent } from '../organization-orgadmin-user-dialog/organization-orgadmin-user-dialog.component';
import { OrgAdminUserModel } from 'src/app/shared/models/org-admin-user.model';
import { MatRadioChange } from '@angular/material/radio';
import { OrganizationFacilityLOB } from 'src/app/shared/models/organization-facility-lob.model';
import { ViewAdminFacilityLobDialogComponent } from '../view-admin-facility-lob-dialog/view-admin-facility-lob-dialog.component';

@Component({
  selector: 'app-organizations-lob',
  templateUrl: './organizations-lob.component.html',
  styleUrls: ['./organizations-lob.component.scss']
})
export class OrganizationsLOBComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<OrganizationFacilityLOB[]>;
  public totalRows$: Observable<number>;
  public appId: number;
  private organizations: OrganizationFacilityLOB[];
  public pageName: string;
  public userSession: UserSession = new UserSession();
  public orgAdminUserDialogSubscription: Subscription;
  public dialogSubscription: Subscription;
  selectedOption = true;
  filterValue = "";
  isLOBManager=false;
  isAdmin=false;
  isEditDisable=false;
  isInternalUserOrgAdminView=false;
  displayedColumns: string[] = ['organization', 'orgId', 'city', 'state', 'orgAdminName', 'facilityLOBs',
    'action'];

  constructor(private snackBar: SnackBarModule,
    private activatedRoute: ActivatedRoute,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private customErrorHandler: CustomErrorHandler,
    private router: Router,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => {
      this.userSession = session;
    });
    this.b2cService.getUserSesssion();
    this.getOrganizations();
    this.pageName = this.activatedRoute.snapshot.data.pageDetails.title;
    this.isLOBManager=this.appSecurityManagementService.getLOBManagerMode();
    this.isAdmin=this.appSecurityManagementService.getAdminMode();
    this.isEditDisable=this.appSecurityManagementService.checkOrganizationLOBEditStatus();
    this.isInternalUserOrgAdminView=this.appSecurityManagementService.getIsInternalUserOrgAdminView();
  }

  getOrganizations(): void {
    this.appSecurityManagementService.getOrganizationLOBs()
    .subscribe({
      next: (response) => {
        this.organizations = response;
        if (this.organizations) {
          this.organizations.forEach(organization => {

            organization.organization = organization.organization.trim();
            organization.orgAdminName = '';

            if (organization.orgAdmins.length == 0) {
              organization.orgAdminName = '';
            } else if (organization.orgAdmins.length == 1 && organization.orgAdmins[0].email) {
              organization.orgAdminName = organization.orgAdmins[0].email.toString()
            } else if (organization.orgAdmins.length > 1) {
              organization.orgAdminName = "Multiple(" + organization.orgAdmins.length + ")";
            }
          });
          this.setDisplayRows(this.organizations);
          this.getFilteredOrganizations(this.selectedOption);
        }

        this.typeOfSpinner = 'none';
      },
      error: (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      }
  });
  }

  setDisplayRows(organizationsList: OrganizationFacilityLOB[]): void {
    this.sort.active = 'organization';
    this.sort.direction = 'asc';
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(organizationsList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    this.filterValue = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    const filteredOrganizations = this.organizations.filter((organization) => {
      return (
        organization.organization && organization.organization.toLowerCase().indexOf(filterValue) !== -1 ||
        organization.orgId.toString().indexOf(filterValue) !== -1 ||
        organization.orgAdminName && organization.orgAdminName.toLowerCase().indexOf(filterValue) !== -1 ||
        organization.orgAdmins && organization.orgAdmins.map(x => x.firstName).join(",").toLocaleLowerCase().indexOf(filterValue) !== -1 ||
        organization.orgAdmins && organization.orgAdmins.map(x => x.lastName).join(",").toLocaleLowerCase().indexOf(filterValue) !== -1 ||
        organization.orgAdmins && organization.orgAdmins.map(x => x.email).join(",").toLocaleLowerCase().indexOf(filterValue) !== -1
      );
    });
    const filteredOrgsByOptions = filteredOrganizations.filter((org) => this.selectedOption == null || org.isActive == this.selectedOption);
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredOrgsByOptions);
  }

  filterOrgAdmin(filterValue: string, orgAdmins: OrgAdminUserModel[]): boolean {
    const filteredOrgAdmin = orgAdmins.filter((org) => {
      return (
        org && org.firstName.toLowerCase().indexOf(filterValue) !== -1 ||
        org && org.lastName.toLowerCase().indexOf(filterValue) !== -1 ||
        org && org.email.toLowerCase().indexOf(filterValue) !== -1
      );
    });

    return filteredOrgAdmin.length > 0;
  }

  onConditionChange(conditionChange: MatRadioChange) {
    this.selectedOption = conditionChange.value;
    if (this.filterValue == "") {
      this.getFilteredOrganizations(conditionChange.value);
    } else {
      this.applyFilter(this.filterValue);
    }
  }
  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  openFacilitiesDetailsDialog(rowData: OrganizationFacilityLOB): void {
    const dialogRef = this.dialog.open(FacilityDetailsDialogComponent, {
      data: {
        dataKey: rowData.orgId
      },
      backdropClass: 'md_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  openFacilityLobDetailsDialog(rowData: OrganizationFacilityLOB): void {
    let dialogRef = this.dialog.open(ViewAdminFacilityLobDialogComponent, {
      // width: '70%',
      data: {
        orgId: rowData.orgId
      },
      backdropClass: 'md_panel-dialog',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      dialogRef = null;
    });
  }


  openOrgAdminUserDetailsDialog(rowData: OrganizationFacilityLOB): void {
    const dialogData = new OrgAdminUserConfirmDialogModel(
      'View User', rowData.orgId, rowData.organization);
    const exportDialogRef = this.dialog.open(OrganizationOrgadminUserDialogComponent, {
      // width: '1000px',
      data: dialogData,
      backdropClass: 'md_panel-dialog'
    });

    this.orgAdminUserDialogSubscription = exportDialogRef.afterClosed().subscribe(result => {
      return;
    });
  }

  getFilteredOrganizations(condition?: boolean) {
    const filteredOrganizations = this.organizations.filter((org) => condition == null || org.isActive == condition);
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredOrganizations);
  }
}
