import { Component } from '@angular/core';
@Component({
  template: `
    <div>
    <p><button type="button" class="btn btn-primary btn-lg" (click)='navigateToHome()'>Go back to Home page</button> <br/></p>
      <lib-cps-b2clibrary></lib-cps-b2clibrary>
  </div>`,
  styles: [`div {
      width: 600px;
      margin-left:30px;
      word-break: break-word;
   }`],
})
export class ViewTokenComponent {

navigateToHome(): void{
  location.href = '#/home';
}

}
