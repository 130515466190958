import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { SecurityGroupRoles } from '../../components/add-securitygroup-with-approle//model/securitygroup-roles';
import { ApplicationRoles } from '../../components/add-securitygroup-with-approle/model/application-roles';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { DisplayMessage, DisplayString, ActionMessage } from 'src/app/shared/components/message';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddSecuritygroupWithApproleService } from '../../components/add-securitygroup-with-approle/service/add-securitygroup-with-approle.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { AppRoleModule } from 'src/app/shared/models/app-role-module.model';
import { AppRoleModuleAppRoles } from 'src/app/shared/models/app-role-module-app-roles.model';
import { ContractAppRoleModule } from 'src/app/shared/models/contract-app-role-module.model';

@Component({
  selector: 'app-approle-module-app-roles',
  templateUrl: './approle-module-app-roles.component.html',
  styleUrls: ['./approle-module-app-roles.component.scss']
})
export class ApproleModuleAppRolesComponent implements OnInit {

  displayedColumns: string[] = ['appName', 'appRoleName', 'appRoleDescription'];
  dataSource: any;
  loadAppRolesSubscription: Subscription;
  AppRoleForModuleMasterList: Array<ApplicationRoles> = [];
  securityGroup: SecurityGroupRoles;
  isNonEmpty = true;
  configuredAppRoleList: Array<ApplicationRoles> = [];
  selectedRole: ApplicationRoles;
  selectedModuleMasterAppRoles: AppRoleModule[];
  typeOfSpinner = "loading";

  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [0, 1];
  jobTitleCtrl = new FormControl();
  isEdit: boolean;
  selectedModuleMasterId: number;

  public displayedRows$: Observable<ApplicationRoles[]>;
  public totalRows$: Observable<number>;

  moduleMaterForm: FormGroup;
  routeParamSubscription: Subscription;
  getMasterModuleAppRolesSubscription: Subscription;
  getJobTitleSubscription: Subscription;

  pageTitle: string;
  currentSecurityGroupName: string;
  public selectedModuleAppRoleIdList: number[];
  public moduleName: string;
  public appRoleIdList: AppRoleModuleAppRoles;
  public contractAppRoleModule: ContractAppRoleModule[];
  public selectedApproleModuleAppRoles: AppRoleModule[];
  public unSelectedAppRoleList: Array<ApplicationRoles> = [];
  public contractId: number;
  public getContractAppRoleModuleSubscription: Subscription;
  public selectedContractAppRoleModule: ContractAppRoleModule[];
  public selectedContractAppRoleModuleAppIdList: number[];

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackBarModule,
    private addSecuritygroupWithApproleService: AddSecuritygroupWithApproleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ApproleModuleAppRolesComponent>
  ) {
  }

  ngOnInit() {
    this.routeParamSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.initForm();
        this.selectedModuleMasterId = this.data.dataKey;
        this.contractId = this.data.contractId;
        this.editModuleMasterAppRoles();
      }
    );
  }

  editModuleMasterAppRoles(): void {
    this.getSelectedModuleMasterDetails();
    this.pageTitle = "Edit Module";
  }

  initForm() {
    this.moduleMaterForm = this.formBuilder.group(
      {
        moduleId: [{ value: '', disabled: false }, Validators.required],
        moduleName: [{ value: '', disabled: false }]
      }
    );
  }

  editInitForm() {
    this.moduleMaterForm = this.formBuilder.group({});
  }

  setDisplayRows(AppRoleForModuleMasterList: ApplicationRoles[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const rows$ = of(AppRoleForModuleMasterList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
    );
  }

  groupingRolesByApp(row: ApplicationRoles, index: number): string {
    const currentIndex = this.AppRoleForModuleMasterList.indexOf(row);
    const previousMetric = this.AppRoleForModuleMasterList[currentIndex - 1];
    if (previousMetric !== undefined) {
      if (index === 0) {
        return 'showSection';
      } else {
        if (row.appName === previousMetric.appName) {
          return 'hideSection';
        }
      }
      return 'showSection';
    }

    return 'showSection';
  }

  getApplicationRoles(): void {
    this.loadAppRolesSubscription = this.addSecuritygroupWithApproleService.GetSecurityGroupAppRole().subscribe(data => {
      if (data) {
        this.AppRoleForModuleMasterList = data;
        this.filterSelectedAppRole();
        this.isNonEmpty = false;
        // this.setDisplayRows(this.AppRoleForModuleMasterList);
        this.setDisplayRows(this.configuredAppRoleList);
        this.typeOfSpinner = "none";
      }
    },
      (error: HttpErrorResponse) => {
        this.snackBar.exceptionMessage(DisplayMessage.noDataMessage(DisplayString.approles), ActionMessage.okAction);
      });
  }

  getSelectedModuleMasterDetails(): void {
    this.getMasterModuleAppRolesSubscription = this.appSecurityManagementService.getModuleMasterApproles(this.selectedModuleMasterId.toString()).subscribe(data => {
      if (data) {
        this.selectedModuleMasterAppRoles = data;
        this.getContractAppRoleModuleSubscription = this.appSecurityManagementService.getContractAppRoleModule(this.contractId.toString()).subscribe(data => {
        if (data) {
        this.selectedContractAppRoleModule = data;
         if(this.selectedContractAppRoleModule && this.selectedContractAppRoleModule.length > 0){
           this.selectedContractAppRoleModuleAppIdList = this.selectedContractAppRoleModule.filter(module => module.moduleId == this.selectedModuleMasterId).map(x => x.appRoleId);
         }
        }
        this.selectedModuleAppRoleIdList = this.selectedModuleMasterAppRoles.map(x => x.appRoleId);
        this.editInitForm();
        this.AppRoleForModuleMasterList = [];
        this.getApplicationRoles();
      },
        (error: HttpErrorResponse) => {
        this.snackBar.exceptionMessage(DisplayMessage.noDataMessage(DisplayString.securitygroup), ActionMessage.okAction);

      });
      }
    },
      (error: HttpErrorResponse) => {
        this.snackBar.exceptionMessage(DisplayMessage.noDataMessage(DisplayString.securitygroup), ActionMessage.okAction);
      });

  }

  onUpdate(): void {
    if (this.moduleMaterForm.valid) {
      this.appRoleIdList = new AppRoleModuleAppRoles();
      this.selectedApproleModuleAppRoles = this.appSecurityManagementService.getAppRoleModuleForContract();
      if(!this.selectedApproleModuleAppRoles){
         this.selectedApproleModuleAppRoles = [];
      }
      this.appRoleIdList.selectedModuleId = this.selectedModuleMasterId;
      if(this.configuredAppRoleList.length >0) {
        this.appRoleIdList.selectedAppRoleModulesAppRoleList = this.configuredAppRoleList.filter((item) => item.isSelected == true).map(x => x.appRoleID);
        this.selectedApproleModuleAppRoles = this.selectedModuleMasterAppRoles.filter(f => this.appRoleIdList.selectedAppRoleModulesAppRoleList.some(s => s === f.appRoleId));
        this.appSecurityManagementService.setAppRoleModuleForContract(this.selectedApproleModuleAppRoles, false);
      }
      else{
        this.appRoleIdList.selectedAppRoleModulesAppRoleList = this.unSelectedAppRoleList.map(x => x.appRoleID);
        this.selectedApproleModuleAppRoles = this.selectedApproleModuleAppRoles.filter(f => !this.appRoleIdList.selectedAppRoleModulesAppRoleList.some(s => s === f.appRoleId && f.moduleId ===this.selectedModuleMasterId));
        this.appSecurityManagementService.setAppRoleModuleForContract(this.selectedApproleModuleAppRoles, true);
      }
      this.dialogRef.close(this.appRoleIdList);
    }
  }
  onCancel(): void{
    this.dialogRef.close();
  }

  cancelClick(): void {
    this.router.navigate(['module']);
  }

  selectedAppRole(event: MatCheckboxChange, applicationRole: ApplicationRoles): void {
    this.selectedRole = new ApplicationRoles();
    this.selectedRole.appId = applicationRole.appId;
    this.selectedRole.appName = applicationRole.appName;
    this.selectedRole.appRoleID = applicationRole.appRoleID;
    this.selectedRole.appRoleName = applicationRole.appRoleName;
    this.selectedRole.appRoleDescription = applicationRole.appRoleDescription;
    this.selectedRole.isSelected = true;
    if (event.source.checked) {
      this.configuredAppRoleList.push(this.selectedRole);
    } else {
      this.configuredAppRoleList = this.configuredAppRoleList.filter(({ appRoleID }) => appRoleID !== this.selectedRole.appRoleID);
      this.unSelectedAppRoleList.push(this.selectedRole);
    }
  }

  filterSelectedAppRole(): void {
      if(this.selectedModuleMasterAppRoles != null) {
        this.configuredAppRoleList = this.AppRoleForModuleMasterList.filter(f => this.selectedModuleAppRoleIdList.some(s => s === f.appRoleID));
      if(this.selectedContractAppRoleModuleAppIdList && this.selectedContractAppRoleModuleAppIdList.length > 0){
        this.configuredAppRoleList.forEach(item1 => {
        const itemFromArr2 = this.selectedContractAppRoleModuleAppIdList.find(item2 => item2 == item1.appRoleID);
          if (itemFromArr2){
             item1.isSelected = true;
          }
        });
      }
      else {
             this.configuredAppRoleList.forEach(element => {
             element.isSelected = true;
           });
      }
      }
  }

}
