import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import {
  B2cService,
  UserSession
} from 'cps-b2clibrary';
import { EnvironmentInfo } from '../../models/EnvironmentInfo';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {
  constructor(private b2cService: B2cService) { }
  userSession: UserSession = new UserSession();
  authServiceSubscription: Subscription;
  environmentInfo = new EnvironmentInfo();
  ngOnInit(): void {
    this.environmentInfo.appCode = environment.appConfig.help.appCode;
    this.environmentInfo.appName = environment.appConfig.help.appName;
    this.environmentInfo.changePasswordUrl = environment.appConfig.help.changePasswordUrl;
    this.environmentInfo.contactApplicationSupportUrl = environment.appConfig.help.contactApplicationSupportUrl;
    this.environmentInfo.rxContentHub = environment.appConfig.apiServer.rxContentHub;

    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.authServiceSubscription)) {
      this.authServiceSubscription.unsubscribe();
    }
  }
}
