import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Sort, MatSort } from "@angular/material/sort";
import { of, Observable, map } from "rxjs";
import { SnackBarModule } from "src/app/shared/modules/snackbar/snack-bar.module";
import { ApplicationSecurityManagementService } from "src/app/shared/services/application-security-management.service";
import { fromMatPaginator, fromMatSort, paginateRows, sortRows } from "src/app/shared/utils/utils";
import { AdminFacility } from "../view-admin-facility/models/admin-facility";
import _ from "lodash";
import { B2cService, UserSession } from "cps-b2clibrary";
import { Router } from "@angular/router";

@Component({
  selector: "app-user-denied-entity-dialog",
  templateUrl: "./user-denied-entity-dialog.component.html",
  styleUrls: ["./user-denied-entity-dialog.component.scss"],
})
export class UserDeniedEntityDialogComponent implements OnInit {
  inheritedEntitiesForDeny: Array<AdminFacility> = [];
  showInactiveEntity = false;
  typeOfSpinner: string;
  isReadOnly= false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public displayedRows$: Observable<AdminFacility[]>;
  public totalRows$: Observable<number>;

  displayedColumns: string[] = [
    "entityId",
    "facilityName",
    "entityType",
    "unitNumber",
    "organization",
    "isActive",
    "actions",
  ];
  selectedCondition: string = Choices.INDIVIDUAL;
  conditions: string[] = ['Individual','Customer', 'CPS', 'LOB'];
  filterdValue = '';
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: any) =>
    row.hasOwnProperty.call("detailRow");
  public userSession: UserSession = new UserSession();
  constructor(
    private snackBar: SnackBarModule,
    private router: Router,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserDeniedEntityDialogComponent>,
    private b2cService: B2cService
  ) {}

  ngOnInit() {
    this.b2cService.userSession$.subscribe((session) => {
      this.userSession = session;
      this.getAllInheritedEntityDetails(this.data.userId);
    });
    this.b2cService.getUserSesssion();
    this.typeOfSpinner = "loading";
    if(this.isOrganizationAdmin() == false && this.isAdmin() == false && this.isUserManagememt() == false && this.isLOBManager()==true)
    {
      this.isReadOnly=true;
    }
  }

  applyFilter(filterValue: string): void {
    this.filterdValue = filterValue.trim().toLowerCase();
    this.filterDataAndDisplay();   
  }

  setDisplayRows(selectedUserEntityList: AdminFacility[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(selectedUserEntityList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  insertUserDenyEntity(entityForDeny: AdminFacility) {
    this.dialogRef.close(entityForDeny);
  }
  
  navigateToOrganization(row: AdminFacility) {
      this.router.navigate(['/', row.organizationID ,'superadminorgcreate'], { fragment: row.organization, queryParams:  {isEdit: !this.isReadOnly } });
      this.dialogRef.close();
  }

  isUserManagememt(): boolean {
    return this.appSecurityManagementService.getUserManagementMode();
  }

  isAdmin(): boolean {
    return this.appSecurityManagementService.getAdminMode();
  }

  isOrganizationAdmin(): boolean {
    return this.appSecurityManagementService.getOrganizationAdminMode();
  }

  isLOBManager(): boolean {
    return this.appSecurityManagementService.getLOBManagerMode();
  }

  getAllInheritedEntityDetails(userId): void {
    this.appSecurityManagementService
      .getInheritedEntitiesOfUser(userId)
      .subscribe((entities) => {
        this.inheritedEntitiesForDeny = entities;
        this.inheritedEntitiesForDeny = this.inheritedEntitiesForDeny.filter(
          (ie) =>
            this.data.userEntity.filter((e) => e.entityId === ie.entityId && e.isDenied)?.length === 0
        );
        
        this.filterDataAndDisplay();
      });
  }


  onConditionChange(choice: any) { 
    this.filterDataAndDisplay();
  }

  onShowActiveEntities(inactive){
    this.filterDataAndDisplay();
  }

  filterDataAndDisplay(){
    this.typeOfSpinner = "loading";
    let data = this.inheritedEntitiesForDeny;    
    if(this.selectedCondition === Choices.INDIVIDUAL){
      data = this.inheritedEntitiesForDeny.filter(e => (e.entityTypeId === 3 || e.entityTypeId === 4) && e.isActive === !this.showInactiveEntity);
    }else if(this.selectedCondition === Choices.CPS){
      data = this.inheritedEntitiesForDeny.filter(e => e.entityTypeId === 0 && e.entityId === 500 && e.isActive === !this.showInactiveEntity);
    }else if(this.selectedCondition === Choices.CUSTOMER){
      data = this.inheritedEntitiesForDeny.filter(e => e.entityTypeId === 1 && e.isActive === !this.showInactiveEntity);
    }else if(this.selectedCondition === Choices.LOB){
      data = this.inheritedEntitiesForDeny.filter(e => e.entityTypeId === 0 && e.entityId !== 500 && e.isActive === !this.showInactiveEntity);
    }
    const filteredFacilities = data.filter((facility) => {
      return (
        (facility.facilityName &&
          facility.facilityName.toLowerCase().indexOf(this.filterdValue) !== -1) ||
        (facility.organization &&
          facility.organization.toLowerCase().indexOf(this.filterdValue) !== -1) ||
        (facility.unitNumber &&
          facility.unitNumber.toLowerCase().indexOf(this.filterdValue) !== -1) ||
        (facility.entityId &&
          facility.entityId.toString().indexOf(this.filterdValue) !== -1) ||
        (facility.entityType &&
          facility.entityType.toLowerCase().indexOf(this.filterdValue) !== -1)
      );
    });

    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredFacilities);
    this.typeOfSpinner = "none";
  }
 
}

enum Choices{
  CPS = 'CPS',
  CUSTOMER = 'Customer',
  INDIVIDUAL = 'Individual',
  LOB = 'LOB'
}