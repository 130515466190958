// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
      width: 600px;
      margin-left:30px;
      word-break: break-word;
   }`, "",{"version":3,"sources":["webpack://./src/app/components/view-accesstoken/view-accesstoken.component.ts"],"names":[],"mappings":"AAAA;MACM,YAAY;MACZ,gBAAgB;MAChB,sBAAsB;GACzB","sourcesContent":["div {\n      width: 600px;\n      margin-left:30px;\n      word-break: break-word;\n   }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
