// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control-height {
  padding-top: 12px;
  font-size: 13px;
  line-height: 11px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/auto-complete/auto-complete.component.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;EACA,eAAA;EACA,iBAAA;AAAJ","sourcesContent":["\r\n.form-control-height {\r\n    padding-top: 12px;\r\n    font-size: 13px;\r\n    line-height: 11px;\r\n    // font-family: Tahoma, Arial, Sans-Serif;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
