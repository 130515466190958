import { UserEntitySecurityGroup } from "./user-entity-security-group.model";
import { FacilityContractTeamMemberModel } from "./facility-contract-team-member.models";
import { FacilityContractAssessmentType } from "src/app/components/add-edit-facility-lob/models/facility-contract-assessment-type";
import { FacilityContractApplication } from "src/app/components/add-edit-facility-lob/models/facility-contract-application";

export class FacilityLOB {
  facilityName: string;
  organizationId: number;
  entityId: number;
  facilityId: number;
  facilityContractId: number;
  lineOfBusinessId: number;
  unitNumber: string;
  address1: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  entityTypeId: number;
  defaultPurchasingMetricId: number;
  fiscalYearStartMonth: number;
  salesforceLineofBusiness: string;
  purchasingVendorIDPrimary: number;
  purchasingVendorIDSecondary: number;
  groupPurchasingOrganizationID: number;
  DEANumber: string;
  serviceProvidedId: number;
  serviceSubcategoryDivisionId: number;
  serviceSubCategoryId: number;
  serviceCategoryId: number;
  startDate: string;
  endDate: string;
  baselineStartDate: string;
  baselineEndDate: string;
  autoRenewMonths: number;
  lastDayOfService: string;
  isExclusionDrugManaged: boolean;
  isActive: boolean;
  isRestricted: boolean;
  parentEntityId: number;
  parentEntityName: string;
  parentEntityTypeId: number;
  exclusionDrugThreshold: number;
  isChildOfFacility: number;
  facilitySettingTypeId: number;
  isDeleted: boolean;
  facilityDescription: string;
  serviceProvided: string;
  earlyAccess: boolean;
  userEntitySecurityGroup: UserEntitySecurityGroup[];
  facilityContractAssessmentTypes: FacilityContractAssessmentType[];
  facilityContractTeamMemberUser: FacilityContractTeamMemberModel[];
  facilityContractApps: FacilityContractApplication[];
  facilityDEANumbers: FacilityDEANumber[];
  children: FacilityLOB[];
}


export class FacilityDEANumber {
  facilityDEANumberId: number;
  facilityId: number;
  deaNumber: string;
}
