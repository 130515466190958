import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { ApplicationRoles } from '../model/application-roles';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs';
import { SecurityGroupRoles, SelectedSecurityGroupRoles } from '../model/securitygroup-roles';
import { JobTitle } from '../model/job-title';
import { SecurityGroupJobTitle } from '../model/securitygroup-job-title';
import { ValidSecurityGroup } from '../model/security-group';


@Injectable({
  providedIn: 'root'
})
export class AddSecuritygroupWithApproleService {

  constructor(private http: HttpClient) { }

  GetSecurityGroupAppRole(): Observable<Array<ApplicationRoles>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SecurityGroupAppRole/GetAll`; const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<ApplicationRoles>>(apiurl, { headers }) //, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'add-security-management.service.ts- GetSecurityGroupAppRole- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetSelectedSecurityGroupDetails(securityGroupId: number): Observable<SelectedSecurityGroupRoles> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SecurityGroupAppRole/${securityGroupId}`; const headers = {
      'Content-Type': 'application/json',
    };

    const params = securityGroupId

    return this.http
      .get<SelectedSecurityGroupRoles>(apiurl, { headers }) //, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'add-security-management.service.ts- GetSecurityGroupAppRole- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  AddSecurityGroupAppRole(securityGroupwithAppRole: SecurityGroupRoles
  ): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SecurityGroupAppRole`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      securityGroupwithAppRole
    };

    return this.http.post<number>(
      apiurl,
      securityGroupwithAppRole
    );
  }

  DeleteSecurityGroup(securityGroupId: number): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SecurityGroup/${securityGroupId}`;
    return this.http.delete<number>(
      apiurl
    );
  }

  GetJobTitleList(): Observable<Array<JobTitle>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AdpJobTitles`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<JobTitle>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'add-security-group.service.ts- GetJobTitleList- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetSecurityGroupJobTitleList(): Observable<Array<SecurityGroupJobTitle>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SecurityGroupJobTitle`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<SecurityGroupJobTitle>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'add-security-group.service.ts- GetJobTitleList- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetAllSecurityGroupList(): Observable<Array<ValidSecurityGroup>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SecurityGroup`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<ValidSecurityGroup>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'add-security-group.service.ts- GetJobTitleList- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }


  updateSecurityGroupwithRolesAndJobTitles(securityGroupId: number, securityGroupwithAppRole: SecurityGroupRoles): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SecurityGroupAppRole/${securityGroupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      securityGroupwithAppRole
    };

    return this.http.put<number>(
      apiurl,
      securityGroupwithAppRole
    );
  }
}
