import { Component, OnInit,  ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import _ from 'lodash';
import { Facility } from '../../shared/models/facility.model';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { FacilityAssignUserComponent } from '../../components/facility-assign-user/facility-assign-user.component';
import { Users } from 'src/app/shared/models/users.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ConfirmationDialogComponent } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-admin-facility-assigned-users',
  templateUrl: './admin-facility-assigned-users.component.html',
  styleUrls: ['./admin-facility-assigned-users.component.scss']
})
export class AdminFacilityAssignedUsersComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() entityIdInput: number;
  @Input() facilityNameInput:string;
  @Input() showOrganizations = true;
  @Input() showLOB = true;
  @Output() userEntitySecurityGroup: EventEmitter<Users[]> = new EventEmitter();
  @Output() unAssignUser: EventEmitter<Users> = new EventEmitter();

  private userSessionSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<Users[]>;
  public totalRows$: Observable<number>;
  public facilityDetail: Facility;
  public assignedUserInEntity: Users[];
  public sortedData = [];
  userSecurityFacilityList: Users[];
  userEntitySecurityGrouplist: Users[];
  unAssignUserlist: Users[];
  selectedOption = false;
  filterValue = "";
  isAdminReadOnly=false;
  isLOBManager=false;
  isUserManagement=false;
  isAdmin=false;
  isOrgAdmin = false;
  isLOBManagerOnly = false;
  isNvaigateToSecurityGroup = false;
  @Input() resetForm: Observable<boolean>;
  private sub: any;
  orgName: string;
  isPopup = false;
  dialogFacilityRef: MatDialogRef<FacilityAssignUserComponent, any>;
  displayedColumns: string[] = ['logo', 'userId', 'firstName', 'lastName', 'email', 'securityGroupName', 'isExternal', 'isDenied',
    'action'];

  constructor(
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.orgName = this.activatedRoute.queryParams["value"].orgName;
        this.isPopup = this.activatedRoute.queryParams["value"].popup === '1' ? true : false;
      }
    );

   }

  assignUser() {
    this.router.navigate(['.'],{relativeTo: this.activatedRoute, queryParams: {tabindex: 1, orgName: this.orgName, popup: 1}});
    this.dialogFacilityRef = this.dialog.open(FacilityAssignUserComponent, {
      data: {
        dataKey: this.entityIdInput,
        selectedAssignedUser: this.userSecurityFacilityList,
        showOrganizations: this.showOrganizations,
        showLOB: this.showLOB,
        doNavigation: true
      },
      backdropClass: 'lg_panel-dialog'
    });

    this.dialogFacilityRef.afterClosed().subscribe(result => {
      if(result !== false){
        this.router.navigate(['.'],{relativeTo: this.activatedRoute, queryParams: {tabindex: 1, orgName: this.orgName, popup: 0}});
      }
      if(result && result !== false){
      this.userEntitySecurityGrouplist.push(result);
      }
      this.userEntitySecurityGroup.emit(this.userEntitySecurityGrouplist);
      if(this.entityIdInput && this.entityIdInput.toString() != "0"){
        if (!_.isNil(this.assignedUserInEntity)) {
        const len = this.assignedUserInEntity.length;
       if(this.assignedUserInEntity.length > len){
        this.assignUserSecurityFacilityList(this.assignedUserInEntity);
        this.setDisplayRows(this.assignedUserInEntity);
       }else{
        this.assignUserSecurityFacilityList(result);
        this.setDisplayRows(result);
       }
      }
      }else{
        this.assignUserSecurityFacilityList(result);
        this.setDisplayRows(result);
      }

    });
  }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.initGetAssignedUsers();
    this.isAdminReadOnly=this.appSecurityManagementService.getAdminReadOnlyMode();
    this.isLOBManager=this.appSecurityManagementService.getLOBManagerMode();
    this.isUserManagement=this.appSecurityManagementService.getUserManagementMode();
    this.isAdmin=this.appSecurityManagementService.getAdminMode();
    this.isOrgAdmin=this.appSecurityManagementService.getOrganizationAdminMode();

    if(this.isOrgAdmin == false && this.isAdmin == false && this.isUserManagement == false && this.isLOBManager==true)
    {
      this.isLOBManagerOnly=true;
    }

    if(this.isAdmin == true ||  this.isUserManagement == true){
      this.isNvaigateToSecurityGroup = true;
    }

    if(this.resetForm){
      this.sub = this.resetForm.subscribe(response => {
        if(response){
         this.initGetAssignedUsers();
        }
      });
    }
  }

  initGetAssignedUsers():void{
    this.userEntitySecurityGrouplist = [];
    this.unAssignUserlist=[];
    if(this.entityIdInput && this.entityIdInput != 0){
    this.getAssignedUsers(this.entityIdInput);
    }else{
      this.typeOfSpinner = 'none';
      this.openAssignPopup();
    }
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
    if(this.dialogFacilityRef){
      this.dialogFacilityRef.close(false);
    }
  }

  openAssignPopup(){
    if( this.isPopup){
      this.assignUser();
    }
  }

  onConditionChange(conditionChange: MatCheckboxChange){
    this.selectedOption = conditionChange.checked;
    if(this.filterValue == "") {
      this.getFilteredAssignedUsers(conditionChange.checked);
    } else {
      this.applyFilter(this.filterValue);
    }
  }

  getFilteredAssignedUsers(condition?: boolean){
    const filteredUsers=this.assignedUserInEntity.filter((user)=>condition == true || user.isInherited == condition || user.isInherited == null);
    this.paginator.pageIndex = 0;
    this.assignUserSecurityFacilityList(filteredUsers);
    this.setDisplayRows(filteredUsers);
  }

  getAssignedUsers(entityId: number): void {
    this.appSecurityManagementService.getEntityMappedUser(entityId.toString()).subscribe(
      (user) => {
        this.assignedUserInEntity = user;
        if (!_.isNil(this.assignedUserInEntity)) {
          this.getFilteredAssignedUsers(this.selectedOption);
        }
        this.openAssignPopup();
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });
  }

  assignUserSecurityFacilityList(userSecurityFacilityList: Users[]): void {
    this.userSecurityFacilityList = userSecurityFacilityList;
  }

  setDisplayRows(userSecurityFacilityList: Users[]): void {
    const filteredUsers=this.getFilterRows(userSecurityFacilityList);
    this.sort.active = 'lastName';
    this.sort.direction = 'asc';
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(filteredUsers);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  onDeleteClick(user: Users): void {
    this.deleteConfirmDialog(user);
  }

  deleteConfirmDialog(user: Users): void {
    const message = 'Are you sure you want to unassign ' + user.lastName+','+user.firstName + ' from ' + this.facilityNameInput + ' ?';
    const dialogData = new DeleteConfirmDialogModel(
      'Unassign User', message, user.userId, user.entityId);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
            if(user) {
              this.unAssignUserEntity(user);
            }
          }
    });
  }

  unAssignUserEntity(value: Users) {
    this.unAssignUserlist.push(value);this.assignedUserInEntity=this.assignedUserInEntity.filter(item => item.userId != value.userId);
    this.unAssignUser.emit(value);
    this.setDisplayRows(this.assignedUserInEntity);
  }

  applyFilter(filterValue: string): void {
    this.filterValue = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    const filteredUserSecurityFacility = this.assignedUserInEntity.filter((userSecurityFacility) => {
      return (
        (userSecurityFacility.userId && userSecurityFacility.userId.toString().indexOf(filterValue) !==
          -1) ||
        (userSecurityFacility.firstName && userSecurityFacility.firstName.toLowerCase().indexOf(filterValue) !==
          -1) ||
        (userSecurityFacility.lastName && userSecurityFacility.lastName
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
        (userSecurityFacility.email && userSecurityFacility.email
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
        (userSecurityFacility.securityGroupName && userSecurityFacility.securityGroupName
          .toLowerCase()
          .indexOf(filterValue) !== -1)
      );
    });

    const filteredUsers=this.getFilterRows(filteredUserSecurityFacility);
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredUsers);
  }

  getFilterRows(userList: Users[]): Users[] {
    let filteredUsers: Users[];
    if(this.selectedOption==true)
    {
     filteredUsers=userList.filter((user)=>user.isInherited == this.selectedOption );
    }
    else if(this.selectedOption==false)
    {
      filteredUsers=userList.filter((user)=>user.isInherited == this.selectedOption || user.isInherited == null);
    }
    return filteredUsers;
  }
}

export class DeleteConfirmDialogModel {
  constructor(public title: string, public message: string, public userId: number, public entityId: number) { }
}
