import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActionMessage, DisplayMessage } from 'src/app/shared/components/message';
import { Users } from 'src/app/shared/models/users.model';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';

const formatter = 'y-MM-dd'; // YYYY-MM-DD

@Component({
  selector: 'app-terminate-user-dialog',
  templateUrl: './terminate-user-dialog.component.html',
  styleUrls: ['./terminate-user-dialog.component.scss']
})
export class TerminateUserDialogComponent  {

  public dateOfTermination = new FormControl('', [Validators.required]);
  public currentDate: Date = new Date();
  public selectedUser: Users;
  public selectedOrganizationEntityId: number;
  public dateWarning:string = DisplayMessage.terminationDateWarningMessage
  constructor(private appSecurityManagementService: ApplicationSecurityManagementService,
    private snackBar: SnackBarModule,
    public dialogRef: MatDialogRef<TerminateUserDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DeleteConfirmDialogModel) {
    this.selectedUser = data.users;
    this.selectedOrganizationEntityId = data.selectedOrganizationEntityId;
  }

  terminateUser(): void {
    if (this.selectedUser.email != null) {
      const dt = new DatePipe(navigator.language);
      const terminationDate  = new Date(this.dateOfTermination.value);
      if(this.dateOfTermination.value == "" || terminationDate > this.currentDate)
      return;
      if(terminationDate <= this.currentDate){
      this.appSecurityManagementService
        .terminateUser(this.selectedUser.onPremisesSecurityIdentifier, dt.transform(this.dateOfTermination.value, formatter), this.selectedOrganizationEntityId)
        .subscribe((response) => {
          if (response !== null) {
            this.snackBar.successMessage(DisplayMessage.terminationSuccessMessage, ActionMessage.okAction);
            this.dialogRef.close();
            this.router.navigate(['allusers']);
          }
        },
          (error: HttpErrorResponse) => {
            if(error.status === 200){
              this.snackBar.successMessage(DisplayMessage.terminationSuccessMessage, ActionMessage.okAction);
              this.dialogRef.close();
              this.router.navigate(['allusers']);
            }else{
            this.snackBar.exceptionMessage(DisplayMessage.updateTerminationFailureMessage, ActionMessage.okAction);
            }
          });
        }else{
           this.snackBar.exceptionMessage(DisplayMessage.terminationDateWarningMessage, ActionMessage.okAction);
        }
      }
    else {
      this.snackBar.exceptionMessage(DisplayMessage.emailRequired, ActionMessage.okAction);
    }

  }

}

export class DeleteConfirmDialogModel {
  constructor(public users: Users, public selectedOrganizationEntityId: number) { }
}
