import { Injectable } from '@angular/core';
import { SharedUserAppDetailService } from '../../shared/services/shared-user-app-detail.service';
import { UserAppSelectionSession } from '../../shared/models/user-app-selection-session.model';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';

@Injectable({
  providedIn: 'root'
})
export class UrlAccessGuardService  {
  private ActivatedRouteSnapshot: any;
  private id: any;
  private userAppDetail: UserAppSelectionSession = new UserAppSelectionSession();
  private userAppDetailSubscription: Subscription;

  constructor(public router: Router, private location: Location,
              private sharedUserAppDetailService: SharedUserAppDetailService,
              private appSecurityManagementService: ApplicationSecurityManagementService) {
  }

  canActivate(
    activatedRoute: ActivatedRouteSnapshot
  ): boolean {
    this.id = activatedRoute.params.id;
    this.userAppDetailSubscription =  this.sharedUserAppDetailService.currentSelectedApp.subscribe(appDetail =>
        this.userAppDetail = appDetail);
    if (this.userAppDetail) {
  if (Number(this.id) !== Number(this.userAppDetail.selectedAppId)) {
    return false;
      }
  return true;
    }
    return true;
  }

  canLoad(): boolean {
    if (this.getRoleMode() =='OrganizationAdmin') {
      return false;
    } else{
    return true;}
  }

  getRoleMode(): string {
    return this.appSecurityManagementService.getRoleMode();
  }

}
