import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { RegionSet } from './models/region-set';
import { Contract } from './services/contract.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';
import { ContractStatus } from './models/contract-status';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganizationContract } from 'src/app/shared/models/organization-contract.model';
import { ManagePropertyDefinitionService } from 'src/app/shared/services/manage-property-definition.service';
import { PropertyDefinition } from 'src/app/shared/models/property-definition';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { DisplayMessage, ActionMessage, DisplayString } from 'src/app/shared/components/message';
import { AdminContractService } from '../view-admin-contracts/services/admin-contract.service';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
const category = 'Contract';

@Component({
  selector: 'app-add-update-contract',
  templateUrl: './add-update-contract.component.html',
  styleUrls: ['./add-update-contract.component.scss']
})
export class AddUpdateContractComponent implements OnInit {
  addUpdateContractForm: FormGroup;
  periodValue = new FormControl(moment());
  public typeOfSpinner = 'loading';
  displayedRows
  companySetList:any;
  regionList: Array<RegionSet>;
  contractStatusList: Array<ContractStatus>;
  private routeParamSubscription: Subscription;
  contractValue: OrganizationContract;
  propertyDefinitionList: Array<PropertyDefinition>;
  contractKey: string[];
  formGroupList : string[];
  isViewContract = true;
  contractId: number;

  constructor(
    private customErrorHandler: CustomErrorHandler,
    private activatedRoute: ActivatedRoute,
    private managePropertyDefinitionService: ManagePropertyDefinitionService,
    private formBuilder : FormBuilder,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private router: Router,
    private adminContractService: AdminContractService,
    private snackBar: SnackBarModule,
  ) { }

  ngOnInit() {
    this.routeParamSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.typeOfSpinner = 'loading';
        this.contractId = this.activatedRoute.fragment["value"];
        if(this.contractId){
        this.getOrganizationContracts();
        }else{
          this.bindcontrols();
        }
        const queryParam = this.activatedRoute.queryParams["value"]
        if(queryParam.view === 'true'){
          this.isViewContract = true;
        }else{
          this.isViewContract = false;
        }
      }
    );
  }

  getOrganizationContracts(): void {
    if (this.contractId !== 0) {
      this.adminContractService
        .getOrganizationContractDetails(this.contractId)
        .subscribe(
          (organizationContract) => {
            this.contractValue = organizationContract;
            this.getContractOverViewDetails();
            this.typeOfSpinner = 'none';
          },
          (error: HttpErrorResponse) => {
            this.typeOfSpinner = 'none';
            this.customErrorHandler.handleError(error);
          });
    }
  }

  getContractOverViewDetails(): void {
    const lstpropertyDefinition:any = [];
    this.managePropertyDefinitionService.getPropertyDefinitionValues(category).subscribe(
      (propertyList) => {
        this.propertyDefinitionList = propertyList;
        propertyList.forEach(element => {
          const propVal = this.contractValue[element.propertyName.charAt(0).toLowerCase() + element.propertyName.slice(1)];
          lstpropertyDefinition.push(element);
          lstpropertyDefinition[lstpropertyDefinition.length-1].defaultValue = propVal;
          if(lstpropertyDefinition.length === this.propertyDefinitionList.length){
            this.initFormGroup();
            this.propertyDefinitionList = lstpropertyDefinition;
            this.typeOfSpinner = "none";
          }
      });

      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = "none";
      });
      // if(lstpropertyDefinition.length > 0){

      //   this.formGroupList = this.propertyDefinitionList.map(m=> m.propertyName);
      //   this.initFormGroup();
      // }
  }

  initFormGroup():void {
    this.addUpdateContractForm = this.formBuilder.group({})
    this.propertyDefinitionList.forEach(item=> {
      if(item.dataTypeID === 'date'){
        this.addUpdateContractForm.addControl(item.propertyName,new FormControl(new Date(item.defaultValue)));
      }else{
      this.addUpdateContractForm.addControl(item.propertyName,new FormControl(item.defaultValue));
      }
    });
  }

  addUpdateContract(){
    this.appSecurityManagementService.addUpdateOrganizationContract(this.contractValue.contractId,this.addUpdateContractForm.value).subscribe(
      (data) => {
        const num = data.toString();
        if (data !== null && num == "1") {
          this.snackBar.successMessage(DisplayMessage.updateMessage, ActionMessage.okAction);
        }else{
        this.snackBar.successMessage(DisplayMessage.failureMessage, ActionMessage.okAction);
        }
      },
      (error: HttpErrorResponse) => {
        this.snackBar.exceptionMessage(DisplayMessage.noDataMessage(DisplayString.applicationWithAppRole), ActionMessage.okAction);
      });
    }

bindcontrols(): void {
      this.managePropertyDefinitionService.getPropertyDefinitionValues(category).subscribe(
        (propertyList) => {
          this.propertyDefinitionList = propertyList;
          this.initFormGroup();
          this.typeOfSpinner = "none";
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = "none";
        });
      }

      addContract(){
        this.addUpdateContractForm.value.IsCapitated = (this.addUpdateContractForm.value.IsCapitated == true)? 1 : 0;
        this.appSecurityManagementService.addOrganizationContract(this.addUpdateContractForm.value).subscribe(
          (data) => {
            const num = data.toString();
            if (data !== null && num == "1") {
              this.snackBar.successMessage(DisplayMessage.updateMessage, ActionMessage.okAction);
            }else{
            this.snackBar.successMessage(DisplayMessage.failureMessage, ActionMessage.okAction);
            }
          },
          (error: HttpErrorResponse) => {
            this.snackBar.exceptionMessage(DisplayMessage.noDataMessage(DisplayString.applicationWithAppRole), ActionMessage.okAction);
          });
      }
}
