import { Observable } from "rxjs";

export class FacilityLOBDropdowns {
  lineOfBusinessTypeList: Array<Dropdown>;
  entityTypeList: Array<Dropdown>;
  facilitySettingTypeList: Array<Dropdown>;
  defaultMetricList: Array<Dropdown>;
  selfServiceAccountTypeList: Array<Dropdown>;
  purchasingVendorList: Array<Dropdown>;
  secondaryPurchasingVendorList: Array<Dropdown>;
  serviceProvidedList: Array<Dropdown>;
  serviceSubCategoryList : Array<Dropdown>;
  serviceCategoryList : Array<Dropdown>;
  serviceSubcategoryDivisionList : Array<Dropdown>;
  groupPurchasingOrganizationList : Array<Dropdown>;
 }

export class Dropdown {
  dropDownId: number;
  dropDownName: string;
}

export class EntityDropdown {
  entityId: number;
  entityName: string;
  isActive: boolean;
  isOrganization: boolean;
}



