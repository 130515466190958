import { Component, ViewEncapsulation } from '@angular/core';
import { LinkService } from '../services/link.service';
@Component({
  selector: 'lib-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TermsAndConditionsComponent {
  termsAndConditions = '';
  constructor(private linkService: LinkService){
    if(this.linkService.menuEnvironment.termsAndConditions != ''){
      this.termsAndConditions = this.linkService.menuEnvironment.termsAndConditions;
    }else{
      this.termsAndConditions = 'http://www.hipaacertification.net' ;
    }

  }
}
