import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ReportItems } from '../models/report-items.model';
import { ReportMethod } from '../models/report-method.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService<T> {

  getSelected$: Observable<ReportMethod | Storage>;
  private getSelectedSubject = new Subject<Storage>();
  public selectedItems = {};

  constructor(private http: HttpClient) {
    this.getSelected$ = this.getSelectedSubject.asObservable();
  }

  reportMethod(data: ReportItems): void {
    localStorage.setItem('selectedItem', JSON.stringify(data));
    this.getSelected();
  }

  facilityMethod(data: string): void {
    localStorage.setItem('selectedFacility', data);
    this.getSelected();
  }

  getSelected(): void {
    this.getSelectedSubject.next(localStorage);
  }
}
