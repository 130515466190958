import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs';
import { SecurityManagerApplist } from '../../shared/models/security-manager-applist.model';
import { AdminApplist } from '../../shared/models/admin-applist.model';
import { AppRoleAll } from '../../shared/models/app-role-all.model';
import { SecurityGroupForUser, SecurityGroupForUserModule } from '../../shared/models/security-group-for-user.model';
import { AppRoleAllAdmin } from '../../shared/models/app-role-all-admin.model';
import { SecurityGroups } from '../../shared/models/security-groups.model';
import { Users } from '../../shared/models/users.model';
import { environment } from 'src/environments/environment';
import { Facility } from '../../shared/models/facility.model';
import { UserSecurityFacility } from '../../shared/models/user-security-facility.model';
import { OrgAdminFacility } from '../../shared/models/org-admin-facility.model';
import { PermissionsMapUserAppRole } from '../../shared/models/permissions-map-user-app-role';
import { OrganizationFacility } from '../../shared/models/organization-facility.model';
import { App, UpsertApp } from '../../shared/models/app.model';
import { FacilityUser } from '../models/facility-user.model';
import { EntityComposite } from '../models/entity-composite.model';
import { UserEntitySecurityGroup } from '../../shared/models/user-entity-security-group.model';
import { AdminContract, AssessmentType, FacilityContractDetail, OrganizationContract } from '../models/organization-contract.model';
import { Organization } from '../models/organization.model';
import { OrganizationContractFacilities } from '../../shared/models/organization-contract-facilities.model';
import { EntityDomain } from '../models/entity-domain.model';
import { AdminFacility } from 'src/app/components/view-admin-facility/models/admin-facility';
import { WebPortal } from '../models/web-portal';
import { AppRole } from 'src/app/components/add-update-app-role/model/app-roles';
import { AppType } from '../models/app-type.model';
import { ModuleMaster } from '../models/module-master.model';
import { AdminModules, AppRoleModule, ModuleMasterBIReport } from '../models/app-role-module.model';
import { Customer } from '../models/customer.model';
import { ContractAppRoleModule } from '../models/contract-app-role-module.model';
import { OrganizationModule } from '../models/organization-module';
import { ApplicationRoles, FacilityApplication, User } from '../models/user';
import { SecurityGroupRoles } from '../models/security-group-roles';
import { AppUserRolesList } from '../models/app-user-role.model';
import { UpsertAppUserRole } from '../models/upsert-app-user-role.model';
import { MenuItem } from '../models/content-menu-module.model';
import { ModuleMasterContent } from '../models/module-master-content.model';
import { CPSProductGroup } from '../models/cps-product-group.model';
import { ModuleMasterProductGroup } from '../models/module-master-product-group.model';
import { appRoleIDs, roleNames } from '../constants/constants';
import { SecurityGroupUserList } from '../models/security-groups';
import { OrgAdminSettingsEntity } from '../models/org-admin-settings-entity.model';
import { PermissionsMapUserEntity } from '../models/permissions-map-user-entity';
import { OrganizationFacilityLOB } from '../models/organization-facility-lob.model';
import { FacilityContractTeamMember } from '../models/facility-contract-team-member.models';
import { TeamMemberRole } from '../models/team-member-role.models';
import {ServiceCategoryApp} from '../models/service-category-app.model';
import {ServiceCategoryAssessmentType} from '../models/service-category-assessmenttype.model';
import {ServiceCategoryServiceProvided} from '../models/service-category-service-provided.model';
import {ServicesubCategory} from '../models/service-subcategory.model';
import { ServiceCategoryServiceSubcategoryDivision } from '../models/service-category-servicesubcategory-division.model';
import { LeftMenuLinkItem } from '../models/left-menu-linkitem.model';
import { ServiceCategory } from '../models/service-category.model';
import { State } from '../models/state.model';
import { FacilityLOB } from '../models/facility-lob.model';

@Injectable({
  providedIn: 'root',
})

export class ApplicationSecurityManagementService {
  private roleMode = 'NotSet';
  private isAdminReadOnly = false;
  private isUserManagement = false;
  private executionRoles: PermissionsMapUserAppRole[] = [];
  private securityGroupName = '';
  private appRoleName = '';
  public organizationList: Organization[];
  public selectedAppRoleModules: AppRoleModule[];
  private isLOBManager = false;
  private isAdmin = false;
  private isOrganizationAdmin = false;
  private isInternalUserOrgAdminView=false;
  private isOrganizationAdminOnly=null;
  constructor(private http: HttpClient) { }

  getSecurityManagerApps(
    apiUrl: string,
    onPremSecurityIdentifier: string
  ): Observable<SecurityManagerApplist[]> {
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      type: "SECURITYMANAGER"
    };
    return this.http
      .get<SecurityManagerApplist[]>(apiUrl, { headers, params })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  setExecutionRoles(executionRoles: PermissionsMapUserAppRole[]): void {
    this.executionRoles = executionRoles;
  }

  getExecutionRoles(): PermissionsMapUserAppRole[] {
    return this.executionRoles;
  }

  getRoleModeByRoleID(roleID: number): string {
    let roleMode: string;
    if(roleID == appRoleIDs.admin || roleID == appRoleIDs.user_management || roleID == appRoleIDs.lob_mgr)
    {
      roleMode = roleNames.admin;
    } else if(roleID == appRoleIDs.app_message_admin ){
      roleMode = roleNames.app_message_admin;
    } else if(roleID == appRoleIDs.report_admin ){
      roleMode = roleNames.report_admin;
    } else if(roleID == appRoleIDs.manager ){
      roleMode = roleNames.manager;
    } else if(roleID == appRoleIDs.organization_admin ){
      roleMode = roleNames.organization_admin;
    } else if(roleID == appRoleIDs.security_manager ){
      roleMode = roleNames.security_manager;
        } else if(roleID == appRoleIDs.left_menu_mgr ){
      roleMode = roleNames.left_menu_manager;
    } else {
      roleMode = 'NotSet';
    }

    return roleMode;
  }

  setRoleMode(roleMode: string): void {
    this.roleMode = roleMode;
  }

  getRoleMode(): string {
    return this.roleMode;
  }

  setAdminReadOnlyMode(isAdminReadOnly: boolean): void {
    this.isAdminReadOnly = isAdminReadOnly;
  }

  getAdminReadOnlyMode(): boolean {
    return this.isAdminReadOnly;
  }

  setUserManagementMode(isUserManagement: boolean): void {
    this.isUserManagement = isUserManagement;
  }

  getUserManagementMode(): boolean {
    return this.isUserManagement;
  }


  setAppRoleModuleForContract(selectedAppRoleModulesAppRoleList: AppRoleModule[], initialConfiguration: boolean): void {

    if (selectedAppRoleModulesAppRoleList.length == 0 && initialConfiguration == true) {
      this.selectedAppRoleModules = [];
    }
    else if (selectedAppRoleModulesAppRoleList) {
      if (initialConfiguration == true) {
        this.selectedAppRoleModules = [];
      }
      this.selectedAppRoleModules = this.selectedAppRoleModules.filter((appRoleModule) => selectedAppRoleModulesAppRoleList.some(e => e.moduleId !== appRoleModule.moduleId));

      selectedAppRoleModulesAppRoleList.forEach(appRoleModule => {
        const objAppRoleModule = {
          appRoleModuleId: appRoleModule.appRoleModuleId,
          appRoleId: appRoleModule.appRoleId,
          moduleId: appRoleModule.moduleId,
          appRoleName: appRoleModule.appRoleName,
          appRoleDisplayName: appRoleModule.appRoleDisplayName,
          appRoleDescription: appRoleModule.appRoleDisplayName
        };
        this.selectedAppRoleModules.push(objAppRoleModule);
      });
    }
  }

  getAppRoleModuleForContract(): AppRoleModule[] {
    return this.selectedAppRoleModules;
  }

  setOrganization(organizationList: Organization[], selectedEntityID: number): void {
    this.organizationList = organizationList
    this.organizationList.forEach(organization => {
      if (selectedEntityID !== undefined) {
        if (organization.entityId === selectedEntityID) {
          organization.selected = true;
        }
        else {
          organization.selected = false
        }
      }
    });

  }

  getOrganization(): Organization[] {
    return this.organizationList
  }

  setScurityGroupName(securityGroupName: string): void {
    this.securityGroupName = securityGroupName;
  }

  getSecurityGroupName(): string {
    return this.securityGroupName;
  }

  setAppRoleName(appRoleName: string): void {
    this.appRoleName = appRoleName;
  }

  getAppRoleName(): string {
    return this.appRoleName;
  }

  getUserAppRole(
    apiUrl: string,
    onPremSecurityIdentifier: string
  ): Observable<string[]> {
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      onPremisesSecurityIdentifier: onPremSecurityIdentifier,
    };
    return this.http
      .get<string[]>(apiUrl, { headers, params })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  isUserEmailAddressValidated(onPremisesSecurityIdentifier: string): Observable<boolean> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}UserEmailVerification/${onPremisesSecurityIdentifier}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<boolean>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- isUserEmailAddressValidated- ThrowingError To Caller...' + err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }
  getAppRoleList(): Observable<AppRoleAll[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}AppRole`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<AppRoleAll[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getAppRoleAll- ThrowingError To Caller...' + err }
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getSelectedAppInfo(appId: number): Observable<App[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}manageapp/${appId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<App[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getAppRoleAll- ThrowingError To Caller...' +err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }


  getAppRoleAll(appID: string): Observable<AppRoleAll[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}AppRole/${appID}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<AppRoleAll[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getAppRoleAll- ThrowingError To Caller...'+ err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getAppUserRolesByAppId(appID: string): Observable<AppUserRolesList[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}AppUserRole/${appID}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<AppUserRolesList[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getAppUserRolesByAppId- ThrowingError To Caller...' + err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getProductGroupByModuleId(moduleMasterId: string): Observable<ModuleMasterProductGroup[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}ModuleMasterProductGroup/${moduleMasterId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<ModuleMasterProductGroup[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getProductGroupByModuleId- ThrowingError To Caller...'+ err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }
  getAppRoleByApplication(applicationCode: string): Observable<AppRoleAll[]> {
    const apiUrl = `${environment.appConfig.apiServer.cossa}AppRole?applicationCode=${applicationCode}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<AppRoleAll[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getAppRoleAll- ThrowingError To Caller...'+ err }
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getSecurityGroupForUser(userId: string): Observable<SecurityGroupForUser> {

    const apiUrl = `${environment.appConfig.apiServer.appSecService
      }SecurityGroupUser/${Number(userId)}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {};
    return this.http
      .get<SecurityGroupForUser>(apiUrl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getSecurityGroupForUser- ThrowingError To Caller...'+ err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getSecurityGroupsForUser(userId: number): Observable<SecurityGroupForUserModule[]> {

    const apiUrl = `${environment.appConfig.apiServer.appSecService
      }SecurityGroupUser/GetAll/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {};
    return this.http
      .get<SecurityGroupForUserModule[]>(apiUrl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getSecurityGroupForUser- ThrowingError To Caller...' + err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getAllSecurityGroups(): Observable<SecurityGroupForUserModule[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SecurityGroup`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<SecurityGroupForUserModule[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'cossa.service.ts- getAllSecurityGroups- ThrowingError To Caller...' + err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getAppRoleBySecurityGroup(
    appID: string,
    securityGroupId: string
  ): Observable<AppRoleAll[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}AppRoleSecurityGroup/${securityGroupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      appID: appID,
    };
    return this.http
      .get<AppRoleAll[]>(apiUrl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getAppRoleBySecurityGroup- ThrowingError To Caller...'+ err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getPermissionsMapUserEntity( userId: string): Observable<PermissionsMapUserEntity[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}PermissionMapUserEntity/`;
    const headers = {
      'Content-Type': 'application/json',
    };

    const params = {
      userId: userId
    };

    return this.http
      .get<PermissionsMapUserEntity[]>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'cossa.service.ts- getPermissionsMapUserEntity- ThrowingError To Caller...'+ err }
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getPermissionsMapUserEntityByOnPremid( onpermId: string): Observable<PermissionsMapUserEntity[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}PermissionMapUserEntity/${onpermId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<PermissionsMapUserEntity[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'cossa.service.ts- getPermissionsMapUserEntity- ThrowingError To Caller...'+ err }
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUserIdForUser(onPremisesSecurityIdentifier: string): Observable<string[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}/api/User/${onPremisesSecurityIdentifier}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<string[]>(apiUrl, { headers })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getAppRolesByUserId(appID: string, userID: string): Observable<AppRoleAll[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AppRolesByUserId/${userID}`;

    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      appID: appID,
    };
    return this.http
      .get<AppRoleAll[]>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getAppRolesByUserId- ThrowingError To Caller...'+ err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  terminateUser(onPremisesSecurityIdentifier: string, terminationDate: string, selectedOrganizationEntityId = 0): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}TerminateUser`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      onPremisesSecurityIdentifier: onPremisesSecurityIdentifier,
      terminationDate: terminationDate,
      selectedOrganizationEntityId: selectedOrganizationEntityId
    };
    return this.http.put<string>(
      apiurl,
      {},
      { headers, params }
    );

  }

  addOrgAdminSettings(orgAdminSettings: OrgAdminSettingsEntity): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrgAdminSettings`;
    const body = {
      orgAdminSettings
    };

    return this.http.put<string>(
      apiurl,
      orgAdminSettings
    );
  }

  addAppRoleToUser(appRoleID: string, userId: string): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AppRoleSecurityGroupUser/${appRoleID}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      userID: userId,
    };

    return this.http.put<string>(
      apiurl,
      {},
      { headers, params }
    );
  }

  removeAppRoleToUser(appRoleID: string, userId: string): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AppRoleSecurityGroupUser/${appRoleID}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      userID: userId,
    };

    return this.http.delete<string>(
      apiurl,
      { headers, params }
    );
  }

  addAppRoleToSecurityGroup(
    appRoleID: string,
    securityGroupId: string
  ): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AppRoleSecurityGroup/${securityGroupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      appRoleID: appRoleID,
    };

    return this.http.put<string>(
      apiurl,
      {},
      { headers, params }
    );
  }

  removeAppRoleForSecurityGroup(
    appRoleID: string,
    securityGroupId: string
  ): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AppRoleSecurityGroup/${securityGroupId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      appRoleID: appRoleID,
    };

    return this.http.delete<string>(
      apiurl,
      { headers, params }
    );
  }

  getAllSecurityGroupUsers(id: string, forSecurityGroup: string): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}User/${id}/${forSecurityGroup}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      forSecurityGroup: forSecurityGroup
    };

    return this.http
      .get<Users[]>(apiurl, { headers, params })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  addUserToFacility(
    userId: string, entityId: number,lineOfBusinessId?: number
  ): Observable<UserEntitySecurityGroup> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}UserEntitySecurityGroup`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      userId: userId,
      entityId: entityId,
      lineOfBusinessId: lineOfBusinessId
    };

    return this.http.post<UserEntitySecurityGroup>(apiurl, body, { headers }
    );
  }


  removeEntityForUser(userId: string, entityId: string,lineOfBusinessId: string): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}UserEntitySecurityGroup/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      entityId: entityId,
      lineOfBusinessId: lineOfBusinessId,
    };

    return this.http.delete<string>(
      apiurl,
      { headers, params }
    );
  }

  unAssignOrgadminFromEntity(userId: string, entityId: string): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrgAdminByUserID/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      entityId: entityId
    };

    return this.http.delete<string>(
      apiurl,
      { headers, params }
    );
  }

  getAllAppRoleUsers(appRoleID: string): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}UsersByAppRoleId/${appRoleID}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUserExecutionRoles(
    onPremSecurityIdentifier: string
  ): Observable<PermissionsMapUserAppRole[]> {
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      onPremisesSecurityIdentifier: onPremSecurityIdentifier,
      executionRoleName: "USER"
    };
    return this.http
      .get<PermissionsMapUserAppRole[]>(`${environment.appConfig.apiServer.appSecService}UserAppRole`, { headers, params })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getAdminAppsAll(): Observable<AdminApplist[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}App`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<AdminApplist[]>(apiurl, { headers })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getAdminApps(
    apiUrl: string,
    onPremSecurityIdentifier: string
  ): Observable<AdminApplist[]> {
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      type: "ADMIN"
    };
    return this.http
      .get<AdminApplist[]>(apiUrl, { headers, params })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getOrgAdminSettings(entityId: string): Observable<OrgAdminSettingsEntity> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}OrgAdminSettings/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      entityId: entityId
    };
    return this.http
      .get<OrgAdminSettingsEntity>(apiUrl, { headers, params })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getAppRoleAllForAdmin(appID: string): Observable<AppRoleAllAdmin[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}AppRole/${appID}/admin`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<AppRoleAllAdmin[]>(apiUrl, { headers })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  addSecurityGroup(
    secGroupName: string,
    secGroupDesc: string,
    secGroupAbbreviation: string
  ): Observable<SecurityGroups> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SecurityGroup/AddSecurityGroup`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      securityGroupName: secGroupName,
      securityGroupDesc: secGroupDesc,
      securityGroupAbbreviation: secGroupAbbreviation,
    };

    return this.http.put<SecurityGroups>(
      apiurl,
      {},
      { headers, params }
    );
  }

  getOrgAdminFacilities(onPremSecurityIdentifier: string): Observable<OrgAdminFacility[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrgAdminFacility/${onPremSecurityIdentifier}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<OrgAdminFacility[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'cossa.service.ts- getFacilities- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getOrganizationList(onPremSecurityIdentifier: string): Observable<Organization[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationByOnPremisesSecurityIdentifier/${onPremSecurityIdentifier}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Organization[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'cossa.service.ts- getOrganizationList- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getAllOrganizationList(): Observable<Organization[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationByOnPremisesSecurityIdentifier/`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Organization[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'cossa.service.ts- getOrganizationList- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getAssignedUsers(entityId: string): Observable<UserSecurityFacility[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}UserFacility/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<UserSecurityFacility[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'cossa.service.ts- getAssignedUsers- ThrowingError To Caller...'+ err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getAssignUsers(entityId: string): Observable<UserSecurityFacility[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}UserFacility/AllUsersNotInFacility`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      id: entityId,
    };
    return this.http.get<UserSecurityFacility[]>(apiUrl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'cossa.service.ts- getAssignUsers- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getFacilityApplications(entityId: string): Observable<App[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AppsEntity/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      entityId: entityId,
    };
    return this.http
      .get<App[]>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getFacilityApplications- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUsersFacility(webPortalCode: string, onPremisesSecurityIdentifier: string): Observable<FacilityUser[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}UserEntity/${webPortalCode}/${onPremisesSecurityIdentifier}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<FacilityUser[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'cossa.service.ts- getAppRoleAll- ThrowingError To Caller...' + err }
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getOrganizationUseNotInEntity(entityRootId: string, entityId: string): Observable<Users[]> {

    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationUserByEntityIdNotInEntity/${entityRootId}/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUserNotInEntity(entityId: string): Observable<Users[]> {

    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationUserByEntityIdNotInEntity/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  isExistEmailId(email: string): Observable<boolean> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}UserEmail/${email}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<boolean>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- isExistEmailId- ThrowingError To Caller...'+err }
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  UpdateUserEmailVerification(onPremisesSecurityIdentifier : string): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}UserEmailVerification/${onPremisesSecurityIdentifier}`;

    return this.http.put<number>(
      apiurl,
      onPremisesSecurityIdentifier
    );
  }

  deleteFacility(facilityId: string, entityId: string): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}Facility/${facilityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      entityId: entityId,
    };
    return this.http.delete<number>(
      apiurl,
      { headers, params }
    );
  }

  isExistEmailDomain(entityId: string): Observable<EntityDomain[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}EntityDomain/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      entityId: entityId
    };

    return this.http
      .get<EntityDomain[]>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- isExistEmailDomain- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetSecurityGroupAppRole(entityId: number): Observable<Array<ApplicationRoles>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationAppsAppRoles/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<ApplicationRoles>>(apiurl, { headers }) //, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- GetSecurityGroupAppRole- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }


  GetSecurityGroupAppRoleForFacility(entityIds: number[]): Observable<Array<ApplicationRoles>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationAppsAppRoles/GetFacilitiesApps`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      entityIds: entityIds
    };
    return this.http
      .get<Array<ApplicationRoles>>(apiurl, {  headers , params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- GetSecurityGroupAppRole- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetSecurityGroupAppRoleForFacilityByUserId(entityIds: number[],onPremisesSecurityIdentifier: string): Observable<Array<ApplicationRoles>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationAppsAppRoles/GetFacilitiesAppsByEntityIdUserId`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      entityIds: entityIds,
      onPremisesSecurityId:onPremisesSecurityIdentifier
    };
    return this.http
      .get<Array<ApplicationRoles>>(apiurl, {  headers , params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- GetSecurityGroupAppRole- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }


  GetSecurityGroupAppsForFacilityByUserId(entityIds: number[],onPremisesSecurityIdentifier: string): Observable<Array<FacilityApplication>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationAppsAppRoles/GetFacilitiesAppsByUserEntityId`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      entityIds: entityIds,
      onPremisesSecurityId:onPremisesSecurityIdentifier
    };
    return this.http
      .get<Array<FacilityApplication>>(apiurl, {  headers , params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- GetSecurityGroupAppRole- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetFacilities(entityRootId: number): Observable<EntityComposite[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationFacility/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<EntityComposite>>(apiurl, { headers }) //, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- GetSecurityGroupAppRole- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetChildFacilities(entityRootId: number): Observable<EntityComposite[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationFacility/${entityRootId}/childern`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<EntityComposite>>(apiurl, { headers }) //, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- GetSecurityGroupAppRole- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetUserEntityDescendants(userId: number): Observable<EntityComposite[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}EntityDescendant/Entities/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<EntityComposite>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- GetUserEntityDescendants- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetClinics(entityRootId: number): Observable<EntityComposite[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}EntityDescendant/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<EntityComposite>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- GetClinics- ThrowingError To Caller...'+ err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  AddSecurityGroupAppRole(securityGroupwithAppRole: SecurityGroupRoles
  ): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SecurityGroupAppRole`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      securityGroupwithAppRole
    };

    return this.http.post<number>(
      apiurl,
      securityGroupwithAppRole
    );
  }

  AddApplications(app: UpsertApp): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}app`;
    const body = {
      app
    };

    return this.http.post<number>(
      apiurl,
      app
    );
  }

  UpdateApplications(appId : number,app: UpsertApp): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}app/${appId}`;
    const body = {
      app
    };

    return this.http.put<number>(
      apiurl,
      app
    );
  }

  AddAppUserRole(upsertAppUserRolesList: UpsertAppUserRole): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AppUserRole`;

      const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      upsertAppUserRolesList
    };

    return this.http.post<number>(
      apiurl,
      upsertAppUserRolesList
    );
  }

  AddNewUser(user: User
  ): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationUser`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      user
    };

    return this.http.post<number>(
      apiurl,
      user
    );
  }

  AddNewExternalUser(user: User
  ): any {

    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationExternalUser`;
    const headers = {
      'Content-Type': 'application/json',
    };

    const body = {
      user
    };

    return this.http.post<number>(
      apiurl,
      user
    );
  }

  getOrganizationFacilityUser(entityRootId: string): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationFacilityUser/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getOrganizationUser(entityRootId: string): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationUser/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUser(): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SuperAdminUser`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- SuperAdminUser- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUsersBasedOnRoles(onPremisesSecurityIdentifier: string): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}SuperAdminUser/${onPremisesSecurityIdentifier}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- SuperAdminUser- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getWebPortalCode(): Observable<WebPortal[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}WebPortal`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<WebPortal[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- WebPortal- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getAppType(): Observable<AppType[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AppType`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<AppType[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- AppType- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getOrganizationUserWithFacility(entityRootId: string): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationBusinessUser/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUserToAssignOrgAdmin(entityRootId: string): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}UserForOrgAdmin/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUserToAssignBusinessUser(entityRootId: string): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}UserForBusiness/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getOrganizationAdminsWithFacility(entityRootId: string): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationOrgAdmin/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }


  getEntityMappedUser(entityId: string): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationUsersByEntityId/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getAllAssignedUsers(): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationUsersByEntityId/`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getOrganizations(): Observable<OrganizationContractFacilities[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}OrganizationContractFacilities`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<OrganizationContractFacilities[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getOrganizations- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }


  getOrganizationLOBs(): Observable<OrganizationFacilityLOB[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}OrganizationFacilityLOB`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<OrganizationFacilityLOB[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getOrganizationLOBs- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getOrganizationFacilities(entityRootId: string): Observable<OrganizationFacility[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}OrganizationFacility/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<OrganizationFacility[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getOrganizationFacilities- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getOrganizationFacilitiesLOB(parentEntityId: string): Observable<FacilityLOB[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}OrganizationFacility/FacilityLOBContract/${parentEntityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<FacilityLOB[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getOrganizationFacilitiesLOB- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getOrganizationFacilitiesForContract(contractId: string): Observable<OrganizationFacility[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}OrganizationFacility/Contract/${contractId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<OrganizationFacility[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getOrganizationFacilitiesForContract- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getContractFacilities(entityRootId: string): Observable<OrganizationFacility[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}OrganizationFacility/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<OrganizationFacility[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getOrganizationFacilities- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getFacilities(): Observable<OrganizationFacility[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<OrganizationFacility[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getOrganizationFacilities- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getUserEntitySecurityGroup(userId: number): Observable<Array<UserEntitySecurityGroup>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}UserEntitySecurityGroup/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<Array<UserEntitySecurityGroup>>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getUserEntitySecurityGroup- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getUserEntitySecurityGroupByOnPremId(onPremId: string): Observable<Array<UserEntitySecurityGroup>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}UserEntitySecurityGroup/by-onprem/${onPremId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<Array<UserEntitySecurityGroup>>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getUserEntitySecurityGroup- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getUserEntityByUserId(userId: number): Observable<Array<AdminFacility>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}AssignUserEntity/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<Array<AdminFacility>>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getUserEntitySecurityGroup- ThrowingError To Caller...'+ err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getUserEntitySecurityGroupList(): Observable<Array<UserEntitySecurityGroup>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}UserEntitySecurityGroup`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<Array<UserEntitySecurityGroup>>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getUserEntitySecurityGroup- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getAssignedAdminFacilityDetails(facilityId: number): Observable<any> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}adminfacility/${facilityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<any>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getAssignedAdminFacilityDetails- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getUnAssignedAdminFacilityDetails(onPremisesSecurityIdentifier: string, userid: number): Observable<Array<AdminFacility>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}adminfacility/${onPremisesSecurityIdentifier}/${userid}/facilities`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<Array<AdminFacility>>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getAssignedAdminFacilityDetails- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getOrganizationContract(entityId: any): Observable<Array<OrganizationContract>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}OrganizationContract/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<Array<OrganizationContract>>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getOrganizationContract- ThrowingError To Caller...'+ err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  addUpdateOrganizationContract(contractId: number, organizationContracts: AdminContract): Observable<number> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationContract/${contractId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      organizationContracts
    };

    return this.http.put<number>(
      apiurl,
      organizationContracts
    );
  }

  addOrganizationContract(organizationContracts: AdminContract): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationContract`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      organizationContracts
    };

    return this.http.post<string>(
      apiurl,
      organizationContracts
    );
  }

  getAssignedApplications(): Observable<App[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}App`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<App[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getAssignedApplications- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getAppRoleByAppRoleId(appRoleId: number): Observable<AppRole> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}AppRole/Admin/${appRoleId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<AppRole>(apiUrl, { headers })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getAssessmentType(): Observable<AssessmentType[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}ContractAssessmentType`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<AssessmentType[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getContractAssessmentType- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getModuleMasterApproles(moduleId: string): Observable<AppRoleModule[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AppRoleModule/${moduleId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      moduleId: moduleId,
    };
    return this.http
      .get<AppRoleModule[]>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getModuleMasterAproles- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getModuleMasterBIReportDetails(moduleMasterId: string): Observable<ModuleMasterBIReport[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}ModuleMasterBIReport`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      moduleMasterId: moduleMasterId,
    };
    return this.http
      .get<ModuleMasterBIReport[]>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getModuleMasterAproles- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getContractAppRoleModule(contrctId: string): Observable<ContractAppRoleModule[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}ContractAppRoleModule/${contrctId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      contrctId: contrctId,
    };
    return this.http
      .get<ContractAppRoleModule[]>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getContractAppRoleModule- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  updateAppRolesToModuleMater(moduleId: number, adminModules: AdminModules): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AppRoleModule/${moduleId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      adminModules
    };
    return this.http.put<number>(
      apiurl, adminModules
    );
  }

  getModuleMaster(): Observable<ModuleMaster[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}ModuleMaster`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<ModuleMaster[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'cossa.service.ts- getModuleMaster- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getCustomer(): Observable<Customer[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}Customer`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Customer[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getCustomer - ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getOrganizationModule(moduleId: number): Observable<OrganizationModule[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationModule/${moduleId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<OrganizationModule[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUserRolesList(): Observable<AppUserRolesList[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}UserRole`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<AppUserRolesList[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getUserRolesList - ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getFacilityContract(facilityId: number, contractId: number): Observable<any[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}FacilityContract/${facilityId}/${contractId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<any[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getFacilityContract - ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  updateFacilityContract(facilityContractId: number, facilityContract: FacilityContractDetail): Observable<number> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}FacilityContract/facilityContractId?facilityContractId=${facilityContractId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      facilityContract
    };

    return this.http.put<number>(
      apiurl,
      facilityContract
    );
  }

  getContentMenuDetails(menu: string): Observable<MenuItem[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}ContentMenu/Menus${menu}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<MenuItem[]>(apiurl, { headers,  })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getContentMenuDetails - ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getModuleMasterById(moduleMasterId: number): Observable<ModuleMaster> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}ModuleMaster/${moduleMasterId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<ModuleMaster>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getModuleMasterById - ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getModuleMasterContentDetails(moduleMasterId: number, contentMenu: string): Observable<ModuleMasterContent[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}ModuleMasterContent/?moduleMasterId=${moduleMasterId}&contentMenu=${contentMenu}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<ModuleMasterContent[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getModuleMasterContentDetails - ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getFacilityDetails(facilityId: string): Observable<Facility> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}AdminFacility/${facilityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      facilityID: facilityId,
    };
    return this.http.get<Facility>(apiUrl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getFacilityDetails- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getCpsProductGroup(): Observable<CPSProductGroup[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}ProductGroup`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<CPSProductGroup[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'purchasing.service.service.ts- getCpsProductGroup - ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getFacilityContractTeamMember(facilityContractID: number): Observable<any[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}FacilityContractTeamMember/${facilityContractID}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<any[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getFacilityContractTeamMember - ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  LeftMenuVisible(userAppRoles: string[]) : boolean {
    return this.CheckRoleByName(userAppRoles, 'RS_Main-Making Left/Top Menu Visible');
  }

  CheckRoleByName(userAppRoles: string[], appRoleName: string) : boolean {
    let isGivenRole = false;
    const userApplicationRoles =  userAppRoles;

    userApplicationRoles.forEach((userApplicationRoleName : any) => {
        if (userApplicationRoleName.appRoleName.trim() === appRoleName) {
          isGivenRole = true;
        }
    });

    return isGivenRole;
  }

  getLineOfBusinessDetails(): Observable<Array<any>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}LineOfBusiness/`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<Array<any>>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getLineOfBusinessDetails- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getSecurityGroups(userId : number): Observable<SecurityGroupUserList[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}AdminSecurityGroupUser/${userId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<SecurityGroupUserList[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  setLOBManagerMode(isLOBManager: boolean): void {
    this.isLOBManager = isLOBManager;
  }

  getLOBManagerMode(): boolean {
    return this.isLOBManager;
  }

  setOrganizationAdminMode(isOrganizationAdmin: boolean): void {
    this.isOrganizationAdmin = isOrganizationAdmin;
  }

  getOrganizationAdminMode(): boolean {
    return this.isOrganizationAdmin;
  }

  getUserNotInFacilityContractTeamMember(facilityContractId: string, orgId: number, isSaasFacility: boolean): Observable<FacilityContractTeamMember[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}UserForFacilityContractTeamMember/${facilityContractId}/${orgId}/${isSaasFacility}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<FacilityContractTeamMember[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

getTeamMemberRoleDetails(): Observable<Array<TeamMemberRole>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}TeamMemberRole/`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http.get<Array<any>>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getLineOfBusinessDetails- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getServiceCategoryAppList(serviceCategoryId: number): Observable<Array<ServiceCategoryApp>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityLOBDropdown/ServiceCategoryApp/?serviceCategoryID=${serviceCategoryId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<ServiceCategoryApp>>(apiUrl, { headers })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getServiceCategoryAssessmentTypeList(serviceCategoryId: number,serviceSubCategoryId: number): Observable<Array<ServiceCategoryAssessmentType>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityLOBDropdown/ServiceCategoryAssessmentType/?serviceCategoryID=${serviceCategoryId}&serviceSubcategoryID=${serviceSubCategoryId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<ServiceCategoryAssessmentType>>(apiUrl, { headers })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getServiceCategoryServiceProvidedList(serviceCategoryId: number): Observable<Array<ServiceCategoryServiceProvided>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityLOBDropdown/ServiceCategoryServiceProvided/?serviceCategoryID=${serviceCategoryId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<ServiceCategoryServiceProvided>>(apiUrl, { headers })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getServiceCategoryListByLineOfBusinessTypeId(lineOfBusinessId: number): Observable<Array<ServiceCategory>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityLOBDropdown/ServiceCategoryByLineOfBusinessId/?lineOfBusinessId=${lineOfBusinessId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<ServiceCategory>>(apiUrl, { headers })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getServiceCategoryServicesubCategoryList(serviceCategoryId: number): Observable<Array<ServicesubCategory>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityLOBDropdown/ServicesubCategory/?serviceCategoryID=${serviceCategoryId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<ServicesubCategory>>(apiUrl, { headers })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getServiceCategoryServiceSubcategoryDivisionList(serviceCategoryId: number): Observable<Array<ServiceCategoryServiceSubcategoryDivision>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityLOBDropdown/ServiceCategoryServiceSubcategoryDivision/?serviceCategoryID=${serviceCategoryId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<ServiceCategoryServiceSubcategoryDivision>>(apiUrl, { headers })
      .pipe(tap((data) => JSON.stringify(data)));
  }

  getInheritedEntitiesOfUser(userId: string): Observable<Array<AdminFacility>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}assignuserentity/${userId}/inherited-entities`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<Array<AdminFacility>>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getInheritedEntitiesOfUser- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  setAdminMode(isAdmin: boolean): void {
    this.isAdmin = isAdmin;
  }

  getAdminMode(): boolean {
    return this.isAdmin;
  }

  getLeftMenuLinkItems(): Observable<LeftMenuLinkItem[]>{
    const apiUrl = `${environment.appConfig.apiServer.appSecService}ClinicalModuleLinkItem/`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<LeftMenuLinkItem[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getLeftMenuLinkItems- ThrowingError To Caller...'+ err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getSelectedLeftMenuLinkItemDetails(clinicalModuleLinkItemGuid: string): Observable<LeftMenuLinkItem> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}ClinicalModuleLinkItem/${clinicalModuleLinkItemGuid}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<LeftMenuLinkItem>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.ts- getSelectedLeftMenuLinkItemDetails- ThrowingError To Caller...' +err}
          );
        }),
        tap((data) => JSON.stringify(data))
        );
      }

      AddLeftMenuLinkItem(linkItem: LeftMenuLinkItem): Observable<number> {
        const apiurl = `${environment.appConfig.apiServer.appSecService}ClinicalModuleLinkItem`;
        const headers = {
          'Content-Type': 'application/json',
        };
        const body = {
          linkItem
        };

        return this.http.post<number>(
          apiurl,
          linkItem
        );
      }

      UpdateLeftMenuLinkItem(clinicalModuleLinkItemGuid : string, linkItem: LeftMenuLinkItem): Observable<number> {
        const apiurl = `${environment.appConfig.apiServer.appSecService}ClinicalModuleLinkItem/${clinicalModuleLinkItemGuid}`;
        const headers = {
          'Content-Type': 'application/json',
        };
        const body = {
          linkItem
        };


        return this.http.put<number>(
          apiurl,
          linkItem
        );
      }

  getCPSInternalUser(): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}User/CPSInternalUser`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getState(): Observable<Array<State>> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}State`; const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<State>>(apiUrl, { headers })
      .pipe(map((data) => data));
  }

  checkOrganizationLOBEditStatus(): boolean {
    if (!this.isOrganizationAdmin && !this.isAdmin && !this.isLOBManager && this.isUserManagement==true) {
      return true;
    }
    else {
      return false;
    }
  }

  setIsInternalUserOrgAdminView(isInternalUserOrgAdminView: boolean): void {
    this.isInternalUserOrgAdminView = isInternalUserOrgAdminView;
  }

  getIsInternalUserOrgAdminView(): boolean {
    return this.isInternalUserOrgAdminView;
  }

  setIsOrganizationAdminOnly(isOrganizationAdminOnly: boolean): void {
    this.isOrganizationAdminOnly = isOrganizationAdminOnly;
  }

  getIsOrganizationAdminOnly(): boolean {
    return this.isOrganizationAdminOnly;
  }
}
