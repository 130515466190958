// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-list-viewport {
  height: 450px;
}

.item_container {
  overflow-y: auto;
  max-height: calc(100vh - 315px);
}

.mat-expansion-panel-header {
  font-weight: normal;
}

/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
.mat-paginator-sticky {
  bottom: 0;
  position: sticky;
  background-color: white;
  z-index: 10;
  border-top: 1px solid #e4e7eb;
}

.mat-drawer-content {
  overflow-y: hidden !important;
  padding-bottom: 15px;
  background-color: #fafafa;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-users-list/app-users-list.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,gBAAA;EACA,+BAAA;AACF;;AAIA;EACE,mBAAA;AADF;;AAIA,+HAAA;AACA;EACE,SAAA;EAEA,gBAAA;EACA,uBAAA;EACA,WAAA;EACA,6BAAA;AADF;;AAIA;EACE,6BAAA;EACA,oBAAA;EACA,yBAAA;AADF;;AAIA;EACE,uBAAA;EACA,gBAAA;EACA,mBAAA;AADF","sourcesContent":[".user-list-viewport {\r\n  height: 450px;\r\n}\r\n\r\n.item_container {\r\n  overflow-y: auto;\r\n  max-height: calc(100vh - 315px);\r\n  // border: 1px solid #e4e7eb;\r\n  // border-radius: 6px;\r\n}\r\n\r\n.mat-expansion-panel-header {\r\n  font-weight: normal;\r\n}\r\n\r\n/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/\r\n.mat-paginator-sticky {\r\n  bottom: 0;\r\n  position: -webkit-sticky;\r\n  position: sticky;\r\n  background-color: white;\r\n  z-index: 10;\r\n  border-top: 1px solid #e4e7eb;\r\n}\r\n\r\n.mat-drawer-content {\r\n  overflow-y: hidden !important;\r\n  padding-bottom: 15px;\r\n  background-color: #fafafa;\r\n}\r\n\r\n.ellipsis {\r\n  text-overflow: ellipsis;\r\n  overflow : hidden;\r\n  white-space: nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
