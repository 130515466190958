// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  width: 520px;
  padding: 20px;
  margin: 20px;
  margin-bottom: 5px;
  background-color: #fff6ef;
}

.padding-10px {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-role-panel/app-role-panel.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".box {\r\n  width: 520px;\r\n  padding: 20px;\r\n  margin: 20px;\r\n  margin-bottom: 5px;\r\n  background-color: #fff6ef;\r\n}\r\n\r\n.padding-10px {\r\n  padding: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
