// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-logo {
  border: none;
  height: 150px;
}

.layout-container {
  margin: 0;
  padding: 15px 30px 75px;
  background: #fafafa;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/terms-and-conditions/terms-and-conditions.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAAY,aAAA;AAEd;;AACA;EACE,SAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAEF","sourcesContent":[".terms-logo{\r\n  border:none;height:150px;\r\n}\r\n\r\n.layout-container  {\r\n  margin: 0;\r\n  padding: 15px 30px 75px;\r\n  background: #fafafa;\r\n  position: relative;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
