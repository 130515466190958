import { AfterViewInit, Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import { Observable, Subscription, of } from 'rxjs';
import { ApplicationsService } from '../../shared/services/applications.service';
import { ApplicationList } from '../../shared/models/application-list';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { AppRoleAll } from '../../shared/models/app-role-all.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { CossaService } from '../../shared/services/cossa.service';
import { ActivatedRoute, Params, Router} from '@angular/router';
import { AppRoleService } from '../../shared/services/app-role.service';
import { MemoVoidArrayIterator } from 'lodash';

@Component({
  selector: 'app-org-admin-user-applications',
  templateUrl: './org-admin-user-applications.component.html',
  styleUrls: ['./org-admin-user-applications.component.scss']
})
export class OrgAdminUserApplicationsComponent implements OnInit {

  private applicationSubscription: Subscription;
  public userApplicationsList: Array<ApplicationList> = [];
  userSession: UserSession = new UserSession();
  private userSessionSubscription: Subscription;
  private appRoleAll: AppRoleAll[];
  public displayedRows$: Observable<ApplicationList[]>;
  public totalRows$: Observable<number>;
  private routeParamSubscription: Subscription;
  @Input() userId: number;
  public sortedData: Array<ApplicationList> = [];
  public expansionOpened: boolean;
  public hideExpansionPanel = false;
  public isOpen = false;
  public currentAppId: number;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor( private applicationsService: ApplicationsService,
               private b2cService: B2cService,
               private appSecurityManagementService: ApplicationSecurityManagementService,
               private cossaService: CossaService,
               private activatedRoute: ActivatedRoute,
               private appRoleService: AppRoleService) {

               }

  ngOnInit(): void {
    this.currentAppId = 0;
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();

      this.getApplications(this.userSession.onPremisesSecurityIdentifier);
        this.setDisplayRows(this.sortedData);
        this.hideExpansionPanel = false;

  }

  applyFilter(): void {
    this.setDisplayRows(this.sortedData);
  }

  getApplications(onPremisesSecurityIdentifier): void {
    this.applicationSubscription = this.applicationsService
      .readApplications()
      .subscribe((values) => {
        this.sortedData = values;
        this.setDisplayRows(this.sortedData);
      });
  }
  getUserapp(userOnPremisesSecurityIdentifier: string): void {
        this.cossaService.getUserApp(userOnPremisesSecurityIdentifier).subscribe((values) => {
        this.userApplicationsList = values;
        this.setDisplayRows(this.userApplicationsList);
      });
  }

  isCurrentApp(application: ApplicationList): boolean {
    if (this.currentAppId === application.applicationId) {
      return true;
    } else {
      return false;
    }
  }

  loadNextApplicationAppRoles(application: ApplicationList): boolean {
     if (this.currentAppId === application.applicationId) {
      return true;
    } else {
      return false;
    }
  }

  panelOpened(application: ApplicationList): void {
    this.currentAppId = application.applicationId;
    this.expansionOpened = true;
    this.isOpen = true;
  }

  afterPanelOpened(application: ApplicationList): void {
    this.currentAppId = application.applicationId;
    this.expansionOpened = true;
  }

  setDisplayRows(applicationsList: ApplicationList[]): void {
    const rows$ = of(applicationsList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ =rows$;
  }

  getAppRoles(application: ApplicationList): ApplicationList {
     if (application.AppRoleAll && application.AppRoleAll.length) {
       return application;
     } else {
      this.appSecurityManagementService.getAppRoleAll(application.applicationId.toString()).subscribe(
        (appRole) => {
          if (appRole !== undefined) {
            return (application.AppRoleAll =
              appRole);
          } else {
           return appRole;
          }
        });

    }
  }

}
