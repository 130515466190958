import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  Input,
  ViewEncapsulation,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarModule } from '../modules/snackbar/snack-bar.module';
import { ApplicationsService } from '../services/applications.service';
import { HelpMenuItem } from '../models/help-menu-items';
import { MessageNotification } from '../models/message-notification';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { environment } from '../models/environment-inject';
import { LinkService } from '../services/link.service';
import { getLocalStorageValue } from '../services/utils';
import { B2cService, UserSession } from 'cps-b2clibrary';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class HeaderComponent implements OnInit, AfterViewInit {
  // Input property for message notification object
  @Input() messageNotification: MessageNotification = new MessageNotification();

  // Input property for login URL
  @Input() loginURL = '';

  // Input property for user ID
  @Input() userId = '';

  // Input property for external forgot password link
  @Input() forgotPasswordLinkExternal = '';

  // Input property to receive menu environment data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() menuEnvironment: any = {};

  // Output property for menu expand and collapse event
  @Output() menuExpandCollapse = new EventEmitter<boolean>();

  // Flag to indicate if the user is external
  isExternal = false;

  // Variable to hold application role name
  appRoleName = '';

  // Icon for search functionality
  searchIcon = 'search';

  // User session object
  userSession: UserSession = new UserSession();

  // Key for user app roles in local storage
  private localStorageUserAppRoles = 'userAppRoles';

  // Key for app messaging in local storage
  private localStorageAppMessaging = 'appMessaging';

  // List of help menu items
  helpMenuList: HelpMenuItem[] = [];

  // Flag to indicate if the menu is expanded
  isExpanded = true;

  // Initials of the username
  usernameInitial!: string;

  // Current URL
  url = window.location.href;

  // Extracted domain from the URL
  domain: string = this.url
    .replace('http://', '')
    .replace('https://', '')
    .split(/[/?#]/)[0];

  // Subscription for roles
  rolesSubscription!: Subscription;

  // User name string
  strUserName!: string;

  // User email address
  email!: string;

  // Array to hold user organization data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UserOrganization: any = [];
  constructor(
    // Injecting required services and dependencies
    private _renderer2: Renderer2,
    private b2cService: B2cService,
    private snackBar: SnackBarModule,
    private applicationsService: ApplicationsService,
    private dataService: DataService,
    private linkService: LinkService,
    @Inject('environment') private currentEnvironment: environment,
    // Injecting document object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(DOCUMENT) private _document: any
  ) {
    // Subscribe to user organization changes
    this.b2cService.userOrganization$.subscribe((userOrg) => {
      this.UserOrganization = userOrg || [];
    });
    // Retrieve user session data
    this.b2cService.getUserSesssion();
  }
  

  ngOnInit(): void {
    // If there's a stored current page URL, redirect to it and clear the storage
    if (
      getLocalStorageValue('currnetpageurl') &&
      getLocalStorageValue('currnetpageurl') != ''
    ) {
      const redirectLink = getLocalStorageValue('currnetpageurl');
      localStorage.setItem('currnetpageurl', '');
      window.location.href = redirectLink ?? '';
    }
    // Retrieve user email value
    this.getUserEmailValue();
    // Retrieve help menu items
    this.getHelpMenu();
    // Retrieve user initials
    this.getUserInitial();
    // Set menu environment for link service
    this.linkService.setMenuEnvironment(this.menuEnvironment);
  }

  isCheckLocalStorageValue() {
    setTimeout(() => {
      // Check if user organization and email are available in local storage
      if (this.UserOrganization && getLocalStorageValue('email')) {
        // Retrieve user email value and load WalkMe script
        this.getUserEmailValue();
        this.loadWalkMeScript();
      } else {
        // Retry checking local storage values
        this.isCheckLocalStorageValue();
      }
    }, 1000);
  }

  ngAfterViewInit() {
    // Check local storage values after the view has been initialized
    this.isCheckLocalStorageValue();
  }

  loadWalkMeScript() {
    setTimeout(() => {
      // Retrieve user email and organization information
      const UserEmail = getLocalStorageValue('email') || '';
      const userAssignOrganization = this.UserOrganization;
      const UserOrganization =
        userAssignOrganization.length > 1
          ? 'Multi'
          : userAssignOrganization[0]?.entityName;
      const url = window.location.href.toLowerCase();

      // Determine WalkMe script source based on environment
      const walkmeSrc =
        url.indexOf('dev-') > -1 ||
        url.indexOf('qa-') > -1 ||
        url.indexOf('stage-') > -1 ||
        url.indexOf('localhost') > -1
          ? 'https://cdn.walkme.com/users/3b4fb2cca1ad433cb74244751f8bd9c6/test/walkme_3b4fb2cca1ad433cb74244751f8bd9c6_https.js'
          : 'https://cdn.walkme.com/users/3b4fb2cca1ad433cb74244751f8bd9c6/walkme_3b4fb2cca1ad433cb74244751f8bd9c6_https.js';

      // Generate WalkMe script text
      const scriptText = `
        {
            var UserEmail = "${UserEmail}";
            var UserOrganization = "${UserOrganization}";
            var walkme = document.createElement('script');
            walkme.type = 'text/javascript';
            walkme.src = "${walkmeSrc}";
            walkme.async = true;
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(walkme, s);
            window._walkmeConfig = {smartLoad:true};
        }
      `;

      // Create and append WalkMe script element
      const script = this._renderer2.createElement('script');
      script.type = 'text/javascript';
      script.text = scriptText;
      this._renderer2.appendChild(this._document.body, script);
    }, 1000);
  }

  // Method to check if user has allowed roles
  userAppRolesAllowed(allowedRoles: string[]): boolean {
    return this.b2cService.userAppRolesAllowed(allowedRoles);
  }

  // Method to redirect to login URL
  login(): void {
    window.location.href = this.loginURL;
  }

  // Method to perform logout actions
  logout(): void {
    // Remove user app roles and app messaging from local storage
    localStorage.removeItem(this.localStorageUserAppRoles);
    localStorage.removeItem(this.localStorageAppMessaging);
    // Perform logout through B2C service
    this.b2cService.logout();
  }

  // Method to toggle sidebar expansion state and emit event
  toggleSidebar(): void {
    // Invert the expansion state
    this.isExpanded = !this.isExpanded;
    // Emit event to notify parent component about sidebar state change
    this.menuExpandCollapse.emit(this.isExpanded);
  }

  // Method to display error message using snack bar
  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  // Method to open application in a new tab
  openApp(applicationPath: string) {
    if (applicationPath) {
      // Open application in a new tab
      window.open(`${applicationPath}`, '_blank');
    }
  }

  // Method to handle logo click event
  logoClick() {
    // Retrieve default URL from applications service
    const url = this.applicationsService.getDefaultUrl();
    if (url && url != '') {
      // Redirect to the default URL
      window.location.href = url;
    }
  }
  // Method to populate the help menu list
  getHelpMenu(): void {
    let biosimilarslink = 'https://biosimilars.rxresourcesolutions.com/#/help';
    let assessmentslink = 'https://rxassess.rxresourcesolutions.com/#/Help';
    let initiativeslink =
      'https://rxclinicalanalytics.rxresourcesolutions.com/#/Help';
    let documentslink = 'https://rxcontenthub.rxresourcesolutions.com/#/Help';
    let metricslink =
      'https://operationalmetrics.rxresourcesolutions.com/#/Help';

    // Override default links with environment-specific links, if available
    if (this.menuEnvironment.headerBiosimilarsLink != '') {
      biosimilarslink = this.menuEnvironment.headerBiosimilarsLink;
    }
    if (this.menuEnvironment.headerAssessmentsLink != '') {
      assessmentslink = this.menuEnvironment.headerAssessmentsLink;
    }
    if (this.menuEnvironment.headerInitiativesLink != '') {
      initiativeslink = this.menuEnvironment.headerInitiativesLink;
    }
    if (this.menuEnvironment.headerDocumentsLink != '') {
      documentslink = this.menuEnvironment.headerDocumentsLink;
    }
    if (this.menuEnvironment.headreMetricsLink != '') {
      metricslink = this.menuEnvironment.headreMetricsLink;
    }

    let helpMenuList: HelpMenuItem[] = [];
    helpMenuList = [
      {
        itemId: 1,
        item: 'Biosimilars',
        routeLink: biosimilarslink,
        isActive: true,
        displayName: 'Biosimilars',
      },
      {
        itemId: 2,
        item: 'Assessments',
        routeLink: assessmentslink,
        isActive: true,
        displayName: 'Assessments',
      },
      {
        itemId: 3,
        item: 'Initiatives',
        routeLink: initiativeslink,
        isActive: true,
        displayName: 'Initiatives',
      },
      {
        itemId: 4,
        item: 'Resources',
        routeLink: documentslink,
        isActive: true,
        displayName: 'Resources',
      },
      {
        itemId: 5,
        item: 'Metrics',
        routeLink: metricslink,
        isActive: true,
        displayName: 'Metrics',
      },
    ];
    this.helpMenuList = helpMenuList;
  }

  // Method to retrieve user initials
  getUserInitial() {
    // Concatenate first name and last name to form full user name
    this.strUserName =
      this.userSession.firstName + ' ' + this.userSession.lastName;
    // Extract initials from full user name
    const matches = this.strUserName.match(/\b(\w)/g);
    // Join initials to form usernameInitial
    this.usernameInitial = matches?.join('') || '';
  }

  // Method to ensure URL is complete with protocol
  GetCompleteUrl(url: string): string {
    const prefix = 'http';
    // Add missing protocol to URL if not present
    if (url.toLowerCase().substr(0, prefix.length) !== prefix) {
      url = `//${url}`;
    }
    return url;
  }

  // Method to check if user is external based on email domain
  checkExternalUser(email: string) {
    // Convert email to lowercase
    email = email.toLowerCase();
    // Check if email domain indicates external user
    if (
      email == '' ||
      email.includes('cpspharm.com') ||
      email.includes('cpspartner.com') ||
      email.includes('therigy.com') ||
      email.includes('cps.com')
    )
      this.isExternal = false;
    else this.isExternal = true;
  }

  // Method to initiate password change process
  changePassword() {
    // Store current page URL in local storage
    localStorage.setItem('currnetpageurl', window.location.href);
    // Open forgot password link in the same tab
    window.open(this.forgotPasswordLinkExternal, '_self');
  }

  // Method to retrieve user email value
  getUserEmailValue() {
    if (!this.email) {
      // Subscribe to user session changes
      this.b2cService.userSession$.subscribe((session) => {
        // Update user session if security identifier is present
        if (session.onPremisesSecurityIdentifier) {
          this.userSession = session;
        }
      });
      // Retrieve user session
      this.b2cService.getUserSesssion();

      // Retrieve email from user session or local storage
      this.email =
        this.userSession.email || getLocalStorageValue('email') || '';
      if (!this.email) {
        // If email is still not available, retrieve from local storage
        this.email = getLocalStorageValue('email') ?? '';
      }
      // Store user email in data service
      this.dataService.setUserEmail('userEmail', this.email);
    } else {
      // Store user email in data service if already available
      this.dataService.setUserEmail('userEmail', this.email);
    }
  }
}
