import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private isThemeChanged = new BehaviorSubject<boolean>(false);

  setTheme(value: boolean) {
    this.isThemeChanged.next(value);
  }

  getTheme() {
    return this.isThemeChanged.asObservable();
  }
}
