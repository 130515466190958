import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { B2cService, UserSession } from "cps-b2clibrary";
import { SnackBarModule } from "../../modules/snackbar/snack-bar.module";
import { HttpErrorResponse } from "@angular/common/http";
import { ApplicationSecurityManagementService } from "../../services/application-security-management.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ApplicationsService } from "../../../shared/services/applications.service";
import { ApplicationList } from "../../../shared/models/application-list";
import { SvgIconService } from "./../../services/svg-icons.service";
import { environment } from "../../../../environments/environment";
import { ChangeOrgComponent } from "../../../components/change-org/change-org.component";
import { PermissionsMapUserAppRole } from "../../../shared/models/permissions-map-user-app-role";
import { MatSort } from "@angular/material/sort";
import { Organization } from "../../../shared/models/organization.model";
import { appRoleIDs, roleNames } from "../../constants/constants";
import { Buffer } from "buffer";
import { ReportService } from '../../../components/reports/services/report.service';
import { ReportListService } from '../../../components/reports/services/report-list.service';
import { CustomErrorHandler } from './../../../shared/utils/custom-error-handler';
import { Reports } from '../../../components/reports/models/report.model';
import { ReportItems } from '../../../components/reports/models/report-items.model';
import { ReportGroupNameRxAdminReports } from '../../../components/reports/constants/constants';
import { UserPermissionsService } from '../../../components/reports/services/user-permissions.service';
import { DataService } from '../../services/data.service';
import { Role } from "../../enum/role.enum";
import { SessionService } from "../../services/session-storage.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() sideNavToggled = new EventEmitter<void>();
  @ViewChild("searchInput", { static: false }) searchInput: ElementRef;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  searchIcon = "search";
  public appName: string;
  private applicationSubscription: Subscription;
  public applicationList = [];
  public secManagerAppList = [];
  public metrics: Array<number> = [];
  public searchUrl: string =
    environment.appConfig.searchIcon.rxContentHubSearchUrl;
  userSession: UserSession = new UserSession();
  private userSessionSubscription: Subscription;
  private subscriptionFacilityMetrics: Subscription;
  private securityManagerAppsSubscription: Subscription;
  private localStorageloggedInString = "loggedInString";
  private localStorageFirstName = "firstName";
  private localStorageLastName = "lastName";
  private localStorageEmail = "email";
  private localStorageRoleMode = "roleMode";
  private localStorageAzureActiveDirectoryObjectId =
    "azureActiveDirectoryObjectId";
  private localStorageOnPremisesSecurityIdentifier =
    "onPremisesSecurityIdentifier";
  private localStorageIsLogoff = "isLogoff";
  private localStorageUserAppRoles = "userAppRoles";
  public forgotPasswordLink = "";
  public isExternal = false;
  public operaReportList = [];
  public RoleUser = ""; // This needs to be App Sec Mgmt Role name
  private userExecutionRoles: PermissionsMapUserAppRole[];
  public isAdmin = false;
  public isAdminReadOnly = false;
  public isSecurityManager = false;
  public isB2BAdmin = false;
  public isOrganizationAdmin = false;
  public isOrganizationAdminOnly = false;
  public isInternalUserOrgAdminView=false;
  public isApplicationMessageAdmin = false;
  public isGraphiteRxDataMgmt = false;
  public isReportAdmin = false;
  public isReportViewer = false
  public roleMode: string;
  public sortedData: Array<ApplicationList> = [];
  public applicationServerPath = environment.serverPath;
  public selectedRole: string;
  public organization: Organization[];
  public selectedOrganization: Organization;
  public orgAssigned = true;
  private clinicalPharmacologySubscription: Subscription;
  onlyAppMessage = false;
  roleChange: string;
  public IsFromCPSUI: string;
  private canUserAccessReport = true;
  public rxadminReportList: Array<Reports> = [];
  public reportItems: ReportItems;
  public appRoleIDConstant = appRoleIDs;
  // isThemeToggled = false;
  typeOfSpinner ="loading";
  selectedRoleMode = "admin";

  constructor(
    private b2cService: B2cService,
    private snackBar: SnackBarModule,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private applicationsService: ApplicationsService,
    private svgIconService: SvgIconService,
    public dialog: MatDialog,
    private reportService: ReportService<Reports>,
    private reportListService: ReportListService,
    private userPermissionsService: UserPermissionsService,
    private customErrorHandler: CustomErrorHandler,
    private dataService: DataService,
    private sessionService: SessionService
  ) {
    this.dataService.getTheme().subscribe(value => {
      // this.isThemeToggled = value;
    });
  }

  changeOrg() {
    if(this.roleMode == "OrganizationAdmin")
    {
    if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) return;
    const dialogRef = this.dialog.open(ChangeOrgComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.organization = this.appSecurityManagementService.getOrganization();
        this.organization.forEach((organization) => {
          if (organization.selected === true) {
            this.selectedOrganization = organization;
          }
        });
        if (this.selectedOrganization !== undefined) {
          this.appSecurityManagementService.setOrganization(
            this.organization,
            this.selectedOrganization.entityId
          );
        } else {
          this.appSecurityManagementService.setOrganization(
            this.organization,
            0
          );
        }
        sessionStorage.setItem(
          "selectedOrganization",
          this.selectedOrganization.cpsEntityName
        );

        this.RedirectCPSUser();
      }
    });
  }
  }

  RedirectCPSUser() {
    if (this.IsFromCPSUI === "CPSAppUser") {
      const urlInfo = this.router.url.split("?")[0];
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate([urlInfo]);
      });
    } else {
      this.router.navigate(["/org-home"]);
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem("currneturl") && localStorage.getItem("currneturl") != '') {
      const rlink = localStorage.getItem("currneturl");
      localStorage.setItem("currneturl", null);
      window.location.href = rlink;
    }
    this.forgotPasswordLink =
      environment.b2cConfig.forgotPasswordLink +
      `&redirect_uri=${encodeURIComponent(window.location.origin)}`;

    if (this.activatedRoute.snapshot.queryParams["name"])
      this.IsFromCPSUI = Buffer.from(
        this.activatedRoute.snapshot.queryParams.name,
        "base64"
      ).toString("utf8");
    this.svgIconService.loadIcons(
      "description-24px.svg",
      "document",
      "../../../../assets/images"
    );
    this.svgIconService.loadIcons(
      "home.svg",
      "home-icon",
      "../../../../assets/images/app-icons"
    );
    this.svgIconService.loadIcons(
      "assessment.svg",
      "assessment-icon",
      "../../../../assets/images/app-icons"
    );
    this.svgIconService.loadIcons(
      "account-balance.svg",
      "account-balance-icon",
      "../../../../assets/images/app-icons"
    );
    this.svgIconService.loadIcons(
      "account_circle.svg",
      "account_circle-icon",
      "../../../../assets/images/app-icons"
    );
    this.svgIconService.loadIcons(
      "help.svg",
      "help_outline-icon",
      "../../../../assets/images/app-icons"
    );
    this.svgIconService.loadIcons(
      "apps.svg",
      "apps-icon",
      "../../../../assets/images/app-icons"
    );

    this.roleChange = sessionStorage.getItem("roleChange");
    this.selectedRole = this.appSecurityManagementService.getRoleMode();
    this.selectedRoleMode = "admin";
    this.checkExternalUser();
    if (this.selectedRole !== "NotSet" || sessionStorage.getItem("roleMode")) {
      this.roleMode = sessionStorage.getItem("roleMode");
      this.appSecurityManagementService.setRoleMode(this.roleMode);
    } else {
      sessionStorage.setItem("roleMode", this.roleMode);
      this.appSecurityManagementService.setRoleMode(this.roleMode);
    }
    if(this.selectedRole == "OrganizationAdmin"){
      this.selectedRoleMode = "org admin";
    }
    this.userSession = this.sessionService.getUserSession();
    if (this.userSession && this.userSession.onPremisesSecurityIdentifier) {
      this.getUserExecutionRoles(
        this.userSession.onPremisesSecurityIdentifier,
        (values: PermissionsMapUserAppRole[]) => {
          this.userExecutionRoles = values;

          if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.organization_admin).length > 0) {
            this.appSecurityManagementService.setOrganizationAdminMode(true);
            sessionStorage.setItem("isOrganizationAdmin", "true");
          }
          else {
            this.appSecurityManagementService.setOrganizationAdminMode(false);
            sessionStorage.setItem("isOrganizationAdmin", "false");
          }

          if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.admin).length > 0) {
            sessionStorage.setItem("isAdminReadOnly", "false");
            this.isAdminReadOnly = false;
            this.appSecurityManagementService.setAdminReadOnlyMode(false);
            this.appSecurityManagementService.setUserManagementMode(false);
          } else if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.user_management).length > 0) {
            sessionStorage.setItem("isAdminReadOnly", "true");
            this.isAdminReadOnly = true;
            this.appSecurityManagementService.setAdminReadOnlyMode(true);
            this.appSecurityManagementService.setUserManagementMode(true);
          } else {
            sessionStorage.setItem("isAdminReadOnly", "false");
            this.isAdminReadOnly = false;
            this.appSecurityManagementService.setAdminReadOnlyMode(false);
            this.appSecurityManagementService.setUserManagementMode(false);
          }

          if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.lob_mgr).length > 0) {
            this.isAdminReadOnly = true;
            this.appSecurityManagementService.setLOBManagerMode(true);
            sessionStorage.setItem("isLOBManager", "true");
          }
          else {
            this.appSecurityManagementService.setLOBManagerMode(false);
            sessionStorage.setItem("isLOBManager", "false");
          }

          if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.admin).length > 0) {
            this.appSecurityManagementService.setAdminMode(true);
            sessionStorage.setItem("isAdmin", "true");
          }
          else {
            this.appSecurityManagementService.setAdminMode(false);
            sessionStorage.setItem("isAdmin", "false");
          }


          if (this.roleChange != "1") {
            this.roleMode = this.appSecurityManagementService.getRoleModeByRoleID(
              this.getRoleId()
            );
            sessionStorage.setItem("roleMode", this.roleMode);
            this.appSecurityManagementService.setRoleMode(this.roleMode);
          }
          this.userExecutionRoles.forEach((roles) => {
            if (roles.applicationRoleId == appRoleIDs.admin) {
              if (this.roleChange != "1") {
                this.roleMode =
                  this.appSecurityManagementService.getRoleModeByRoleID(
                    roles.applicationRoleId
                  );
                sessionStorage.setItem("roleMode", this.roleMode);
                this.appSecurityManagementService.setRoleMode(this.roleMode);
              }
              this.isAdmin = true;
            }
            if (roles.applicationRoleId == appRoleIDs.organization_admin) {
              this.isOrganizationAdmin = true;
            }
            if (roles.applicationRoleId == appRoleIDs.app_message_admin) {
              this.isApplicationMessageAdmin = true;
            }
            if (roles.applicationRoleId == appRoleIDs.graphiteRx_DataMgmt) {
              this.isGraphiteRxDataMgmt = true;
            }
            if (roles.applicationRoleId == appRoleIDs.report_admin) {
              this.isReportAdmin = true;
            }
            if (roles.applicationRoleId == appRoleIDs.report_viewer) {
              this.isReportViewer = true;
            }
            if (roles.applicationRoleId == appRoleIDs.user_management) {
              this.isAdmin = true;
            }
          });

          if (this.roleMode) {
            this.selectedRole = this.appSecurityManagementService.getRoleMode();
          }
          if(this.isOrganizationAdmin == true && this.isAdmin == false && this.isUserManagememt() == false && this.appSecurityManagementService.getLOBManagerMode()==false)
          {
              this.isOrganizationAdminOnly=true;
              this.appSecurityManagementService.setIsOrganizationAdminOnly(true);
          }
          else{
            this.appSecurityManagementService.setIsOrganizationAdminOnly(false);
          }

          if (
            this.userExecutionRoles.length == 1 &&
            this.getRoleId() ==
            appRoleIDs.app_message_admin
          ) {
            this.roleMode = this.appSecurityManagementService.getRoleModeByRoleID(
              this.getRoleId()
            );
            sessionStorage.setItem("roleMode", this.roleMode);
            this.appSecurityManagementService.setRoleMode(this.roleMode);
            this.onlyAppMessage = true;
          } else {
            this.onlyAppMessage = false;
          }

          if (
            this.isAdmin == false && this.isAdminReadOnly == false &&
            this.isSecurityManager == false &&
            this.isB2BAdmin == false &&
            this.isOrganizationAdmin == true
          ) {
            this.roleMode = "OrganizationAdmin";
            this.appSecurityManagementService.setRoleMode(this.roleMode);
            sessionStorage.setItem("roleMode", this.roleMode);
          }

          if (!(this.sortedData && this.sortedData.length)) {
            this.getApplications(this.userSession.onPremisesSecurityIdentifier);
          }
          if (this.roleMode == "OrganizationAdmin") {
            this.appSecurityManagementService.setRoleMode(this.roleMode);
            this.organization =
              this.appSecurityManagementService.getOrganization();
            if (this.organization == undefined) {
              this.getOrganization();
            }
            if (this.organization && this.organization.length == 1) {
              this.selectedOrganization = this.organization[0];
              sessionStorage.setItem(
                "selectedOrganization",
                this.selectedOrganization.cpsEntityName
              );
              sessionStorage.setItem("singleOrganization", "true");
              this.appSecurityManagementService.setOrganization(
                this.organization,
                this.selectedOrganization.entityId
              );
            } else if (this.organization && this.organization.length > 1) {
              this.organization.forEach((organization) => {
                if (organization.selected === true) {
                  this.selectedOrganization = organization;
                }
              });
              if (this.selectedOrganization == undefined) {
                this.changeOrg();
              }
            }
          } else if (this.isAdmin === true && this.IsFromCPSUI === "CPSAppUser") {
            this.roleMode = "OrganizationAdmin";
            this.roleChange = "1";
            this.appSecurityManagementService.setRoleMode(this.roleMode);
            sessionStorage.setItem("roleMode", this.roleMode);
            sessionStorage.setItem("roleChange", this.roleChange);
            this.changeOrg();
          }else{
            this.organization =
              this.appSecurityManagementService.getOrganization();
            if (this.organization == undefined) {
              this.appSecurityManagementService
                .getOrganizationList(this.userSession.onPremisesSecurityIdentifier)
                .subscribe({
                  next: response => {
                    this.organization = response;
                    if (this.organization && this.organization.length == 0) {
                      this.orgAssigned=false;
                    }
                    if (this.organization && this.organization.length > 0) {
                      this.orgAssigned=true;
                    }
                  },
                  error: error => {
                    this.showSnackBarMessageError(error);
                  }
                });
            }
            if (this.organization && this.organization.length > 1) {
              this.orgAssigned=true;
            }
          }
          this.typeOfSpinner="none";
          if(this.roleMode == "OrganizationAdmin"){
            this.selectedRoleMode = "org admin";
          }
        }
      );
      this.PageInit();
    }

  }
  getRoleMode(): string {
    return this.appSecurityManagementService.getRoleMode();
  }

  isUserManagememt(): boolean {
    return this.appSecurityManagementService.getUserManagementMode();
  }

  getRoleId(): number {
    if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.admin).length > 0) {
      return appRoleIDs.admin;
    } else if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.user_management).length > 0) {
      return appRoleIDs.user_management;
    } else if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.lob_mgr).length > 0) {
      return appRoleIDs.lob_mgr;
    } else if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.lobmgrold).length > 0) {
      return appRoleIDs.lobmgrold;
    } else if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.organization_admin).length > 0) {
      return appRoleIDs.organization_admin;
    } else if (this.userExecutionRoles.filter(x => x.applicationRoleId == appRoleIDs.left_menu_mgr).length > 0) {
      return appRoleIDs.left_menu_mgr;
    } else {
      return this.userExecutionRoles[0].applicationRoleId;
    }
  }

  hasPermissionOfRole(roleId: number): boolean {
    return this.userExecutionRoles.filter(x => x.applicationRoleId === roleId).length > 0;
  }

  toggleSearch(): void {
    const searchContainer = document.getElementById("search-container");
    this.toggleClass(searchContainer, "open");
    if (!this.hasClass(searchContainer, "open")) {
      this.searchInput.nativeElement.value = "";
    }
    this.searchIcon = this.hasClass(searchContainer, "open")
      ? "clear"
      : "search";
  }

  private toggleClass(elem, className): void {
    this.hasClass(elem, className)
      ? elem.classList.remove(className)
      : elem.classList.add(className);
  }

  changeRoleMode(roleMode): void {
    this.selectedRoleMode = "admin";
    sessionStorage.setItem("roleChange", "1");
    this.roleMode = roleMode.currentTarget.value;
    sessionStorage.setItem("roleMode", this.roleMode);
    this.appSecurityManagementService.setRoleMode(this.roleMode);
    if (this.roleMode != "OrganizationAdmin") {
      this.router.navigate(["/home", [this.roleMode, Role.UserManagement]]);
    } else if (this.roleMode == "OrganizationAdmin") {
      this.selectedRoleMode = "org admin";
      this.organization = this.appSecurityManagementService.getOrganization();
      if (this.organization == undefined) {
        this.getOrganization();
      }
      if (this.organization && this.organization.length > 1) {
        this.changeOrg();
      } else if (this.organization && this.organization.length == 1) {
        this.selectedOrganization = this.organization[0];
        this.appSecurityManagementService.setOrganization(
          this.organization,
          this.selectedOrganization.entityId
        );
        this.router.navigate(["/org-home"]);
      } else if (!this.organization && this.organization.length == 0) {
        if(this.isExternal==true)
        {
          this.router.navigate(["/noorganization"]);
        }
      }
    }
  }

  setInternalUserOrgAdminView():void{
    if(this.isOrganizationAdminOnly === true && this.isExternal === false)
    {
        this.isInternalUserOrgAdminView=true;
        this.appSecurityManagementService.setIsInternalUserOrgAdminView(this.isInternalUserOrgAdminView);
        this.selectedRoleMode = "admin";
        sessionStorage.setItem("roleChange", "1");
        this.roleMode = "Admin";
        sessionStorage.setItem("roleMode", this.roleMode);
        this.appSecurityManagementService.setRoleMode(this.roleMode);
        this.router.navigate(["/home", []]);
    }
    else{
      this.isInternalUserOrgAdminView=false;
      this.appSecurityManagementService.setIsInternalUserOrgAdminView(this.isInternalUserOrgAdminView);
    }

  }

  getOrganization(): void {
    this.appSecurityManagementService
      .getOrganizationList(this.userSession.onPremisesSecurityIdentifier)
      .subscribe({
        next: response => {
          this.organization = response;
          if (this.organization && this.organization.length == 0) {
            this.orgAssigned=false;
            this.setInternalUserOrgAdminView();
            if(this.isExternal==true)
            {
            this.router.navigate(["/noorganization"]);
            }
          }
          if (this.organization && this.organization.length > 0) {
            this.orgAssigned=true;
            this.setInternalUserOrgAdminView();
            if (this.organization.length == 1) {
              this.selectedOrganization = this.organization[0];
              sessionStorage.setItem(
                "selectedOrganization",
                this.selectedOrganization.cpsEntityName
              );
              this.appSecurityManagementService.setOrganization(
                this.organization,
                this.selectedOrganization.entityId
              );
            } else if (this.organization && this.organization.length > 1) {
              this.organization = this.organization.sort(
                (sourceData, comparisionData) =>
                  sourceData.cpsEntityName > comparisionData.cpsEntityName
                    ? 1
                    : -1
              );
              this.appSecurityManagementService.setOrganization(
                this.organization,
                0
              );
              this.changeOrg();
            }
          }
        },
        error: error => {
          this.showSnackBarMessageError(error);
        }
      });
  }

  private hasClass(elem: Element, className: string): boolean {
    return elem.classList.contains(className);
  }

  login(): void {
    window.location.href = `${environment.appConfig.apiServer.loginUrl}`;
  }

  logout(): void {
    if (sessionStorage["roleMode"]) {
      sessionStorage.removeItem("roleMode");
    }
    this.b2cService.logout();
    localStorage.removeItem("appMessaging");
    if (sessionStorage["roleChange"]) {
      sessionStorage.removeItem("roleChange");
    }
    if (sessionStorage["index"]) {
      sessionStorage.removeItem("index");
    }
  }

  getApplications(onPremisesSecurityIdentifier): void {
    this.applicationSubscription = this.applicationsService
      .getSetApplications(onPremisesSecurityIdentifier)
      .subscribe((applicationList) => {
        this.sortedData = applicationList;
      });
  }

  openApp(application: ApplicationList): void {
    if (_.isNil(application) || _.isEmpty(application)) {
      return;
    }

    const applicationServerPath = application.applicationPath.includes("http")
      ? ""
      : this.applicationServerPath;

    if (application.applicationCode === "ClinPharm") {
      const clinicalPharmacologyCossaEndpoint = `${environment.appConfig.apiServer.cossa}ClinicalPharmacology`;
      this.clinicalPharmacologySubscription = this.applicationsService
        .getClinicalPharmacology(clinicalPharmacologyCossaEndpoint)
        .subscribe({
          next: response => {
            application.applicationPath = response.clinicalPharmacologyUrl;
            window.open(`${application.applicationPath}`, "_blank");
          },
          error: error => {
            this.snackBar.warningMessage(error.message, "OK");
          }
        });
    } else {
      window.open(
        `${applicationServerPath}${application.applicationPath}`,
        "_blank"
      );
    }
  }

  toggleSidebar(): void {
    this.sideNavToggled.emit();
  }

  searchInRxIntelnet(searchInput?: string): void {
    if (!_.isNull(searchInput) && !_.isEmpty(searchInput)) {
      window.open(`${this.searchUrl}?search=${searchInput}`, "_blank");
    } else {
      window.open(`${this.searchUrl}`, "_blank");
    }
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  getUserExecutionRoles(
    onPremisesSecurityIdentifier,
    callback: (executionRoles: PermissionsMapUserAppRole[]) => void
  ): void {
    this.securityManagerAppsSubscription = this.appSecurityManagementService
      .getUserExecutionRoles(onPremisesSecurityIdentifier)
      .subscribe((values) => {
        if (callback) {
          callback(values);
        }
      });
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.subscriptionFacilityMetrics)) {
      this.subscriptionFacilityMetrics.unsubscribe();
    }
  }

  checkExternalUser() {
    if (localStorage.getItem('email') &&
    (localStorage.getItem('email').indexOf('@cps.com') !== -1 ||
    localStorage.getItem('email').indexOf('@cpspharm.com') !== -1 ||
    localStorage.getItem('email').indexOf('@cpspartner.com') !== -1 ||
    localStorage.getItem('email').indexOf('@therigy.com') !== -1)
    ) {
      this.isExternal = false;
    }
    else this.isExternal = true;
  }

  changePassword() {
    localStorage.setItem("currneturl", window.location.href);
    window.open(this.forgotPasswordLink, "_self");
  }

  onReportClicked(item: ReportItems): boolean {
    this.reportItems = item;
    sessionStorage.setItem('previousSelectedReport', sessionStorage.getItem('selectedReport'));
    sessionStorage.setItem('selectedReport', JSON.stringify(this.reportItems));
    this.reportService.reportMethod(item);
    return true;
  }

  showSnackBarMessageErrorForCossa(error: HttpErrorResponse): void {
    this.customErrorHandler.handleCossaError(error);
  }

  async PageInit(): Promise<void> {
    // this.b2cService.userSession$.subscribe(userSession => {
    //   this.userSession = userSession;
    this.userSession = this.sessionService.getUserSession();
    if (this.userSession && this.userSession.onPremisesSecurityIdentifier) {
      this.reportListService.getReports(`${environment.appConfig.apiServer.cossa}Report`,
        environment.appConfig.apiServer.appCode, environment.appConfig.biReports.environmentName)
        .subscribe(values => {
          this.rxadminReportList = [];
          const reportListWithAttribute = this.setReportAttributes(values, environment.appConfig.apiServer.appCode);
          this.rxadminReportList.push(...reportListWithAttribute.filter(report => report.biReportGroupName === ReportGroupNameRxAdminReports));
        });
      // });
    }
  }

  setReportAttributes(allowedReports: Reports[], appCode: string): Array<Reports> {

    let reportList: Array<Reports> = [];
    const tempData = JSON.parse(JSON.stringify(allowedReports)).slice();
    reportList = tempData.sort((a, b) => a.sortOrder - b.sortOrder);

    /* Comment : Availability based filtering of Report Status */
    reportList = reportList.filter(report => report.isActive === true);

    reportList.forEach(report => {
      this.reportListService.setReportFileName(report);
      report.appCode = appCode;
      report.isActive = this.userPermissionsService.applyReportAccessRole(report);
      report.canExport = this.userPermissionsService.applyReportCanExport(report);
    });
    /* Comment : Role based filtering of Report Status */
    reportList = reportList.filter(report => report.isActive === true);

    return reportList;
  }

}

function compare(
  sortSourceData: number | string,
  sortComparisionData: number | string,
  isAsc: boolean
) {
  return (sortSourceData < sortComparisionData ? -1 : 1) * (isAsc ? 1 : -1);
}
