import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, of } from 'rxjs';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { SnackBarModule } from '../../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../../shared/services/application-security-management.service';
import {  Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { Users } from '../../../shared/models/users.model';
import { OrganizationUser } from '../../../shared/models/organization-user.model';
import { Organization } from '../../../shared/models/organization.model';
import { roleNames, UserFilter } from '../../../shared/constants/constants';
import { GraphiteRxService } from 'src/app/graphiterx/service/graphiterx.sercive';
import { PurchasingVendor } from 'src/app/graphiterx/models/purchasing-Vendor.model';
import { DisplayMessage,ActionMessage } from '../../components/message';
import { ConfirmationDialogComponent, ConfirmDialogModel } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { GraphiteRxEntity } from 'src/app/graphiterx/models/graphiterxentity.model';

const emailAddressValidatedDefaultDate = '1900-01-01T00:00:00';

@Component({
  selector: 'app-grxentityreconcil-dialog',
  templateUrl: './grxentityreconcil-dialog.component.html',
  styleUrls: ['./grxentityreconcil-dialog.component.scss']
})
export class GrxentityreconcilDialogComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public typeOfSpinner = 'loading';
  public flag:any;
  public displayedRows$: Observable<GraphiteRxEntity[]>;
  public totalRows$: Observable<number>;
  public entityId: number;
  public pageName: string;
  public userSession: UserSession = new UserSession();
  public allUsers: Users[];
  public selectedOrganization: Organization;
  public distinctAllUser: OrganizationUser[];
  public selectedRole: string;
  public roles: { admin: string; security_manager?: string; organization_admin?: string; };
  selectedCondition: string;
  conditions: string[] = ['Active', 'Terminated', 'All'];
  public inputDialogData:GraphiteRxEntity;
  public correspondingCPSVendors:PurchasingVendor[];
  public correspondingCPSEntities:GraphiteRxEntity[];
  public grxVendorName:string;
  isGraphiteRxVendor = false;
  isGraphiteRxEntity = false;

  constructor(private snackBar: SnackBarModule,
    public dialogRef: MatDialogRef<GrxentityreconcilDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GraphiteRxEntity,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private b2cService: B2cService,
    private router: Router,
    public dialog: MatDialog,
    private graphiteRxService:GraphiteRxService) { this.selectedCondition = UserFilter.active,
      this.flag=0;
      this.inputDialogData=data;
    }

    ngOnInit(): void {
      if(this.inputDialogData.soldtoFacilityName!=null)
        this.isGraphiteRxVendor=true;
        this.isGraphiteRxEntity=true;
      this.getCPSEntitiesList(this.inputDialogData.soldtoFacilityName);
      this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
      this.b2cService.getUserSesssion();
      this.selectedRole = this.getRoleMode();
      this.roles = roleNames;
      }

    getRoleMode(): string {
      return this.appSecurityManagementService.getRoleMode();
    }

    getUser(): void {
      this.appSecurityManagementService.getUser().subscribe(
        (allUsersObject) => {
          this.allUsers = allUsersObject.filter(({ isDisabled }) => isDisabled != true);
          this.distinctAllUser = [];
          const map = new Map();
          for (const item of this.allUsers) {
            if (!map.has(item.userId)) {
              map.set(item.userId, true);
              this.distinctAllUser.push({
                userId: item.userId,
                firstName: item.firstName,
                lastName: item.lastName,
                firstNameLastName: item.firstNameLastName,
                email: item.email,
                jobTitle: item.jobTitle,
                isOrgUser: item.isOrgUser,
                securityGroupName: item.securityGroupName,
                assignedEntityName: item.assignedEntityName,
                emailAddressValidatedDate: item.emailAddressValidatedDate,
                isValidUser: item.emailAddressValidatedDate != null && item.emailAddressValidatedDate.toString() !== emailAddressValidatedDefaultDate ? true : false,
                terminationDate: item.terminationDate,
                isExternal: item.isExternal
              });
            }
          }

          this.distinctAllUser.forEach(user => {
            if (user.assignedEntityName === null) {
              user.assignedEntityName = 'UNASSIGNED';
            }
          });

          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.showSnackBarMessageError(error);
        }
      );

    }

    showSnackBarMessageError(error: HttpErrorResponse): void {
      this.snackBar.errorMessage(error);
    }

    setDisplayRows(userList: GraphiteRxEntity[]): void {
      const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
      const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
      const rows$ = of(userList);
      this.totalRows$ = rows$.pipe(map((rows) => rows.length));
      this.displayedRows$ = rows$.pipe(
        sortRows(sortEvents$),
        paginateRows(pageEvents$)
      );
    }

    getCPSEntitiesList(name:string):void{
      this.grxVendorName=name;
      this.graphiteRxService
      .GetCPSEntityDetailsToReconcile(name)
      .subscribe(
        (response) => {
          this.correspondingCPSEntities = response;
          this.setDisplayRows(this.correspondingCPSEntities);
          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.snackBar.errorMessage(error);
         }
      );
    }

    EntityAssignmentEvent(filterValue: GraphiteRxEntity)
    {
      this.userConfirmationDialog(this.inputDialogData.soldtoFacilityName,this.inputDialogData.graphiteRxEntityId,filterValue.entityName.toString(),filterValue.entityId.toString());
    }

    updateCPSEntityIDInGrxEntityXref(grxEntityID:string,cpsEntityID:string):void{
      this.graphiteRxService
      .UpdateCPSEntityIDInGrxEntityXref(grxEntityID,cpsEntityID)
      .subscribe(
        (response) => {
          this.correspondingCPSEntities = response;
          if (response !== null) {
            this.closeDialog();
            this.snackBar.successMessage(DisplayMessage.updateMessage, ActionMessage.okAction);
            this.router.navigate(['graphiterx']);
          } else {
            this.snackBar.successMessage(DisplayMessage.failureMessage, ActionMessage.okAction);
          }
        },
        (error: HttpErrorResponse) => {
          this.showSnackBarMessageError(error);
         }
      );
    }
   closeDialog()
   {
      this.dialogRef.close({event:'true'});
    }

    userConfirmationDialog(grxEntityName:string,grxEntityID:string,cpsentityName:string,cpsEntityID:string){

      const message = `CPS Entity Name :<b>` + cpsentityName +`</b></br> CPS Entity ID : <b>`+ cpsEntityID +
          `</b><br/> Grx Entity Name : <b>`+ grxEntityName +`</b></br> Grx Entity ID : `+`<b>`+ grxEntityID +
          `</b><br/><br/><br/> Are you sure you want to assign CPS EntityID  <b> ` + cpsEntityID  + `</b>  to GraphiteRx Entity  <b>` + grxEntityName + ' </b> from CPS Entity list?';

          const dialogData = new ConfirmDialogModel('Assign Entity', message);
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            // width: '450px',
            data: dialogData,
            backdropClass: 'sm_panel-dialog'
          }).afterClosed().subscribe(response=>{
           if(response){
            this.updateCPSEntityIDInGrxEntityXref(grxEntityID.toString(),cpsEntityID.toString());
           }
          });
    }
}

