export const WebPortalCode = 'RXRS';
export const WidgetAmountNumberType = 1;
export const WidgetAmountCurrencyType = 2;
export const WidgetStandardMetType = 3;
export const WidgetAmountNumberOverdueType = 4;

export const successMsgs = {
  user_entiy_delete: 'User unassigned successfully.',
  user_entity_insert: 'User assigned successfully.',
  orgadmin_entity_insert: 'Org Admin assigned successfully.',
  orgadmin_entiy_delete: 'Org Admin unassigned successfully.',

  user_entiy_unassign: 'Entity unassigned successfully.',
  user_entity_assign: 'Entity assigned successfully.',
  contract_facility_assign: 'Facility assigned successfully.',
  contract_assign_module: 'Module assigned successfully.'
};

export const errorMsgs = {
  user_in_securitygroup: 'User is part of Security Group.',
  user_entity_record_not_inserted: 'Record not inserted.',
  orgadmin_entity_record_not_inserted: 'Record not inserted.',
  something_went_wrong: 'something went wrong, please try again.'
};

export const roleNames = {
  security_manager: 'SecurityManager',
  admin: 'Admin',
  organization_admin: 'OrganizationAdmin'
}

export const appNames = {
  executiveDashboard: 'Executive Dashboard',
  learningCenter: 'Learning Center',
  clinicalPharmacology: 'Clinical Pharmacology',
  internalApplications: 'Internal Applications'
}

export const Contract = {
  entityTypeID: 3
};

export const UserFilter = {
  active: 'Active',
  terminated: 'Terminated',
  all :'All'
}

export const ActiveFilter = {
  active: 'Active',
  inactive: 'Inactive',
  all: 'All'
}
