import { Entity } from "src/app/components/add-organization/models/organization";
import { UserEntitySecurityGroup } from "./user-entity-security-group.model";

export class Facility {
  facilityId: number;
  entityId: number;
  facilityName: string;
  facilityTypeName: string;
  unitNumber: string;
  shortName: string;
  greatPlainsNumber: string;
  customerFacilityId: string;
  cMSNumber: string;
  facilityTypeID: number;
  groupPurchasingOrganizationId: number;
  GPOName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  facilityDescription: string;
  licenseNumber: string;
  purchasingVendorIDPrimary: number;
  purchasingVendorIDSecondary: number;
  costFactor: string;
  AnnualWholesalerPurchases:  string;
  isForProfit:  boolean;
  hasSNF:  boolean;
  hasNF:  boolean;
  hasRetail:  boolean;
  hasOutpatient:  boolean;
  companyID: number;
  regionID: number;
  isCriticalAccess:  boolean;
  numberBeds: number;
  facilityStatusID: number;
  county:  string;
  fax:  string;
  has340B: boolean;
  CEO:  string;
  CFO:  string;
  COO:  string;
  CNO:  string;
  CMO:  string;
  ITISurveyCompleted: boolean;
  pharmacySystemSoftware:  string;
  automationSoftware:  string;
  DEANumber:  string;
  percentIndigent:  string;
  DSH: boolean;
  definitiveID:  string;
  hasResidencyProgram: boolean;
  hasCPSResidencyProgram: boolean;
  numberEmployees: number;
  SNFBeds: number;
  NFBeds: number;
  comments:  string;
  entityName:  string;
  facilitySettingTypeID: number;
  _340BService:  string;
  netSuiteNumber:  string;
  affiliationEntityID: number;
  organizationEntityID: number;
  _340BNumber: number;
  createBy: string;
  createDate: string;
}

export class EntityComposite{
        entityCompositeId: number;
        entityRootId: number;
        entityRootName: string;
        entityId: number;
        parentEntityId: number;
        unitNumber: string;
        cpsentityName: string;
        entityTypeId: number;
        entityTypeName: string;
        recursionLevel: number;
        webPortalCode: string;
        sortNumber: number;
        createDate: string;
        facilityTypeId: number;
        facilityTypeName: string;
}


export class AddFacility{
  entity: Entity;
  facility: any;
  userEntitySecurityGroup: UserEntitySecurityGroup[];
}
