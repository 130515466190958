import { Component, OnInit, NgZone, OnDestroy } from "@angular/core";
import { Subscription, take } from "rxjs";
import { B2cService, UserSession } from "cps-b2clibrary";
import { ApplicationSecurityManagementService } from "../../shared/services/application-security-management.service";
import { SecurityManagerApplist } from "../../shared/models/security-manager-applist.model";
import { SnackBarModule } from "../../shared/modules/snackbar/snack-bar.module";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { SharedUserAppDetailService } from "../../shared/services/shared-user-app-detail.service";
import { UserAppSelectionSession } from "../../shared/models/user-app-selection-session.model";
import { AdminApplist } from "../../shared/models/admin-applist.model";
import _ from "lodash";
import { environment } from "src/environments/environment";
import { PermissionsMapUserAppRole } from "../../shared/models/permissions-map-user-app-role";
import { MatDialog } from "@angular/material/dialog";
import {
  DescriptionDialogComponent,
  DescriptionDialogModel,
} from "src/app/shared/dialog/description-dialog/description-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { appRoleIDs } from "src/app/shared/constants/constants";
import { ReportService } from "../../components/reports/services/report.service";
import { ReportListService } from "../../components/reports/services/report-list.service";
import { CustomErrorHandler } from "./../../shared/utils/custom-error-handler";
import { Reports } from "../../components/reports/models/report.model";
import { ReportItems } from "../../components/reports/models/report-items.model";
import {
  RoleUser,
  ReportGroupNameRxAdminReports,
} from "../../components/reports/constants/constants";
import { UserPermissionsService } from "../../components/reports/services/user-permissions.service";
import { SessionService } from "src/app/shared/services/session-storage.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  public isAdmin = false;
  private appId: string;
  private appName: string;
  private userExecutionRoles: PermissionsMapUserAppRole[];
  private securityManagerAppsSubscription: Subscription;
  public secManagerAppList: SecurityManagerApplist[];
  public adminApplist: AdminApplist[] = [];
  public router: Router;
  public userSession: UserSession = new UserSession();
  private userAppDetail: UserAppSelectionSession =
    new UserAppSelectionSession();
  public typeOfSpinner = "loading";
  public roleMode = "Admin";
  public roleDefined: boolean;
  private routeParamSubscription: Subscription;
  private selectedRole: string;
  public orgAssigned = true;
  private assignedOrganizations: any;
  roleChange: string;
  isIconClicked: boolean;
  private canUserAccessReport = true;
  showSpinner = true;
  public clickedReport: ReportItems;
  public rxadminReportList: Array<Reports> = [];
  isInternalUserOrgAdminView=false;
  isOrganizationAdminOnly=false;

  constructor(
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private snackBar: SnackBarModule,
    private urlRouter: Router,
    private sharedUserAppDetailService: SharedUserAppDetailService,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private reportService: ReportService<Reports>,
    private reportListService: ReportListService,
    private userPermissionsService: UserPermissionsService,
    private customErrorHandler: CustomErrorHandler,
    private sessionService: SessionService
  ) {
    this.router = urlRouter;
    //const myComponent = this;

    this.routeParamSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (params.roleMode) {
          this.roleMode = params.roleMode;
          this.getApplications();
        }
      }
    );



  }

  getRoleId(): number {
    if (
      this.userExecutionRoles.filter(
        (x) => x.applicationRoleId == appRoleIDs.admin || x.applicationRoleId == appRoleIDs.lob_mgr
      ).length > 0
    ) {
      return appRoleIDs.admin;
    } else if (
      this.userExecutionRoles.filter(
        (x) => x.applicationRoleId == appRoleIDs.user_management
      ).length > 0
    ) {
      return appRoleIDs.user_management;
    } else if (
      this.userExecutionRoles.filter(
        (x) => x.applicationRoleId == appRoleIDs.organization_admin
      ).length > 0
    ) {
      return appRoleIDs.organization_admin;
    } else {
      return this.userExecutionRoles[0].applicationRoleId;
    }
  }

  ngOnInit(): void {
    this.isIconClicked = false;
    this.userSession = this.sessionService.getUserSession();
    this.isInternalUserOrgAdminView=this.appSecurityManagementService.getIsInternalUserOrgAdminView();
    this.isOrganizationAdminOnly=this.appSecurityManagementService.getIsOrganizationAdminOnly();
    if (this.userSession && this.userSession.onPremisesSecurityIdentifier) {
      this.PageInit();
      this.getUserExecutionRoles(
        this.userSession.onPremisesSecurityIdentifier,
        (userExecutionRoles: PermissionsMapUserAppRole[]) => {
          this.userExecutionRoles = userExecutionRoles;

          if (
            this.userExecutionRoles.filter(
              (x) => x.applicationRoleId == appRoleIDs.admin
            ).length > 0
          ) {
            sessionStorage.setItem("isAdminReadOnly", "false");
            this.appSecurityManagementService.setAdminReadOnlyMode(false);
            this.appSecurityManagementService.setUserManagementMode(false);
          } else if (
            this.userExecutionRoles.filter(
              (x) => x.applicationRoleId == appRoleIDs.user_management
            ).length > 0
          ) {
            sessionStorage.setItem("isAdminReadOnly", "true");
            this.appSecurityManagementService.setAdminReadOnlyMode(true);
            this.appSecurityManagementService.setUserManagementMode(true);
          } else {
            sessionStorage.setItem("isAdminReadOnly", "false");
            this.appSecurityManagementService.setAdminReadOnlyMode(false);
            this.appSecurityManagementService.setUserManagementMode(false);
          }
          if (this.roleChange != "1") {
            const element: HTMLInputElement = document.querySelector('#roleMode') as HTMLInputElement;
            if(element!=null ){
              this.roleMode = element.value;
            }else{
              this.roleMode = this.appSecurityManagementService.getRoleModeByRoleID(
              this.getRoleId()
            );
            }
            sessionStorage.setItem("roleMode", this.roleMode);
            this.appSecurityManagementService.setRoleMode(this.roleMode);
          }
          this.roleDefined = false;
          this.orgAssigned = false;
          this.userExecutionRoles.forEach((roles) => {
            if (roles.applicationRoleId == appRoleIDs.admin) {
              if (this.roleChange != "1") {
                this.roleMode =
                  this.appSecurityManagementService.getRoleModeByRoleID(
                    roles.applicationRoleId
                  );
                sessionStorage.setItem("roleMode", this.roleMode);
                this.appSecurityManagementService.setRoleMode(this.roleMode);
              }
              this.isAdmin = true;
              this.roleDefined = true;
              return false;
            } else if (roles.applicationRoleId == appRoleIDs.manager) {
              this.roleDefined = true;
              return false;
            } else if (roles.applicationRoleId == appRoleIDs.app_message_admin) {
              this.roleDefined = true;
              return false;
            } else if (roles.applicationRoleId == appRoleIDs.report_admin || roles.applicationRoleId == appRoleIDs.report_viewer) {
              this.roleDefined = true;
              return false;
            } else if (roles.applicationRoleId == appRoleIDs.user_management) {
              this.roleDefined = true;
              return false;
            } else if (roles.applicationRoleId == appRoleIDs.lob_mgr) {
              this.roleDefined = true;
              return false;
            } else if (roles.applicationRoleId == appRoleIDs.lobmgrold) {
              this.roleDefined = true;
              return false;
            } else if (roles.applicationRoleId == appRoleIDs.organization_admin) {
              this.roleDefined = true;
              this.orgAssigned = true;
              return false;
            }
          });

          this.selectedRole = this.getRoleMode();
          if (this.selectedRole !== "NotSet") {
            this.roleMode = this.selectedRole;
          }
          this.getApplications();
          this.typeOfSpinner = "none";
        }
      );
    }
  }

  getRoleMode(): string {
    return this.appSecurityManagementService.getRoleMode();
  }

  getApplications(): void {
    //const myComponent = this;
    if (
      !_.isNil(this.userSession.onPremisesSecurityIdentifier) &&
      this.userSession.onPremisesSecurityIdentifier !== ""
    ) {
      if (this.roleMode === "Admin"  || this.roleMode ==="LOBMgr" || this.roleMode ==="LOBMgr-Old") {
        this.getAdminApps(
          this.userSession.onPremisesSecurityIdentifier,
          (adminApplist: AdminApplist[]) => {
            this.adminApplist = adminApplist;
            if (this.adminApplist.length === 1) {
              let appId = this.adminApplist[0].appId;
              if (this.adminApplist.length === 1) {
                this.userAppDetail.selectedAppName =
                  this.adminApplist[0].appName;
                this.userAppDetail.selectedAppId = this.adminApplist[0].appId;
                this.userAppDetail.selectedAppCode =
                  this.adminApplist[0].appCode;
                this.sharedUserAppDetailService.updateAppSelection(
                  this.userAppDetail
                );
                appId = this.adminApplist[0].appId;
                location.href = "#/" + appId + "/overview";
              }
            }
          }
        );
      } else if (this.roleMode === "SecurityManager") {
        this.getSecurityManagerApps(
          this.userSession.onPremisesSecurityIdentifier,
          (securityManagerAppList: SecurityManagerApplist[]) => {
            this.secManagerAppList = securityManagerAppList;
            if (this.secManagerAppList.length === 1) {
              let appId = this.secManagerAppList[0].appId;
              if (this.secManagerAppList.length === 1) {
                this.userAppDetail.selectedAppName =
                  this.secManagerAppList[0].appName;
                this.userAppDetail.selectedAppId =
                  this.secManagerAppList[0].appId;
                this.userAppDetail.selectedAppCode =
                  this.secManagerAppList[0].appCode;
                this.sharedUserAppDetailService.updateAppSelection(
                  this.userAppDetail
                );
                appId = this.secManagerAppList[0].appId;
                location.href = "#/" + appId + "/overview";
              }
            }
          }
        );
      }
    }
  }

  getSecurityManagerApps(
    onPremisesSecurityIdentifier: string,
    callback: (securityManagerApps: SecurityManagerApplist[]) => void
  ): void {
    this.securityManagerAppsSubscription = this.appSecurityManagementService
      .getSecurityManagerApps(
        `${environment.appConfig.apiServer.appSecService}App/${onPremisesSecurityIdentifier}`,
        onPremisesSecurityIdentifier
      )
      .subscribe((securityManagerApps) => {
        if (callback) {
          callback(securityManagerApps);
        }
      });
  }

  onCardClicked(
    appName: string,
    appId: number,
    appCode: string,
    description: string
  ): boolean {
    this.isIconClicked = false;
    this.userAppDetail.selectedAppName = appName;
    this.userAppDetail.selectedAppId = appId;
    this.userAppDetail.selectedAppCode = appCode;
    this.userAppDetail.selectedAppDescription = description;
    if (this.roleMode === "Admin") {
      this.userAppDetail.isAdmin = true;
    }
    this.sharedUserAppDetailService.updateAppSelection(this.userAppDetail);
    return true;
  }

  getUserExecutionRoles(
    onPremisesSecurityIdentifier: string,
    callback: (executionRoles: PermissionsMapUserAppRole[]) => void
  ): void {
    this.securityManagerAppsSubscription = this.appSecurityManagementService
      .getUserExecutionRoles(onPremisesSecurityIdentifier)
      .subscribe((userExecutionRoles) => {
        if (callback) {
          callback(userExecutionRoles);
        }
      });
  }

  getAdminApps(
    onPremisesSecurityIdentifier: string,
    callback: (adminApps: AdminApplist[]) => void
  ): void {
    this.appSecurityManagementService
      .getAdminApps(
        `${environment.appConfig.apiServer.appSecService}App/${onPremisesSecurityIdentifier}`,
        onPremisesSecurityIdentifier
      )
      .subscribe((adminApps) => {
        if (callback) {
          callback(adminApps);
        }
      });
  }

  getOrganization(): void {
    this.appSecurityManagementService
      .getOrganizationList(this.userSession.onPremisesSecurityIdentifier)
      .subscribe((response) => {
        this.assignedOrganizations = response;
        if (response !== undefined) {
          this.orgAssigned = response.length > 0;
        } else {
          this.orgAssigned = false;
        }
      });
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.routeParamSubscription)) {
      this.routeParamSubscription.unsubscribe();
    }
  }

  showDescriptionDialog(appName: string, description: string): void {
    this.isIconClicked = true;
    if (description == null) {
      description =
        "No description available - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
    }
    const dialogData = new DescriptionDialogModel(appName, description);

    const dialogRef = this.dialog.open(DescriptionDialogComponent, {
      // width: "450px",
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  showSnackBarMessageErrorForCossa(error: HttpErrorResponse): void {
    this.customErrorHandler.handleCossaError(error);
  }

  onReportClicked(item: ReportItems): void {
    this.clickedReport = item;
    sessionStorage.setItem(
      "previousSelectedReport",
      sessionStorage.getItem("selectedReport")
    );
    sessionStorage.setItem(
      "selectedReport",
      JSON.stringify(this.clickedReport)
    );
    this.reportService.reportMethod(item);
    this.router.navigate(["/viewreports"]);
  }

  async PageInit(): Promise<void> {
    this.getRxAdminReportList();
    this.showSpinner = false;
  }

  private getRxAdminReportList() {
    try {
      this.reportListService.getReports(
        `${environment.appConfig.apiServer.cossa}Report`,
        environment.appConfig.apiServer.appCode,
        environment.appConfig.biReports.environmentName
      )
        .subscribe(values => {

          const reportListWithAttribute = this.setReportAttributes(
            values,
            environment.appConfig.apiServer.appCode
          );
          this.rxadminReportList.push(
            ...reportListWithAttribute.filter(
              (report) => report.biReportGroupName === ReportGroupNameRxAdminReports
            )
          );
          this.canAccessReports();
        });
    } catch (error) {
      this.showSnackBarMessageErrorForCossa(error);
    }
  }

  setReportAttributes(
    allowedReports: Reports[],
    appCode: string
  ): Array<Reports> {
    let reportList: Array<Reports> = [];
    const tempData = JSON.parse(JSON.stringify(allowedReports)).slice();
    reportList = tempData.sort((a, b) => a.sortOrder - b.sortOrder);

    /* Comment : Availability based filtering of Report Status */
    reportList = reportList.filter((report) => report.isActive === true);

    reportList.forEach((report) => {
      this.reportListService.setReportFileName(report);
      report.appCode = appCode;
      report.isActive =
        this.userPermissionsService.applyReportAccessRole(report);
      report.canExport =
        this.userPermissionsService.applyReportCanExport(report);
    });
    /* Comment : Role based filtering of Report Status */
    reportList = reportList.filter((report) => report.isActive === true);

    return reportList;
  }

  canAccessReports(): boolean {
    let reportsCount = 0;
    this.canUserAccessReport = false;

    if (!_.isNil(this.rxadminReportList)) {
      reportsCount = reportsCount + this.rxadminReportList.length;
    }
    if (reportsCount > 0) {
      sessionStorage.setItem("CanUserAccessReport", "true");
      this.canUserAccessReport = true;
    } else {
      sessionStorage.setItem("CanUserAccessReport", "false");
      this.canUserAccessReport = false;
    }
    return this.canUserAccessReport;
  }
}
