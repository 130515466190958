import {
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../models/environment-inject';
import { DataService } from '../services/data.service';
import { LinkService } from '../services/link.service';
import { LocalStorageService } from '../services/local-storage-service';

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class FooterComponent implements OnInit {
  // Input property to receive menu environment data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() menuEnvironment: any = {};

  // Property to hold the current year
  year: number = new Date().getFullYear();

  // Extracting the domain from the current URL
  url = window.location.href;
  domain: string = this.url
    .replace('http://', '')
    .replace('https://', '')
    .split(/[/?#]/)[0];

  // Subscription for roles
  rolesSubscription!: Subscription;

  // Footer terms and conditions text
  footerTermsAndConditons = '';

  constructor(
    // Injecting required services and dependencies
    private router: Router,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private linkService: LinkService,
    @Inject('environment') private environment: environment
  ) {}

  ngOnInit(): void {
    // Initialize footer terms and conditions text from menu environment
    this.footerTermsAndConditons = this.menuEnvironment.footerTermsAndConditons;
  }

  // Method to redirect to specified URL, opens in a new tab
  RedirectUrl(): void {
    if (this.footerTermsAndConditons !== '') {
      // Open provided URL in a new tab if footerTermsAndConditons is not empty
      window.open(this.GetCompleteUrl(this.footerTermsAndConditons), '_blank');
    } else {
      // Open default URL in a new tab if footerTermsAndConditons is empty
      window.open(
        this.GetCompleteUrl(
          'https://prodrxadmin.blob.core.windows.net/prod/ad-b2c-ui-customize/Signin/TnC.html'
        ),
        '_blank'
      );
    }
  }

  // Method to ensure the URL is complete with protocol
  GetCompleteUrl(url: string): string {
    const prefix = 'http';
    if (url.toLowerCase().substr(0, prefix.length) !== prefix) {
      // Add missing protocol to URL if not present
      url = `//${url}`;
    }
    return url;
  }
}
