export class SecurityGroupForUser {
  securityGroupId: number;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  securityGroupName: string;
  securityGroupDescription: string;
  abbreviation: string;
}
export class SecurityGroupForUserModule {
  securityGroupId: number;
  securityGroupName: string;
  securityGroupDescription: string;
  isCurrentlySelected: boolean;
  isSelected: boolean;
}

export class SecurityGroupModule {
  securityGroupId: number;
  securityGroupName: string;
}
