import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { LeftMenuLinkItem } from 'src/app/shared/models/left-menu-linkitem.model';
import { B2cService, UserSession } from 'cps-b2clibrary';
import _ from 'lodash';
import { MatRadioChange } from '@angular/material/radio';
import { ActiveFilter } from '../../shared/constants/constants';

@Component({
  selector: 'app-view-leftmenu-linkitems',
  templateUrl: './view-leftmenu-linkitems.component.html',
  styleUrls: ['./view-leftmenu-linkitems.component.scss']
})

export class ViewLeftMenuLinkItemsComponent implements OnInit{
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  selectedCondition: string;
  conditions: string[] = ['Active', 'Inactive', 'All'];
  filterText: string;

  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<LeftMenuLinkItem[]>;
  public totalRows$: Observable<number>;
  private leftMenuLinkItem: LeftMenuLinkItem[];
  public pageName: string;
  public userSession: UserSession = new UserSession();
  displayedColumns: string[] = ['clinicalModuleLinkItemGuid', 'linkKey', 'displayText', 'isActive', 'action'];

  constructor(
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private b2cService: B2cService,
    private activatedRoute: ActivatedRoute,
    private customErrorHandler: CustomErrorHandler
  ) {}


  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.pageName = this.activatedRoute.snapshot.data.pageDetails.title;
    this.getLeftMenuLinkItems();
  }

  onConditionChange(conditionChange: MatRadioChange) {
    this.selectedCondition = conditionChange.value;
    this.radioSelectionFilter(this.selectedCondition);
  }

  getLeftMenuLinkItems(): void {
    this.appSecurityManagementService
      .getLeftMenuLinkItems()
      .subscribe({
        next: response => {
          this.leftMenuLinkItem = response;
          this.selectedCondition = ActiveFilter.active;
          this.radioSelectionFilter(ActiveFilter.active);
          this.typeOfSpinner = 'none';
        },
        error: (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.customErrorHandler.handleError(error);
        }
  });
  }

  setDisplayRows(leftMenuLinkItemsList: LeftMenuLinkItem[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(leftMenuLinkItemsList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.filterText = filterValue;
    this.selectedCondition = ActiveFilter.all;
     const filteredLinkItems =  this.leftMenuLinkItem.filter((leftMenuLink) => {
       return (
        leftMenuLink.clinicalModuleLinkItemGuid && leftMenuLink.clinicalModuleLinkItemGuid.toLowerCase().toString().indexOf(filterValue) !== -1||
        leftMenuLink.linkKey.toLowerCase().toString().indexOf(filterValue) !== -1||
        leftMenuLink.displayText.toLowerCase().toString().indexOf(filterValue) !== -1||
        leftMenuLink.displayText.toLowerCase().indexOf(filterValue) !== -1
       );
     });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredLinkItems);
  }

  radioSelectionFilter(filterValue: string): void {

    const filteredLeftMenuLinkItems = this.leftMenuLinkItem.filter((leftMenuLink) => {
      if (filterValue.toString() == ActiveFilter.active){return ((leftMenuLink.isActive == true));}
        else if (filterValue.toString() == ActiveFilter.inactive){return ((leftMenuLink.isActive == false ));}
          else {return ((leftMenuLink));}
      });
    this.setDisplayRows(filteredLeftMenuLinkItems);
    this.typeOfSpinner = 'none';
  }

}
