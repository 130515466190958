import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './components/home/home.component';
import { OverviewComponent } from './components/overview/overview.component';
import { UrlAccessGuardService } from './shared/services/url-access-guard.service';
import { OrgManagerHomeComponent } from './components/org-manager-home/org-manager-home.component';
import { ViewFacilityComponent } from './components/view-facility/view-facility.component';
import { AssignApplicationComponent } from './components/assign-application/assign-application.component';
import { UserDetailsDialogComponent } from './components/user-details-dialog/user-details-dialog.component';
import { ApprolesUserDialogComponent } from './components/approles-user-dialog/approles-user-dialog.component';
import { ViewTokenComponent } from './components/view-accesstoken/view-accesstoken.component';
import { NoOrganizationComponent } from './components/no-organization/no-organization.component';
import { OrganizationContractListComponent } from './components/organization-contract-list/organization-contract-list.component';
import { AddUpdateContractComponent } from './components/add-update-contract/add-update-contract.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { SettingsComponent } from './components/settings/settings.component';
import { Role } from './shared/enum/role.enum';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import {NgxCustomPermissionsGuard} from './shared/services/ngxCustomPermissionsGuard.service';
import { ViewOrgAdminFacilityLOBComponent } from './components/view-org-admin-facility-lob/view-org-admin-facility-lob.component';

const settings: ExtraOptions = {
   //enableTracing: true,
  useHash: true
};

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.AppMessageAdmin, Role.UserManagement, Role.OrganizationAdmin, Role.LOBMgr, Role.LOBMgrOld,Role.ReportAdmin, Role.ReportViewer]
      }, title: 'Home'
    },
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.AppMessageAdmin, Role.UserManagement, Role.LOBMgr, Role.LOBMgrOld,  Role.ReportAdmin, Role.ReportViewer, Role.OrganizationAdmin]
      }, title: 'Home'
    },
  },
  {
    path: 'thankyou',
    component: ThankyouComponent,
  },

  {
    path: 'org-home',
    component: OrgManagerHomeComponent,
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.OrganizationAdmin],
      }, title: 'Home'
    },
  },

  {
    path: ':id/view-facility',
    component: ViewFacilityComponent,
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.UserManagement,Role.OrganizationAdmin],
        // requiredMatchAllRoles: true
      }, title: 'View Facility'
    },
  },
  {
    path: ':id/view-facility-lob',
    component: ViewOrgAdminFacilityLOBComponent,
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.UserManagement,Role.OrganizationAdmin],
        // requiredMatchAllRoles: true
      }, title: 'View Facility'
    },
  },
  {
    path: '',
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.UserManagement, Role.LOBMgr, Role.LOBMgrOld, Role.OrganizationAdmin]
      }
    },
    loadChildren: () => import('./organization.module').then(m => m.OrganizationModule)
  },
  {
    path: '',
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.UserManagement, Role.OrganizationAdmin, Role.LOBMgr],
      }
    },
    loadChildren: () => import('./user.module').then(m => m.UserModule)

  },
  {
    path: '',
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.LeftMenuMgr]
      }
    },
    loadChildren: () => import('./leftmenulinkitems.module').then(m => m.LeftMenuLinkItemsModule)

  },
  {
    path: '',
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.OrganizationAdmin, Role.UserManagement, Role.LOBMgr, Role.LOBMgrOld]
      }
    },
    loadChildren: () => import('./facility.module').then(m => m.FacilityModule)

  },
  {
    path: 'access-denied',
    data: { title: 'Access Denied' },
    component:AccessDeniedComponent
  },
  {
    path: '',
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.UserManagement, Role.OrganizationAdmin, Role.LOBMgrOld]
      }
    },
    loadChildren: () => import('./contracts.module').then(m => m.ContractsModule)

  },
  {
    path: '',
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.UserManagement]
      }
    },
    loadChildren: () => import('./modules.module').then(m => m.ModulesModule)

  },
  {
    path: '',
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.UserManagement]
      }
    },
    loadChildren: () => import('./application.module').then(m => m.ApplicationModule)

  },
  {
    path: '',
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.UserManagement]
      }
    },
    loadChildren: () => import('./approle.module').then(m => m.AppRoleModule)

  },
  {
    path: '',
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.UserManagement]
      }
    },
    loadChildren: () => import('./securitygroups.module').then(m => m.SecurityGroupsModule)

  },

  {
    path: '',
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions:{
        roleExpected:[Role.Admin, Role.ReportAdmin, Role.ReportViewer],
        redirectTo:'access-denied'
      }
    },
    loadChildren: () => import('./reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'noorganization',
    component: NoOrganizationComponent,
    canLoad: [UrlAccessGuardService],
    data: {
      breadcrumb: 'no organization',
      pageDetails: {
        title: 'No Organization',
        description: 'This page will show no organization information.',
      }
    }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.OrganizationAdmin]
      }, title: 'Settings'
    },
  },
  {
    path: 'assign-application',
    component: AssignApplicationComponent
  },
  {
    path: ':id/overview',
    component: OverviewComponent,
    canActivate: [UrlAccessGuardService],
    data: {
      breadcrumb: 'Overview',
      pageDetails: {
        title: 'Overview'
      }
    }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.LOBMgr, Role.LOBMgrOld, Role.OrganizationAdmin]
      }, title: 'Home'
    },
  },
  {
    path: 'home/:roleMode',
    component: HomeComponent,
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.LOBMgr, Role.LOBMgrOld]
      }, title: 'Home'
    },
  },
  {
    path: '',
    component: HomeComponent,
    data: {
      permissions: {
        roleExpected: [Role.Admin, Role.LOBMgr, Role.LOBMgrOld, Role.OrganizationAdmin]
      }, title: 'Home'
    },
  },
  {
    path: 'TermsAndConditions',
    component: TermsAndConditionsComponent,
     canLoad: [UrlAccessGuardService],
    data: {
      pageDetails: {
        title: 'Terms And Conditions',
      }
    }
  },
  {
    path: '**',
    component: HomeComponent,
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin]
      }, title: 'Home'
    },
  },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'viewuserdetails', component: UserDetailsDialogComponent, data: {
      breadcrumb: 'viewuserdetails',
      pageDetails: {
        title: 'User List',
        description: 'This page lists all user details for the selected security group.',
      },
      canActivate: [UrlAccessGuardService]
    }
  },
  {
    path: 'viewapproleuserdetails', component: ApprolesUserDialogComponent, data: {
      breadcrumb: 'viewapproleuserdetails',
      pageDetails: {
        title: 'User List',
        description: 'This page lists all user details for the selected app role.',
      },
      canActivate: [UrlAccessGuardService]
    }
  },

  {
    path: 'view-token',
    component: ViewTokenComponent
  },
  {
    path: 'contractlist',
    component: OrganizationContractListComponent,
    canLoad: [UrlAccessGuardService],
    data: {
      breadcrumb: 'Users',
      pageDetails: {
        title: 'Users List',
        description: 'This page lists all the Users.',
      }
    }
  },
  {
    path: ':id/modify-contracts',
    component: AddUpdateContractComponent,
    canLoad: [UrlAccessGuardService],
    data: {
      breadcrumb: 'Update Contracts',
      pageDetails: {
        title: 'Update Contracts',
        description: 'This page shows added user in view mode.',
      }
    }
  },
  {
    path: ':add-contracts',
    component: AddUpdateContractComponent,
    canLoad: [UrlAccessGuardService],
    canActivate: [NgxCustomPermissionsGuard],
    data: {
      permissions: {
        roleExpected: [Role.Admin]
      }, title: 'Update Contracts'
    }
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes,  settings )],
  exports: [RouterModule],
})

export class AppRoutingModule { }
