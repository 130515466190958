import { AppRoleAll } from '../models/app-role-all.model';
import { SecurityGroupForUser } from '../models/security-group-for-user.model';
import { AppRoleAllAdmin } from '../models/app-role-all-admin.model';

export class UserDetail {
  public selectedAppId: number;
  public appRolesAll: Array<AppRoleAll> = [];
  public appRolesAllForAdmin: Array<AppRoleAllAdmin> = [];
  public appRoleBySecGroup: Array<AppRoleAll> = [];
  public securityGroupId: number;
  public securityGroupForUser: SecurityGroupForUser;
  public userAppRoles: Array<AppRoleAll> = [] ;
  public deniedRole: Array<number> = [];
}

