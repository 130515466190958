import { Injectable } from '@angular/core';
import {UserSession,B2cService} from 'cps-b2clibrary';
import { NgxPermissionsService} from 'ngx-permissions';

@Injectable({
    providedIn: 'root'
  })
export class RolePermissionsService {
  userSession: UserSession = new UserSession();
  constructor(private b2cService: B2cService,private ngxPermissions:NgxPermissionsService){
    
    this.b2cService.getUserSesssion();
    this.b2cService.userAppRoles$.subscribe(approles => {
        this.userSession.userAppRoles = approles;
    });
  }
  loadPermissionsRole():string[]{
    const permissions:string[]=[];
             this.userSession?.userAppRoles.forEach(
               (userAppRole: any) => {
                 permissions.push(userAppRole.appRoleName);
               });
        return permissions;
    }
    setPermissionRole(approles: string[]):void{
      this.userSession.userAppRoles = approles;
      this.ngxPermissions.loadPermissions(this.loadPermissionsRole());
    }
}
