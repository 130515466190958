import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { UserSession, B2cService } from "cps-b2clibrary";
import { BehaviorSubject, Observable, combineLatest, of, map, take, distinctUntilChanged } from "rxjs";
import { Role } from "../enum/role.enum";
import { RouteGuardConst, SettingMenus } from "../enum/routeguard-const.enum";
import { ApplicationSecurityManagementService } from "./application-security-management.service";
import { debug } from "console";
import { SessionService } from "./session-storage.service";

@Injectable({
  providedIn: "root",
})
export class NgxCustomPermissionsGuard  {
  userSession: UserSession = new UserSession();
  private canActivateRoute = false;
  doIGetMyAppRoles = new BehaviorSubject<boolean>(null);
  doIGetMyAppRoles$ = this.doIGetMyAppRoles.asObservable();
  intervalId: NodeJS.Timer;
  assigned = false;

  constructor(
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private b2cService: B2cService,
    private sessionService: SessionService,
    private route: Router,
    private router: Router
  ) {

    this.doIGetMyAppRoles$.subscribe(res => {
      if (sessionStorage.getItem(RouteGuardConst.RoutedUrl) == '/' && res == true) {
        window.location.reload();
      }

      return of(res == null ? false : true);
    });
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    
    this.userSession = this.sessionService.getUserSession();
    if (this.userSession && this.userSession.onPremisesSecurityIdentifier) {
      
      if (this.userSession.onPremisesSecurityIdentifier && this.userSession.onPremisesSecurityIdentifier != '') {
        localStorage.setItem('onpremisesId', this.userSession.onPremisesSecurityIdentifier);
      }
      const currentRoute = state.url;
      this.canActivateRoute = false;
      const userPermissions = route.data.permissions.roleExpected;
      const requiredMatchAllRoles =
        route.data.permissions.requiredMatchAllRoles ?? false;
      const routedUrl = this.getResolvedUrl(route);
      sessionStorage.setItem(
        RouteGuardConst.UserPermissions,
        JSON.stringify(userPermissions)
      );
      sessionStorage.setItem(RouteGuardConst.RoutedUrl, currentRoute);
      let matchedRoles = 0;
      userPermissions?.forEach((roleExpected) => {
        if (this.userSession.userAppRoles.length > 0) {
          this.userSession.userAppRoles.forEach((userRole: any) => {
            if (userRole.appRoleName === roleExpected) {
              matchedRoles++;
              return;
            }
          });
        }
      });
      if (
        (requiredMatchAllRoles && matchedRoles >= userPermissions.length) ||
        (!requiredMatchAllRoles && matchedRoles >= 1)
      ) {
        // const status = this.requiredToCheckOrganization(this.userSession.userAppRoles);
        // if (status == true) {
        //  return  this.checkAssignedOrganizations();
        // }else{
        this.canActivateRoute = true;
        // }
      }

      if (this.canActivateRoute === false) {
        this.router.navigate([RouteGuardConst.AccessDeniedURL]);
      }
      return of(this.canActivateRoute);
    }
  }

  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    let url = "";

    if (route) {
      url = route.pathFromRoot
        .map((v) => {
          return v.url
            .map((segment) => {
              return segment.toString();
            })
            .join("/");
        })
        .join("/");

      const queryParam = route.queryParamMap;
      if (queryParam.keys.length > 0) {
        url +=
          "?" +
          queryParam.keys
            .map((key) => {
              return queryParam
                .getAll(key)
                .map((value) => {
                  return key + "=" + value;
                })
                .join("&");
            })
            .join("&");
      }
    }

    return url;
  }

  private requiredToCheckOrganization(userPermissions) {
    userPermissions = userPermissions.filter(x => x.appNameForClinicalModule == "Settings");
    const element: HTMLInputElement = document.querySelector('#roleMode') as HTMLInputElement;
    if ((userPermissions.length == 1 && userPermissions[0].appRoleName == Role.OrganizationAdmin)
      || (userPermissions.length > 1 && userPermissions.filter(r => r.appRoleName === Role.OrganizationAdmin).length > 0 && element != null && element.value == 'OrganizationAdmin')) {
      return true;
    }
    return false;
  }


  private checkAssignedOrganizations(): Observable<boolean> {
    return this.appSecurityManagementService
      .getOrganizationList(localStorage.getItem('onpremisesId'))
      .pipe(map((organizations) => {
        if (organizations?.length == 0) {
          this.router.navigate([RouteGuardConst.NoOrganizationURL]);
          return false;
        }
        return true;
      }));
  }



}
