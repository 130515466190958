import { Component, OnInit, AfterViewInit, ViewChild, Input, Output,EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { B2cService, User, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import _ from 'lodash';
import { CossaService } from '../../shared/services/cossa.service';
import { Facility } from '../../shared/models/facility.model';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Users } from '../../shared/models/users.model';
import { successMsgs } from '../../shared/constants/constants';
import { errorMsgs } from '../../shared/constants/constants';
import { Router } from '@angular/router';
import { Organization } from 'src/app/shared/models/organization.model';

@Component({
  selector: 'app-organization-assign',
  templateUrl: './organization-assign.component.html',
  styleUrls: ['./organization-assign.component.scss']
})
export class OrganizationAssignComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public assignSelectedUser: Users;

  private userId: string;
  private successMessage: string;
  private userSessionSubscription: Subscription;
  private routeParamSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedOrgUserRows$: Observable<Users[]>;
  public totalRows$: Observable<number>;
  public facilityDetail: Facility;
  public userNotInFacility: Users[];
  public sortedData = [];
  public selectedOrganization: Organization;
  public organizationUserNotInEntity: Users[];
  public entityId: number;
  public organizationUsers: Users[];
  public isSuperAdminPage = false;
  public cpsUser: Users[];
  public displayedUserRows$: Observable<Users[]>;
  public users: Users[];
  displayedColumns: string[] = ['logo', 'firstName', 'lastName', 'userId', 'email', 'securityGroupName', 'action'];

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private cossaService: CossaService,
    public dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OrganizationAssignComponent>
  ) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    if (this.data.isForOrgAdmin){
       this.getUserToAssignOrgAdmin(this.data.orgId);
    }
    if (!this.data.isForOrgAdmin){
      this.getUserToAssignBusinessUser(this.data.orgId);
    }

  }

  getUserToAssignOrgAdmin(orgId: string): void {
    this.appSecurityManagementService.getUserToAssignOrgAdmin(orgId).subscribe(
      (organizationUsers) => {
        this.organizationUsers = organizationUsers;
        this.setDisplayOrgUserRows(this.organizationUsers);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  getUserToAssignBusinessUser(orgId: string): void {
    this.appSecurityManagementService.getUserToAssignBusinessUser(orgId).subscribe(
      (users) => {
        this.users = users;
        this.setDisplayUserRows(this.users);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  selectOrgAdmin(item: Users){
    this.assignSelectedUser = item;
    this.dialogRef.close(item);
  }


selectUser(item: Users){
  this.assignSelectedUser = item;
  this.dialogRef.close(item);
}

  setDisplayOrgUserRows(userSecurityFacilityList: Users[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(userSecurityFacilityList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedOrgUserRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  setDisplayUserRows(cpsUser: Users[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(cpsUser);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedUserRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }
  applyOrgUserFilter(filterValue: string): void {
    filterValue = filterValue.toLowerCase().trim();
    const filteredOrganizationUser = this.organizationUsers.filter((organizationUser) => {
      return (
        (organizationUser.firstNameLastName && organizationUser.firstNameLastName.toLowerCase().indexOf(filterValue) !== -1) ||
        (organizationUser.email && organizationUser.email.toLowerCase().indexOf(filterValue) !== -1) ||
        (organizationUser.userId && organizationUser.userId.toString().indexOf(filterValue) !== -1) ||
        (organizationUser.securityGroupName && organizationUser.securityGroupName.toLowerCase().indexOf(filterValue) !== -1)
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayOrgUserRows(filteredOrganizationUser);
  }

  applyUserFilter(filterValue: string): void {
    filterValue = filterValue.toLowerCase().trim();
    const filteredUser = this.users.filter((user) => {
      return (
        (user.fullName && user.fullName.toLowerCase().indexOf(filterValue) !== -1) ||
        (user.email && user.email.toLowerCase().indexOf(filterValue) !== -1) ||
        (user.userId && user.userId.toString().indexOf(filterValue) !== -1) ||
        (user.securityGroupName && user.securityGroupName.toLowerCase().indexOf(filterValue) !== -1)
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayUserRows(filteredUser);
  }
}
