import { Injectable } from '@angular/core';
import { ApplicationStorageService } from '.';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class LocalApplicationStorageService implements ApplicationStorageService {
  private firstNameKeyName = 'firstName';
  private lastNameKeyName = 'lastName';
  private emailKeyName = 'email';
  private azureActiveDirectoryObjectIdKeyName = 'AzureActiveDirectoryObjectId';
  private onPremisesSecurityIdentifierKeyName = 'onPremisesSecurityIdentifier';
  private isLogoffKeyName = 'isLogoff';
  private userAppRolesKeyName = 'userAppRoles';
  private userAppsKeyName = 'userApps';
  private userRolePermissionsKeyName = 'RolePermissions';
  private loggedInApplicationVersionKeyName = 'loggedInApplicationVersion';
  private loggedInStringKeyName = 'loggedInString';
  private menuItemsKeyName = 'menuList';
  private entitiesKeyName = 'entityIds';
  private userOrganizationskeyName = 'userOrganizations';
  private clinicalModuleLinkItemKeyName = 'clinicalModuleLinkItems';


  constructor(
    
  ) { }

  setLoggedInString(value: string): void {
    this.setItem(this.loggedInStringKeyName, value);
  }

  getLoggedInString(): string {
    return this.getItem(this.loggedInStringKeyName);
  }

  setFirstName(value: string): void {
    this.setItem(this.firstNameKeyName, value);
  }

  getFirstName(): string {
    return this.getItem(this.firstNameKeyName);
  }

  setLastName(value: string): void {
    this.setItem(this.lastNameKeyName, value);
  }

  getLastName(): string {
    return this.getItem(this.lastNameKeyName);
  }

  setEmail(value: string): void {
    this.setItem(this.emailKeyName, value);
  }

  getEmail(): string {
    return this.getItem(this.emailKeyName);
  }

  setAzureActiveDirectoryObjectId(value: string): void {
    this.setItem(this.azureActiveDirectoryObjectIdKeyName, value);
  }

  getAzureActiveDirectoryObjectId(): string {
    return this.getItem(this.azureActiveDirectoryObjectIdKeyName);
  }

  setOnPremisesSecurityIdentifier(value: string): void {
    this.setItem(this.onPremisesSecurityIdentifierKeyName, value);
  }

  getOnPremisesSecurityIdentifier(): string {
    return this.getItem(this.onPremisesSecurityIdentifierKeyName);
  }

  setIsLogoff(value: string): void {
    this.setItem(this.isLogoffKeyName, value);
  }

  getIsLogoff(): string {
    return this.getItem(this.isLogoffKeyName);
  }

  setUserAppRoles(value: string): void {
    this.setItem(this.userAppRolesKeyName, value);
  }

  getUserAppRoles(): string {
    return this.getItem(this.userAppRolesKeyName);
  }

  setUserApps(value: string): void {
    this.setItem(this.userAppsKeyName, value);
  }

  getUserApps(): string {
    return this.getItem(this.userAppsKeyName);
  }

  setUserRolePermissions(value: string): void {
    this.setItem(this.userRolePermissionsKeyName, value);
  }

  getUserRolePermissions(): string {
    return this.getItem(this.userRolePermissionsKeyName);
  }

  setLoggedInApplicationVersion(value: string): void {
    this.setItem(this.loggedInApplicationVersionKeyName, value);
  }

  getLoggedInApplicationVersion(): string {
    return this.getItem(this.loggedInApplicationVersionKeyName);
  }

  setMenuItems(value: string): void {
    this.setItem(this.menuItemsKeyName, value);
  }

  getMenuItems(): string {
    return this.getItem(this.menuItemsKeyName);
  }

  setEntities(value: string): void {
    this.setItem(this.entitiesKeyName, value);
  }

  getEntities(): string {
    return this.getItem(this.entitiesKeyName);
  }

  setUserOrganizations(value: string): void {
    this.setItem(this.userOrganizationskeyName, value);
  }

  getUserOrganizations(): string {
    return this.getItem(this.userOrganizationskeyName);
  }

  setUserClinicalModuleLinkItem(value: string): void {
    this.setItem(this.clinicalModuleLinkItemKeyName, value);
  }

  getUserClinicalModuleLinkItem(): string {
    return this.getItem(this.clinicalModuleLinkItemKeyName);
  }

  removeUserClinicalModuleLinkItem(): void {
    return this.removeItem(this.clinicalModuleLinkItemKeyName);
  }


  private setItem(key: string, value: string): void {
    if (_.isNil(key)) {
      throw new Error('Unable to set an undefined value to storage.');
    }

    localStorage.setItem(key, value);
  }

  private getItem(key: string): string {
    const localStorageValue = localStorage.getItem(key);

    if (_.isNil(localStorageValue) === false) {
      return localStorage.getItem(key);
    } else {
      return localStorageValue;
    }
  }

  private removeItem(key: string): void {
      return localStorage.removeItem(key);
  }

}
