// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-assessment-modal,
.delete-assessment-modal,
.change-long-modal,
.confirm_dialog_modal {
  width: 550px !important;
}

.close_btn {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 28px;
  height: 28px;
  text-align: center;
  background-color: rgb(34, 54, 91);
  color: #FFF;
  border-radius: 4px;
  cursor: pointer;
}

.close_btn > span {
  line-height: 28px;
}

.back-nav .material-icons {
  margin-left: -7px;
  vertical-align: middle;
}

.scrollTop .material-icons {
  transform: scale(1.25);
  position: absolute;
  top: 6px;
  left: 6px;
  font-size: 26px;
}

.mat-mdc-dialog-title {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/facility-user-delete-dialog/facility-user-delete-dialog.component.scss"],"names":[],"mappings":"AAAA;;;;EAIE,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iCAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,sBAAA;AACF;;AACA;EACE,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;AAEF;;AACA;EACE,2BAAA;EACA,8BAAA;AAEF","sourcesContent":[".edit-assessment-modal,\r\n.delete-assessment-modal,\r\n.change-long-modal,\r\n.confirm_dialog_modal {\r\n  width: 550px !important;\r\n}\r\n\r\n.close_btn {\r\n  position: absolute;\r\n  right: 2px;\r\n  top: 2px;\r\n  width: 28px;\r\n  height: 28px;\r\n  text-align: center;\r\n  background-color: rgb(34,54, 91);\r\n  color: #FFF;\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n}\r\n\r\n.close_btn > span {\r\n  line-height: 28px;\r\n}\r\n\r\n.back-nav .material-icons {\r\n  margin-left: -7px;\r\n  vertical-align: middle;\r\n}\r\n.scrollTop .material-icons {\r\n  transform: scale(1.25);\r\n  position: absolute;\r\n  top: 6px;\r\n  left: 6px;\r\n  font-size: 26px;\r\n}\r\n\r\n.mat-mdc-dialog-title {\r\n  margin-top: 10px !important;\r\n  margin-bottom: 10px !important;\r\n}\r\n\r\n.cdk-overlay-pane .mat-mdc-dialog-container{\r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
