import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { successMsgs } from '../../shared/constants/constants';
import { errorMsgs } from '../../shared/constants/constants';

@Component({
  selector: 'app-organization-orgadmin-delete-dialog',
  templateUrl: './organization-orgadmin-delete-dialog.component.html',
  styleUrls: ['./organization-orgadmin-delete-dialog.component.scss']
})

export class OrganizationOrgadminDeleteDialogComponent {

  title: string;
  message: string;
  userId: number;
  entityId: number;
  deleteUserEntitySubscription: Subscription;

  constructor(private router: Router,
    private dialog: MatDialog,
    private snackBar: SnackBarModule,
    public dialogRef: MatDialogRef<OrganizationOrgadminDeleteDialogComponent>,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    @Inject(MAT_DIALOG_DATA) public data: DeleteConfirmDialogModel) {
      this.title = data.title;
      this.message = data.message;
      this.userId = data.userId;
      this.entityId = data.entityId;
     }

  onConfirm(): void {
    this.deleteUserEntitySubscription = this.appSecurityManagementService.unAssignOrgadminFromEntity(this.userId.toString(), this.entityId.toString()).subscribe(response => {
      if (response !== null) {
        this.snackBar.successMessage(successMsgs.orgadmin_entiy_delete);
        this.dialogRef.close();
      }
      else {
        this.snackBar.errMessage(errorMsgs.something_went_wrong);
        this.dialogRef.close();
      }
    }
    , (error: HttpErrorResponse) => {
      this.showSnackBarMessageError(error);
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.deleteUserEntitySubscription)) {
      this.deleteUserEntitySubscription.unsubscribe();
    }
  }

}

export class DeleteConfirmDialogModel {
  constructor(public title: string, public message: string, public userId: number, public entityId: number, public lineOfBusinessId: number) { }
}

