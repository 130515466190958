import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import _ from 'lodash';
import { Facility } from '../../shared/models/facility.model';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { Users } from '../../shared/models/users.model';
import { DeleteConfirmDialogModel } from 'src/app/components/organization-orgadmin-delete-dialog/organization-orgadmin-delete-dialog.component';
import { OrganizationOrgadminDeleteDialogComponent } from 'src/app/components/organization-orgadmin-delete-dialog/organization-orgadmin-delete-dialog.component';
import { Organization } from 'src/app/shared/models/organization.model';
import { OrganizationAssignComponent } from '../organization-assign/organization-assign.component';
import { FacilityUserDeleteDialogComponent } from 'src/app/components/facility-user-delete-dialog/facility-user-delete-dialog.component';
import { MatRadioChange, MatRadioButton } from '@angular/material/radio';
import { UserFilter } from '../../shared/constants/constants';

@Component({
  selector: 'app-orgadmin-user',
  templateUrl: './orgadmin-user.component.html',
  styleUrls: ['./orgadmin-user.component.scss']
})
export class OrgadminUserComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() orgIdInput: number;
  @Input() orgNameInput: string;
  selectedCondition: string;
  conditions: string[] = ['Active', 'Terminated', 'All'];

  private userId: string;
  private successMessage: string;
  private userSessionSubscription: Subscription;
  private routeParamSubscription: Subscription;
  public  userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedUserRows$: Observable<Users[]>;
  public displayedOrgAdminRows$: Observable<Users[]>;
  public totalRows$: Observable<number>;
  public facilityDetail: Facility;
  private applicationsSubscription: Subscription;
  public sortedData = [];
  private unsubscribeList: any[] = [];
  private organization: Organization[];
  public selectedOrganization: Organization;
  public organizationUsers: Users[];
  public organizationAdmins: Users[];
  public listedOrganizationAdmins: Users[];
  displayedColumns: string[] = ['logo', 'firstName', 'lastName', 'userId', 'email', 'terminationDate',
    'securityGroupName','action'];

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    public dialog: MatDialog
  ) {
    this.selectedCondition = UserFilter.active;
  }

  assignOrgAdmin() {
    const dialogRef = this.dialog.open(OrganizationAssignComponent, {
      data: {
        orgId: this.orgIdInput,
        isForOrgAdmin: true
      },
      backdropClass: 'md_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrgAdmins(this.orgIdInput);
    });
  }

  assignUser() {
    const dialogRef = this.dialog.open(OrganizationAssignComponent, {
      data: {
        orgId: this.orgIdInput,
        isForOrgAdmin: false
      },
      backdropClass: 'md_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrgAdmins(this.orgIdInput);
    });
  }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.getOrgAdmins(this.orgIdInput);
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  onDeleteClick(userId: number, entityId: number, userName: string): void {
    this.deleteConfirmDialog(userId, entityId, userName);
  }

  onConditionChange(conditionChange: MatRadioChange) {
    this.selectedCondition = conditionChange.value;
    this.radioSelectionFilter(this.selectedCondition);
  }

  getOrgAdmins(orgId: number): void {
    this.appSecurityManagementService.getOrganizationAdminsWithFacility(orgId.toString()).subscribe(
      (organizationAdmins) => {
        this.organizationAdmins = organizationAdmins;
        this.radioSelectionFilter(UserFilter.active);
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  deleteConfirmDialog(userId: number, entityId: number, userName: string): void {
    this.typeOfSpinner = 'loading';
    const message = 'Are you sure you want to unassign ' + userName +' from ' + this.orgNameInput + ' ?';
    const dialogData = new DeleteConfirmDialogModel(
      'Delete User', message,  userId,  entityId, 0);
    const dialogRef = this.dialog.open(OrganizationOrgadminDeleteDialogComponent, {
      // panelClass: 'delete-assessment-modal',
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    }).afterClosed().subscribe(r => {
      this.typeOfSpinner = 'loading';
      this.getOrgAdmins(this.orgIdInput);
    });

  }

  setOrgAdminDisplayRows(orgAdminSecurityFacilityCountList: Users[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const rows$ = of(orgAdminSecurityFacilityCountList);
    this.displayedOrgAdminRows$ = rows$.pipe(
      sortRows(sortEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    const filteredOrgAdminSecurityFacility = this.listedOrganizationAdmins.filter((orgAdminSecurityFacility) => {
      return (
        (orgAdminSecurityFacility.firstNameLastName && orgAdminSecurityFacility.firstNameLastName.toLowerCase().indexOf(filterValue) !== -1) ||
        (orgAdminSecurityFacility.createDate && orgAdminSecurityFacility.createDate.indexOf(filterValue) !== -1)||
        (orgAdminSecurityFacility.userId && orgAdminSecurityFacility.userId.toString().indexOf(filterValue) !== -1) ||
        (orgAdminSecurityFacility.email && orgAdminSecurityFacility.email.toLowerCase().indexOf(filterValue) !== -1)||
        (orgAdminSecurityFacility.securityGroupName && orgAdminSecurityFacility.securityGroupName.toLowerCase().indexOf(filterValue) !== -1) ||
        (orgAdminSecurityFacility.facilities && orgAdminSecurityFacility.facilities.toString().indexOf(filterValue) !== -1)||
        (orgAdminSecurityFacility.terminationDate && orgAdminSecurityFacility.terminationDate.indexOf(filterValue) !== -1)
        );
    });
    this.paginator.pageIndex = 0;
    this.setOrgAdminDisplayRows(filteredOrgAdminSecurityFacility);
  }

  radioSelectionFilter(filterValue: string): void {
    const filteredOrgAdminSecurityFacility = this.organizationAdmins.filter((orgAdminSecurityFacility) => {
      if (filterValue == UserFilter.active){return ((orgAdminSecurityFacility.terminationDate == null));}
        else if (filterValue == UserFilter.terminated){return ((orgAdminSecurityFacility.terminationDate != null ));}
          else {return ((orgAdminSecurityFacility));}
      });
    this.listedOrganizationAdmins = filteredOrgAdminSecurityFacility;
    this.setOrgAdminDisplayRows(filteredOrgAdminSecurityFacility);
    this.typeOfSpinner = 'none';
  }
  }
