import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  ViewEncapsulation,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MenuItem } from '../models/menu-item';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ApplicationsService } from '../services/applications.service';
import { Subscription, combineLatest, take } from 'rxjs';
import { environment } from '../environments/environment';
import { UserAppRoleModel } from '../models/user-app-role.model';
import { AppUserRoles } from '../models/user-app-roles';
import { applicationRoles } from '../enum/roles.enum';
import { DataService } from '../services/data.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { clinicaLinkModuleItems } from '../models/clinical-link-module-items';
import {
  Router,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
} from '@angular/router';
import { LinkService } from '../services/link.service';
import { NewRelicService } from '../services/new-relic.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { userApps } from '../models/user-apps';
import { filter } from 'rxjs';
import { LeftMenuPackageService } from './menu.service';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { UserAppActivity } from 'cps-b2clibrary/lib/shared/models/use-app-activity';
import { ChildEntity } from '../models/userOrganization';
import { appNames } from '../constants/constants';

/** Flat node with expandable and level information */
interface MenuFlatNode {
  expandable: boolean;
  item: string;
  itemId: number;
  name: string;
  fullName: string;
  iconName: string;
  isActive: boolean;
  selected: boolean | undefined;
  route: string | undefined;
  routeLink: string | undefined;
  appRoleName: string | undefined;
  children: MenuItem[] | undefined;
  isSelected: boolean;
  ItemUiId: string | undefined;
  level: number;
}

interface MenuNode {
  item: string;
  itemId: number;
  displayName: string;
  iconName: string;
  selected?: boolean;
  route?: string;
  children?: MenuItem[];
  isActive: boolean;
  routeLink?: string;
  indeterminate?: boolean;
  parent?: MenuNode;
}

@Component({
  selector: 'lib-menu-package',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MenuComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  // ViewChild to access MatSlideToggle element
  @ViewChild(MatSlideToggle) toggleButton!: MatSlideToggle;

  // Instance of MatSidenavModule for side navigation
  sidenav: MatSidenavModule = new MatSidenavModule();

  // Flag to indicate menu expansion state
  is_Expanded = true;

  // Flag to indicate whether Session Variable organizations are loaded
  isLocalStorageOrgsLoaded = true;

  // Flag to control submenu visibility
  showSubmenu = false;

  // Flag to indicate submenu visibility
  isShowing = false;

  // Flag to control sub-submenu visibility
  showSubSubMenu = false;

  // List of menu items
  menuList: MenuItem[] = [];

  // List of selected menu items
  menuItemSelected: string[] = [];

  // EventEmitter for menu expand/collapse event
  @Output() menuExpandCollapse = new EventEmitter<boolean>();

  // EventEmitter for data load completion event
  @Output() isLoadedData = new EventEmitter<boolean>();

  // Input property for menu expansion state
  @Input() isExpanded = false;

  // Input property to indicate mobile screen
  @Input() isMobileScreen = false;

  // Input property to control expandable menu
  @Input() isExpandableMenu = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() menuEnvironment: any = {};

  // Flag to control submenu hiding
  isSubHide = false;

  // Flag to control submenu hiding
  isHideSubMenu = true;

  // Tree control for nested menu
  public treeControl = new NestedTreeControl<MenuNode>(
    (node: MenuNode) => node.children
  );

  // Data source for nested menu
  public dataSource = new MatTreeNestedDataSource<MenuNode>();

  // Flag to indicate left menu visibility
  isLeftMenuVisible = false;

  // List of user app role items
  UserAppRoleItems: AppUserRoles[] = [];

  // Retry count for data loading
  retryCount = 1;

  // Initial retry count for data loading
  initialRetryCount = 1;

  // Subscription for clinical pharmacology
  clinicalPharmacologySubscription: Subscription | undefined;

  // Path of application server
  public applicationServerPath?: string;

  // Label for internal applications
  strInternalApps = appNames.internalApplications;

  // Link for marketing page
  marketingPageLink = '';

  // User session information
  public userSession: UserSession = new UserSession();

  // Count for Session Variable
  localstrgCount = 0;

  // List of clinical module link items
  clinicalModuleLinkItems: clinicaLinkModuleItems[] = [];

  // List of user apps
  userApps: userApps[] = [];

  // User email address
  email = '';

  // URL for logo
  logoUrl = '';

  // Transformer function to transform menu node
  private _transformer = (node: MenuItem, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name:
        node.displayName.trim().length > 20
          ? node.displayName.trim().substring(0, 20) + '...'
          : node.displayName.trim(),
      fullName: node.displayName.trim(),
      item: node.item,
      itemId: node.itemId,
      iconName: node.iconName,
      isActive: node.isActive,
      isSelected: node.isSelected,
      selected: node.selected,
      route: node.route,
      routeLink: node.routeLink,
      appRoleName: node.appRoleName,
      children: node.children,
      ItemUiId: node.ItemUiId,
      level: level,
    };
  };

  // Tree control for flat menu
  treeControl1 = new FlatTreeControl<MenuFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  // Flattener for flat menu
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  // Data source for flat menu
  dataSource1 = new MatTreeFlatDataSource(
    this.treeControl1,
    this.treeFlattener
  );

  constructor(
    private applicationsService: ApplicationsService,
    private b2cService: B2cService,
    private leftMenuPackageService: LeftMenuPackageService,
    private dataService: DataService,
    private router: Router,
    private linkService: LinkService,
    private newRelicService: NewRelicService
  ) {
    // Set timeout to initialize flat menu data
    setTimeout(() => {
      this.initialRetryCount = this.initialRetryCount + 1;
      this.dataSource1.data = this.menuList.filter((x) => x.isActive == true);
    }, 1000);

}

  // Function to determine if a node has children in the flat tree
  hasChild = (_: number, node: MenuFlatNode) => node.expandable;

  // Function to determine if a node has children in the mobile tree
  public hasMobileChild = (_: number, node: MenuNode) =>
    !!node.children && node.children.length > 0;

  // Function to set the parent node for each child node recursively
  private setParent(node: MenuNode, parent: MenuNode) {
    node.parent = parent;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.setParent(childNode, node);
      });
    }
  }

  // Event listener to handle window clicks
  @HostListener('window:click')
  onClick() {
    // If menu items are selected and submenu is hidden, reset selected items
    if (this.menuItemSelected.length > 0) {
      if (this.isSubHide) {
        this.menuItemSelected = [];
        this.hasSelectedParentMenuItem('');
      }
      this.isSubHide = true;
    }
  }

  // Method to handle click on the menu icon for expanding/collapsing the menu
  menuIconClick() {
    // Toggle the expanded state of the menu
    this.is_Expanded = !this.is_Expanded;
    // Emit event to notify parent component about menu expand/collapse
    this.menuExpandCollapse.emit(this.is_Expanded);
  }

  ngOnInit() {
    combineLatest([this.b2cService.userSession$, this.b2cService.userAppRoles$, this.b2cService.userUserApps$, this.b2cService.userOrganization$, this.b2cService.userMenus$])
    .pipe(take(1))
    .subscribe(([userSession, userAppRoles, userApps, userOrganization, userMenus]) => {
      this.userSession.onPremisesSecurityIdentifier = userSession.onPremisesSecurityIdentifier;
      this.userSession.userAppRoles = userAppRoles;
      this.userSession.userApps = userApps;
      this.userSession.userOrganizations = userOrganization;
      this.userSession.clinicalModuleLinkItems = userMenus;
      // Check user permissions
      this.checkUserPersmission();
  })

    // Retrieve marketing page link from menu environment
    this.marketingPageLink = this.menuEnvironment.marketingPageLink;
    // Subscribe to router events for navigation handling
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd ||
            event instanceof NavigationError ||
            event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        // Handle URL change upon navigation end
        if (event instanceof NavigationEnd) {
          this.handleUrlChange();
        }
      });
  }

  // Method to handle URL change
  private handleUrlChange(): void {
    // Retrieve current URL
    const url = window.location.href;
    // Check if menu needs to be expanded based on the URL
    this.isExpandMenu(url);
  }

  // Lifecycle hook called after the view is initialized
  ngAfterViewInit() {
    // Subscribe to user app roles changes
    this.b2cService.userAppRoles$.subscribe(() => {
      // Delayed execution to ensure updated roles are retrieved
      setTimeout(() => {
        // Retrieve current URL
        const currentUrl = window.location.href;
        // Check if menu needs to be expanded based on the URL
        this.isExpandMenu(currentUrl);
      }, 1000);
    });

    // Retrieve user session
    this.b2cService.getUserSesssion();
}

  // Lifecycle hook called when input properties change
  ngOnChanges(): void {
    // Set the expanded state based on input
    this.is_Expanded = this.isExpanded;
    // If on mobile screen, collapse the menu
    if (this.isMobileScreen) {
      this.is_Expanded = false;
    }
  }

  isExpandMenu(currentUrl: string) {
    // Retrieve tree control and data nodes
    const treeControl = this.treeControl1;
    const dataNodes = this.treeControl1.dataNodes;

    // Find the index of the tertiary node matching the current URL
    const tertiaryNodeIndex = dataNodes.findIndex(
      (item) => item.routeLink === currentUrl && !item.expandable
    );

    this.menuList.forEach((element) => {
      if(element.children) {
          element.children.forEach((item) => {
            if(item.routeLink === currentUrl) {
              item.selected = true;
              item.isSelected = true;
              if (this.isSubHide) {
                this.menuItemSelected = [];
                this.hasSelectedParentMenuItem('');
              }
              this.isSubHide = true;
            } else {
              item.selected = false;
              item.isSelected = false;
            }

          })
      }
    });

    // If tertiary node found
    if (tertiaryNodeIndex !== -1) {
      // Collapse all nodes
      treeControl.collapseAll();
      // Reset selection for all nodes
      dataNodes.forEach((node) => {
        node.selected = false;
        node.isSelected = false;
      });

      // Select and expand the tertiary node
      dataNodes[tertiaryNodeIndex].selected = true;
      dataNodes[tertiaryNodeIndex].isSelected = true;
      treeControl.expand(dataNodes[tertiaryNodeIndex]);

      // Find and select the parent secondary node
      const secondaryNodeIndex = this.getParentNodeIndex(
        tertiaryNodeIndex,
        dataNodes
      );
      if (secondaryNodeIndex !== -1) {
        dataNodes[secondaryNodeIndex].selected = true;
        dataNodes[secondaryNodeIndex].isSelected = true;
        treeControl.expand(dataNodes[secondaryNodeIndex]);

        // Find and select the parent primary node
        const primaryNodeIndex = this.getParentNodeIndex(
          secondaryNodeIndex,
          dataNodes
        );
        if (primaryNodeIndex !== -1) {
          dataNodes[primaryNodeIndex].selected = true;
          dataNodes[primaryNodeIndex].isSelected = true;
          treeControl.expand(dataNodes[primaryNodeIndex]);
        }
      }
    }
  }

  // Function to get the index of the parent node based on the child index and nodes array
  getParentNodeIndex(childIndex: number, nodes: MenuFlatNode[]): number {
    // Calculate the level of the parent node
    const parentLevel = nodes[childIndex].level - 1;
    // Iterate backward from the child index to find the parent node
    for (let i = childIndex - 1; i >= 0; i--) {
      if (nodes[i].level === parentLevel) {
        // Return the index of the parent node
        return i;
      }
    }
    // Return -1 if no parent node found
    return -1;
  }

  // Function to get the parent node of a given node
  getParentNode(node: MenuFlatNode): MenuFlatNode | null {
    // Retrieve the current level of the node
    const currentLevel = node.level;
    // Determine the start index for searching backward in the data nodes array
    const startIndex = this.treeControl1.dataNodes.indexOf(node) - 1;
    // Iterate backward to find the parent node
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl1.dataNodes[i];
      // If the level of the current node is less than the current level, it is the parent node
      if (currentNode.level < currentLevel) {
        // Return the parent node
        return currentNode;
      }
    }
    // Return null if no parent node found
    return null;
  }

  // Method to handle mouse enter event
  mouseenter() {
    // If menu is collapsed, set isShowing flag to true
    if (!this.is_Expanded) {
      this.isShowing = true;
    }
  }

  // Method to handle mouse leave event
  mouseleave() {
    // If menu is collapsed, set isShowing flag to false
    if (!this.is_Expanded) {
      this.isShowing = false;
    }
  }

  // Method to set the selected parent menu item
  setSelectedParentMenuItem(itemName: string, item: MenuItem) {
    // Find the index of the item in the menuItemSelected array
    const itemIndex: number = this.menuItemSelected.findIndex(
      (item) => item === itemName
    );
    // Clear the menuItemSelected array
    this.menuItemSelected = [];
    // Add the item name to the menuItemSelected array if not already present
    if (itemIndex === -1) {
      this.menuItemSelected.push(itemName);
    }
    const currentUrl = window.location.hostname;
    // Reset selection for all menu items and data nodes
    this.menuList.forEach((item) => (item.selected = false));
    this.menuList.forEach((item) => (item.isSelected = false));
    this.treeControl1.dataNodes.forEach((item) => (item.selected = false));
    this.treeControl1.dataNodes.filter((item) => (item.routeLink == currentUrl)).forEach((item) => (item.isSelected = false));
    // Set the selected state for the current item
    item.selected = true;
    item.isSelected = true;
    // Reset submenu visibility flag
    this.isSubHide = false;
  }

  // Method to check if a parent menu item is selected
  hasSelectedParentMenuItem(itemName: string): boolean {
    // Find the index of the item in the menuItemSelected array
    const itemIndex: number = this.menuItemSelected.findIndex(
      (item) => item === itemName
    );
    // If the item is selected, set the submenu visibility flag and return true
    if (itemIndex >= 0) {
      this.isHideSubMenu = true;
      return true;
    } else {
      // If the item is not selected, reset the submenu visibility flag and return false
      this.isHideSubMenu = false;
      return false;
    }
  }

  // Method to handle node expanding
  nodeExpanding(node: MenuItem): void {
    // Iterate through each data node in the tree control
    this.treeControl1.dataNodes.forEach((element) => {
      // Check if the node has children
      if (element.children !== undefined) {
        // Check if the current node is not the same as the target node,
        // and if the current node is expandable and does not contain the target node in its children
        if (
          node.fullName !== element.fullName &&
          element.expandable &&
          !element.children.find((e) => e.fullName === node.fullName)
        ) {
          // Collapse the current node
          this.treeControl1.collapse(element);
        }
      }
    });
  }

  // Method to handle node collapse/expand
  nodeCollapseExpand(item: MenuNode, node: MenuItem): void {
    // Check if the item has children
    if (item.children !== undefined) {
      // Iterate through each child of the item
      item.children.forEach((element) => {
        // Check if the display name of the node is not the same as the display name of the element
        if (node.displayName !== element.displayName) {
          // Collapse the element
          this.treeControl.collapse(element);
        }
      });
    }
  }

  // Method to expand/collapse a node in the tree
  nodeTreeExpand(node: MenuFlatNode): void {
    // Check if the node is expanded
    if (this.treeControl1.isExpanded(node)) {
      // Collapse the node if it is expanded
      this.treeControl1.collapse(node);
    } else {
      // Expand the node if it is collapsed
      this.treeControl1.expand(node);
    }
    // Iterate through each data node in the tree control
    this.treeControl1.dataNodes.forEach((element) => {
      // Check if the node has children
      if (element.children !== undefined) {
        // Check if the current node is not the same as the target node,
        // and if the current node is expandable and does not contain the target node in its children
        if (
          node.fullName !== element.fullName &&
          element.expandable &&
          !element.children.find((e) => e.fullName === node.fullName)
        ) {
          // Collapse the current node
          this.treeControl1.collapse(element);
        }
      }
    });
  }

  // Method to get the first child node of a given node
  getFirstChild(node: MenuFlatNode): MenuFlatNode | null {
    // Get the descendants of the node
    const descendants = this.treeControl1.getDescendants(node);
    // Return the first descendant if exists, otherwise return null
    return descendants.length > 0 ? descendants[0] : null;
  }

  // Method to toggle the expand/collapse state of a node
  toggleNodeExpand(event: Event, node: MenuItem): void {
    // Iterate through each data node in the tree control
    this.treeControl1.dataNodes.forEach((element) => {
      // Check if the node has children
      if (element.children !== undefined) {
        // Check if the current node is not the same as the target node,
        // and if the current node is expandable and does not contain the target node in its children
        if (
          node.fullName !== element.fullName &&
          element.expandable &&
          !element.children.find((e) => e.fullName === node.fullName)
        ) {
          // Collapse the current node
          this.treeControl1.collapse(element);
        }
      }
    });
    // Stop event propagation to prevent further actions
    event.stopPropagation();
  }

  // Method to perform cleanup when the component is destroyed
  ngOnDestroy(): void {
    // Unsubscribe from clinical pharmacology subscription if exists
    if (this.clinicalPharmacologySubscription) {
      this.clinicalPharmacologySubscription.unsubscribe();
    }
  }

  getFacilityNamesFromChildEntities(entities: ChildEntity[], appCode: string) {
    const facilities: string[] = [];
    function recursiveCount(entities: ChildEntity[]) {
      for (const entity of entities) {
        if (entity.entityTypeName === environment.facilityEntityTypeName && (appCode === environment.appcode || entity.apps.includes(appCode))) {
          facilities.push(entity.entityName);
        }

        if (entity.childEntities.length) {
          recursiveCount(entity.childEntities);
        }
      }
    }

    recursiveCount(entities);
    return facilities;
  }

  // Method to open an application
  async openApp(application: MenuItem, itemName: MenuItem | null = null): Promise<void> {
    // Reset selection for all menu items and data nodes
    if (window.location.href === application.routeLink) return;
    this.menuList.forEach((item) => (item.selected = false));
    this.menuList.forEach((item) => (item.isSelected = false));
    this.treeControl1.dataNodes.forEach((item) => (item.selected = false));
    this.treeControl1.dataNodes.forEach((item) => (item.isSelected = false));

    // Set the default target for opening application
    let target = '_self';

    // Return if application is not provided
    if (!application) {
      return;
    }

    // Check if the application is the Learning Center or an internal app, set target to '_blank' for opening in a new tab
    if (
      (itemName && itemName.item === this.strInternalApps) ||
      application.item === appNames.learningCenter
    ) {
      target = '_blank';
    }

    // Retrieve the application server path
    this.applicationServerPath = environment.serverPath;
    const applicationServerPath =
      application &&
      application.routeLink &&
      application.routeLink.includes('http')
        ? ''
        : this.applicationServerPath;

    // Log menu item click event
    this.newRelicService.handleError(
      `started log for menu item click -  ${localStorage.getItem('email')}`
    );
    this.newRelicService.handleError(application);

    // Open the Clinical Pharmacology application if selected
    if (application.item.trimEnd().toLowerCase() === environment.clinicalPharmName) {
      try {
        let menu = this.userSession.clinicalModuleLinkItems.find(m => m.linkUrl.split('/#/')[1] === window.location.href.split('/#/')[1]);
        while (menu?.parentClinicalModuleLinkItemGuid) {
          menu = this.userSession.clinicalModuleLinkItems.find((m) => m.clinicalModuleLinkItemGuid === menu.parentClinicalModuleLinkItemGuid);
        }
        const appCode = this.applicationsService.getAppCode();
        const orgs = appCode === environment.appcode ? this.userSession.userOrganizations : this.userSession.userOrganizations.filter(org => org?.apps?.includes(appCode));
        let facilities: string[] = [];
        for (const org of orgs) {
          if (org.entityTypeName === environment.facilityEntityTypeName) facilities.push(org.entityName);
          if (org.childEntities.length) {
            const facilityNames = this.getFacilityNamesFromChildEntities(org.childEntities, appCode);
            if (facilityNames.length) {
              facilities = facilities.concat(facilityNames);
            }
          }
        }
        if (!this.email?.length) {
          this.email = this.dataService.getUserEmail('userEmail');
        }
        const activity: UserAppActivity = {
          userEmail: this.email,
          facilityName: facilities?.length === 1 ? facilities[0] : environment.clinicalPharmMultiFacilityId,
          appID: menu?.appId ?? 0
        }
        await this.b2cService.logUserAppActivity(activity);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        this.newRelicService.handleError(
          `Info : Clinical Pharmacology logging user app activity for ${this.email} error: ` + e.message
        );
      } finally {
        this.clinicalPharmacologySubscription = this.applicationsService
          .getClinicalPharmacology()
          .subscribe((response) => {
            application.routeLink = response.clinicalPharmacologyUrl;
            window.open(`${application.routeLink}`, '_blank');
          });
      }
    } else {
      // Open the application in a new tab or the same tab based on the target
      window.open(`${applicationServerPath}${application.routeLink}`, target);
    }
    this.hasSelectedParentMenuItem('');
  }

  // Method to handle click event on the logo
  logoClick(): void {
    // Stop event propagation to prevent further actions
    const event = window.event || null;
    event?.stopPropagation();
    // Redirect to the logo URL
    if(window.location.href == this.logoUrl)
    {
      window.location.href = "";
    } else {
      window.location.href = this.logoUrl;
    }
  }

  // Method to check if the role for making the left/top menu visible is present for the current user
  isLeftMenuShown(): boolean {
    // Initialize a flag to indicate if the role is present
    let isRolePresent = false;

    // Check if userAppRoles are present in the Session Variable
    if (this.userSession.userAppRoles !== null) {
      // Parse the userAppRoles from the Session Variable
      const userAppRoles =
        JSON.parse(JSON.stringify(this.userSession.userAppRoles) ?? '{}') || [];

      // Iterate through each user application role
      userAppRoles.forEach((userApplicationRoleName: UserAppRoleModel) => {
        // Check if the role name is 'RS_Main-Making Left/Top Menu Visible'
        if (
          userApplicationRoleName.appRoleName.trim() ===
          'RS_Main-Making Left/Top Menu Visible'
        ) {
          // Set the flag to true if the role is found
          isRolePresent = true;
        }
      });
    }
    // Return the flag indicating if the role is present
    return isRolePresent;
  }

  // Method to check if the provided URL is localhost
  isLocalHost(url: string): boolean {
    // Check if the URL contains 'localhost' or '127.0.0.1'
    return url.indexOf('localhost') !== -1 || url.indexOf('127.0.0.1') !== -1;
  }

  // Method to check user permissions and initialize menu based on user roles and data availability
  checkUserPersmission() {
    // Get user email from data service
    this.email = this.dataService.getUserEmail('userEmail');

    try {
      // Retrieve user app roles, clinical module link items, and user apps from Session Variable
      this.UserAppRoleItems = JSON.parse(JSON.stringify(this.userSession.userAppRoles) ?? '[]') || [];
      this.clinicalModuleLinkItems = this.userSession.clinicalModuleLinkItems.length > 0 ? this.userSession.clinicalModuleLinkItems : [];

      this.userApps = JSON.parse(JSON.stringify(this.userSession.userApps) ?? '[]') || [];

      // Check if all required data are present
      if (
        this.clinicalModuleLinkItems !== null &&
        this.clinicalModuleLinkItems.length > 0 &&
        this.UserAppRoleItems !== null &&
        this.UserAppRoleItems.length > 0 &&
        this.userApps !== null &&
        this.userApps.length > 0
      ) {
        // Process redirection and map menu if data are available
        this.mapMenu();
        // Select the first menu item
        this.menuList[0].selected = true;
      }

      // Check if maximum retry count is reached
      if (this.retryCount === 10) {
        // Log and hide the menu
        this.newRelicService.handleError(
          'Info : Retry count reached maximum 10 for User: ' +
            this.email +
            '. User Approles Count - ' +
            this.UserAppRoleItems.length +
            ', Clinical Module Items Count - ' +
            this.clinicalModuleLinkItems.length +
            ' and User Apps Count - ' +
            this.userApps.length +
            ' any of this are null or empty hence left menu hidden.'
        );
        this.isLeftMenuVisible = false;
      } else {
        // Check if required data are available and left menu access is granted
        if (
          this.clinicalModuleLinkItems !== null &&
          this.clinicalModuleLinkItems.length > 0 &&
          this.UserAppRoleItems !== null &&
          this.UserAppRoleItems.length > 0 &&
          this.userApps !== null &&
          this.userApps.length > 0
        ) {
          // Check if user has permission to view the left menu
          if (this.isLeftMenuShown()) {
            this.isLeftMenuVisible = true;
          } else {
            // Log if user does not have left menu access
            this.newRelicService.handleError(
              'Info : User- ' +
                this.email +
                ', dont have left menu access hence left menu hidden.'
            );
          }
        } else {
          // If required data are not available, retry to check Session Variable values
          this.isCheckLocalStorageValue();
        }
      }
    } catch (error) {
      // Log any errors occurred during the process
      this.newRelicService.handleError(
        'User- ' + this.email + ' Error: ' + error
      );
    }
  }

  // Method to check Session Variable values after a delay
  isCheckLocalStorageValue() {
    // Set a timeout to delay the checking of Session Variable values
    setTimeout(() => {
      // Increment the retry count
      this.retryCount = this.retryCount + 1;
      // Check user permissions after the delay
      this.checkUserPersmission();
    }, 1000);
  }

  /**
   * Method to map menu items retrieved from Session Variable and update the menu list accordingly.
   */
  mapMenu(): void {
    // Initialize an empty menu array
    const menu: MenuItem[] = [];
    // Retrieve menu items from Session Variable
    const menuItems = this.userSession.clinicalModuleLinkItems.length > 0 ? this.userSession.clinicalModuleLinkItems : [];

    // Filter parent menu items
    let parentMenu = menuItems.filter(
      (a: clinicaLinkModuleItems) => a.parentClinicalModuleLinkItemGuid == null
    );
    // Sort parent menu items by sort number
    parentMenu = parentMenu.sort(
      (a: clinicaLinkModuleItems, b: clinicaLinkModuleItems) =>
        a.sortNumber > b.sortNumber ? 1 : -1
    );

    // Iterate through parent menu items
    parentMenu.forEach((element: clinicaLinkModuleItems) => {
      const obj: MenuItem = {
        itemId: element.sortNumber,
        item: element.displayText.trim(),
        fullName: element.displayText.trim(),
        iconName: element.thumbnailImageName,
        route: '',
        routeLink: element.linkUrl,
        isActive: true,
        displayName:
          element.displayText.trim().length > 30
            ? element.displayText.trim().substring(0, 30) + '...'
            : element.displayText,
        selected: false,
        children: [],
        appRoleName: element.appRoleName,
        isSelected: false,
        ItemUiId: 'id_'+element.displayText.trim(),
      };

      // Filter child menu items for the current parent
      let childMenu = menuItems.filter(
        (a: clinicaLinkModuleItems) =>
          a.parentClinicalModuleLinkItemGuid ==
          element.clinicalModuleLinkItemGuid
      );
      // Sort child menu items by sort number
      childMenu = childMenu.sort(
        (a: clinicaLinkModuleItems, b: clinicaLinkModuleItems) =>
          a.sortNumber > b.sortNumber ? 1 : -1
      );

      // Check if child menu items exist
      if (childMenu.length > 0) {
        // Iterate through child menu items
        childMenu.forEach((element1: clinicaLinkModuleItems) => {
          const objChild: MenuItem[] = [];
          // Filter second-level child menu items
          let childMenu2 = menuItems.filter(
            (a: clinicaLinkModuleItems) =>
              a.parentClinicalModuleLinkItemGuid ==
              element1.clinicalModuleLinkItemGuid
          );
          // Sort second-level child menu items by sort number
          childMenu2 = childMenu2.sort(
            (a: clinicaLinkModuleItems, b: clinicaLinkModuleItems) =>
              a.sortNumber > b.sortNumber ? 1 : -1
          );

          // Check if second-level child menu items exist
          if (childMenu2.length > 0) {
            // Iterate through second-level child menu items
            childMenu2.forEach((element2: clinicaLinkModuleItems) => {
              // Create MenuItem objects for second-level child menu items
              objChild.push({
                itemId: element2.sortNumber,
                item: element2.displayText.trim(),
                fullName: element2.displayText.trim(),
                iconName: element2.thumbnailImageName,
                route: '',
                routeLink: element2.linkUrl,
                isActive: true,
                displayName:
                  element2.displayText.trim().length > 30
                    ? element2.displayText.trim().substring(0, 30) + '...'
                    : element2.displayText,
                selected: false,
                children: [],
                appRoleName: element2.appRoleName,
                isSelected: false,
                ItemUiId: 'id_'+element2.displayText.trim(),
              });
            });
          }
          // Add child menu items to parent's children array
          obj.children?.push({
            itemId: element1.sortNumber,
            item: element1.displayText.trim(),
            iconName: element1.thumbnailImageName,
            route: '',
            routeLink: element1.linkUrl,
            isActive: true,
            displayName:
              element1.displayText.trim().length > 30
                ? element1.displayText.trim().substring(0, 30) + '...'
                : element1.displayText,
            fullName: element1.displayText.trim(),
            selected: false,
            children: objChild,
            appRoleName: element1.appRoleName,
            isSelected: false,
            ItemUiId: 'id_'+element1.displayText.trim(),
          });
        });
      } else {
        // If no child menu items exist, remove the children property
        delete obj.children;
      }
      // Add parent menu item to the menu array
      menu.push(obj);
    });
    // Retrieve user apps from Session Variable
    const userApps = JSON.parse(JSON.stringify(this.userSession.userApps) ?? '[]') || [];
    // Retrieve user apps from Session Variable
    const sorteduserApps = userApps.sort(
      (a: { applicationName: string }, b: { applicationName: string }) =>
        a.applicationName > b.applicationName ? 1 : -1
    );
    const userAppschildren: MenuItem[] = [];

    // Iterate through user applications
    for (let j = 0; j < sorteduserApps.length; j++) {
      const item: MenuItem = {
        itemId: 17 + (j + 1),
        item: sorteduserApps[j].applicationName.trim(),
        displayName:
          sorteduserApps[j].applicationName.trim().length > 30
            ? sorteduserApps[j].applicationName.trim().substring(0, 30) + '...'
            : sorteduserApps[j].applicationName,
        fullName: sorteduserApps[j].applicationName.trim(),
        routeLink: sorteduserApps[j].appPathForClinicalModule,
        isActive: true,
        selected: false,
        iconName: '',
        isSelected: false,
        ItemUiId: 'id_'+sorteduserApps[j].applicationName.trim(),
        appRoleName: sorteduserApps[j].appRoleName,
      };
      // Check if the application is not a clinical module app
      if (sorteduserApps[j].isClinicalModuleApp !== true) {
        userAppschildren.push(item);
      }
    }
    this.menuList = menu;
    // Update menu list with user applications
    menu.forEach((module) => {
      const appRole = applicationRoles.find(
        (app) => app.appName.toLowerCase() === module.item.toLowerCase()
      );
      if (appRole) {
        module.appRoleName = appRole.appRoleName;
      }
    });

    if (this.userSession.userAppRoles != null) {
      this.UserAppRoleItems =
        JSON.parse(JSON.stringify(this.userSession.userAppRoles) ?? '[]') || [];
    }

    // Update menu list with filtered clinical modules
    this.menuList = menu
      .filter((module) => module.isActive === true)
      .sort((a, b) => a.itemId - b.itemId);

    // Add user applications as children of internal apps menu item
    const internalApps = menu.filter(
      (a: MenuItem) => a.displayName === this.strInternalApps
    );
    if (internalApps.length > 0 && userAppschildren.length > 0) {
      internalApps[0].children = userAppschildren;
    } else {
      this.menuList.filter(
        (menuItem: MenuItem) => menuItem.item === this.strInternalApps
      )[0].isActive = false;
    }
    // Update 'Reports' menu item based on permissions
    const ReportMenu: MenuItem | undefined = this.menuList.find(
      (menu: MenuItem) => menu.item === 'Reports'
    );
    this.CheckPermission(ReportMenu);
    const reportChild = ReportMenu?.children?.filter(
      (menu) => menu.isActive == true
    );
    if (reportChild?.length == 0 && ReportMenu) {
      ReportMenu.routeLink = this.marketingPageLink;
      delete ReportMenu.children;
    }
    // Set unique identifiers for menu items
    for (const item of this.menuList) {
      item.ItemUiId = `LeftMenu${item.displayName}id`;
    }
    // Update and display the menu list
    this.menuList = this.updateMenuList(this.menuList);
    this.processRedirectionOnLoad();
    this.redirectToAccessDenied(this.menuList);
    this.dataSource1.data = this.menuList.filter((x) => x.isActive == true);
  }

  /**
   * Redirects to the access denied page if the user does not have access to any menu item.
   * @param menuItem The array of menu items to check access for.
   */
  redirectToAccessDenied(menuItem: MenuItem[]): void {
    const currentUrl = window.location.hostname;
    let hasAccess = false;

    // Iterate through each menu item
    menuItem.forEach((item) => {
      // Check if the menu item has a route link
      if (item.routeLink) {
        try {
          const menuItemUrl = new URL(item.routeLink);
          // Check if the hostname of the menu item URL matches the current URL
          if (menuItemUrl.hostname === currentUrl) {
            // If the menu item doesn't have children, mark it as selected
            if (!item.children) {
              item.isSelected = true;
            }
            hasAccess = true;
          } else {
            item.isSelected = false;
          }
        } catch (error) {
          console.error(
            `Error creating URL object for menu item: ${item.fullName}. Invalid URL: ${item.routeLink}`,
            error
          );
        }
      } else {
        // If the menu item doesn't have a route link, check its children
        if (item.children) {
          item.children.forEach((childMenu) => {
            if (childMenu.routeLink) {
              try {
                const childMenuItemUrl = new URL(childMenu.routeLink);
                // Check if the hostname of the child menu item URL matches the current URL
                if (childMenuItemUrl.hostname === currentUrl) {
                  item.isSelected = true;
                  hasAccess = true;
                  // Check if the child menu item has children and if any of them matches the current URL
                  if (childMenu.children) {
                    childMenu.children.forEach((childSecondaryMenu) => {
                      if (
                        childSecondaryMenu.routeLink === window.location.href
                      ) {
                        item.isSelected = true;
                        hasAccess = true;
                      }
                    });
                  }
                }
              } catch (error) {
                console.error(
                  `Error creating URL object for child menu item: ${childMenu.fullName}. Invalid URL: ${childMenu.routeLink}`,
                  error
                );
              }
            }
          });
        }
      }
    });

    // If no menu item has access and the current URL is not localhost, navigate to the access denied page
    if (!hasAccess && currentUrl !== 'localhost') {
      this.router.navigate(['/access-denied']);
    }

    // Logo Redirection Logic
    try {
      // Retrieve menu items from Session Variable
      const menuItems = this.userSession.clinicalModuleLinkItems.length > 0 ? this.userSession.clinicalModuleLinkItems : [];

      // Find the URL for the Executive Dashboard
      const executiveDashboardUrl =
        menuItems
          .filter(
            (item: clinicaLinkModuleItems) =>
              item.parentClinicalModuleLinkItemGuid === null &&
              item.appRoleId !== null
          )
          .find(
            (item: clinicaLinkModuleItems) =>
              item.displayText === appNames.executiveDashboard
          )?.linkUrl || '';

          const checkExecutiveDashboardAccess =
        menuItem.filter((x) => x.isActive == true).find((x)=> x.displayName === appNames.executiveDashboard)?.displayName || '';

      // Set the default URL for the applications service
      this.applicationsService.setDefaultUrl(executiveDashboardUrl || '');

      // Check if the logo URL should redirect to the Executive Dashboard
      this.logoUrl = executiveDashboardUrl;

      // If the logo URL includes the subdomain and the user doesn't have access, set the logo URL to the first accessible menu item or the marketing page link
      if (
       checkExecutiveDashboardAccess === "" &&
        menuItem
      ) {
        let foundLogo = false;
        menuItem.forEach((item) => {
          if (item.children?.[0]?.routeLink && !foundLogo) {
            this.logoUrl = item.children[0].routeLink;
            foundLogo = true;
          } else if (
            item.routeLink &&
            !this.isAppTobeExcluded(item.displayName) &&
            !foundLogo
          ) {
            this.logoUrl = item.routeLink;
            foundLogo = true;
          }
        });
        if (!foundLogo) {
          this.logoUrl = this.marketingPageLink;
        }
      } else if (
        checkExecutiveDashboardAccess !== ""  &&
        menuItem
      ) {
        // If the logo URL includes the subdomain and the user has access, clear the logo URL
        this.logoUrl = executiveDashboardUrl;
      } else {
        // Otherwise, set the logo URL to the marketing page link
        this.logoUrl = this.marketingPageLink;
      }
    } catch (error) {
      console.warn(
        'Error setting logo click URL: ' + this.logoUrl + ', Error: ' + error
      );
    }
  }

  /**
   * Checks if the application name should be excluded from logo redirection.
   * @param appName The name of the application to check.
   * @returns A boolean indicating whether the application should be excluded.
   */
  isAppTobeExcluded(appName: string): boolean {
    const excludedApps: string[] = [appNames.learningCenter, appNames.clinicalPharmacology];
    return excludedApps.includes(appName);
  }

  /**
* Updates the menu list based on the user's application roles.
* @param menuList The menu list to be updated.
* @returns The updated menu list.
*/
updateMenuList(menuList: MenuItem[]): MenuItem[] {
  // Check if user app roles array is invalid or not an array
  if (!this.UserAppRoleItems || !Array.isArray(this.UserAppRoleItems)) {
    return menuList;
  }

  // Extract app role names from user app roles
  const userRoles: string[] = this.UserAppRoleItems.map(
    (role: AppUserRoles) => role.appRoleName
  );

  // Iterate over the menu items from the end to avoid index issues
  for (let i = menuList.length - 1; i >= 0; i--) {
    const primaryMenuItem = menuList[i];

    // Skip Internal Applications menu item
    if (primaryMenuItem.fullName === appNames.internalApplications) {
      continue;
    }

    // Filter secondary and tertiary menus based on user roles
    if (primaryMenuItem.children && primaryMenuItem.children.length > 0) {
      this.filterMenuItems(primaryMenuItem.children, userRoles);
    }

    // Remove primary menu item if it has no valid secondary menu or children
    const hasValidSecondaryMenu = primaryMenuItem.children?.some(child =>
      (child.children && child.children.length > 0) || (child.appRoleName && userRoles.includes(child.appRoleName)) || !child.appRoleName
    );

    if (!hasValidSecondaryMenu && (!primaryMenuItem.appRoleName || !userRoles.includes(primaryMenuItem.appRoleName))) {
      menuList.splice(i, 1);
    }
  }

  return menuList;
}

/**
* Filters menu items based on user roles.
* @param menuItems The menu items to be filtered.
* @param userRoles The user roles.
*/
filterMenuItems(menuItems: MenuItem[], userRoles: string[]): void {
  for (let i = menuItems.length - 1; i >= 0; i--) {
    const menuItem = menuItems[i];

    if (menuItem.appRoleName && !userRoles.includes(menuItem.appRoleName)) {
      menuItems.splice(i, 1);
    } else if (!menuItem.appRoleName && (!menuItem.children || menuItem.children.length === 0)) {
      menuItems.splice(i, 1);
    }
  }
}

  /**
   * Checks permission for a specific menu item and its children.
   * @param data The menu item to check permission for.
   */
  CheckPermission(data: MenuItem | undefined): void {
    if (data?.children && data.children.length > 0) {
      let isShowMenu = 0;
      data.children.forEach((menu) => {
        // Check if the user has the required role for the menu item
        const isMenuPermission: UserAppRoleModel | undefined =
          this.UserAppRoleItems.find(
            (userAppRole: UserAppRoleModel) =>
              userAppRole.appRoleName === menu.appRoleName
          );
        // If user does not have the required role, deactivate the menu item
        if (!isMenuPermission) {
          menu.isActive = false;
        } else {
          // Increment the count of active menu items
          isShowMenu += 1;
        }
      });
      // If no active menu items found, deactivate the parent menu item
      if (isShowMenu === 0) {
        data.isActive = false;
      }
    }
  }

  processRedirectionOnLoad() {
    try {
      // get the base URL to find the application user logged into
      const baseUrl = window.location.origin;

      // Function to check for the URL in menu list recursively
      const checkUrlInMenuList = (menuList: MenuItem[]): boolean => {
        for (const item of menuList) {
          // Check if the routeLink matches the current URL
          if (item.routeLink && item.routeLink.includes(baseUrl)) {
            return true;
          }
           // Recursively check in children if Primary Menu URL is NULL or empty
           if (item.children && item.children.length > 0) {
            if (checkUrlInMenuList(item.children)) {
              return true;
            }
          }
        }
        return false;
      };

      // Get the default redirect URL from environment file, if not present assign default value.
      let defaultRedirectUrl = this.marketingPageLink ? this.marketingPageLink : "https://www.cpssaassolutions.com/";
      if (this.menuList.length > 0) {
        const firstItem = this.menuList[0];
        if (firstItem.routeLink) {
          defaultRedirectUrl = firstItem.routeLink;
        } else if (firstItem.children && firstItem.children.length > 0) {
          const firstChild = firstItem.children[0];
          if (firstChild.routeLink) {
            defaultRedirectUrl = firstChild.routeLink;
          }
        }
      }

      // Check the current URL in the menu list
      const hasCurrentUrl = checkUrlInMenuList(this.menuList);

      // If the current URL is found in menuList, set redirectTo to null
      const redirectTo = hasCurrentUrl ? null : defaultRedirectUrl;

      // Perform the redirection if redirectTo is not null and not in localhost
      if (redirectTo !== null && window.location.hostname.toLowerCase() !== 'localhost') {
        window.location.href = redirectTo;
      }
    } catch (error) {
      console.error('An error occurred during menu redirection on load:', error);
    }
  }

  // Retrieve active menus from the menuList
  getActiveMenus() {
    return this.menuList.filter((x) => x.isActive == true);
  }
}
