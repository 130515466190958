import { OrganizationFacility } from "src/app/shared/models/organization-facility.model";
import { ApplicationRoles } from "./application-roles";
import { SecurityGroupUserList } from "./security-groups";

export class UserApproleFacility {
  appRoleSecurityGroupUsers : Array<ApplicationRoles>;
  UserEntitySecurityGroupUsers : Array<OrganizationFacility>;
  appRoleSecurityGroupUsersForDeniedRoles : Array<ApplicationRoles>;
  securityGroupUserList : Array<SecurityGroupUserList>;
  oldSecurityGroupId: number;
}
