import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import _ from 'lodash';
import { of } from 'rxjs';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { AdminFacilityService } from '../view-admin-facility-lob/services/admin-facility.service';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';
import { FacilityLOBList } from 'src/app/shared/models/facility-lob-list-model';
import { FacilityLOB } from 'src/app/shared/models/facility-lob.model';

@Component({
  selector: 'app-child-tree-org-admin-facility-lob',
  templateUrl: './child-tree-org-admin-facility-lob.component.html',
  styleUrls: ['./child-tree-org-admin-facility-lob.component.scss']
})
export class ChildTreeOrgAdminFacilityLOBComponent implements OnInit {


  constructor(
    private adminFacilityService: AdminFacilityService,
    private customErrorHandler: CustomErrorHandler) { }

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() facilities: FacilityLOB[];
  @Output()
  openChildFacility = new EventEmitter<FacilityLOBList>();
  expandedElement: any;
  displayedColumns: string[] = ['indent', 'facilityName', 'city', 'state', 'serviceProvided',
    'startDate', 'endDate', 'action'];
    lastChild = 0;
  public displayedRows$: Observable<FacilityLOB[]>;

  typeOfSpinner: string;

  ngOnInit() {
    this.setDisplayRows();
  }

  onEdit(item): void {
    this.openChildFacility.emit(item);
  }


  setDisplayRows(): void {
    // const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    // const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(this.facilities);
    this.displayedRows$ = rows$;
    this.lastChild = this.facilities[this.facilities.length - 1].facilityContractId;
    
  }

}
