// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-content .filter-wrap {
  padding: 15px 0;
}

.dialog-content .search-facility {
  margin: 0 20px 0 0;
}

.cont-nowrap {
  white-space: nowrap;
  overflow: hidden;
  margin-right: 28px;
}

.more-vert {
  position: absolute;
  top: -3px;
  right: 0;
}

.icons-cta-wrap {
  margin-left: 0;
  padding: 5px 2px;
}

.mobile-label {
  display: none;
}

@media (max-width: 1023px) {
  .item_container .mat-mdc-header-row {
    display: none;
  }
  .assign-user-dialog {
    width: 70vw;
  }
  .mobile-label {
    display: block;
    color: #999;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .cont-nowrap {
    white-space: pre-line;
    overflow: inherit;
  }
  .more-vert {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/facility-assign-user/facility-assign-user.component.scss"],"names":[],"mappings":"AACA;EACE,eAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,gBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;AAAF;;AAGA;EACE,cAAA;EACA,gBAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE;IACE,aAAA;EAAF;EAGA;IACE,WAAA;EADF;EAIA;IACE,cAAA;IACA,WAAA;IACA,eAAA;IACA,gBAAA;IACA,mBAAA;EAFF;EAKA;IACE,qBAAA;IACA,iBAAA;EAHF;EAMA;IACE,aAAA;EAJF;AACF","sourcesContent":["\r\n.dialog-content .filter-wrap {\r\n  padding: 15px 0;\r\n}\r\n\r\n.dialog-content .search-facility {\r\n  margin: 0 20px 0 0;\r\n}\r\n\r\n.cont-nowrap {\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  margin-right: 28px;\r\n}\r\n\r\n.more-vert {\r\n  position: absolute;\r\n  top: -3px;\r\n  right: 0;\r\n}\r\n\r\n.icons-cta-wrap {\r\n  margin-left: 0;\r\n  padding: 5px 2px;\r\n}\r\n\r\n.mobile-label {\r\n  display: none;\r\n}\r\n\r\n@media(max-width:1023px) {\r\n  .item_container .mat-mdc-header-row {\r\n    display: none;\r\n  }\r\n\r\n  .assign-user-dialog {\r\n    width: 70vw;\r\n  }\r\n\r\n  .mobile-label {\r\n    display: block;\r\n    color: #999;\r\n    font-size: 13px;\r\n    font-weight: 500;\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  .cont-nowrap {\r\n    white-space: pre-line;\r\n    overflow: inherit;\r\n  }\r\n\r\n  .more-vert {\r\n    display: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
