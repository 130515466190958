import { Component } from '@angular/core';

@Component({
    templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})

export class TermsAndConditionsComponent {



}
