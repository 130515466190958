import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { of } from 'rxjs';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { AdminFacilityService } from '../view-admin-facility-lob/services/admin-facility.service';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';
import { ViewAdminFacilityLobDialogComponent } from '../view-admin-facility-lob-dialog/view-admin-facility-lob-dialog.component';
import { FacilityLOBList } from 'src/app/shared/models/facility-lob-list-model';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { ActionMessage, DisplayMessage } from 'src/app/shared/components/message';
import { MatDialog } from '@angular/material/dialog';
import { FacilityLOB } from 'src/app/shared/models/facility-lob.model';
import { ConfirmationDialogComponent, ConfirmDialogModel } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-child-facility-lob',
  templateUrl: './child-facility-lob.component.html',
  styleUrls: ['./child-facility-lob.component.scss']
})
export class ChildFacilityLobComponent implements OnInit {
  displayedColumns: string[] = ['indent', 'facilityName', 'lineOfBusinessType', 'serviceCategory', 'unitNumber', 'entityId',
    'startDate', 'endDate', 'action'];
  
    lastChild = 0;

  constructor(
    private adminFacilityService: AdminFacilityService,
    private customErrorHandler: CustomErrorHandler,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private dialog: MatDialog,
    private snackBar: SnackBarModule,
    ){ }

  @Input() parentEntityIdInput: number;
  @Input() selectedOption: boolean;
  @Input() fromOrganization: false;
  @Input() facilityContractId=0;
  @Input() fromDialog=false;
  @Output()
  openChildFacility = new EventEmitter<FacilityLOBList>();
  @Output() deleteChildEvent: EventEmitter<boolean> = new EventEmitter();
  isLOBManager=false;
  isAdmin=false;
  isOrgAdmin=false;

  childAdminFacilitiesList: FacilityLOBList[] = [];

  public displayedRows$: Observable<FacilityLOBList[]>;
  public totalRows$: Observable<number>;

  dataSource = new MatTableDataSource<FacilityLOBList>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  typeOfSpinner: string;

  ngOnInit() {
    if (!_.isNil(this.parentEntityIdInput))
    {
      this.typeOfSpinner ='loading';
      this.isLOBManager= this.adminFacilityService.getLOBManagerMode();
      this.isAdmin= this.adminFacilityService.getAdminMode();
      this.isOrgAdmin=this.adminFacilityService.getOrganizationAdminMode();
      if(this.fromOrganization && (this.facilityContractId===0 || this.facilityContractId===undefined)){
        this.getOrganizationChildFacilities(this.parentEntityIdInput);
        }
        else{
        this.getChildFacilities(this.parentEntityIdInput);
        }
    }
  }

  onEdit(item): void{
    this.openChildFacility.emit(item);
  }

  getOrganizationChildFacilities(parentEntityId: number): void {
    this.adminFacilityService.getFacilityLOBListByOrgId(parentEntityId).subscribe(
      (response) => {
        this.childAdminFacilitiesList = response;
        this.childAdminFacilitiesList = this.childAdminFacilitiesList.sort((a, b) => {
          return (a.facilityName && b.facilityName) ? (a.facilityName.trim().toLocaleLowerCase() < b.facilityName.trim().toLocaleLowerCase()) ? -1 : 1: null;
        });
        this.childAdminFacilitiesList.forEach(element => {
          element.isParentEntityString= String(element.isParentEntity);
        });
        this.childAdminFacilitiesList = this.childAdminFacilitiesList.filter((x) => this.selectedOption == null || x.isActive == this.selectedOption);
        this.setDisplayRows(this.childAdminFacilitiesList);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  getChildFacilities(parentEntityId: number): void {
    this.adminFacilityService.getOrganizationFacilityLOBList(parentEntityId).subscribe(
      (response) => {
        this.childAdminFacilitiesList = response;
        this.childAdminFacilitiesList = this.childAdminFacilitiesList.sort((a, b) => {
          return (a.facilityName && b.facilityName) ? (a.facilityName.trim().toLocaleLowerCase() < b.facilityName.trim().toLocaleLowerCase()) ? -1 : 1: null;
        });
        this.childAdminFacilitiesList.forEach(element => {
          element.isParentEntityString= String(element.isParentEntity);
        });
        this.childAdminFacilitiesList = this.childAdminFacilitiesList.filter((x) => this.selectedOption == null || x.isActive == this.selectedOption);
        this.setDisplayRows(this.childAdminFacilitiesList);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  setDisplayRows(childAdminFacilitiesList: FacilityLOBList[]): void {
    const rows$ = of(childAdminFacilitiesList);
    this.displayedRows$ = rows$.pipe();
    this.lastChild = childAdminFacilitiesList[childAdminFacilitiesList.length - 1].facilityContractId;
  }

  onDelete(facilityLOB:FacilityLOB):void{
    if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) return;
    this.openDeleteDialog(facilityLOB);
  }

  openDeleteDialog(facilityLOB:FacilityLOB): void {
    const message = 'Are you sure you want to delete "' + facilityLOB.facilityName + '" ?  ';
    const dialogData = new ConfirmDialogModel('Delete' , message);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // width: '450px',
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          this.deleteFacility(facilityLOB.facilityId,facilityLOB.entityId);
      }
    });
  }

  deleteFacility(facilityId:number,entityId:number): void {
    this.appSecurityManagementService.deleteFacility(facilityId.toString(), entityId.toString()).subscribe(
      (updatedRows) => {
        if (updatedRows > 0) {
          this.snackBar.successMessage(DisplayMessage.deleteMessage, ActionMessage.okAction);
          this.ngOnInit();
          this.deleteChildEvent.emit(true);
        }
      },
      (error: HttpErrorResponse) => {
        this.snackBar.errorMessage(error, ActionMessage.okAction);
      }
    );
  }

}
