import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Reports } from '../models/report.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ReportListService {

  constructor(private http: HttpClient) { }

  getReports(apiurl, appCode, environmentName): Observable<Reports[]>  {
    const headers = {
      'Content-Type': 'application/json'
    };
    const params = {
      applicationCode: appCode,
      deploymentEnvironmentName: environmentName
    };

    return this.http.get<Reports[]>(apiurl, { headers, params })
      .pipe(
        tap(data => JSON.stringify(data)),
      );
  }

  getReportsInGroup(apiurl: string, appCode: string, environmentName: string, group: string): Observable<Reports[]> {
    const headers = {
      'Content-Type': 'application/json'
    };
    const params = {
      applicationCode: appCode,
      deploymentEnvironmentName: environmentName,
      groupName: group
    };

    return this.http.get<Reports[]>(apiurl, { headers, params })
      .pipe(
        tap(data => JSON.stringify(data)),
      );
  }

  setReportFileName(selectedReport: Reports): void {
    if (_.isNil(selectedReport.reportImageFilename) || _.isEmpty(selectedReport.reportImageFilename)) {
      selectedReport.reportImageFilename = 'default.png';
    } else {
      if (selectedReport.reportImageFilename.indexOf('.') < 0) {
        selectedReport.reportImageFilename = selectedReport.reportImageFilename + '.png';
      }
    }
  }
}
