export class Entity {
  entityId: number;
  entityName: string;
  isActive: boolean;
  isRestricted: boolean;
  fiscalYearStartMonth: number;
  salesforceAccountId: string;
  entityTypeId: number;
  parentEntityId: number;
}

export class Customer {
  entityId: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  salesforceId :number;
  isHealthSystem : boolean
}

export class Organization {
 entity : Entity;
 customer : Customer;
 domain : EntityDomain[];
 orgAdminList: OrgAdminUser[];
}

export class EntityDomain {
  entityDomainId: number;
  entityId: number;
  domain: string;
  entityName: string;
}

export class BusinessUser {
  userId: number;
  entityId: number;
}

export class OrgAdminUser {
  userId: number;
  onPremisesSecurityIdentifier: string;
  entityId: number;
}

export class RestrictedDomain{
  id: number;
  domain: string;
}
