import { Component, OnInit, Inject } from '@angular/core';
import { Users } from '../../shared/models/users.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'app-approles-user-dialog',
  templateUrl: './approles-user-dialog.component.html',
  styleUrls: ['./approles-user-dialog.component.scss']
})
export class ApprolesUserDialogComponent implements OnInit {
  private allUsers: Users[];
  public appRoleId: number;
  public appRoleName: string;
  dataSource: MatTableDataSource<Users>;
  private routeParamSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ApprolesUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public rowData: UserConfirmDialogModel,
    private activatedRoute: ActivatedRoute
  ) {
    this.dataSource = new MatTableDataSource<Users>(this.allUsers);
  }

  ngOnInit() {
    this.appRoleId = this.rowData.appRoleId;
    this.appRoleName = this.rowData.appRoleName;
    this.routeParamSubscription = this.activatedRoute.params.subscribe((params) => {
       if (params.appRoleId) {
        this.appRoleId = params.appRoleId;
      }
    });
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.routeParamSubscription)) {
      this.routeParamSubscription.unsubscribe();
    }
  }
}
export class UserConfirmDialogModel {
  constructor(public title: string, public appRoleId: number, public appRoleName: string) { }
}
