export enum Roles {
  RS_Main_DashUIRollout = 'RS_Main-DashUIRollout',
  RS_Main_ThemeToggleDisplay = 'RS_Main-DashboardThemeToggle'
}
export const applicationRoles = [
  {
    "appName": "Executive dashboard",
    "appRoleName": "RS_Main-ExecutiveDashboard",
    "appUrl": ""
  },
  {
    "appName": "Assessments",
    "appRoleName": "RxAssess-User",
    "appUrl": ""
  },
  {
    "appName": "Initiatives",
    "appRoleName": "RCA3-InitiativesOpportunityQueue",
    "appUrl": ""
  }
];
