import { HttpErrorResponse } from '@angular/common/http';
import { Component,  OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { Subscription,Subject } from 'rxjs';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { FacilityDetailedService } from './services/facility-detailed.service';
import { AddFacility, Facility } from 'src/app/shared/models/facility.model';
import { ActionMessage, DisplayMessage } from 'src/app/shared/components/message';
import { Users } from 'src/app/shared/models/users.model';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { ConfirmationDialogComponent, ConfirmDialogModel } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UpsertUserEntitySecurityGroup } from 'src/app/shared/models/upsert-user-entity-security-group.model';
import { FacilityLOBDropdowns,Dropdown } from 'src/app/shared/models/facility-lob-dropdowns.model';
import { FacilityLOBService } from '../detailed-admin-facility/services/facility-lob.service';
import { FiscalYearStartMonth } from 'src/app/shared/models/fiscal-year-start-month.model';
import { MatTabGroup } from '@angular/material/tabs';


@Component({
  selector: 'app-detailed-admin-facility-user-assignment',
  templateUrl: './detailed-admin-facility-user-assignment.component.html',
  styleUrls: ['./detailed-admin-facility-user-assignment.component.scss']
})

export class DetailedAdminFacilityUserAssignmentComponent implements OnInit {

  facilityOverviewForm: FormGroup;
  facilityName: string;
  public typeOfSpinner = 'loading';
  isOrg = false;
  orgName: string;
  selectedFacility: any;
  entityId: number;
  facilityId: number;
  validationMessage: string;
  selectedTabIndex = 0;
  public formResetter: Subject<boolean> = new Subject<boolean>();
  routeParamSubscription: Subscription;
  userSession: UserSession;
  isDisabled=true;
  objUserEntitySecurityGroup: UpsertUserEntitySecurityGroup[];
  facilityLOBDropdowns: FacilityLOBDropdowns;
  facilityContractId: number;
  fiscalYearStartMonthList: FiscalYearStartMonth[] = [
    { monthId: 1, monthName: 'January' },
    { monthId: 2, monthName: 'February' },
    { monthId: 3, monthName: 'March' },
    { monthId: 4, monthName: 'April' },
    { monthId: 5, monthName: 'May' },
    { monthId: 6, monthName: 'June' },
    { monthId: 7, monthName: 'July' },
    { monthId: 8, monthName: 'August' },
    { monthId: 9, monthName: 'September' },
    { monthId: 10, monthName: 'October' },
    { monthId: 11, monthName: 'November' },
    { monthId: 12, monthName: 'December' },
  ];
  selectedMonth: number;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackBarModule,
    private b2cService: B2cService,
    private activatedRoute: ActivatedRoute,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private facilityDetailedService: FacilityDetailedService,
    private router: Router,
    private customErrorHandler: CustomErrorHandler,
    private dialog: MatDialog,
    private facilityLOBService: FacilityLOBService,
  ) { }

  ngOnInit() {
    this.b2cService.getUserSesssion();
    this.initFormGroup();
    this.validationMessage = "";
    this.facilityLOBDropdowns = new FacilityLOBDropdowns();
    this.populateDropdowns();

    this.routeParamSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.selectedFacility = new Facility();
        this.facilityContractId = params.id;
        this.orgName = this.activatedRoute.queryParams["value"].orgName;
        if (this.activatedRoute.queryParams["value"].isOrg) {
          this.isOrg = JSON.parse(this.activatedRoute.queryParams["value"].isOrg);
        }
        this.getFacilityDetails();
        this.selectedTabIndex = this.activatedRoute.queryParams["value"].tabindex ?  parseInt(this.activatedRoute.queryParams["value"].tabindex) : 0;

      }
    );
  }

  populateDropdowns(): void {
    this.facilityLOBService.getFacilityLOBDropdowns()
    .subscribe({
      next: facilityLOBDropdowns => {
        if (facilityLOBDropdowns != null) {
          this.facilityLOBDropdowns = facilityLOBDropdowns;
          this.getServiceCategoryServiceProvided();
          this.getServiceCategoryServicesubCategory();
          this.getServiceCategoryServiceSubcategoryDivision();
        }
      },
      error: error => {
        console.log(error);
      }
    });
  }

  getFacilityDetails(): void {
    this.facilityLOBService.getFacilityLOBDetails(this.facilityContractId)
    .subscribe({
      next: facilityDetails => {
        if (facilityDetails != null) {
          this.selectedFacility = facilityDetails;
          this.selectedFacility.facilityId = facilityDetails.facilityId;
          this.facilityId = facilityDetails.facilityId;
          this.facilityOverviewForm.patchValue(this.selectedFacility);
          this.facilityName = facilityDetails.facilityName;
          this.entityId=facilityDetails.entityId;
          this.typeOfSpinner = 'none';
          if(this.selectedTabIndex > 0){
            this.tabGroup.selectedIndex = this.selectedTabIndex;
          }
        }
      },
      error: error => {
        console.log(error);
      }
    });
  }

  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  initFormGroup(): void {
    this.facilityOverviewForm = this.formBuilder.group({
      facilityName: [{ value: '', disabled: this.isDisabled }],
      organization: [],
      organizationId: [],
      lineOfBusinessId: [{ value: '', disabled: this.isDisabled }],
      unitNumber: [{ value: '', disabled: this.isDisabled }],
      address1: [{ value: '', disabled: this.isDisabled }],
      city: [{ value: '', disabled: this.isDisabled }],
      country: [{ value: '', disabled: this.isDisabled }],
      state: [{ value: '', disabled: this.isDisabled }],
      zip: [{ value: '', disabled: this.isDisabled }],
      phone: [{ value: '', disabled: this.isDisabled }],
      entityTypeId: [{ value: '', disabled: this.isDisabled }],
      defaultPurchasingMetricId: [{ value: '', disabled: this.isDisabled }],
      fiscalYearStartMonth: [{ value: '', disabled: this.isDisabled }],
      salesforceLineofBusiness: [{ value: '', disabled: this.isDisabled }],
      purchasingVendorIDPrimary: [{ value: '', disabled: this.isDisabled }],
      purchasingVendorIDSecondary: [{ value: '', disabled: this.isDisabled }],
      serviceProvidedId: [{ value: '', disabled: this.isDisabled }],
      serviceSubCategoryId: [{ value: '', disabled: this.isDisabled }],
      serviceSubcategoryDivisionId: [{ value: '', disabled: this.isDisabled }],
      startDate: [{ value: '', disabled: this.isDisabled }],
      endDate: [{ value: '', disabled: this.isDisabled }],
      baselineStartDate: [{ value: '', disabled: this.isDisabled }],
      baselineEndDate: [{ value: '', disabled: this.isDisabled }],
      autoRenewMonths: [{ value: '0', disabled: this.isDisabled }],
      lastDayOfService: [{ value: '', disabled: this.isDisabled }],
      isActive: [{ value: true, disabled: this.isDisabled }],
      isRestricted: [false],
      isExclusionDrugManaged: [false],
      serviceCategoryId: [{ value: '', disabled: this.isDisabled }],
      exclusionDrugThreshold: [{ value: '0', disabled: this.isDisabled }],
      facilitySettingTypeId: [{value:"", disabled: this.isDisabled }],
    });
    this.facilityOverviewForm.disable();
  }

  updateFacilityDetails(event: any): void {
    if (event.target.enabled == undefined || event.target.enabled == true) {
      event.target.enabled = false;
      this.typeOfSpinner = 'loading';
    if (this.objUserEntitySecurityGroup.length>0) {
      const objEntity = {
        entityId: this.entityId,
        entityName: "",
        isActive: true,
        entityTypeId: 3,
        parentEntityId: 0,
        createBy: this.selectedFacility.createBy,
        createDate: this.selectedFacility.createDate,
      }
      const updateFacility = new AddFacility();
      updateFacility.entity = objEntity;
      updateFacility.userEntitySecurityGroup = (this.objUserEntitySecurityGroup) ? this.objUserEntitySecurityGroup : null;
      this.validationMessage = "";
      this.facilityDetailedService.UpdateFacilityUserAssignment(this.facilityId, updateFacility)
      .subscribe({
        next: result => {
          event.target.enabled = true;
          this.typeOfSpinner = 'none';
          this.snackBar.successMessage(DisplayMessage.updateMessage, ActionMessage.okAction);
          this.router.navigate(['viewadminfacilityuserassignmentlist'])
        },
        error: error => {
          this.typeOfSpinner = 'none';
          event.target.enabled = true;
          this.snackBar.exceptionMessage(DisplayMessage.updateFacilityFailureMessage, ActionMessage.okAction);
        }
      });
    } else {
      this.typeOfSpinner = 'none';
      event.target.enabled = true;
      return;
    }
    }
}

  userEntitySecurityGroup(userEntitySecurityGroup: Users[]) {
    if(!this.objUserEntitySecurityGroup)
    {
    this.objUserEntitySecurityGroup = [];
    }
    userEntitySecurityGroup.forEach(element => {
      const objEntity = {
        userEntiySecurityGroupId: 0,
        userId: element[0].userId,
        entityId: 0,
        securityGroupId: 0,
        lineOfBusinessId: element[0].lineOfBusinessId,
        IsDenied:false,
        IsInherited:false,
      };
      this.objUserEntitySecurityGroup.push(objEntity);
    });
  }

  unAssignUser(userEntitySecurityGroup: Users) {
    if(!this.objUserEntitySecurityGroup)
    {
    this.objUserEntitySecurityGroup = [];
    }

      const objEntity = {
        userEntiySecurityGroupId: 0,
        userId: userEntitySecurityGroup.userId,
        entityId: 0,
        securityGroupId: 0,
        lineOfBusinessId: userEntitySecurityGroup.lineOfBusinessId,
        IsDenied:true,
        IsInherited:userEntitySecurityGroup.isInherited
      };
      this.objUserEntitySecurityGroup.push(objEntity);
  }

  onCancelClick(): void {
    const message = DisplayMessage.facilityCancelWarningMessage;
    const dialogData = new ConfirmDialogModel('Facility User Assignment', message);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // width: '450px',
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedTabIndex = 0;
        this.formResetter.next(true);
      }
    });
  }

  getServiceCategoryServiceProvided() {
    const serviceCategoryId=this.facilityOverviewForm.controls.serviceCategoryId.value;
    if(serviceCategoryId) {
      this.appSecurityManagementService.getServiceCategoryServiceProvidedList(serviceCategoryId)
        .subscribe(
          (data) => {
            const serviceProvidedList=[] as Dropdown[];
            data.forEach(element=>{
            const obj = {
              dropDownId: element.serviceProvidedID,
              dropDownName: element.serviceProvidedName
            }
            serviceProvidedList.push(obj);
          });
          this.facilityLOBDropdowns.serviceProvidedList=serviceProvidedList;
          },
          (error: HttpErrorResponse) => {
            this.customErrorHandler.handleError(error);
          });
      }
  }

  getServiceCategoryServicesubCategory() {
    const serviceCategoryId=this.facilityOverviewForm.controls.serviceCategoryId.value;
    if(serviceCategoryId) {
    this.appSecurityManagementService.getServiceCategoryServicesubCategoryList(serviceCategoryId)
      .subscribe(
        (data) => {
          const serviceSubCategoryList=[] as Dropdown[];
          data.forEach(element=>{
            const obj = {
              dropDownId: element.serviceSubcategoryID,
              dropDownName: element.serviceSubcategoryName
            }
            serviceSubCategoryList.push(obj);
          });
          this.facilityLOBDropdowns.serviceSubCategoryList=serviceSubCategoryList;
        },
        (error: HttpErrorResponse) => {
          this.customErrorHandler.handleError(error);
        });
      }
  }

  getServiceCategoryServiceSubcategoryDivision() {
    const serviceCategoryId=this.facilityOverviewForm.controls.serviceCategoryId.value;
    if(serviceCategoryId) {
    this.appSecurityManagementService.getServiceCategoryServiceSubcategoryDivisionList(serviceCategoryId)
      .subscribe(
        (data) => {
          const serviceSubcategoryDivisionList=[] as Dropdown[];

          data.forEach(element=>{
            const obj = {
              dropDownId: element.serviceSubcategoryDivisionID,
              dropDownName: element.serviceSubcategoryDivisionName
            }
            serviceSubcategoryDivisionList.push(obj);
          });
          this.facilityLOBDropdowns.serviceSubcategoryDivisionList=serviceSubcategoryDivisionList;
        },
        (error: HttpErrorResponse) => {
          this.customErrorHandler.handleError(error);
        });
      }
  }

  selectedTabIndexChange(index){
    this.router.navigate(['.'],{relativeTo: this.activatedRoute, queryParams: {tabindex: index, orgName: this.orgName}});
  }

}
