import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs';
import { SecurityGroups } from '../../shared/models/security-groups.model';
import { environment } from 'src/environments/environment';
import { Facility } from '../../shared/models/facility.model';
import { FacilityUser } from '../../shared/models/facility-user.model';
import { SortedFacility } from '../models/sorted-facility.model';
import _ from 'lodash';
import { FacilityHierarchicalNode } from '../models/facility-hierarchical-node.model';
import { ApplicationList } from '../../shared/models/application-list';
import { Users } from '../../shared/models/users.model';
import { EntityComposite } from '../models/entity-composite.model';

@Injectable({
  providedIn: 'root',
})

export class CossaService {

  constructor(private http: HttpClient) { }

  getAllSecurityGroups(): Observable<SecurityGroups[]> {
    const apiurl = `${environment.appConfig.apiServer.cossa}SecurityGroup`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<SecurityGroups[]>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'cossa.service.ts- getAllSecurityGroups- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getFacilityUsers(entityID: string): Observable<FacilityUser[]> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}UserEntity/${entityID}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<FacilityUser[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'cossa.service.ts- getAppRoleAll- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getFacilityUserInfo(id: string): Observable<FacilityUser> {
    const apiurl = `${environment.appConfig.apiServer.cossa}User`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      id: id,
    };
    return this.http
      .get<FacilityUser>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'cossa.service.ts- getFacilityUserInfo - ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getFacilityDetails(facilityId: string): Observable<Facility> {
    const apiUrl = `${environment.appConfig.apiServer.cossa}Facility/${facilityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      facilityID: facilityId,
    };
    return this.http.get<Facility>(apiUrl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'cossa.service.ts- getFacilityDetails- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getAllFacilityDetails(): Observable<Facility> {
    const apiUrl = `${environment.appConfig.apiServer.cossa}Facility`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<Facility>(apiUrl, { headers})
      .pipe(
        catchError((err) => {
          return throwError(
            'cossa.service.ts- getFacilityDetails- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getAllUsers(): Observable<Users[]> {
    const apiurl = `${environment.appConfig.apiServer.cossa}User`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Users[]>(apiurl, { headers })
      .pipe(
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUserApp(onPremisesSecurityIdentifier: string): Observable<ApplicationList[]> {
    const apiurl = `${environment.appConfig.apiServer.cossa}UserApp/${onPremisesSecurityIdentifier}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const params = {
      webPortal: 'RxRs',
    };
    return this.http
      .get<ApplicationList[]>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'cossa.service.ts- getUserApp- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getUserAssignFaclities(entityRootId): Observable<EntityComposite[]> {
    const apiUrl = `${environment.appConfig.apiServer.cossa}EntityComposite/Root/${entityRootId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<EntityComposite[]>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'cossa.service.ts- getAppRoleAll- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

}



function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
