import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort, Sort} from '@angular/material/sort';
import { combineLatest, concat, defer, of } from "rxjs";


export class SimpleDataSource<T> extends DataSource<T> {
  constructor(private rows$: Observable<T[]>) { super(); }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connect(collectionViewer: CollectionViewer): Observable<T[]> { return this.rows$; }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  disconnect(collectionViewer: CollectionViewer): void { }
}

/** Creates an Observable stream of Sort objects from a MatSort component */
export function fromMatSort(sort: MatSort): Observable<Sort> {
  return concat(
    defer(() => of({
      active: sort.active,
      direction: sort.direction
    })),
    sort.sortChange.asObservable()
  );
}

/** Creates an Observable stream of PageEvent objects from a MatPaginator component */
export function fromMatPaginator(pager: MatPaginator): Observable<PageEvent> {
  return concat(
    defer(() => of({
      pageIndex: pager.pageIndex,
      pageSize: pager.pageSize,
      length: pager.length,
    })),
    pager.page.asObservable()
  );
}

/** RxJs operator to paginate an array based on an Observable of PageEvent objects **/
export function paginateRows<U>(page$: Observable<PageEvent>): (obs$: Observable<U[]>) => Observable<U[]> {
  return (rows$: Observable<U[]>) => combineLatest(
    rows$,
    page$,
    (rows, page) => {
      const startIndex = page.pageIndex * page.pageSize;
      const copy = rows.slice();
      return copy.splice(startIndex, page.pageSize);
    }
  );

}

export function getLocalStorageValue(key: string): string | null {
  const keys = Object.keys(localStorage);

  const matchedKey = keys.find(k => k.toLowerCase() === key.toLowerCase());

  if (matchedKey) {
    return localStorage.getItem(matchedKey);
  }

  return null;
}

