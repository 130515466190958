// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-title {
  position: relative;
  margin: 0 -24px 10px;
  padding: 15px 25px;
  border-bottom: 1px solid #ccc;
}

.dialog-title h2 {
  margin: 0;
  color: #333333;
}

.dialog-title .close_btn {
  position: absolute;
  top: 8px;
  right: 20px;
  color: #333333;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/dialog/description-dialog/description-dialog.component.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,oBAAA;EACA,kBAAA;EACA,6BAAA;AAFF;;AAKA;EACE,SAAA;EACA,cAAA;AAFF;;AAKA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;AAFF","sourcesContent":["div {\r\n  // font-family: Tahoma, Arial, Sans-Serif;\r\n}\r\n.dialog-title {\r\n  position: relative;\r\n  margin: 0 -24px 10px;\r\n  padding: 15px 25px;\r\n  border-bottom: 1px solid #ccc;\r\n}\r\n\r\n.dialog-title h2 {\r\n  margin: 0;\r\n  color: #333333;\r\n}\r\n\r\n.dialog-title .close_btn {\r\n  position: absolute;\r\n  top: 8px;\r\n  right: 20px;\r\n  color: #333333;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
