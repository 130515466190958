// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  padding-top: 80px;
  padding-bottom: 100px;
  color: #1f5181;
}

.access-denied-content {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/access-denied/access-denied.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".center {\r\n  margin: auto;\r\n  width: 50%;\r\n  padding: 10px;\r\n  text-align: center;\r\n  vertical-align: middle;\r\n  padding-top: 80px;\r\n  padding-bottom: 100px;\r\n  color:#1f5181;\r\n}\r\n\r\n.access-denied-content{\r\n  text-align: left;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
