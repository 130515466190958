export enum Role {
  OrganizationAdmin = 'AppSecMgmt-OrganizationAdmin',
  Admin = 'AppSecMgmt-Admin',
  AppMessageAdmin= 'AppSecMgmt-AppMessageAdmin',
  UserManagement = 'AppSecMgmt-UserMgmt',
  GraphiteRxDataMgmt = 'AppSecMgmt-GraphiteRx DataMgmt',
  canExport='AppSecMgmt-CanExport',
  canPrint = 'AppSecMgmt-CanPrint',
  LOBMgr='AppSecMgmt-LOBMgr',
  ReportAdmin= 'AppSecMgmt-CorporateReports',
  ReportViewer= 'AppSecMgmt-SettingsReports',
  LeftMenuMgr= 'AppSecMgmt-LeftMenuMgr',
  LOBMgrOld='AppSecMgmt-LOBMgr-Old'
}
