import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, Subscription, of } from 'rxjs';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs/operators';
import _ from 'lodash';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { MatRadioChange } from '@angular/material/radio';
import { FacilityLOBList } from 'src/app/shared/models/facility-lob-list-model';
import { AdminFacilityService } from '../view-admin-facility-lob/services/admin-facility.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfirmationDialogComponent, ConfirmDialogModel } from '../../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FacilityLOB } from 'src/app/shared/models/facility-lob.model';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { ActionMessage, DisplayMessage } from 'src/app/shared/components/message';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';

@Component({
  selector: 'app-view-admin-facility-lob-dialog',
  templateUrl: './view-admin-facility-lob-dialog.component.html',
  styleUrls: ['./view-admin-facility-lob-dialog.component.scss'],
  animations: [
    trigger('detailExpand', [
     state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
     state('*', style({ height: '*', visibility: 'visible' })),
     transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
   ]),
 ],
})
export class ViewAdminFacilityLobDialogComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  orgIdInput: number;
  orgNameInput : string;

  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<FacilityLOBList[]>;
  public totalRows$: Observable<number>;
  private unsubscribeList: any[] = [];
  public userSession: UserSession = new UserSession();
  userSessionSubscription : Subscription;
  private adminFaciliitesList: FacilityLOBList[];
  selectedOption = true;
  filterValue = "";
  isLOBManager=false;
  isAdmin=false;
  isOrgAdmin=false;
  isUserMgr=false;
  isConfirmationDialogOpened=false;

  displayedColumns: string[] = ['expand', 'facilityName', 'lineOfBusinessType', 'serviceCategory', 'unitNumber', 'entityId',
                                'startDate','endDate', 'action'];
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: any) => row.hasOwnProperty.call('detailRow');
  @Output() deleteEvent: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private customErrorHandler: CustomErrorHandler,
    private adminFacilityService: AdminFacilityService,
    private activatedRoute: ActivatedRoute,
    private b2cService: B2cService,
    private router: Router,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private dialogConfirm: MatDialog,
    private snackBar: SnackBarModule,


    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewAdminFacilityLobDialogComponent>
    ) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.typeOfSpinner = 'loading';
    this.orgIdInput = this.data.orgId;

    if(this.orgIdInput > 0 && this.orgIdInput !=null) {
        this.getOrganizationFacilities(this.orgIdInput);
        this.isLOBManager= this.adminFacilityService.getLOBManagerMode();
        this.isAdmin= this.adminFacilityService.getAdminMode();
        this.isOrgAdmin=this.adminFacilityService.getOrganizationAdminMode();
        this.isUserMgr=this.adminFacilityService.getUserManagementMode();

    }
  }

  ngOnDestroy(): void {
    this.unsubscribeList.forEach((observable) => {
      observable.unsubscribe();
    });
  }

  getOrganizationFacilities(parentEntityId: number): void {
    this.adminFacilityService.getFacilityLOBListByOrgId(parentEntityId).subscribe(
      (response) => {
        this.adminFaciliitesList = response;
        this.adminFaciliitesList = this.adminFaciliitesList.sort((a, b) => {
          return (a.facilityName && b.facilityName) ? (a.facilityName.trim().toLocaleLowerCase() < b.facilityName.trim().toLocaleLowerCase()) ? -1 : 1: null;
        });
        this.adminFaciliitesList.forEach(element => {
          element.isParentEntityString= String(element.isParentEntity);
        });
        this.setDisplayRows(this.adminFaciliitesList);
        this.getFilteredFacilities(this.selectedOption);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  setDisplayRows(adminFacilitiesList: FacilityLOBList[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(adminFacilitiesList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    this.filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    const filteredFacilities = this.adminFaciliitesList.filter((facility) => {
      return (
        (facility.facilityName && facility.facilityName.toLowerCase().indexOf(filterValue) !==
          -1) ||
        (facility.lineOfBusinessType && facility.lineOfBusinessType.toLowerCase().indexOf(filterValue) !==
          -1) ||
          (facility.serviceCategory && facility.serviceCategory.toLowerCase().indexOf(filterValue) !==
          -1) ||
          (facility.entityId && facility.entityId.toString().indexOf(filterValue) !==
          -1) ||
          (facility.salesforceLineofBusiness && facility.salesforceLineofBusiness.toLowerCase().indexOf(filterValue) !==
          -1) ||
        (facility.unitNumber && facility.unitNumber
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
        (facility.organization && facility.organization.toLowerCase().indexOf(filterValue) !== -1) ||
        (facility.salesforceLineofBusiness && facility.salesforceLineofBusiness.toLowerCase().indexOf(filterValue) !== -1)
      );
    });


    const filteredFacilitiesByOptions = filteredFacilities.filter((org) => this.selectedOption == null || org.isActive == this.selectedOption);

    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredFacilitiesByOptions);
  }

  onConditionChange(conditionChange: MatRadioChange){
    this.selectedOption = conditionChange.value;
    if(this.filterValue == "") {
      this.getFilteredFacilities(conditionChange.value);
    } else {
      this.applyFilter(this.filterValue);
    }
  }

  getFilteredFacilities(condition?: boolean){

    const filteredFacilitiesByOptions = this.adminFaciliitesList.filter((org) => this.selectedOption == null || org.isActive == this.selectedOption);

    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredFacilitiesByOptions);
  }

  onEdit(item): void{
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/', item.entityId ,'addeditfacilitylob'], { queryParams:  {isEdit: true, isFromOrg : false, orgName: item.organization, orgId: item.organizationId, facilityContractId: item.facilityContractId } });
    this.dialogRef.close();
  }

  onDelete(facilityLOB:FacilityLOB):void{
    if (this.isConfirmationDialogOpened) return;
    this.openDeleteDialog(facilityLOB);
  }
  openDeleteDialog(facilityLOB:FacilityLOB): void {
    const message = 'Are you sure you want to delete "' + facilityLOB.facilityName + '" ?  ';
    const dialogData = new ConfirmDialogModel('Delete' , message);
    this.isConfirmationDialogOpened = true;
    const dialogRef = this.dialogConfirm.open(ConfirmationDialogComponent, {
      // width: '450px',
      data: dialogData,
      backdropClass: 'sm_panel-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          this.deleteFacility(facilityLOB.facilityId,facilityLOB.entityId);
      }
      this.isConfirmationDialogOpened = false;
    });
  }

  deleteFacility(facilityId:number,entityId:number): void {
    this.appSecurityManagementService.deleteFacility(facilityId.toString(), entityId.toString()).subscribe(
      (updatedRows) => {
        if (updatedRows > 0) {
          this.snackBar.successMessage(DisplayMessage.deleteMessage, ActionMessage.okAction);
          this.initAfterDelete();
        }
      },
      (error: HttpErrorResponse) => {
        this.snackBar.errorMessage(error, ActionMessage.okAction);
      }
    );
  }

  initAfterDelete():void{
    this.ngOnInit();
    this.deleteEvent.emit(true);
  }

  onChildFacilityDeleteEvent(isDeleted: boolean){
    if(isDeleted)
    {
      this.initAfterDelete();
    }
  }
  showCaret(element: any){
    return (element.isParentEntity === true && this.selectedOption === null) || (element.isParentOfActiveChildEntity === true && this.selectedOption === true) || (element.isParentOfInActiveChildEntity === true && this.selectedOption === false)
  }

  onExpand(element: any){
    this.expandedElement = element;
  }

  onCollaspe(){
    this.expandedElement = undefined;
  }
}
