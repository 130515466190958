import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { SharedUserAppDetailService } from "./shared/services/shared-user-app-detail.service";
import { UserAppSelectionSession } from "./shared/models/user-app-selection-session.model";
import { ApplicationSecurityManagementService } from "./shared/services/application-security-management.service";
import { Subscription, combineLatest, filter, map, take, takeUntil, takeWhile } from "rxjs";
import * as _ from "lodash";
import { environment } from "../environments/environment";
import { ApplicationsService } from "./shared/services/applications.service";
import {
  appRoleIDs,
  roleNames,
  WebPortalCode,
} from "./shared/constants/constants";
import { SvgIconService } from "./shared/services/svg-icons.service";
import { ApplicationList } from "./shared/models/application-list";
import { MediaObserver, MediaChange } from "@angular/flex-layout";
import { ProgressSpinnerService } from "../app/shared/services/progress-spinner.service";
import { SnackBarModule } from "./shared/modules/snackbar/snack-bar.module";
import { HttpErrorResponse } from "@angular/common/http";
import {
  UserSession,
  CPSLibAppComponent,
  B2cService,
} from "cps-b2clibrary";
import { PermissionsMapUserAppRole } from "./shared/models/permissions-map-user-app-role";
import { Organization } from "./shared/models/organization.model";
import { MatDialog } from "@angular/material/dialog";
import { ChangeOrgComponent } from "./components/change-org/change-org.component";
import { RolePermissionsService } from "./shared/services/role-permissions.service";
import { NewRelicService } from "cps-menu";
import { DataService } from './shared/services/data.service';
import { Role } from "./shared/enum/role.enum";
import { SessionService } from "./shared/services/session-storage.service";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private params: Params;
  private appCode: string;
  public appName: string;
  public title = "RxAdmin";
  public sideNavOpened = true;
  public appId: string = null;
  private showSubmenu: any;
  public sideNavMode: "side" | "over" = "side";
  public toolBarHeight = 10;
  private securityManagerAppsSubscription: Subscription;
  private userExecutionRolesSubscription: Subscription;
  public userAppDetail: UserAppSelectionSession = new UserAppSelectionSession();
  private userAppDetailSubscription: Subscription;
  private routeParamSubscription: Subscription;

  public isIframe: boolean;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = "loading";
  private localStorageIsLogoff = "isLogoff";
  public isLogoff = localStorage.getItem(this.localStorageIsLogoff);
  private mediaWatcher: Subscription;
  private media: MediaObserver;
  public normalViewMenuOpened = true;
  public normalViewSideNavMode: "side" | "over" = "side";
  public organization: Organization[];
  public selectedOrganization: Organization;
  private userSessionSubscription: Subscription;
  private progressSpinnerSubscription: Subscription;
  private loginSuccessSubscription: Subscription;
  private loginFailureSubscription: Subscription;
  private acquireTokenSuccessSubscription: Subscription;
  private acquireTokenFailureSubscription: Subscription;
  private userExecutionRoles: PermissionsMapUserAppRole[];
  private isDisabled = true;
  public searchUrl: string =
    environment.appConfig.searchIcon.rxContentHubSearchUrl;
  private clickedFacility = "";
  public showAppmenu: boolean;
  public showRole: boolean;
  public showManageMenu: boolean;
  public showAppsMenu: boolean;
  public sortedData = [];
  public applicationList = [];
  private applicationsSubscription: Subscription;
  public applicationServerPath = environment.serverPath;
  private localStorageloggedInString = "loggedInString";
  private localStorageFirstName = "firstName";
  private localStorageLastName = "lastName";
  private localStorageEmail = "email";
  private localStorageAzureActiveDirectoryObjectId =
    "azureActiveDirectoryObjectId";
  private localStorageOnPremisesSecurityIdentifier =
    "onPremisesSecurityIdentifier";
  private localStorageUserAppRoles = "userAppRoles";
  public RoleUser = "";
  public isAdmin = false;
  public isAdminViewOnly = false;
  public isSecurityManager = false;
  public isB2BAdmin = false;
  public roleMode: string;
  public displayAnilString: string;
  respSubscription: any;
  isExpanded = true;
  public leftMenuRoleExist = false;
  isMobileScreen = false;
  isExpandableMenu = true;
  public menuOpenClassname = "open";
  public mobileClassName = "show";
  public isSideNavRequired = false;
  public routedPage: string;
  public selectedRole: string;
  public selectedOrgnizationName: string;
  public singleOrganization: boolean;
  public selectedRoleName: string;
  roleChange: string;
  public isOrganizationAdmin = false;
  public isApplicationMessageAdmin = false;
  public isReportAdmin = false;
  public isReportViewer = false;
  public isGraphiteRxDataMgmt = false;
  private isUserSession = true;
  onlyAppMessage = false;
  isToggleClickEventFired = false;
  menuEnvironment: any = {};
  private isExternalUser = true;
  menuEnvironmentobj: any = {};

  constructor(
    private mediaObserver: MediaObserver,
    private b2cService: B2cService,
    private newRelicService: NewRelicService,
    private progressSpinnerService: ProgressSpinnerService,
    private snackBar: SnackBarModule,
    private activatedRoute: ActivatedRoute,
    private applicationsService: ApplicationsService,
    private svgIconService: SvgIconService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private router: Router,
    private sharedUserAppDetailService: SharedUserAppDetailService,
    private testLibComp: CPSLibAppComponent,
    private rolePermissions: RolePermissionsService,
    public dialog: MatDialog,
    private dataService: DataService,
    private sessionService: SessionService,
  ) {
    this.menuEnvironment = environment.menuPackage;
    this.media = mediaObserver;
    this.isIframe = window !== window.parent && !window.opener;
    this.loadScript();
  }

  forgotPasswordLink = '';
  ngOnInit(): void {
    this.menuEnvironmentobj = {footerTermsAndConditons: environment.menuPackage.footerTermsAndConditons};
    this.newRelicService.Initialize(environment.newRelic);
    this.forgotPasswordLink = environment.b2cConfig.forgotPasswordLink + `&redirect_uri=${encodeURIComponent(window.location.origin)}`;
    this.roleMode = "Admin";
    if (sessionStorage.getItem("roleChange") != "") {
      this.roleChange = sessionStorage.getItem("roleChange");
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      this.params = params;
      this.appId = params.id;
    });

    this.typeOfSpinner = "loading";

    combineLatest([this.b2cService.userSession$, this.b2cService.userAppRoles$, this.b2cService.userUserApps$, this.b2cService.userOrganization$])
      .pipe(take(1))
      .subscribe(([userSession, userAppRoles, userApps, userOrganizations]) => {
        this.userSession = userSession;
        this.userSession.userAppRoles = userAppRoles;
        this.userSession.userApps = userApps;
        this.userSession.userOrganizations = userOrganizations;
        if (this.userSession.onPremisesSecurityIdentifier) {
          this.sessionService.setUserSession(this.userSession);
        }
        //this.rolePermissions.setPermissionRole(userAppRoles);
        if (this.userSession.onPremisesSecurityIdentifier) {
          if (!(this.sortedData && this.sortedData.length)) {
            this.applicationsService
              .getApplications(this.userSession.onPremisesSecurityIdentifier)
              .subscribe({
                next: x => (this.sortedData = x),
                error: err => console.log(err),
              });
          }
        }
        if (userAppRoles) {
          this.getSideNavDetails(userAppRoles);
        }
        this.typeOfSpinner = "none";
      });

    this.b2cService.getUserSesssion();
  }

  ngAfterViewInit(){
    let times =0 ;
    const sessionInterval = setInterval(() =>{
      this.b2cService.getUserSesssion();
      times++;
      if(times >= 10 || document.getElementById('rx-logo')!=null){
        clearInterval(sessionInterval);
      }
    }, 100);
  }


  loadScript(): void {
    const node = document.createElement('script');
    const attrKey = document.createAttribute('key');
    node.setAttributeNode(attrKey);
    node.type = 'text/javascript';
    attrKey.value = "newRelicLoader";
    node.src = "https://js-agent.newrelic.com/nr-loader-spa-current.min.js";
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  setLeftMenuVisibility(userAppRoles: string[]): void {
    if (this.appSecurityManagementService.LeftMenuVisible(userAppRoles)) {
      this.leftMenuRoleExist = true;
      this.menuOpenClassname = "open";
      this.mobileClassName = "show";
    } else {
      this.menuOpenClassname = "no-menu";
      this.mobileClassName = "hide";
    }
  }

  getUserExecutionRoles(
    onPremisesSecurityIdentifier,
    callback: (executionRoles: PermissionsMapUserAppRole[]) => void
  ): void {
    this.userExecutionRolesSubscription = this.appSecurityManagementService
      .getUserExecutionRoles(onPremisesSecurityIdentifier)
      .subscribe((values) => {
        if (callback) {
          callback(values);
        }
      });
  }

  menuExpandCollapse(event) {
    this.isExpanded = event;
    if (event) {
      this.menuOpenClassname = "open";
      this.mobileClassName = "show";
    } else {
      this.menuOpenClassname = "close";
      this.mobileClassName = "hide";
    }
  }

  hideshowMenu(event) {
    this.isExpanded = event;
    this.menuOpenClassname = "open";
    if (event) {
      this.mobileClassName = "show";
    } else {
      this.mobileClassName = "hide";
    }
  }

  logout(): void {
    localStorage.setItem(this.localStorageIsLogoff, "yes");
    if (sessionStorage["roleMode"]) {
      sessionStorage.removeItem("roleMode");
    }
    if (sessionStorage["roleChange"]) {
      sessionStorage.removeItem("roleChange");
    }

    if (sessionStorage["index"]) {
      sessionStorage.removeItem("index");
    }

    if (sessionStorage["isLOBManager"]) {
      sessionStorage.removeItem("isLOBManager");
    }

    this.b2cService.logout();
  }

  getApplications(onPremisesSecurityIdentifier: string): void {
    this.applicationsSubscription = this.applicationsService
      .getSetApplications(onPremisesSecurityIdentifier)
      .subscribe((values) => {
        this.sortedData = values;
      });
  }

  searchInRxIntelnet(): void {
    window.open(`${this.searchUrl}`, "_blank");
  }

  openApp(applicationServerPath: string, applicationPath: string): void {
    window.open(`${applicationServerPath}${applicationPath}`, "_blank");
  }

  getExecutionRoles(): void {
    this.userExecutionRoles =
      this.appSecurityManagementService.getExecutionRoles();

    this.userExecutionRoles.forEach((roles) => {
      if (roles.applicationRoleId == appRoleIDs.admin) {
        this.isAdmin = true;
      }
      if (roles.applicationRoleId == appRoleIDs.organization_admin) {
        this.isOrganizationAdmin = true;
      }
      if (roles.applicationRoleId == appRoleIDs.app_message_admin) {
        this.isApplicationMessageAdmin = true;
      }
      if (roles.applicationRoleId == appRoleIDs.report_admin) {
        this.isReportAdmin = true;
      }
      if (roles.applicationRoleId == appRoleIDs.graphiteRx_DataMgmt) {
        this.isGraphiteRxDataMgmt = true;
      }
    });
  }

  changeRoleMode(roleMode: string): void {
    sessionStorage.setItem("roleChange", "1");
    this.roleMode = roleMode;
    this.appSecurityManagementService.setRoleMode(this.roleMode);
    sessionStorage.setItem("roleMode", this.roleMode);
    if (this.roleMode) {
      if (this.roleMode == roleNames.security_manager) {
        this.selectedRoleName = "Security Manager";
      }
      if (this.roleMode == roleNames.admin) {
        this.selectedRoleName = "Super Admin";
      }
      if (this.roleMode == roleNames.organization_admin) {
        this.selectedRoleName = "Org Admin";
      }
    }
    if (this.roleMode != "OrganizationAdmin") {
      this.router.navigate(["/home", [this.roleMode, Role.UserManagement]]);
    } else if (this.roleMode == "OrganizationAdmin") {
      this.organization = this.appSecurityManagementService.getOrganization();
      if (this.organization == undefined) {
        this.getOrganization();
      }
      if (this.organization && this.organization.length > 1) {
        this.changeOrg();
      } else if (this.organization && this.organization.length == 1) {
        this.selectedOrganization = this.organization[0];
        sessionStorage.setItem(
          "selectedOrganization",
          this.selectedOrganization.cpsEntityName
        );
        sessionStorage.setItem("singleOrganization", "true");
        this.appSecurityManagementService.setOrganization(
          this.organization,
          this.selectedOrganization.entityId
        );
        this.router.navigate(["/org-home"]);
      } else if (this.organization && this.organization.length == 0) {
        this.router.navigate(["/noorganization"]);
      }
    }
  }

  getOrganization(): void {
    this.appSecurityManagementService
      .getOrganizationList(this.userSession.onPremisesSecurityIdentifier)
      .subscribe({
        next: (response) => {
          this.organization = response;
          if (this.organization && this.organization.length == 0) {
            this.router.navigate(["/noorganization"]);
          }
          if (this.organization && this.organization.length > 0) {
            if (this.organization.length == 1) {
              this.selectedOrganization = this.organization[0];
              this.appSecurityManagementService.setOrganization(
                this.organization,
                this.selectedOrganization.entityId
              );
              if (this.roleMode == "OrganizationAdmin") {
                this.router.navigate(["/org-home"]);
              }
            } else if (this.organization && this.organization.length > 1) {
              this.organization = this.organization.sort(
                (sourceData, comparisionData) =>
                  sourceData.cpsEntityName > comparisionData.cpsEntityName
                    ? 1
                    : -1
              );
              this.appSecurityManagementService.setOrganization(
                this.organization,
                0
              );
              this.changeOrg();
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          this.showSnackBarMessageError(error);
        }
      });
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  changeOrg(): void {
    if (this.dialog.openDialogs && this.dialog.openDialogs.length > 0) return;
    const dialogRef = this.dialog.open(ChangeOrgComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.organization = this.appSecurityManagementService.getOrganization();
        this.organization.forEach((organization) => {
          if (organization.selected === true) {
            this.selectedOrganization = organization;
            sessionStorage.setItem(
              "selectedOrganization",
              this.selectedOrganization.cpsEntityName
            );
            this.selectedOrgnizationName =
              this.selectedOrganization.cpsEntityName;
          }
        });
        if (this.selectedOrganization !== undefined) {
          this.appSecurityManagementService.setOrganization(
            this.organization,
            this.selectedOrganization.entityId
          );
        } else {
          this.appSecurityManagementService.setOrganization(
            this.organization,
            0
          );
        }
        sessionStorage.setItem(
          "selectedOrganization",
          this.selectedOrganization.cpsEntityName
        );
        this.selectedOrgnizationName = this.selectedOrganization.cpsEntityName;
        this.router.navigate(["/org-home"]);
      }
    });
  }

  getApplicationID(): void {
    this.routeParamSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.appId = params.id;
      }
    );
  }

  login(): void {
    window.location.href = `${environment.appConfig.apiServer.loginUrl}`;
  }

  getRoleMode(): string {
    return this.appSecurityManagementService.getRoleMode();
  }

  getSelectedOrganization(): string {
    return sessionStorage.getItem("selectedOrganizaion");
  }

  getSideNavDetails(userAppRoles: string[]): void {
    this.isSideNavRequired = false;
    if (this.activatedRoute.snapshot.firstChild !== null) {
      if (
        this.activatedRoute.snapshot.firstChild.data.pageDetails != undefined
      ) {
        this.routedPage =
          this.activatedRoute.snapshot.firstChild.data.pageDetails.title;
      }
      this.selectedRole = this.getRoleMode();

      this.selectedOrgnizationName = sessionStorage.getItem(
        "selectedOrganization"
      );
      if (sessionStorage.getItem("singleOrganization")) {
        this.singleOrganization =
          sessionStorage.getItem("singleOrganization").toLowerCase() == "true";
      }
      this.roleChange = sessionStorage.getItem("roleChange");
      this.selectedRole = this.appSecurityManagementService.getRoleMode();
      if (
        this.selectedRole !== "NotSet" ||
        sessionStorage.getItem("roleMode")
      ) {
        this.roleMode = sessionStorage.getItem("roleMode");
        this.appSecurityManagementService.setRoleMode(this.roleMode);
      }

      if (this.roleMode) {
        if (this.roleMode == roleNames.security_manager) {
          this.selectedRoleName = "Security Manager";
        }
        if (this.roleMode == roleNames.admin) {
          this.selectedRoleName = "Super Admin";
        }
        if (this.roleMode == roleNames.organization_admin) {
          this.selectedRoleName = "Org Admin";
        }
      }

      this.isSideNavRequired =
        this.roleMode === "SecurityManager" ? true : false;
    }

    if (localStorage.getItem('email') && localStorage.getItem('email').indexOf('@cps.com') !== -1) {
      this.isExternalUser = false;
    }
    if (this.isExternalUser) {
      this.appSecurityManagementService
        .isUserEmailAddressValidated(
          this.userSession.onPremisesSecurityIdentifier
        )
        .subscribe((validated) => {
          if (!validated) {
            this.appSecurityManagementService
              .UpdateUserEmailVerification(
                this.userSession.onPremisesSecurityIdentifier
              )
              .subscribe((rows) => {
                if (!_.isNil(rows) && rows > 0) {
                  this.typeOfSpinner = "none";
                  this.router.navigate(["thankyou"]);
                }
              }),
              (error: HttpErrorResponse) => {
                this.showSnackBarMessageError(error);
              };
          }
        });
    }

    this.getUserExecutionRoles(
      this.userSession.onPremisesSecurityIdentifier,
      (values: PermissionsMapUserAppRole[]) => {
        this.userExecutionRoles = values;

        if (
          this.userExecutionRoles.filter(
            (x) => x.applicationRoleId == appRoleIDs.admin
          ).length > 0
        ) {
          sessionStorage.setItem("isAdminReadOnly", "false");
          this.appSecurityManagementService.setAdminReadOnlyMode(false);
          this.appSecurityManagementService.setUserManagementMode(false);
        } else if (
          this.userExecutionRoles.filter(
            (x) => x.applicationRoleId == appRoleIDs.user_management
          ).length > 0
        ) {
          sessionStorage.setItem("isAdminReadOnly", "true");
          this.appSecurityManagementService.setAdminReadOnlyMode(true);
          this.appSecurityManagementService.setUserManagementMode(true);
        } else {
          sessionStorage.setItem("isAdminReadOnly", "false");
          this.appSecurityManagementService.setAdminReadOnlyMode(false);
          this.appSecurityManagementService.setUserManagementMode(false);
        }

        if (this.roleChange != "1") {
          this.roleMode = this.appSecurityManagementService.getRoleModeByRoleID(
            this.getRoleId()
          );
          sessionStorage.setItem("roleMode", this.roleMode);
          this.appSecurityManagementService.setRoleMode(this.roleMode);
        }
        this.userExecutionRoles.forEach((roles) => {
          if (roles.applicationRoleId == appRoleIDs.admin) {
            if (this.roleChange != "1") {
              this.roleMode =
                this.appSecurityManagementService.getRoleModeByRoleID(
                  roles.applicationRoleId
                );
              sessionStorage.setItem("roleMode", this.roleMode);
              this.appSecurityManagementService.setRoleMode(this.roleMode);
            }
            this.isAdmin = true;
          }
          if (roles.applicationRoleId == appRoleIDs.organization_admin) {
            this.isOrganizationAdmin = true;
          }
          if (roles.applicationRoleId == appRoleIDs.app_message_admin) {
            this.isApplicationMessageAdmin = true;
          }
          if (roles.applicationRoleId == appRoleIDs.graphiteRx_DataMgmt) {
            this.isGraphiteRxDataMgmt = true;
          }
          if (roles.applicationRoleId == appRoleIDs.report_admin) {
            this.isReportAdmin = true;
          }
          if (roles.applicationRoleId == appRoleIDs.report_viewer) {
            this.isReportViewer = true;
          }
        });
        if (
          this.userExecutionRoles.length == 1 &&
          this.getRoleId() == appRoleIDs.app_message_admin
        ) {
          this.onlyAppMessage = true;
        } else {
          this.onlyAppMessage = false;
        }
        if (this.roleMode) {
          if (this.roleMode == roleNames.security_manager) {
            this.selectedRoleName = "Security Manager";
          }
          if (this.roleMode == roleNames.admin) {
            this.selectedRoleName = "Super Admin";
          }
          if (this.roleMode == roleNames.organization_admin) {
            this.selectedRoleName = "Org Admin";
          }
        }
      }
    );

    this.mediaWatcher = this.media
      .asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0])
      )
      .subscribe((change: MediaChange) => {
        if (change.mqAlias >= "992px") {
          if (this.sideNavOpened) {
            this.sideNavOpened = false;
          }
          this.sideNavMode = "over";
        }
        if (change.mqAlias === "xs") {
          this.toolBarHeight = 45;
        } else if (change.mqAlias === "sm") {
          this.toolBarHeight = 45;
        } else {
          this.toolBarHeight = 80;
        }
      });

    this.userAppDetailSubscription =
      this.sharedUserAppDetailService.currentSelectedApp.subscribe(
        (appDetail) => (this.userAppDetail = appDetail)
      );

    if (typeof this.userAppDetail !== "undefined") {
      this.appName = this.userAppDetail.selectedAppName;
      if (this.userAppDetail.selectedAppId) {
        this.appId = this.userAppDetail.selectedAppId.toString();
      }
      this.appCode = this.userAppDetail.selectedAppCode;
      localStorage.setItem(
        "selectedAppName",
        this.userAppDetail.selectedAppName
      );
      localStorage.setItem(
        "selectedAppId",
        this.userAppDetail.selectedAppId
          ? this.userAppDetail.selectedAppId.toString()
          : ""
      );
      localStorage.setItem(
        "selectedAppCode",
        this.userAppDetail.selectedAppCode
      );
    }
    if (typeof this.userAppDetail === "undefined") {
      this.appName = localStorage.getItem("selectedAppName");
      this.appId = localStorage.getItem("selectedAppId");
      this.appCode = localStorage.getItem("selectedAppCode");
    }
    this.setLeftMenuVisibility(userAppRoles);
  }

  getRoleId(): number {
    if (
      this.userExecutionRoles.filter(
        (x) => x.applicationRoleId == appRoleIDs.admin
      ).length > 0
    ) {
      return appRoleIDs.admin;
    } else if (
      this.userExecutionRoles.filter(
        (x) => x.applicationRoleId == appRoleIDs.user_management
      ).length > 0
    ) {
      return appRoleIDs.user_management;
    } else if (
      this.userExecutionRoles.filter(
        (x) => x.applicationRoleId == appRoleIDs.organization_admin
      ).length > 0
    ) {
      return appRoleIDs.organization_admin;
    } else {
      return this.userExecutionRoles[0].applicationRoleId;
    }
  }


  ngOnDestroy(): void {
    if (!_.isNil(this.routeParamSubscription)) {
      this.routeParamSubscription.unsubscribe();
    }

    if (!_.isNil(this.mediaWatcher)) {
      this.mediaWatcher.unsubscribe();
    }

    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }

    if (!_.isNil(this.progressSpinnerSubscription)) {
      this.progressSpinnerSubscription.unsubscribe();
    }

    if (!_.isNil(this.loginSuccessSubscription)) {
      this.loginSuccessSubscription.unsubscribe();
    }

    if (!_.isNil(this.loginFailureSubscription)) {
      this.loginFailureSubscription.unsubscribe();
    }

    if (!_.isNil(this.acquireTokenSuccessSubscription)) {
      this.acquireTokenSuccessSubscription.unsubscribe();
    }

    if (!_.isNil(this.acquireTokenFailureSubscription)) {
      this.acquireTokenFailureSubscription.unsubscribe();
    }
  }
}

function compare(
  sortSourceData: number | string,
  sortComparisionData: number | string,
  isAsc: boolean
): number {
  return (sortSourceData < sortComparisionData ? -1 : 1) * (isAsc ? 1 : -1);
}
