export class ApplicationRoles{
    appId: number;
    appName: string;
    appRoleId: number;
    appRoleName: string;
    appRoleDescription: string;
    appRoleShortDescription: string;
    displayName: string;
    hideForExtranet: boolean;
    isSelected : boolean
    isDisabled : boolean;
    isInherited : boolean;
    isDenied : boolean;
    calculatedChecked: boolean;
    accessStatus: string;
    securityGroupId?: number;
}
