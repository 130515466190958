import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AppRoleService } from '../../shared/services/app-role.service';
import { SharedUserAppDetailService } from '../../shared/services/shared-user-app-detail.service';
import _ from 'lodash';
import { CossaService } from '../../shared/services/cossa.service';
import { Facility } from '../../shared/models/facility.model';
import { ApplicationsService } from '../../shared/services/applications.service';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { App } from 'src/app/shared/models/app.model';
 
export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}

@Component({
  selector: 'app-view-facility',
  templateUrl: './view-facility.component.html',
  styleUrls: ['./view-facility.component.scss']
})

export class ViewFacilityComponent implements OnInit{

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public facilityId: number;
  public entityId: number;
  private userId: string;
  private successMessage: string;
  private userSessionSubscription: Subscription;
  private routeParamSubscription: Subscription;
  private userSession: UserSession = new UserSession();
  private typeOfSpinner = 'loading';
  public facilityDetail: Facility;
  public facilityApplications: App[];
  private applicationsSubscription: Subscription;
  public sortedData = [];
  public tabIndex = 0 ;

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private activatedRoute: ActivatedRoute,
    private appRoleService: AppRoleService,
    private sharedUserAppDetailService: SharedUserAppDetailService,
    private cossaService: CossaService,
    private applicationsService: ApplicationsService
  ) {}


  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();

    this.routeParamSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.facilityId = this.activatedRoute.fragment["value"];
        this.entityId=params.id;
        this.facilityDetail = new Facility();
        this.getFacilityDetails(this.facilityId);
        this.getFacilityApplications(this.entityId);

        if (this.activatedRoute.queryParams["value"].isFromViewContract) {
          this.tabIndex = 1
        }
      }
    );

  }

  getFacilityDetails(facilityId): void {
    this.appSecurityManagementService.getFacilityDetails(facilityId.toString())
    .subscribe({
      next: (facilityDetail) => {
        this.facilityDetail = facilityDetail;
        this.typeOfSpinner = 'none';
      },
      error: (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      }
    });

}

getFacilityApplications(entityId): void {
  this.appSecurityManagementService.getFacilityApplications(entityId.toString())
  .subscribe({
    next: (facilityApplications) => {
      this.facilityApplications = facilityApplications;
      this.typeOfSpinner = 'none';
    },
    error: (error: HttpErrorResponse) => {
      this.typeOfSpinner = 'none';
      this.customErrorHandler.handleError(error);

    }
  });

}

}
