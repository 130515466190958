export class GraphiteRxEntity {
  graphiteRxRecordId: number;
  entityId: number;
  graphiteRxEntityId: string;
  soldtoFacilityName: string;
  entityName:string;
  soldtoFacilityAddress:string;
  facilityCity:string;
  facilityState:string;
  facilityZipCode:string;
  createDate: string;
  createBy: string;
  updateDate: string;
  updateBy: string;
  }
