// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-form {
  min-width: 150px;
  max-width: 900px;
  width: 100%;
}

.settings-full-width {
  width: 100%;
}

.container {
  margin: 10px;
}

.spacer {
  padding: 5px;
}

.setting_btn {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/settings/settings.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".settings-form {\r\n  min-width: 150px;\r\n  max-width: 900px;\r\n  width: 100%;\r\n}\r\n\r\n.settings-full-width {\r\n  width: 100%;\r\n}\r\n\r\n.container {\r\n  margin: 10px;\r\n}\r\n\r\n.spacer{\r\n  padding: 5px;\r\n}\r\n\r\n.setting_btn{\r\n  display:none;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
