import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { Observable, Subscription, map, startWith, tap } from 'rxjs';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import _ from 'lodash';
import { OrganizationFacilityLOB } from 'src/app/shared/models/organization-facility-lob.model';
import { AdminFacilityService } from '../view-admin-facility-lob/services/admin-facility.service';
import { FacilityLOBList } from 'src/app/shared/models/facility-lob-list-model';
import { MatRadioChange } from '@angular/material/radio';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-change-parent-entity',
  templateUrl: './change-parent-entity.component.html',
  styleUrls: ['./change-parent-entity.component.scss']
})
export class ChangeParentEntityComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private userSessionSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  
  organizations: OrganizationFacilityLOB[];
  searchOrganizationList: Observable<any[]>;
  filteredSearchOrganizationList: any = [];

  
  facilities: FacilityLOBList[];
  filteredSearchFacilityList: any = [];
  searchFacilityList: Observable<any[]>;

  orgName: string;
  facilityName: string;
  parentEntityName: string;

  facilityContractId: number;
  lineOfBusiness: string;
  lineOfBusinessId: number;
  
  public entityId:  number;
  facilityId: number;
  selectedEntityId: number;
  selectedEntityName: string;
  selectedOrgId: number;
  selectedOrgName: string;

  isActiveOption = true;  
  validationMessage: string;

  changeParentForm: FormGroup;
  orgList = new FormControl();
  facilityList = new FormControl();
  
  selected_org: any ;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog, public dialogRef: MatDialogRef<ChangeParentEntityComponent>,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private b2cService: B2cService,
    private customErrorHandler: CustomErrorHandler,
    private adminFacilityService: AdminFacilityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.initForm();

    this.orgName = this.data.orgName;
    this.facilityName = this.data.facilityName;
    this.lineOfBusiness = this.data.lineOfBusiness;
    this.lineOfBusinessId =  this.data.lineOfBusinessId;
    this.parentEntityName = this.data.parentEntityName;

    this.selectedEntityId = this.data.parentEntityId;
    this.selectedOrgId = parseInt(this.data.orgId);
    this.facilityId = parseInt(this.data.facilityId);
    this.entityId = 0;
    this.getOrganizations();     

  }

  displayOrganization(entity: OrganizationFacilityLOB): string | undefined {
    this.entityId  =  entity.orgId;
    return entity ? entity.organization : undefined;
  }

  displayFacility(entity: FacilityLOBList): string | undefined {
    this.entityId  = entity.entityId;
    return entity ? entity.facilityName : undefined; 
  }

  initForm() {
    this.changeParentForm = this.formBuilder.group(
      {
        orgList: [{ value: null}],
        facilityList: [{ value: null}],
      }
    );
    this.typeOfSpinner = 'none';
  }
  
  getOrganizations(): void {
    this.appSecurityManagementService.getOrganizationLOBs()
    .subscribe({
      next: (response) => {
        this.organizations = response;   
        const orgoption = this.organizations.find((dropdown)=> this.selectedOrgId === dropdown.orgId);
        this.filteredSearchOrganizationList = this.organizations.filter((dropdown) => (this.isActiveOption == null || dropdown.isActive == this.isActiveOption));
        this.searchOrganizationList = this.orgList.valueChanges       
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this.filterOrg(name) : this.filteredSearchOrganizationList.slice())
        );   

        this.orgList.setValue(orgoption);
        this.onOrganizationSelected(orgoption);
        this.typeOfSpinner = 'none';
      },
      error: (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      }
    });
  }

  filterOrg(name: string) {
    return this.filteredSearchOrganizationList.filter(x =>
      x.organization.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterFacilities(name: string) {
    return this.filteredSearchFacilityList.filter(x =>
      x.facilityName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  onEnter(evt: any){
    if (evt.source.selected) {
    alert("hello ");
    }
  }

  getFacilitiesByOrgId(orgId: number): void {
    this.adminFacilityService.getLineOfBusinessFacilityLOBListByOrgId(this.lineOfBusinessId, orgId)
    .subscribe({
      next: (response) => {
        this.facilities = response;
        this.facilities = this.facilities.filter((dropdown) => dropdown.facilityId != this.facilityId);
        this.filteredSearchFacilityList = this.facilities.filter((dropdown) => (this.isActiveOption == null || dropdown.isActive == this.isActiveOption));
        this.searchFacilityList = this.facilityList.valueChanges
        .pipe( 
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this.filterFacilities(name) : this.filteredSearchFacilityList.slice())
        );
       
        this.typeOfSpinner = 'none';
      },
      error: (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      }
    });
  }

  onActiveConditionChange(conditionChange: MatRadioChange) {
    this.isActiveOption = JSON.parse(conditionChange.value);
   this.filterList();
  }
  
  filterList(){
    this.filteredSearchOrganizationList = this.organizations.filter((dropdown) => (this.isActiveOption == null || dropdown.isActive == this.isActiveOption));
    this.filteredSearchFacilityList = this.facilities.filter((dropdown) => (this.isActiveOption == null || dropdown.isActive == this.isActiveOption));
    this.searchOrganizationList = this.orgList.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this.filterOrg(name) : this.filteredSearchOrganizationList.slice())
    );  
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
  onSave(): void{
    this.validationMessage = "";
    if (this.entityId == 0) {
      this.validationMessage = "Please enter the required fields.";
    }  else {
      this.dialogRef.close(this.entityId);
    }
  }
  
  public onOrganizationSelected(option: any):void {
    this.entityId = option.orgId;
   const orgId  = option ? option.orgId : undefined;
   if( orgId != undefined) {
    this.getFacilitiesByOrgId(orgId);
   }
  }
  public onFacilitySelected(option: any):void {
   this.entityId = option.entityId;
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }
}