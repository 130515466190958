export const ErrorMessages = {
  ServiceOffline: 'RxAdmin api service is offline',
  ServiceUnavailable: 'API Service Unavailable',
  NotFound: 'Not Found',
  Unauthorized: 'Unauthorized User',
  CossaServiceOffline: 'Cossa api service is offline',
  DataWarehouseServiceOffline: 'Data Warehouse api service is offline',
  ReportConfigurationLoadFail: 'Report configuration loading failed',
  CossaServiceOfflineMessage : 'Cossa api service is offline'
};
