import { Component, OnInit, AfterViewInit, ViewChild, Input, OnDestroy, OnChanges } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AppRoleService } from '../../shared/services/app-role.service';
import { SharedUserAppDetailService } from '../../shared/services/shared-user-app-detail.service';
import _ from 'lodash';
import { CossaService } from '../../shared/services/cossa.service';
import { Facility } from '../../shared/models/facility.model';
import { ApplicationsService } from '../../shared/services/applications.service';
import { UserSecurityFacility } from '../../shared/models/user-security-facility.model';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { FacilityAssignUserComponent } from '../../components/facility-assign-user/facility-assign-user.component';
import { OrgAdminFacility } from '../../shared/models/org-admin-facility.model';
import { Users } from '../../shared/models/users.model';
import { DeleteConfirmDialogModel } from 'src/app/components/facility-user-delete-dialog/facility-user-delete-dialog.component';
import { FacilityUserDeleteDialogComponent } from 'src/app/components/facility-user-delete-dialog/facility-user-delete-dialog.component';
import { Organization } from 'src/app/shared/models/organization.model';

@Component({
  selector: 'app-facility-assigned-users',
  templateUrl: './facility-assigned-users.component.html',
  styleUrls: ['./facility-assigned-users.component.scss']
})
export class FacilityAssignedUsersComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() entityIdInput: number;
  @Input() entityNameInput: string;

  private userId: string;
  private successMessage: string;
  private userSessionSubscription: Subscription;
  private routeParamSubscription: Subscription;
  public  userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<Users[]>;
  public totalRows$: Observable<number>;
  public facilityDetail: Facility;
  public organizationUserInEntity: Users[];
  private applicationsSubscription: Subscription;
  public sortedData = [];
  private unsubscribeList: any[] = [];
  private organization: Organization[];
  public selectedOrganization: Organization;
  @Input() isOrgAdminView = false;
  displayedColumns: string[] = ['name', 'email', 'jobTitle', 'action'];

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private activatedRoute: ActivatedRoute,
    private appRoleService: AppRoleService,
    private sharedUserAppDetailService: SharedUserAppDetailService,
    private cossaService: CossaService,
    private applicationsService: ApplicationsService,
    public dialog: MatDialog
  ) {}

    assignUser() {
    const dialogRef = this.dialog.open(FacilityAssignUserComponent, {
      data: {
        dataKey: this.entityIdInput,
        showOrganizations: this.isOrgAdminView? false:true,
        showLOB: this.isOrgAdminView? false:true,
        isOrgAdminView: this.isOrgAdminView,
      },
      backdropClass: 'lg_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.selectedOrganization){
        this.getAssignedUsers(this.selectedOrganization.entityId, this.entityIdInput.toString());
       }
    });
  }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.organization = this.appSecurityManagementService.getOrganization();
    this.organization.forEach(organization => {
            if (organization.selected === true) {
              this.selectedOrganization = organization;
            }
       });
       if(this.selectedOrganization){
        this.getAssignedUsers(this.selectedOrganization.entityId, this.entityIdInput.toString());
       }
  }

  ngOnChanges (): void {
    this.organization = this.appSecurityManagementService.getOrganization();
    this.organization.forEach(organization => {
            if (organization.selected === true) {
              this.selectedOrganization = organization;
            }
       });
       if(this.selectedOrganization){
        this.getAssignedUsers(this.selectedOrganization.entityId, this.entityIdInput.toString());
       }
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  getFacilityDetails(facilityId): void {
    this.cossaService.getFacilityDetails(facilityId.toString()).subscribe(
      (facilityDetail) => {
        this.facilityDetail = facilityDetail;
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);

      });

}

getAssignedUsers(entityRootId: number, entityId: string): void {
    this.appSecurityManagementService.getOrganizationFacilityUser(entityId.toString()).subscribe(
    (organizationUser) => {
      this.organizationUserInEntity = organizationUser;
      this.organizationUserInEntity = !_.isNil(entityId) ?
      organizationUser.filter(x => x.entityId == Number(entityId)) : null;
      if(!_.isNil(this.organizationUserInEntity)){
        this.setDisplayRows(this.organizationUserInEntity);
      }
      this.typeOfSpinner = 'none';
    },
    (error: HttpErrorResponse) => {
      this.typeOfSpinner = 'none';
      this.customErrorHandler.handleError(error);
    });
}

onDeleteClick(userId: number, entityId: number, userName: string): void {
  this.deleteConfirmDialog(userId, entityId, userName);
}

deleteConfirmDialog(userId: number, entityId: number, userName: string): void {
  this.typeOfSpinner = 'loading';
  const message = 'Are you sure you want to unassign ' + userName +' from ' + this.entityNameInput + ' ?';
  const dialogData = new DeleteConfirmDialogModel(
    'Delete User', message,  userId,  entityId, 0);
  const dialogRef = this.dialog.open(FacilityUserDeleteDialogComponent, {
    // panelClass: 'delete-assessment-modal',
    data: dialogData,
    backdropClass: 'sm_panel-dialog'
  }).afterClosed().subscribe(r => {
    this.typeOfSpinner = 'loading';
     if(this.selectedOrganization){
       this.getAssignedUsers(this.selectedOrganization.entityId, this.entityIdInput.toString());

      }
  });

}

setDisplayRows(userSecurityFacilityList: Users[]): void {
  const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
  const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
  const rows$ = of(userSecurityFacilityList);
  this.totalRows$ = rows$.pipe(map((rows) => rows.length));
  this.displayedRows$ = rows$.pipe(
    sortRows(sortEvents$),
    paginateRows(pageEvents$)
  );
}

applyFilter(filterValue: string): void {
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  const filteredUserSecurityFacility = this.organizationUserInEntity.filter((userSecurityFacility) => {
    return (
      (userSecurityFacility.firstName && userSecurityFacility.firstName.toLowerCase().indexOf(filterValue) !==
        -1) ||
      (userSecurityFacility.lastName && userSecurityFacility.lastName
        .toLowerCase()
        .indexOf(filterValue) !== -1) ||
        (userSecurityFacility.email && userSecurityFacility.email
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
          (userSecurityFacility.jobTitle && userSecurityFacility.jobTitle
            .toLowerCase()
            .indexOf(filterValue) !== -1)
       );
  });
  this.paginator.pageIndex = 0;
  this.setDisplayRows(filteredUserSecurityFacility);
}


}
