import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisplayMessage } from '../../components/message';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  domain = false;
  isClose = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
      // Update view with given values
      this.title = data.title;
      this.message = data.message;
      this.isClose=data.isClose;
      if(this.message === DisplayMessage.domainWarningMessage || this.message === DisplayMessage.facilityCancelWarningMessage){
       this.domain = true;
      }else{
        this.domain = false;
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export class ConfirmDialogModel {
  constructor(public title: string, public message: string, public isClose: boolean=false) {
  }
}
