import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApplicationList } from '../models/application-list';
import { SvgIconService } from './svg-icons.service';
import { WebPortalCode } from '../constants/constants';
import { ClinicalPharmacology } from '../models/clinical-pharmacology'
import { HttpErrorResponse } from '@angular/common/http';
import { CustomErrorHandler } from '../modules/custom-error-handler/custom-error-handler';
import { environment } from '../models/environment-inject';

@Injectable({
  providedIn: 'root',
})

export class ApplicationsService {
  public sorted = false;
  public defaultUrl = '';

  constructor(@Inject('environment') private environment: environment,
              private http: HttpClient,
              private customErrorHandler: CustomErrorHandler,
              private svgIconService: SvgIconService) {}


  showSnackBarMessageErrorForCossa(error: HttpErrorResponse): void {
    this.customErrorHandler.handleCossaError(error);
  }

  getAppCode()
  {
    return this.environment.environment.appcode;
  }

  getApplications(
    onPremisesSecurityIdentifier: string
  ): Observable<Array<ApplicationList>> {
    if(!onPremisesSecurityIdentifier){
      console.log("onPremisesSecurityIdentifier is empty ");
    }
    const apiUrl = `${this.environment.environment.appConfig.apiServer.cossa}UserApp/${onPremisesSecurityIdentifier}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    const params = {
      webPortal: WebPortalCode,
    };

    return this.http
      .get<Array<ApplicationList>>(apiUrl, { headers, params })
      .pipe(map((data) => data));
  }

  public getClinicalPharmacology(): Observable<ClinicalPharmacology> {
    const apiUrl = `${this.environment.environment.appConfig.apiServer.cossa}ClinicalPharmacology`;
    return this.http.get<ClinicalPharmacology>(apiUrl)
      .pipe(
        map(data => data),
      );
  }

  public setDefaultUrl(url: string){
    this.defaultUrl = url;
  }
  public getDefaultUrl(){
    return this.defaultUrl;
  }
}
