import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organization-contract-list',
  templateUrl: './organization-contract-list.component.html',
  styleUrls: ['./organization-contract-list.component.scss']
})
export class OrganizationContractListComponent {
  contractId:any;
}
