import { Component, OnInit } from '@angular/core';
// import { B2CAuthService } from './core/authentication/b2c-auth.service';

@Component({
  selector: 'lib-cps-b2clibrary',
  template: `
    <p>
      B2C Access Token (Use this token for Swagger UI)
    </p><br/>
    <p>{{ acessToken }}</p>
  `,
  styles: [
  ]
})
export class CPSLibAppComponent implements OnInit {
  acessToken: string;
  constructor() { }

  ngOnInit(): void {
      this.acessToken = localStorage.getItem('AccessToken');
  }


}
