import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { of, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { fromMatPaginator, fromMatSort, paginateRows, sortRows } from 'src/app/shared/utils/utils';
import _ from 'lodash';
import { UserSession, B2cService } from 'cps-b2clibrary';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';
import { PermissionsMapUserEntity } from 'src/app/shared/models/permissions-map-user-entity';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-permission-map-entities-dialog',
  templateUrl: './view-permission-map-entities-dialog.component.html',
  styleUrls: ['./view-permission-map-entities-dialog.component.scss']
})
export class ViewPermissionMapEntitiesDialogComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private userSessionSubscription: Subscription;
  public userSession: UserSession = new UserSession()
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<PermissionsMapUserEntity[]>;
  public totalRows$: Observable<number>;
  public sortedData = [];
  userId: number;
  permissionsMapUserEntity: PermissionsMapUserEntity[];
  isReadOnly= false;
  displayedColumns: string[] = ['entityId', 'entityName', 'entityTypeName', 'unitNumber', 'entityRootName', 'webPortalCode'];

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    public dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewPermissionMapEntitiesDialogComponent>
  ) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    if(this.isOrganizationAdmin() == false && this.isAdmin() == false && this.isUserManagememt() == false && this.isLOBManager()==true)
    {
      this.isReadOnly=true;
    }

    this.getPermissionsMapUserEntity();

  }
  getPermissionsMapUserEntity(): void {
    this.appSecurityManagementService
      .getPermissionsMapUserEntity(this.data.userId)
      .subscribe(
        (permissionsMapUserEntityList) => {
          this.permissionsMapUserEntity = permissionsMapUserEntityList;

          this.setDisplayRows(this.permissionsMapUserEntity);
          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.customErrorHandler.handleError(error);
        });
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  setDisplayRows(permissionsMapUserEntityList: PermissionsMapUserEntity[]): void {
    this.sort.active = 'entityName'
    this.sort.direction = 'asc';
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(permissionsMapUserEntityList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  navigateToOrganization(row: PermissionsMapUserEntity) {
     this.router.navigate(['/', row.entityRootId ,'superadminorgcreate'], { fragment: row.entityRootName, queryParams:  {isEdit: !this.isReadOnly } });
     this.dialogRef.close();
  }

  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  isUserManagememt(): boolean {
    return this.appSecurityManagementService.getUserManagementMode();
  }

  isAdmin(): boolean {
    return this.appSecurityManagementService.getAdminMode();
  }

  isOrganizationAdmin(): boolean {
    return this.appSecurityManagementService.getOrganizationAdminMode();
  }

  isLOBManager(): boolean {
    return this.appSecurityManagementService.getLOBManagerMode();
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.toLowerCase().trim();
    const permissionsMapUserEntityList = this.permissionsMapUserEntity.filter((entities) => {

      return (
        (entities.entityId && entities.entityId.toString().indexOf(filterValue) !== -1) ||
        (entities.entityName && entities.entityName.toLowerCase().indexOf(filterValue) !== -1) ||
        (entities.entityTypeName && entities.entityTypeName.toLowerCase().indexOf(filterValue) !== -1) ||
        (entities.unitNumber && entities.unitNumber.toLowerCase().indexOf(filterValue) !== -1) ||
        (entities.entityRootName && entities.entityRootName.toLowerCase().indexOf(filterValue) !== -1) ||
        (entities.webPortalCode && entities.webPortalCode.toLowerCase().indexOf(filterValue) !== -1)
      );

    });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(permissionsMapUserEntityList);
  }

}
