import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from './status-codes';
import { ErrorMessages } from './error.messages';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { NgModule } from '@angular/core';

@NgModule({})

export class CustomErrorHandler {
    constructor(private snackBar: SnackBarModule) { }

    handleError(error: HttpErrorResponse): void {
        if (error.status === StatusCodes.ServiceOffline) {
          this.snackBar.errorMessage(error, 'OK', ErrorMessages.ServiceOffline);
        } else {
          this.snackBar.errorMessage(error.error);
        }

        throw error;
    }

    handleDataWarehouseError(error: HttpErrorResponse): void {
      if (error.status === StatusCodes.ServiceOffline) {
        this.snackBar.errorMessage(error, 'OK', ErrorMessages.DataWarehouseServiceOffline);
      } else {
        this.snackBar.errorMessage(error.error);
      }

      throw error;
  }

  handleCossaError(error: HttpErrorResponse): void {
    if (error.status === StatusCodes.ServiceOffline) {
      this.snackBar.errorMessage(error, 'OK', ErrorMessages.CossaServiceOfflineMessage);
    } else {
      this.snackBar.errorMessage(error);
    }
}
}
