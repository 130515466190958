import { ContractAppRoleModule } from '../models/contract-app-role-module.model';

export class OrganizationContract {
  contractId: number;
  customerId: number;
  contractName: string;
  contractNumber: string;
  affiliationEntityID: number;
  serviceProvidedID: number;
  COID: number;
  regionID: number;
  operationsRegionID: number;
  companyID: number;
  startDate: string;
  endDate: string;
  lineOfBusinessID: number;
  capitatedDrugs: boolean;
  capitatedLabor: boolean;
  autoRenewMonths: number;
  confirmedTermination: boolean;
  isCapitated: number;
  capitated: string;
  isPriority: number;
  sharedSavings: string;
  contractSignedDate: string;
  renewalDate: string;
  expirationDate: string;
  baselineStartDate: string;
  baselineEndDate: string;
  cPSPresidentUserID: number;
  sRVPUserID: number;
  rVPRegionalManagerUserID: number;
  hSDUserID: number;
  analystUserID: number;
  cRSUserID: number;
  rVPCUserID: number;
  cPAUserID: number;
  pSUserID: number;
  dOPUserID: number;
  clientDOP: string;
  dOPEmail: string;
  synergy: string;
  contractComments: string;
  lastDayOfService: string;
  terminationReason: string;
  importNotes: string;
  isActive: boolean;
  contractStatus: boolean;
  cDOPUserID: number;
  vPOUserID: number;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  serviceProviderName: string;
  entityName: string;
  entityRootId: number;
}

export class ContractApp {
  contractAppId: number;
  contractId: number;
  AppId: number;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  isSelected: boolean;
}

export class ContractAssignedApplication {
      contractAppId: number;
      contractId: number;
      appId: number;
      contractName: string;
      appName: string;
}

export class FacilityContractApp {
  facilityContractAppId: number;
  facilityContractId: number;
  AppId: number;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  isSelected: boolean;
}

export class FacilityContractDetail {
  facilityContract: FacilityContract;
  facilityContractApp: FacilityContractApp[];
  facilityContractAssessmentType : FacilityContractAssessmentType[];
}

export class FacilityContract {
    facilityContractID: number;
    facilityID: number;
    contractID: number;
    createBy: string;
    createDate: string;
    updateBy: string;
    updateDate: string;
    startDate: string;
    endDate: string;
    lastDayOfService: string;
    terminationReason: string;
    cpsPresidentUserID: number;
    vpoUserID: number;
    svpUserID: number;
    hsdUserID: number;
    financialAnalystUserID: number;
    purchasingSpecialistUserID: number;
    crsUserID: number;
    vpcUserID: number;
    cdopUserID: number;
    dopUserID: number;
    dopEmail: string;
    cpaUserID: number;
    clientDOP: string;
    defaultPurchasingMetricID: number;
    defaultFinancialMetricID: number;
    contractComments: string;
    contractName: string;
    serviceProvidedID: number;
    serviceProvidedName: string;
    serviceCategoryName : string;
    serviceSubcategoryName : string;
    pharmacyRegulatoryAccreditation: string;
}

export class ContractFacility {
  entityId: number;
  facilityId: number;
  unitNumber: string;
  facilityName: string;
  assignedDate: string;
  organization: string;
  organizationId: number;
  isSelected: boolean;
  serviceCategoryName : string;
  serviceSubcategoryName : string;
}

export class FacilityContractAssignedApplication {
  facilityContractAppId: number;
  facilityContractId: number;
  appId: number;
  appName: string;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
}

export class AdminContract {
  organizationContracts: any;
  contractApp: ContractApp[];
  facilityContract: FacilityContract[];
  contractAssessmentType: ContractAssessmentType[];
  contractModule: ContractModule[];
  contractAppRoleModule: ContractAppRoleModule[];
}

export class ContractAssessmentType {
  contractAssessmentTypeId: number;
  contractId: number;
  assessmentTypeId: number;
}

export class FacilityContractAssessmentType {
  facilityContractAssessmentTypeId: number;
  facilityContractId: number;
  assessmentTypeId: number;
}

export class AssessmentType {
  assessmentTypeId: number;
  assessmentTypeName: string;
  instructions: string;
  showCompliance: boolean;
  isActive: boolean;
  statusGroupId: number;
  sortOrder: number;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  typeDescription: string;
  imageFileName: string;
  statusId: number;
  allowAutomatedStatusReport: boolean;
  isNewAssessmentAllowed: boolean;
  imageFileType: string;
  isSelected: boolean;
  isSavedInDB: boolean;
}

export class ContractModule {
  contractModuleId: number;
  contractId: number;
  moduleId: number;
  moduleName: string;
  isSelected: boolean;
  isCurrentlySelected: boolean;
}

export class Module {
  moduleId: number;
  moduleName: string;
  isActive: boolean;
}
