import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { roleNames } from "src/app/shared/constants/constants";
import { ApplicationSecurityManagementService } from "src/app/shared/services/application-security-management.service";

@Component({
  selector: "app-no-organization",
  templateUrl: "./no-organization.component.html",
  styleUrls: ["./no-organization.component.scss"],
})
export class NoOrganizationComponent implements OnInit {
  constructor( private appSecurityManagementService: ApplicationSecurityManagementService,  private router: Router,){}

  ngOnInit(): void {
    const roleMode = sessionStorage.getItem("roleMode");
    if (roleMode == roleNames.organization_admin) {
      this.getOrganization();
    }

  }

  getOrganization(): void {
    this.appSecurityManagementService
      .getOrganizationList(localStorage.getItem('onpremisesId'))
      .subscribe(
        (response) => {
          const organization = response;
          if (organization?.length > 0) {
            this.router.navigate(["/org-home"]);
          }
        }
      );
  }
}
