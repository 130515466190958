import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-roles',
  templateUrl: './app-roles.component.html',
  styleUrls: ['./app-roles.component.scss']
})

export class AppRolesComponent {

}
