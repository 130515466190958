import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { SnackBarModule } from '../../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../../shared/services/application-security-management.service';
import _ from 'lodash';
import { CossaService } from '../../../shared/services/cossa.service';
import { Facility } from '../../../shared/models/facility.model';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { CustomErrorHandler } from '../../../shared/utils/custom-error-handler';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Users } from '../../../shared/models/users.model';
import { successMsgs } from '../../../shared/constants/constants';
import { Router } from '@angular/router';
import { Organization } from 'src/app/shared/models/organization.model';
import { ContractFacility, FacilityContract } from 'src/app/shared/models/organization-contract.model';
import { AdminContractService } from '../services/admin-contract.service';

@Component({
  selector: 'app-contract-facility-assign',
  templateUrl: './contract-facility-assign.component.html',
  styleUrls: ['./contract-facility-assign.component.scss']
})
export class ContractFacilityAssignComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private userId: string;
  private successMessage: string;
  private userSessionSubscription: Subscription;
  private routeParamSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<ContractFacility[]>;
  public totalRows$: Observable<number>;
  public facilityDetail: Facility;
  public userNotInFacility: Users[];
  public sortedData = [];
  public selectedOrganization: Organization;
  facilityContract: ContractFacility[];
  selectFacilityList: ContractFacility[] = [];
  objFacility: FacilityContract;
  organization: any;
  assignFacilitites: ContractFacility[];
  customerEntityId: any;

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private cossaService: CossaService,
    public dialog: MatDialog,
    private router: Router,
    private adminContractService: AdminContractService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ContractFacilityAssignComponent>
    ) { }



  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.organization = this.data.organization;
    this.assignFacilitites = this.data.assignFacilitites;
    this.facilityContract = this.data.facilityContract;
    this.customerEntityId = this.data.customerEntityId;
     this.getContractFacility();
  }

  getContractFacility(): void {
    this.adminContractService
        .getFacilityContract()
        .subscribe(
          (organizationContract) => {
            this.facilityContract = organizationContract.filter(i => i.organizationId === this.customerEntityId);
            if(this.assignFacilitites){
            this.assignFacilitites.forEach(element => {
              this.facilityContract = this.facilityContract.filter(i=> i.facilityId != element.facilityId);
            });
          }
            this.setDisplayRows(this.facilityContract);
            this.typeOfSpinner = 'none';
          },
          (error: HttpErrorResponse) => {
            this.typeOfSpinner = 'none';
            this.customErrorHandler.handleError(error);
          });

  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  setDisplayRows(organizationFacilitiesList: ContractFacility[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(organizationFacilitiesList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );

  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.toLowerCase().trim();
     const facilitycontract = this.facilityContract.filter((facilityContract) => {
       return (
         (facilityContract.facilityName && facilityContract.facilityName.toLowerCase().indexOf(filterValue) !== -1) ||
         (facilityContract.assignedDate && facilityContract.assignedDate.toLowerCase().indexOf(filterValue) !== -1) ||
         (facilityContract.organization && facilityContract.organization.toString().indexOf(filterValue) !== -1) ||
         (facilityContract.facilityId && facilityContract.facilityId.toString().indexOf(filterValue) !== -1) ||
         (facilityContract.unitNumber && facilityContract.unitNumber.toLowerCase().indexOf(filterValue) !== -1)
       );
     });
     this.paginator.pageIndex = 0;
     this.setDisplayRows(facilitycontract);
  }

  selectFacility(contractFacility: ContractFacility){
    this.objFacility = new FacilityContract();
    this.objFacility.facilityID = contractFacility.facilityId;
    const selectFacilityList = this.selectFacilityList.filter(i=> i.facilityId === contractFacility.facilityId);
    if(selectFacilityList.length == 0){
    this.selectFacilityList.push(contractFacility);
    }
    this.snackBar.successMessage(successMsgs.contract_facility_assign);
    this.dialogRef.close(this.selectFacilityList);
  }

}
