import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AdminFacility } from '../models/admin-facility';
import { environment} from 'src/environments/environment';
import { AdminFacilityLOB } from '../models/admin-facility-lob-list-model';
import { FacilityLOBList } from 'src/app/shared/models/facility-lob-list-model';

@Injectable({
  providedIn: 'root'
})
export class AdminFacilityService {

  constructor(private http: HttpClient) { }

  public getFacilityLOBList(): Observable<Array<AdminFacilityLOB>> {
    return this.http.get<Array<AdminFacilityLOB>>(`${environment.appConfig.apiServer.appSecService}FacilityLOBList`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public getOrganizationFacilityLOBList(parentEntityId: number): Observable<Array<FacilityLOBList>> {
    const headers = {
      'Content-Type': 'application/json',
      };

    return this.http
      .get<Array<FacilityLOBList>>(`${environment.appConfig.apiServer.appSecService}FacilityLOBList/${parentEntityId}`, { headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public getLineOfBusinessFacilityLOBList(lineOfBusinessId: number): Observable<Array<FacilityLOBList>> {
    const headers = {
      'Content-Type': 'application/json',
      };

    return this.http
      .get<Array<FacilityLOBList>>(`${environment.appConfig.apiServer.appSecService}LineOfBusinessFacilityLOBList/${lineOfBusinessId}`, { headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public getLineOfBusinessFacilityLOBListByOrgId(lineOfBusinessId: number, orgId: number): Observable<Array<FacilityLOBList>> {
    const headers = {
      'Content-Type': 'application/json',
      };

    return this.http
      .get<Array<FacilityLOBList>>(`${environment.appConfig.apiServer.appSecService}LineOfBusinessFacilityLOBList/${lineOfBusinessId}/${orgId}`, { headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }


  getLOBManagerMode(): boolean {
   return JSON.parse(sessionStorage.getItem("isLOBManager"));
  }

  getAdminMode(): boolean {
    return JSON.parse(sessionStorage.getItem("isAdmin"));
  }

  getOrganizationAdminMode(): boolean {
    return JSON.parse(sessionStorage.getItem("isOrganizationAdmin"));
  }

  getUserManagementMode(): boolean {
    return JSON.parse(sessionStorage.getItem("isAdminReadOnly"));
  }

  public getFacilityLOBListByOrgId(orgId: number): Observable<Array<FacilityLOBList>> {
    const headers = {
      'Content-Type': 'application/json',
      };

    return this.http
      .get<Array<FacilityLOBList>>(`${environment.appConfig.apiServer.appSecService}FacilityLOBList/${orgId}/orgfacilities`, { headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
