import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatRadioChange } from '@angular/material/radio';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { fromMatPaginator, fromMatSort, paginateRows, sortRows } from 'src/app/shared/utils/utils';
import { AdminFacility } from '../view-admin-facility/models/admin-facility';
import _, { filter } from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-unassign-entity-dialog',
  templateUrl: './user-unassign-entity-dialog.component.html',
  styleUrls: ['./user-unassign-entity-dialog.component.scss'],
  animations: [
     trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('collapsed <=> expanded', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserUnassignEntityDialogComponent implements OnInit {

  EntityList: Array<AdminFacility> = [];
  unAssignedEntityList: Array<AdminFacility> = [];
  assignedEntityList: Array<AdminFacility> = [];
  savedEntityList: Array<AdminFacility> = [];
  lineOfBusinessData: any[];
  selectedFilterValue: string;
  cpsLobEntity: any[];
  isNonEmpty = true;
  inActiveEntityChk = false;
  showInactiveEntity = false;
  typeOfSpinner: string;
  cpsExist:false;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  isReadOnly= false;
  
  public displayedRows$: Observable<AdminFacility[]>;
  public totalRows$: Observable<number>;

  selectedCondition: string;
  conditions: string[] = ['All','CPS','LOB'];
  displayedColumns: string[] = ['expand', 'entityId', 'facilityName', 'entityType', 'unitNumber', 'organization', 'isActive', 'actions'];

  expandedElement: any;
  isExpansionDetailRow = (i: number, row: any) => row.hasOwnProperty.call('detailRow');
  public userSession: UserSession = new UserSession();
  constructor(
    private snackBar: SnackBarModule,
    private router: Router,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserUnassignEntityDialogComponent>,
    private b2cService: B2cService,
  ) { }

  ngOnInit() {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session;});
    this.b2cService.getUserSesssion();
    this.typeOfSpinner = 'loading';
    this.selectedCondition = ActiveFilter.all;
    this.assignedEntityList = this.data.userEntity;
    this.savedEntityList  = this.data.userEntity;
    this.cpsExist=this.data.cpsExist;
    this.getLineOfBusinessDetails();
    if(this.isOrganizationAdmin() == false && this.isAdmin() == false && this.isUserManagememt() == false && this.isLOBManager()==true)
    {
      this.isReadOnly=true;
    }
  }

  onConditionChange(conditionChange: MatRadioChange) {
    this.selectedCondition = conditionChange.value;
     if (this.selectedCondition === 'CPS' || this.selectedCondition === 'LOB') {
      this.displayedColumns = ['entityId', 'facilityName', 'entityType', 'unitNumber', 'organization', 'isActive', 'actions'];
     }
     else {
      this.displayedColumns = ['expand', 'entityId', 'facilityName', 'entityType', 'unitNumber', 'organization', 'isActive', 'actions'];
    }
    this.radioSelectionFilter(this.selectedCondition);
  }

  radioSelectionFilter(filterValue: string): void {
    this.selectedFilterValue = filterValue;

  if (filterValue.toString() == ActiveFilter.all){
    this.inActiveEntityChk = false;
    let filteredOrganizations : AdminFacility[];
    if(!this.cpsExist){
      filteredOrganizations = this.unAssignedEntityList;
      if(!this.showInactiveEntity){
        filteredOrganizations = filteredOrganizations.filter(
          (element)=> {return (element.isActive == true || Boolean(element.isDenied ?? false) == true)}
        )
        this.paginator.pageIndex = 0;
      }
    }
    this.setDisplayRows(filteredOrganizations);
   }
   else{
    this.inActiveEntityChk = true;
    const filteredCpsLobEntity = this.cpsLobEntity.filter((entity) => {
      if (filterValue.toString() == ActiveFilter.cps) {
        return ((entity.entityType == 'CPS' && entity.entityId == 500));
      }
      else if (filterValue.toString() == ActiveFilter.lob) {
        const lobExist = this.lineOfBusinessData.filter(lob => {return(lob.cossaEntityId == entity.entityId)})
        if(lobExist.length > 0  && !this.cpsExist)
        {
          return ((entity.entityType == 'CPS' && entity.entityId != 500));
        }
      }
     }
    );
    this.setDisplayRows(filteredCpsLobEntity);
   }
  }

  getLineOfBusinessDetails(): void {
    this.appSecurityManagementService.getLineOfBusinessDetails().subscribe(
      (lineOfBusiness) => {
        this.lineOfBusinessData = lineOfBusiness.filter((lob) => {
          return(lob.cossaEntityId != null);
        });
        this.getUserEntityDetails();
      });
  }

  getUserEntityDetails(): void {
    this.appSecurityManagementService.getUserEntityByUserId(this.data.userId).subscribe(data => {
      if (data) {
        this.assignedEntityList = data;

        this.savedEntityList.forEach(element => {
        const unassignedList =  this.assignedEntityList.filter(e => e.entityId === element.entityId && e.lineOfBusinessId === element.lineOfBusinessId && Boolean(e.isDenied ?? false) != true);
        if(unassignedList.length == 0){
          this.assignedEntityList.push(element);
        }
       });

        this.getAllEntityDetails(this.userSession.onPremisesSecurityIdentifier);
      }
    });
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    let filteredOrganizations = this.unAssignedEntityList;
    if (this.selectedFilterValue == ActiveFilter.all){
      if(!this.showInactiveEntity){
        filteredOrganizations = filteredOrganizations.filter(
          (element)=> {return (element.isActive == true) }
        )
      }
     }
     else{
      filteredOrganizations = this.cpsLobEntity.filter((entity) => {
        if (this.selectedFilterValue == ActiveFilter.cps) {
          return ((entity.entityType == 'CPS' && entity.entityId == 500));
        }
        else if (this.selectedFilterValue == ActiveFilter.lob) {
          const lobExist = this.lineOfBusinessData.filter(lob => {return(lob.cossaEntityId == entity.entityId)})
          if(lobExist.length > 0)
          {
            return ((entity.entityType == 'CPS' && entity.entityId != 500));
          }
        }
       }
      );
     }

    const filteredFacilities = filteredOrganizations.filter((facility) => {
      return (
        (facility.facilityName && facility.facilityName.toLowerCase().indexOf(filterValue) !==
          -1) || (facility.organization && facility.organization
            .toLowerCase()
            .indexOf(filterValue) !== -1) ||
        (facility.unitNumber && facility.unitNumber.toLowerCase().indexOf(filterValue) !== -1)
        || (facility.entityId && facility.entityId.toString().indexOf(filterValue) !== -1)
        || (facility.entityType && facility.entityType.toLowerCase().indexOf(filterValue) !== -1)
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredFacilities);
  }

  setDisplayRows(selectedUserEntityList: AdminFacility[]): void {

    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(selectedUserEntityList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  insertUserEntity(entity: AdminFacility) {
    this.dialogRef.close(entity);

  }

  navigateToOrganization(row: AdminFacility) {
    this.router.navigate(['/', row.organizationID ,'superadminorgcreate'], { fragment: row.organization, queryParams:  {isEdit: !this.isReadOnly } });
    this.dialogRef.close();
}

isUserManagememt(): boolean {
  return this.appSecurityManagementService.getUserManagementMode();
}

isAdmin(): boolean {
  return this.appSecurityManagementService.getAdminMode();
}

isOrganizationAdmin(): boolean {
  return this.appSecurityManagementService.getOrganizationAdminMode();
}

isLOBManager(): boolean {
  return this.appSecurityManagementService.getLOBManagerMode();
}

  inActiveEntities(chvalue: MatCheckboxChange){
    this.showInactiveEntity = chvalue.checked;
    let filteredOrganizations : AdminFacility[];
    if(!this.cpsExist){
      filteredOrganizations = this.unAssignedEntityList;
      if(!this.showInactiveEntity){
        filteredOrganizations = filteredOrganizations.filter(
          (element)=> {return (element.isActive == true)}
        )
        const rows = [];
        this.paginator.pageIndex = 0;
        filteredOrganizations.forEach(element => rows.push(element));
        filteredOrganizations = rows;
      }
    }
    this.setDisplayRows(filteredOrganizations);
  }

  getAllEntityDetails(onPremisesSecurityIdentifier): void {
    this.appSecurityManagementService.getUnAssignedAdminFacilityDetails(onPremisesSecurityIdentifier,this.data.userId).subscribe(
      (entity) => {
        const cpsLob = [];
        const rows = [];
        this.EntityList = entity;
        this.EntityList = this.EntityList.filter(function(elem, index, self) {
          return index == self.indexOf(elem);
       });
        this.unAssignedEntityList = this.EntityList;
        this.unAssignedEntityList.forEach(element => {
        const dataExist = rows.filter(e=> e.entityId == element.entityId);
         if(dataExist.length == 0){
        const unassignedList =  this.assignedEntityList.filter(e => e.entityId === element.entityId);
        if(unassignedList.length == 0 || Boolean(unassignedList[0].isDenied ?? false) == true){
          element.isDenied = unassignedList.length > 0 && unassignedList[0].isDenied ;
          if((element.entityType == 'Customer' || element.entityType == 'Facility'  || element.entityType == 'Clinic')){
            rows.push(element);
          }
          if((element.entityType == 'CPS')){
            const CpsDataExist = cpsLob.filter(e=> e.entityId == element.entityId);
            if(CpsDataExist.length == 0){
            cpsLob.push(element);
            }
          }
        }
       }
    });
        this.unAssignedEntityList = rows;
        this.cpsLobEntity = cpsLob;
        this.typeOfSpinner = 'none';
        this.radioSelectionFilter(ActiveFilter.all);
      });
  }
}

export const ActiveFilter = {
  all: 'All',
  cps: 'CPS',
  lob:'LOB'
}
