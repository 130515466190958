import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { EntityComposite } from 'src/app/shared/models/entity-composite.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import _ from 'lodash';
import { of } from 'rxjs';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';

@Component({
  selector: 'app-inherited-entity',
  templateUrl: './inherited-entity.component.html',
  styleUrls: ['./inherited-entity.component.scss']
})
export class InheritedEntityComponent implements OnInit {

  constructor(private appSecurityManagementService: ApplicationSecurityManagementService,) { }

  @Input() assignedEntityIdInput: number;
  @Input() lineofBusinessName: string;
  @Input() assignedEntityIdForClinicInput: number;
  @Input() assignedEntityIdInputForCustomer: number;
  @Input() assignedEntityIdInputForCPS: number;
  @Output() inheritedEntityList: EventEmitter<EntityComposite[]> = new EventEmitter();

  userAssignFaclitiesData: EntityComposite[] = [];

  public displayedRows$: Observable<EntityComposite[]>;
  public totalRows$: Observable<number>;

  dataSource = new MatTableDataSource<EntityComposite>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  typeOfSpinner: string;

  ngOnInit() {
    if (!_.isNil(this.assignedEntityIdInput))
    {
      this.typeOfSpinner ='loading';
      this.getUserAssignedClinics(this.assignedEntityIdInput);
    }
  }


  getUserAssignFaclities(entityRootId: number) {
    this.appSecurityManagementService
      .GetFacilities(entityRootId)
      .subscribe(
        (response) => {
          this.userAssignFaclitiesData = response;
          this.setDisplayRows(this.userAssignFaclitiesData);
          this.typeOfSpinner ='none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner ='none';
        });
  }

  getUserAssignedClinics(entityRootId: number) {
    this.appSecurityManagementService
      .GetClinics(entityRootId)
      .subscribe(
        (response) => {
          this.userAssignFaclitiesData = response;
          this.inheritedEntityList.emit(this.userAssignFaclitiesData);
          this.setDisplayRows(this.userAssignFaclitiesData);
          this.typeOfSpinner ='none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner ='none';
        });
  }
  setDisplayRows(selectedUserEntityList: EntityComposite[]): void {
    this.typeOfSpinner ='none';
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(selectedUserEntityList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

}
