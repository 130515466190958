import { UserEntitySecurityGroup } from "src/app/components/view-admin-facility/models/user-entity-security-groups";
import { SecurityGroupForUserModule } from "./security-group-for-user.model";

export class Users {
  entityId: number;
  userId: number;
  createBy: string;
  createDate: string;
  updateDate: string;
  updateBy: string;
  itmsUserId: number;
  adUserId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  failedLoginAttempts: number;
  lastLoginAttempt: Date;
  lastSuccessfulLogin: string;
  startDate: Date;
  terminationDate: string;
  corporateEmployee: boolean;
  resourceSolutionsUser: boolean;
  disableApplicationAccessChangesByUM: boolean;
  emailAddressValidatedDate: Date;
  adSid: string;
  logonCount: number;
  passwordChangeNeeded: boolean;
  passwordLastSetDate: string;
  passwordExpirationDays: boolean;
  forcePasswordReset: boolean;
  isDisabled: boolean;
  fullName: string;
  firstNameLastName: string;
  onPremisesImmutableId: string;
  onPremisesSecurityIdentifier: string;
  azureActiveDirectoryObjectId: string;
  isOrgUser: boolean;
  assignedEntityName: string;
  assignedEntityID: number;
  securityGroupName: string;
  jobTitle: string;
  phoneNumber: string;
  facilities: number;
  cpsEmployeeID: string;
  cpsEntityName: string;
  lineOfBusiness: string;
  b2CAzureActiveDirectoryObjectID : string;
  lineOfBusinessId: number;
  lineOfBusinessData: any[];
  lineOfBusinessName: string;
  secGrpForUserModule: SecurityGroupForUserModule[]
  securityGroupId:number;
  managerFirstName: string;
  managerLastName: string;
  isCPSEmployee: boolean;
  isExternal: boolean;
  isInherited: boolean;
  isDenied: boolean;
  userEntitySecurityGroups: UserEntitySecurityGroup[];
  userDeniedEntitySecurityGroups: UserEntitySecurityGroup[];
  assignedOrgCount?: number;
  isDomainUser?: boolean;
}
