import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { ApplicationList } from '../../shared/models/application-list';
import { environment} from 'src/environments/environment';
import _ from 'lodash';
import { SvgIconService } from '../../shared/services/svg-icons.service';
import { WebPortalCode } from 'src/app/shared/constants/constants'
import { ClinicalPharmacology } from '../../shared/models/clinical-pharmacology'
import { HttpErrorResponse } from '@angular/common/http';
import { App } from '../models/app.model';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';

@Injectable({
  providedIn: 'root',
})

export class ApplicationsService {
  private applicationsList: Array<ApplicationList>[];
  public applicationList = [];
  public sortedData = [];
  public apps = [];
  public sorted = false;

  constructor(private http: HttpClient,
              private customErrorHandler: CustomErrorHandler,
              private svgIconService: SvgIconService) {}

  getSetApplications(onPremisesSecurityIdentifier: string): Observable<Array<ApplicationList>> {
      this.getApplications(onPremisesSecurityIdentifier)
      .subscribe((values) => {
        this.applicationList = values;

        this.getAllApps().subscribe((values) => {
        this.apps = values;
        if (this.sorted == false ){
          this.apps = this.apps.filter(result => result.offline === false  || result.isActive === true);
          this.applicationList = this.applicationList.filter(appList => this.apps.some(apps => apps.appId == appList.applicationId ));

        const applications: Array<{[key: string]: ApplicationList[]}> = [];
        let iconImage: string;
        let appName: string;
        Object.keys(this.applicationList).map((key) => {
          applications.push({[key]: this.applicationList[key]});
          const applicationRowData: ApplicationList = this.applicationList[key];
          if (!_.isNil(applicationRowData.iconImage)) {
            iconImage = applicationRowData.iconImage;
          } else {
            iconImage = 'default.svg';
          }

          if (!_.isNil(applicationRowData.appNameAbbreviation)) {
            appName = applicationRowData.appNameAbbreviation;
          } else {
            appName = applicationRowData.applicationName;
          }
          this.svgIconService.loadIcons(iconImage, appName , '../../../../assets/images/app-icons');
        });

        const sortedApplicationList: Array<ApplicationList[]> = applications.slice() as any;

        sortedApplicationList.sort((applicationFirstRow: ApplicationList[], applicationSecondRow: ApplicationList[]) => {
            const isAsc = true;
            for (const index in applicationFirstRow) {
              if (applicationFirstRow.hasOwnProperty.call(index)) {
                for (const indexSecond in applicationSecondRow) {
                  if (applicationSecondRow.hasOwnProperty.call(indexSecond)) {
                    const sortSourceData = applicationFirstRow[index].applicationName;
                    const sortComparisionData = applicationSecondRow[indexSecond].applicationName;
                    return compare(sortSourceData, sortComparisionData, isAsc);
                  }
                }
              }
            }
          }).forEach((applicationData) => {
              for (const index in applicationData) {
                if (applicationData.hasOwnProperty.call(index)) {
                  this.sortedData.push(applicationData[index]);
                }
              }
          });
        this.sorted = true;
        }
      });
      }, (error: HttpErrorResponse) => {
        this.showSnackBarMessageErrorForCossa(error);
      });
      return of(this.sortedData);
  }

  showSnackBarMessageErrorForCossa(error: HttpErrorResponse): void {
    this.customErrorHandler.handleCossaError(error);
  }

  readApplications(): Observable<Array<ApplicationList>> {
    return of(this.sortedData);
  }

  getApplications(
    onPremisesSecurityIdentifier: string
  ): Observable<Array<ApplicationList>> {
    if(!onPremisesSecurityIdentifier){
      console.log("onPremisesSecurityIdentifier is empty ");
    }
    const apiUrl = `${environment.appConfig.apiServer.cossa}UserApp/${onPremisesSecurityIdentifier}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    const params = {
      webPortal: WebPortalCode,
    };

    return this.http
      .get<Array<ApplicationList>>(apiUrl, { headers, params })
      .pipe(map((data) => data));
  }

  getAllApps(): Observable<Array<App>> {
    const apiUrl = `${environment.appConfig.apiServer.cossa}App`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http
      .get<Array<App>>(apiUrl, { headers })
      .pipe(map((data) => data));
  }

  public getClinicalPharmacology(apiUrl: string): Observable<ClinicalPharmacology> {
    return this.http.get<ClinicalPharmacology>(apiUrl)
      .pipe(
        map(data => data),
      );
  }

}

function compare(
  sortSourceData: number | string,
  sortComparisionData: number | string,
  isAsc: boolean
): number {
  return (sortSourceData < sortComparisionData ? -1 : 1) * (isAsc ? 1 : -1);
}
