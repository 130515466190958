import { Injectable } from '@angular/core';
import { Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { AppRoleAll } from '../models/app-role-all.model';
import { SecurityGroupForUser } from '../models/security-group-for-user.model';
import { AppRoleAllAdmin } from '../models/app-role-all-admin.model';
import { UserDetail } from '../models/user-detail.model';

@Injectable({
  providedIn: 'root',
})
export class AppRoleService {
  private userAppRoles: string[];
  private appId: number;
  @Input() userData: string[];
  private successMessage: string;
  private deniedRole: Array<number> = [];
  private securityGroupForUser: SecurityGroupForUser;
  private appRoleAll: AppRoleAll[];
  private appRoleAllForAdmin: AppRoleAllAdmin[];
  private isAdmin = false;
  private appRoleBySecGroup: string[];
  private securityGroupId: number;
  private userId: string;
  private appRoleValues = [];
  private state: number;
  private retStatus: boolean;
  private userDetail: UserDetail;

  constructor(
    private snackBar: SnackBarModule,
    private appSecurityManagementService: ApplicationSecurityManagementService
  ) {
    this.state = 0;
    this.userDetail = new UserDetail();
  }

  getUserDetail(
    isAdmin: boolean,
    userID: string,
    appId: number,
    callback: (userDetail: UserDetail) => void): void {
    this.userDetail = new UserDetail();
    this.retStatus = false;
    this.userId = userID;
    this.isAdmin = isAdmin;
    if (isAdmin === false) {
      this.getAppRoleAll(appId, (appRolesAll: UserDetail) => {
        callback(appRolesAll);
      });
    } else if (isAdmin === true) {
      this.getAppRoleAllForAdmin(appId, (appRolesAllForAdmin: UserDetail) => {
        callback(appRolesAllForAdmin);
      });
    }
  }

  getAppRoleAllForAdmin(appId: number, callback: (appRoleAllForAdmin: UserDetail) => void): void {
    this.state = 0;
    this.appSecurityManagementService
      .getAppRoleAllForAdmin(appId.toString())
      .subscribe(
        (appRolesAllForAdmin) => {
          this.userDetail.appRolesAllForAdmin = appRolesAllForAdmin;
          this.getAppRoleBySecurityGroup(appId, (appRolesBySecurityGroup: UserDetail) => {
            callback(appRolesBySecurityGroup);
          });
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          return this;
        }
      );
  }

  getAppRoleAll(appId: number, callback: (appRoleAll: UserDetail) => void): void {
    this.state = 0;
    this.appSecurityManagementService.getAppRoleAll(appId.toString()).subscribe(
      (appRolesAll) => {
        this.userDetail.appRolesAll = appRolesAll;
        this.getAppRoleBySecurityGroup(appId, (appRolesBySecurityGroup: UserDetail) => {
          callback(appRolesBySecurityGroup);
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        return this;
      }
    );
  }

  getAppRoleBySecurityGroup(appId: number, callback: (appRolesBySecurityGroup: UserDetail) => void): void {
    this.appSecurityManagementService
      .getSecurityGroupForUser(this.userId)
      .subscribe({
        next: securityGroup => {
          this.appSecurityManagementService
            .getAppRoleBySecurityGroup(
              appId.toString(),
              securityGroup.securityGroupId.toString()
            )
            .subscribe({
              next: (appRolesBySecGroup) => {
                this.userDetail.appRoleBySecGroup = appRolesBySecGroup;
                this.getAppRolesByUserId(appId, this.userId, (appRolesByUserId: UserDetail) => {
                  callback(appRolesByUserId);
                });
              },
              error: (error: HttpErrorResponse) => {
                console.log(error);
                this.getAppRolesByUserId(appId, this.userId, (appRolesByUserId: UserDetail) => {
                  callback(appRolesByUserId);
                });
              }
            });
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          this.getAppRolesByUserId(appId, this.userId, (appRolesByUserId: UserDetail) => {
            callback(appRolesByUserId);
          });
        }
      });
  }

  getAppRolesByUserId(appId: number, userID: string, callback: (appRolesByUserId: UserDetail) => void): void {
    this.appSecurityManagementService
      .getAppRolesByUserId(appId.toString(), userID)
      .subscribe({
        next: (appRolesByUserId) => {
          this.userDetail.userAppRoles = appRolesByUserId;
          this.getDeniedRole((deniedRoles: UserDetail) => {
            callback(deniedRoles);
          });
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          this.getDeniedRole((deniedRoles: UserDetail) => {
            callback(deniedRoles);
          });
        }
      });
  }

  getDeniedRole(callback: (deniedRole: UserDetail) => void): void {
    this.deniedRole = [];
    // to check for admin
    if (this.isAdmin === false) {
      this.userDetail.appRolesAll.forEach((appRole) => {
        if (
          (appRole.denyForAppRoleId !== null &&
            this.userDetail.userAppRoles &&
            this.userDetail.userAppRoles.find(
              (name) => name.appRoleName === appRole.appRoleName
            ))
          ||
          (this.userDetail.appRoleBySecGroup &&
            this.userDetail.appRoleBySecGroup.find(
              (name) => name.appRoleName === appRole.appRoleName
            ))
        ) {
          this.userDetail.deniedRole.push(appRole.denyForAppRoleId);
        }
      });
      callback(this.userDetail);
    }
    if (this.isAdmin === true) {
      this.userDetail.appRolesAllForAdmin.forEach((appRole) => {
        if (
          (appRole.denyForAppRoleId !== null &&
            this.userDetail.userAppRoles &&
            this.userDetail.userAppRoles.find(
              (name) => name.appRoleName === appRole.appRoleName
            )) ||
          (this.userDetail.appRoleBySecGroup &&
            this.userDetail.appRoleBySecGroup.find(
              (name) => name.appRoleName === appRole.appRoleName
            ))
        ) {
          this.userDetail.deniedRole.push(appRole.denyForAppRoleId);
        }
      });
      callback(this.userDetail);
    }
  }

  getSecurityGroupDetail(
    securityGroupId: number,
    appId: number,
    callback: (securityGroupDetail: UserDetail) => void): void {
    this.appSecurityManagementService
      .getAppRoleAll(appId.toString())
      .subscribe((appRolesAll) => {
        this.userDetail.appRolesAll = appRolesAll;
        this.appSecurityManagementService
          .getAppRoleBySecurityGroup(
            appId.toString(),
            securityGroupId.toString()
          )
          .subscribe((appRolesBySecurityGroup) => {
            this.userDetail.appRoleBySecGroup = appRolesBySecurityGroup;
            if (callback) {
              callback(this.userDetail);
            }
          });
      });
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

}
