import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, Subscription, of } from 'rxjs';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { Facility } from '../../shared/models/facility.model';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import _ from 'lodash';
import { OrgAdminFacility } from '../../shared/models/org-admin-facility.model';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { CossaService } from '../../shared/services/cossa.service';
import { OrganizationFacility } from '../../shared/models/organization-facility.model';
import { Organization } from 'src/app/shared/models/organization.model';
import { FacilityLOB } from 'src/app/shared/models/facility-lob.model';
import { FacilityLOBList } from 'src/app/shared/models/facility-lob-list-model';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-facility-loblist',
  templateUrl: './facility-loblist.component.html',
  styleUrls: ['./facility-loblist.component.scss'],
  animations: [
    trigger('detailExpand', [
     state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
     state('*', style({ height: '*', visibility: 'visible' })),
     transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
   ]),
  ],
})
export class FacilityLOBListComponent implements OnInit, OnChanges  {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() entityId: number;

  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<FacilityLOB[]>;
  public totalRows$: Observable<number>;
  private organization: Organization[];
  public pageName: string;
  private unsubscribeList: any[] = [];
  public userSession: UserSession = new UserSession();
  private userSessionSubscription: Subscription;
  public selectedOrganizationEntityId = 0;
  private organizationFacilities: FacilityLOB[];
  public selectedOrganizationName: string;
  displayedColumns: string[] = ['expand', 'facilityName', 'city', 'state', 'serviceProvided', 
  'startDate','endDate', 'action']; 

  myAssignedEntityList:number[] =[];
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: any) => row.hasOwnProperty.call('detailRow');
  
  constructor(
    private customErrorHandler: CustomErrorHandler,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private activatedRoute: ActivatedRoute,
    private b2cService: B2cService,
    private router: Router) { }

    ngOnInit(): void {    
      this.pageName = 'Facilities';
  
      this.organization = this.appSecurityManagementService.getOrganization();
  
      if (this.organization) {
        this.organization.forEach(organization => {
          if (organization.selected === true) {
            this.selectedOrganizationEntityId = organization.entityId;
            this.selectedOrganizationName = organization.cpsEntityName;
          }
        });
      }
      if(this.entityId > 0) {
        this.displayedRows$ = new Observable<FacilityLOB[]>;
        this.selectedOrganizationEntityId = this.entityId;
      }
      this.b2cService.userSession$.subscribe(session => { this.userSession = session;
        this.getMyEntitySecurityGroupDetails();
       });
        this.b2cService.getUserSesssion();
    }

  ngOnChanges (): void {
    if(this.entityId > 0) {
      this.displayedRows$ = new Observable<FacilityLOB[]>;
      this.selectedOrganizationEntityId = this.entityId;
      this.getOrganizationFacilities();
    }    
  }

  
  getMyEntitySecurityGroupDetails(): void {
    if(this.userSession.IsOrgUser === 'True'){
      this.appSecurityManagementService.getPermissionsMapUserEntityByOnPremid(this.userSession.onPremisesSecurityIdentifier)
        .subscribe({
          next: response => {
            if(response){
              this.myAssignedEntityList = response.map(e=>e.entityId);
            }
            this.getOrganizationFacilities();
          },
          error: error => {
            console.log(error);
          }
        });
    }else{
      this.appSecurityManagementService.getUserEntitySecurityGroupByOnPremId(this.userSession.onPremisesSecurityIdentifier)
      .subscribe({
        next: response => {
          if(response){
            this.myAssignedEntityList = response.map(e=>e.entityId);
          }
          this.getOrganizationFacilities();
        },
        error: error => {
          console.log(error);
        }
      });
    }
  }

  getOrganizationFacilities(): void {
    if (this.selectedOrganizationEntityId !== 0) {
      this.appSecurityManagementService
        .getOrganizationFacilitiesLOB(this.selectedOrganizationEntityId.toString())
        .subscribe({
          next: response => {
            this.organizationFacilities = response;
            this.removedUnassignedFacilities();
            this.setDisplayRows(this.organizationFacilities);
            this.typeOfSpinner = 'none';
          },
          error : error => {
            this.typeOfSpinner = 'none';
            this.customErrorHandler.handleError(error);
          }
        });
    }
  }

  removedUnassignedFacilities() {
    for (let i = 0; i < this.organizationFacilities.length; i++) {
      if (this.organizationFacilities[i].children?.length > 0) {
        for (let j = 0; j < this.organizationFacilities[i].children.length; j++) {
          if (this.organizationFacilities[i].children[j].children?.length > 0) {
            for (let k = 0; k < this.organizationFacilities[i].children[j].children.length; k++) {
              if(this.myAssignedEntityList.findIndex(me => me === this.organizationFacilities[i].children[j].children[k].entityId) === -1){
                this.organizationFacilities[i].children[j].children.splice(k, 1);
                k--;
              }
            }
          }

          if(this.myAssignedEntityList.findIndex(me => me === this.organizationFacilities[i].children[j].entityId) === -1){
            if(this.organizationFacilities[i].children[j].children?.length > 0){
              this.organizationFacilities = this.organizationFacilities.concat(this.organizationFacilities[i].children[j].children);
            }
            this.organizationFacilities[i].children.splice(j, 1);
            j--;
          }
        }
      }
      if(this.myAssignedEntityList.findIndex(me => me === this.organizationFacilities[i].entityId) === -1){
        if(this.organizationFacilities[i].children?.length > 0){
          this.organizationFacilities = this.organizationFacilities.concat(this.organizationFacilities[i].children);
        }
        this.organizationFacilities.splice(i, 1);
        i--;
      }
    }
  }

  setDisplayRows(organizationFacilitiesList: FacilityLOB[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(organizationFacilitiesList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  onEdit(item): void{
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/', item.entityId ,'view-facility-lob'], { queryParams:  {facilityId: item.facilityId } });
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    const filteredFacilities = this.organizationFacilities.filter((facility) => {
      return (
        (facility.facilityName && facility.facilityName.toLowerCase().indexOf(filterValue) !==
          -1) ||
        (facility.city && facility.city
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
        (facility.state && facility.state.toLowerCase().indexOf(filterValue) !== -1) ||
        (facility.facilityDescription && facility.facilityDescription
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
        (facility.state && facility.state.toLowerCase().indexOf(filterValue) !== -1)
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredFacilities);
  }

}
