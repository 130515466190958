export class User {
    userId: string;
    createBy: string;
    createDate: string;
    updateDate: string;
    updateBy: string;
    itmsUserId: number;
    adUserId: string;
    firstName: string;
    lastName: string;
    email: string;
    failedLoginAttempts: number;
    lastLoginAttempt: string;
    lastSuccessfulLogin: string;
    startDate: string;
    terminationDate: Date;
    corporateEmployee: boolean;
    resourceSolutionsUser: boolean;
    disableApplicationAccessChangesByUM: boolean;
    emailAddressValidatedDate: string;
    adSid: string;
    logonCount: number;
    passwordChangeNeeded: boolean;
    passwordLastSetDate: string;
    passwordExpirationDays: boolean;
    forcePasswordReset: boolean;
    isDisabled: boolean;
    fullName: string;
    firstNameLastName: string;
    onPremisesImmutableId: string;
    onPremisesSecurityIdentifier: string;
    azureActiveDirectoryObjectId: string;
    securityGroupName: string;
    assignedEntityId: string;
    assignedEntityName: string;
    appCode: string;
    appName: string;
    userType: string;
    isOrgUser: string ;
}
