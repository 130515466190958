// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.readmore {
  color: #6FB3FC;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/read-more/read-more.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;AACF","sourcesContent":[".readmore{\r\n  color:#6FB3FC;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
