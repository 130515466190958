import { Component, OnDestroy, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { FiscalYearStartMonth } from 'src/app/shared/models/fiscal-year-start-month.model';
import { Organization } from '../../shared/models/organization.model';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { OrgAdminSettings } from '../../shared/models/org-admin-settings.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { DisplayMessage, DisplayString, ActionMessage } from 'src/app/shared/components/message';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDatepicker } from '@angular/material/datepicker';
import { OrgAdminSettingsEntity } from 'src/app/shared/models/org-admin-settings-entity.model';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class SettingsComponent implements OnInit {
  date = new FormControl(moment().startOf('day'));


  fiscalYearStartMonthList: FiscalYearStartMonth[] =[
    {monthId: 0, monthName: ''},
    {monthId: 1, monthName: 'January'},
    {monthId: 2, monthName: 'February'},
    {monthId: 3, monthName: 'March'},
    {monthId: 4, monthName: 'April'},
    {monthId: 5, monthName: 'May'},
    {monthId: 6, monthName: 'June'},
    {monthId: 7, monthName: 'July'},
    {monthId: 8, monthName: 'August'},
    {monthId: 9, monthName: 'September'},
    {monthId: 10, monthName: 'October'},
    {monthId: 11, monthName: 'November'},
    {monthId: 12, monthName: 'December'},
  ];
  selectedMonth: string;
  fiscalYearStartMonth: number;
  monthsOutOfDate: number;
  ignoreMissingMetricsPriorMonthYear: Date;
  orgAdminSettings: OrgAdminSettingsEntity = new OrgAdminSettingsEntity();
  settingsForm: FormGroup;

  public organization: Organization[];
  public selectedOrganization: Organization;
  //public orgAdminSettings: OrgAdminSettings;
  public userSession: UserSession = new UserSession();

  constructor(private b2cService: B2cService,
    private formBuilder: FormBuilder,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private snackBar: SnackBarModule,
    private router: Router) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();

    this.settingsForm = this.formBuilder.group({
      fiscalYearStartMonth: [''],
      monthsOutOfDate: [''],
      ignoreMissingMetricsPriorMonthYear: ['']

    });

    const ctrlValue = this.date.value;
      ctrlValue.date(1).set("hour", 0).set("minute", 0).set("second", 0);
      this.date.setValue(ctrlValue);

    this.organization = this.appSecurityManagementService.getOrganization();
        this.organization.forEach(organization => {
          if (organization.selected === true) {

            this.selectedOrganization = organization;
          }
        });
    this.getOrgAdminSettings(this.selectedOrganization.entityId.toString());


  }

  getOrgAdminSettings(entityId: string): void {
    this.appSecurityManagementService
      .getOrgAdminSettings(entityId)
      .subscribe({
        next: orgAdminSettings => {
          if(orgAdminSettings != null){
            this.settingsForm.patchValue(orgAdminSettings);
            this.fiscalYearStartMonth = orgAdminSettings.fiscalYearStartMonth;
          }
          else{
            this.fiscalYearStartMonth = 0;
          }
        },
        error: error => {
          console.log(error);
        }
  });
  }

  saveMonth() {
    this.orgAdminSettings = this.settingsForm.value;
    this.orgAdminSettings.entityId = this.selectedOrganization.entityId;

    this.appSecurityManagementService
    .addOrgAdminSettings(this.orgAdminSettings)
    .subscribe({
      next: response => {
        if (response !== null && response == "1") {
          this.snackBar.successMessage(DisplayMessage.updateMessage, ActionMessage.okAction);
        } else {
          this.snackBar.successMessage(DisplayMessage.failureMessage, ActionMessage.okAction);
        }
        this.router.navigate(['/org-home']);
         },
      error: error => {
        console.log(error);
      }
  });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    ctrlValue.date(1);
    this.date.setValue(ctrlValue);
    this.settingsForm.controls.ignoreMissingMetricsPriorMonthYear.setValue(this.date.value);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.date(1);
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.settingsForm.controls.ignoreMissingMetricsPriorMonthYear.setValue(this.date.value);
  }

  cancelClick(): void {
      this.router.navigate(['org-home']);
  }
}
