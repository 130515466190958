// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-close {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;AAFF","sourcesContent":["div {\r\n  // font-family: Tahoma, Arial, Sans-Serif;\r\n}\r\n.dialog-close {\r\n  float: right;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
