export class GraphiteRxVendor {
  graphiteRxVendorRecordId: number;
  purchasingVendorID: number;
  vendorName: string;
  vendorId: string;
  createDate: string;
  createBy: string;
  updateDate: string;
  updateBy: string;
  }
