import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'treeviewhighlight' })
export class TreeViewHighlightPipe implements PipeTransform {

    constructor(public sanitizer: DomSanitizer) {
    }

    transform(text: string, search: string): SafeHtml {
        if (search && text) {
            // eslint-disable-next-line no-useless-escape
            let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
            pattern = pattern.split(' ').filter((t) => {
                return t.length > 0;
            }).join('|');
            const regex = new RegExp(pattern, 'gi');
            return this.sanitizer.bypassSecurityTrustHtml(
                String(text).replace(regex, (match) => `<span style='background-color:yellow'><b>${match}</b></span>`)
            );

        } else {
            return text;
        }
    }
}
