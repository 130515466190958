import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-assign-application',
  templateUrl: './assign-application.component.html',
  styleUrls: ['./assign-application.component.scss']
})

export class AssignApplicationComponent implements AfterViewInit {
  displayedColumns: string[] = ['facilityname', 'role', 'description'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}

export interface PeriodicElement {
  facilityname: string;
  role: string;
  description: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { facilityname: 'Clinical Pharmacology', role: 'Standard', description: 'Lorem ipsum dollat sit amet lorem ipsum dollar sit amet'},
  { facilityname: 'CPS Cares', role: 'Standard', description: 'Lorem ipsum dollat sit amet lorem ipsum dollar sit amet'},
  { facilityname: 'Operational Data Metrics', role: 'Standard', description: 'Lorem ipsum dollat sit amet lorem ipsum dollar sit amet'},
  { facilityname: 'RxOrientation', role: 'Standard', description: 'Lorem ipsum dollat sit amet lorem ipsum dollar sit amet'},
  { facilityname: 'RxClinical Analytical', role: 'Standard', description: 'Lorem ipsum dollat sit amet lorem ipsum dollar sit amet'}
];
