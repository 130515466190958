import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'app-instructions-dialog',
  templateUrl: './app-message-instruction-dialog.component.html',
  styleUrls: ['./app-message-instruction-dialog.component.scss']
})
export class InstructionsDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InstructionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              private dialog: MatDialog) { }

pdfisLoading = false;
pdfSrc: string;
isDownload: boolean;
instructionsFilePath = '../../../assets/files/ApplicationMessage.pdf';
instructionsFileName = 'ApplicationMessage';

 async ngOnInit(): Promise<void> {
     this.pdfSrc = this.instructionsFilePath;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  OnDownload(): void {
      this.isDownload = true;
      const a = document.createElement('a');
      a.href =  this.instructionsFilePath;
      a.download = this.instructionsFileName;
      a.click();
      this.isDownload = false ;
  }

}
