
export class AppSettings {
    private static port = '9984';
    public static showPager = false;
}

export const ReportNameOptimizerUsers = 'CPS Optimizer Users';
export const OnPremisesSecurityIdentifier = 'OnPremisesSecurityIdentifier';
export const ReportGroupNameRxAdminReports = 'RxAdmin Reports';
export const RoleUser = 'AppSecMgmt-Admin';
export const RoleReportAdmin = 'AppSecMgmt-CorporateReports';
export const RoleReportViewer = 'AppSecMgmt-SettingsReports';
export const RoleCanPrint = 'AppSecMgmt-CanPrint';
export const RoleCanExport = 'AppSecMgmt-CanExport';
export const LaunchReportLabel = 'Launch Report';
export const ResetReportLabel = 'Reset Report';


