import { ClinicalModuleLinkItem } from "../../shared/models/clinicalModuleLinkItem.model";
import { organization } from "../../shared/models/organization.model";

export class UserSession {
    public loggedInString = 'false'; // Has to be a String to be stored in Cookie
    public firstName = '';
    public lastName = '';
    public email = '';
    public azureActiveDirectoryObjectId = '';
    public onPremisesSecurityIdentifier = '';
    public userAppRoles: any[] = [];
    public userApps: any[] = [];
    public userOrganizations: any[] = [];
    public UserId = '';
    public UserType = '';
    public AssignedEntityId = '';
    public IsOrgUser = '';
    public AppCode = '';
    public AppName = '';
    public DisplayName = '';
    public IsClinicalModuleApp = false;
    public clinicalModuleLinkItems: any[] = [];
}

