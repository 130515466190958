import { Injectable } from '@angular/core';
import { Observable, throwError} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { GraphiteRxVendor } from '../models/graphiteRxVendor.model';
import { environment} from 'src/environments/environment';
import { PurchasingVendor } from '../models/purchasing-Vendor.model';
import { GraphiteRxEntity } from '../models/graphiterxentity.model';
const headers = {
  'Content-Type': 'application/json',
};

@Injectable({
  providedIn: 'root'
})
export class GraphiteRxService {

  constructor(private httpClient: HttpClient) { }

  GetUnassignedGraphiteRxVendorDetails(): Observable<GraphiteRxVendor[]> {
    const apiurl = `${environment.appConfig.apiServer.purchasing}GraphiteRxVendor/Unassigned`;
    return this.httpClient
      .get<Array<GraphiteRxVendor>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'unassigned vendor graphiterx.service.ts- GetUnassignedGraphiteRxVendorDetails- ThrowingError To Caller...',
            err
          );
        })
      );
  }

  GetUnassignedGraphiteRxEntityDetails(): Observable<GraphiteRxEntity[]> {
    const apiurl = `${environment.appConfig.apiServer.purchasing}GraphiteRxVendor/UnassignedEntity`;
    return this.httpClient
      .get<Array<GraphiteRxEntity>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'unassigned entity graphiterx.service.ts- GetUnassignedGraphiteRxEntityDetails- ThrowingError To Caller...',
            err
          );
        })
      );
  }

   GetCPSVendorDetails(vendorName:string): Observable<PurchasingVendor[]> {
    const apiurl = `${environment.appConfig.apiServer.purchasing}GraphiteRxVendor/VendorsReconcile/${vendorName}`;
    return this.httpClient
      .get<Array<PurchasingVendor>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'unassigned vendor graphiterx.service.ts- GetUnassignedGraphiteRxVendorDetails- ThrowingError To Caller...',
            err
          );
        })
      );
  }

  UpdateCPSPurchasingVendorIDInGrxVendorXref(grxVendorID:string,cpsVendorID:string): Observable<PurchasingVendor[]> {
    const apiurl = `${environment.appConfig.apiServer.purchasing}GraphiteRxVendor/UpdateCPSVendorIDInGrx/${grxVendorID}/${cpsVendorID}`;
    return this.httpClient
      .post<Array<PurchasingVendor>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'graphiterx.service.ts- UpdateCPSPurchasingVendorIDInGrxVendorXref- ThrowingError To Caller...',
            err
          );
        })
      );
  }

  GetCPSEntityDetails(): Observable<GraphiteRxEntity[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}GraphiteRxVendor/UnassignedEntity`;
    return this.httpClient
      .get<Array<GraphiteRxEntity>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'unassigned entity graphiterx.service.ts- GetUnassignedGraphiteRxEntityDetails- ThrowingError To Caller...',
            err
          );
        })
      );
  }

  GetCPSEntityDetailsToReconcile(entityName:string): Observable<GraphiteRxEntity[]> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}Entity/GraphiteRx/${entityName}`;
    return this.httpClient
      .get<Array<GraphiteRxEntity>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'unassigned vendor graphiterx.service.ts- GetUnassignedGraphiteRxVendorDetails- ThrowingError To Caller...',
            err
          );
        })
      );
  }
  UpdateCPSEntityIDInGrxEntityXref(grxEntityID:string,cpsEntityID:string): Observable<GraphiteRxEntity[]> {
    const apiurl = `${environment.appConfig.apiServer.purchasing}GraphiteRxVendor/UpdateCPSEntityIDInGrx/${grxEntityID}/${cpsEntityID}`;
    return this.httpClient
      .post<Array<GraphiteRxEntity>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'graphiterx.service.ts- UpdateCPSEntityIDInGrxEntityXref- ThrowingError To Caller...',
            err
          );
        }),
      );
  }
}
