import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { CossaService } from 'src/app/shared/services/cossa.service';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { SharedUserAppDetailService } from 'src/app/shared/services/shared-user-app-detail.service';
import { AdminContractService } from '../services/admin-contract.service';
import { successMsgs } from 'src/app/shared/constants/constants';
import { ContractModule, Module } from 'src/app/shared/models/organization-contract.model';
import { AppRoleModule } from 'src/app/shared/models/app-role-module.model';

@Component({
  selector: 'app-contract-assigned-modules',
  templateUrl: './contract-assigned-modules.component.html',
  styleUrls: ['./contract-assigned-modules.component.scss']
})
export class ContractAssignedModulesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private successMessage: string;
  private userSessionSubscription: Subscription;
  private routeParamSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<Module[]>;
  public totalRows$: Observable<number>;
  private applicationsSubscription: Subscription;
  public sortedData = [];
  private unsubscribeList: any[] = [];
  public entityId: number;
  public assignedModule: Module[];
  assignedContractModule: ContractModule[];
  contractModule: Module[];
  selectedContractModuleList: ContractModule[] = [];
  contractId: number;
  unAssignedModules: Module[] = [];
  public selectedApproleModuleAppRoles: AppRoleModule[];
  public selectedModuleMasterAppRoles: AppRoleModule[];
  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private activatedRoute: ActivatedRoute,
    private sharedUserAppDetailService: SharedUserAppDetailService,
    private cossaService: CossaService,
    private applicationsService: ApplicationsService,
    public dialog: MatDialog,
    private router: Router,
    private adminContractService: AdminContractService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ContractAssignedModulesComponent>
  ) { }

   ngOnInit(): void {
    const pagename = this.activatedRoute.snapshot['_routerState'].url;
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.assignedModule = this.data.assignedModule;
    if (this.data.unAssignedModules){
      this.unAssignedModules = this.data.unAssignedModules;
    }
    this.contractId = this.data.dataKey;
    if(this.contractId && this.contractId != 0){
      this.getContractAssignedModule();
    }else{
      this.getAssignedModule();
    }
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  getContractAssignedModule(){
    this.adminContractService
    .getAssignedModulesByContractId(this.contractId)
    .subscribe(
      (assignedModule) => {
        if(assignedModule){
        this.assignedContractModule = assignedModule;
        this.assignedModule.forEach(element => {
        const assignedContract = this.assignedContractModule.filter(i=> i.moduleId === element.moduleId);
        if(assignedContract.length == 0){
        const contractModule = new ContractModule();
        contractModule.moduleId = element.moduleId;
        contractModule.moduleName = element.moduleName;
        this.assignedContractModule.push(contractModule);
        }
        });
        this.getupdateAssignedModule();
        }
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });
  }

  getAssignedModule(){
    this.adminContractService
    .getAssignedModules()
    .subscribe(
      (assignedModule) => {
        this.contractModule = assignedModule;
        if(this.assignedModule){
          this.assignedModule.forEach(element => {
            const unAssigned = this.unAssignedModules.filter(i=> i.moduleId === element.moduleId);
            if (unAssigned){
            const assignedContractModule = new ContractModule();
            assignedContractModule.moduleId = element.moduleId;
            assignedContractModule.moduleName = element.moduleName;
            this.selectedContractModuleList.push(assignedContractModule);
            this.contractModule = this.contractModule.filter(i=> i.moduleId !== element.moduleId);
            }
          });
        }
        this.setDisplayRows(this.contractModule);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });
  }

  getupdateAssignedModule(){
    this.adminContractService
    .getAssignedModules()
    .subscribe(
      (assignedModule) => {
        this.contractModule = assignedModule;
        if(this.assignedModule){
          this.assignedContractModule.forEach(element => {
            this.contractModule = this.contractModule.filter(i=> i.moduleId !== element.moduleId);
            this.selectedContractModuleList.push(element);
          });
        }
        if(this.unAssignedModules){
          this.unAssignedModules.forEach(element => {
            this.selectedContractModuleList.filter(i => i.moduleId !== element.moduleId);
            const conModule = this.contractModule.filter(i => i.moduleId === element.moduleId);
            if(conModule.length == 0){
              this.contractModule.push(element);
            }
          });
        }
        this.setDisplayRows(this.contractModule);
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });
  }

  getAssignedModuleByContractId(): void {
    this.adminContractService
    .getAssignedModulesByContractId(this.data.dataKey)
    .subscribe(
      (contractModule) => {
        this.assignedContractModule = contractModule;
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }
  public selectedAssignedModuleList: ContractModule[];

  selectModule(module: Module){
   const contractModule = new ContractModule();
   const selectedModuleList = this.selectedContractModuleList.filter(i=> i.moduleId === module.moduleId);
   if(selectedModuleList.length == 0){
   contractModule.moduleId = module.moduleId;
   contractModule.moduleName = module.moduleName;
   contractModule.isSelected = true;
   contractModule.isCurrentlySelected = true;
   this.selectedContractModuleList.push(contractModule);
   }else{
    this.selectedContractModuleList.filter(i=> i.moduleId === module.moduleId)[0].isSelected = true;
   }

   this.dialogRef.close(this.selectedContractModuleList);
   this.snackBar.successMessage(successMsgs.contract_assign_module);
  }

  setDisplayRows(module: Module[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(module);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.toLowerCase().trim();
     const assignedModule = this.contractModule.filter((module) => {
       return (
         (module.moduleId && module.moduleId.toString().toLowerCase().indexOf(filterValue) !== -1) ||
         (module.moduleName && module.moduleName.toLowerCase().indexOf(filterValue) !== -1)
       );
     });
     this.paginator.pageIndex = 0;
     this.setDisplayRows(assignedModule);
  }
}
