import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { of, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { fromMatPaginator, fromMatSort, paginateRows, sortRows } from 'src/app/shared/utils/utils';
import _ from 'lodash';
import { UserSession, B2cService } from 'cps-b2clibrary';
import { CustomErrorHandler } from 'src/app/shared/utils/custom-error-handler';
import { SecurityGroupForUserModule, SecurityGroupModule } from 'src/app/shared/models/security-group-for-user.model';
import { successMsgs } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-user-assign-security-group-dialog',
  templateUrl: './user-assign-security-group-dialog.component.html',
  styleUrls: ['./user-assign-security-group-dialog.component.scss'],
})
export class UserAssignSecuityGroupDialogComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private userSessionSubscription: Subscription;
  public userSession: UserSession = new UserSession()
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<SecurityGroupModule[]>;
  public totalRows$: Observable<number>;
  public sortedData = [];
  userId: number;s
  assignedSecGrpModules: SecurityGroupForUserModule[];
  unAssignedSecGrpModules: SecurityGroupForUserModule[] = [];
  securityGroups: SecurityGroupForUserModule[];
  selectSecurityGroupsList: SecurityGroupForUserModule[] = [];

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserAssignSecuityGroupDialogComponent>
  ) { }


  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.assignedSecGrpModules = this.data.assignedSecGrpModules;
    if (this.data.unAssignedSecGrpModules) {
      this.unAssignedSecGrpModules = this.data.unAssignedSecGrpModules;
    }
    this.userId = this.data.dataKey;
    if(this.data.assignedSecGrpModules.length==0 && this.data.unAssignedSecGrpModules.length==0)
    {
      this.getAssignedSecurityGroupForUser(this.userId);
    }
    this.getSecurityGroups();
  }

  getSecurityGroups(): void {
    this.appSecurityManagementService
      .getAllSecurityGroups()
      .subscribe(
        (objSecurityGrouplist) => {
          this.securityGroups = objSecurityGrouplist;
          if (this.assignedSecGrpModules) {
            this.assignedSecGrpModules.forEach(element => {
              const unAssigned = this.unAssignedSecGrpModules.filter(i => i.securityGroupId === element.securityGroupId);
              if (unAssigned) {
                const assignedSecurityGroup = new SecurityGroupForUserModule();
                assignedSecurityGroup.securityGroupId = element.securityGroupId;
                assignedSecurityGroup.securityGroupName = element.securityGroupName;
                assignedSecurityGroup.securityGroupDescription = element.securityGroupDescription;
                this.selectSecurityGroupsList.push(assignedSecurityGroup);
                this.securityGroups = this.securityGroups.filter(i => i.securityGroupId !== element.securityGroupId);
              }
            });
          }
          this.setDisplayRows(this.securityGroups);
          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.customErrorHandler.handleError(error);
        });
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  selectSecurityGroup(securityGroup: SecurityGroupForUserModule) {
    const objSecurityGroup = new SecurityGroupForUserModule();
    const selectedSecurityGroup = this.selectSecurityGroupsList.filter(i => i.securityGroupId === securityGroup.securityGroupId);
    if (selectedSecurityGroup.length == 0) {
      objSecurityGroup.securityGroupId = securityGroup.securityGroupId;
      objSecurityGroup.securityGroupName = securityGroup.securityGroupName;
      objSecurityGroup.securityGroupDescription = securityGroup.securityGroupDescription
      objSecurityGroup.isSelected = true;
      objSecurityGroup.isCurrentlySelected = true;
      this.selectSecurityGroupsList.push(objSecurityGroup);
    } else {
      this.selectSecurityGroupsList.filter(i => i.securityGroupId === securityGroup.securityGroupId)[0].isSelected = true;
    }
    this.dialogRef.close(this.selectSecurityGroupsList);
    this.snackBar.successMessage(successMsgs.securityGroup_assign);
  }

  getAssignedSecurityGroupForUser(userId: number): void {
    this.appSecurityManagementService.getSecurityGroupsForUser(userId)
    .subscribe(
     (assignedSecGrpModules) => {
     if (assignedSecGrpModules) {
       this.assignedSecGrpModules = assignedSecGrpModules;
     }
       this.assignedSecGrpModules.sort((a, b) => (a.securityGroupName > b.securityGroupName) ? 1 : -1);
     },
     (error: HttpErrorResponse) => {
       this.typeOfSpinner = 'none';
       this.customErrorHandler.handleError(error);
     });
 }

  setDisplayRows(securityGroupList: SecurityGroupForUserModule[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(securityGroupList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.toLowerCase().trim();
    const securityGroup = this.securityGroups.filter((securityGroup) => {
      return (
        (securityGroup.securityGroupName && securityGroup.securityGroupName.toLowerCase().indexOf(filterValue) !== -1)
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(securityGroup);
  }

}


