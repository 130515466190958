import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ValidateResponse } from 'src/app/shared/models/ValidateResponse';
import { environment } from 'src/environments/environment';
import { Organization, EntityDomain, Entity, RestrictedDomain } from '../models/organization';
import { ValidationRequest } from '../models/ValidationRequest';
@Injectable({
  providedIn: 'root'
})
export class SuperAdminOrgCreateService {
  private organizationData: Organization;

  constructor(private http: HttpClient) { }

  addOrganization(organization: Organization): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationLOB`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      organization
    };

    return this.http.post<number>(
      apiurl,
      organization
    );
  }

  GetDomainList(): Observable<Array<EntityDomain>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}EntityDomain`;
     const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<EntityDomain>>(apiurl, { headers }) //, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'super-admin-org-create.service.ts- GetSecurityGroupAppRole- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetRestrictedDomainList(): Observable<Array<RestrictedDomain>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}EntityDomain/GetRestrictedDomain`;
     const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<RestrictedDomain>>(apiurl, { headers }) //, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'add-organization.service.ts- GetRestrictedDomainList- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  GetCpsDomainList(): Observable<Array<any>> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}CpsDomain`;
     const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Array<any>>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'add-organization.service.ts- GetCpsDomainList- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  getOrganizationDetail(entityId: number): Observable<Organization> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationCustomer/${entityId}`;
     const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Organization>(apiurl, { headers }) //, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'super-admin-org-create.service.ts- getOrganizationDetail- ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  updateOrganization(entityId: number, organization: Organization): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationLOB/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      organization
    };

    return this.http.put<number>(
      apiurl,
      organization
    );
  }

  setOrganizationData(organization: Organization): void {
    this.organizationData = organization;
  }

  getOrganizationData(): Organization {
    return  this.organizationData;
  }

  getOrganizationName(entityName: string): Observable<Entity> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationLOB/${entityName}`;
     const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<Entity>(apiurl, { headers }) //, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'super-admin-org-create.service.ts- getOrganizationName - ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  validateOrganization(validationRequest:ValidationRequest): Observable<ValidateResponse> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationValidate`;
     const headers = {
      'Content-Type': 'application/json',
    };
    const params = new HttpParams()
    .set('EntityId', validationRequest.entityId)
    .set('EntityName', validationRequest.entityName)
    .set('SalesforceAccountId', validationRequest.salesforceAccountId);

    return this.http
      .get<ValidateResponse>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'super-admin-org-create.service.ts- getOrganization - ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => { JSON.stringify(data) })
      );
  }

  deleteOrganization(entityId: number): Observable<number> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationLOB/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.delete<number>(apiurl,{ headers })
    .pipe(
      catchError((err) => {
        return throwError(
          'super-admin-org-create.service.ts- deleteOrganization - ThrowingError To Caller...',
          err
        );
      }),
      tap((data) => { JSON.stringify(data) })
    );
  }

  validateOrgLOBDelete(entityId: number): Observable<boolean> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}OrganizationValidate/${entityId}`;
     const headers = {
      'Content-Type': 'application/json',
    };

    return this.http
      .get<boolean>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'super-admin-org-create.service.ts- validateOrgLOBDelete - ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => { JSON.stringify(data) })
      );
  }

}
