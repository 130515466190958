import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable, Subscription, of } from 'rxjs';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { Facility } from '../../shared/models/facility.model';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import _ from 'lodash';
import { OrgAdminFacility } from '../../shared/models/org-admin-facility.model';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { CossaService } from '../../shared/services/cossa.service';
import { OrganizationFacility } from '../../shared/models/organization-facility.model';
import { Organization } from 'src/app/shared/models/organization.model';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-organization-contract-dialog',
  templateUrl: './organization-contract-dialog.component.html',
  styleUrls: ['./organization-contract-dialog.component.scss']
})
export class OrganizationContractDialogComponent implements OnInit {public orgId: number;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<OrganizationFacility[]>;
  public totalRows$: Observable<number>;
  public appId: number;
  private organization: Organization[];
  public pageName: string;
  private unsubscribeList: any[] = [];
  public userSession: UserSession = new UserSession();
  private userSessionSubscription: Subscription;
  public selectedOrganizationEntityId = 0;
  public organizationFacilities: OrganizationFacility[];
  public selectedOrganizationName: string;
  private routeParamSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<OrganizationContractDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public rowData: FacilitiesDialogModel,
    private activatedRoute: ActivatedRoute,
    private customErrorHandler: CustomErrorHandler,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.orgId = this.data.dataKey;
    this.getOrganizationFacilities();
  }

  getOrganizationFacilities(): void {
    if (this.orgId !== 0) {
      this.appSecurityManagementService
        .getOrganizationFacilities(this.orgId.toString())
        .subscribe(
          (organizationFacilities) => {
            this.organizationFacilities = organizationFacilities;
            this.setDisplayRows(this.organizationFacilities);
            this.typeOfSpinner = 'none';
          },
          (error: HttpErrorResponse) => {
            this.typeOfSpinner = 'none';
            this.customErrorHandler.handleError(error);
          });
    }
  }

  setDisplayRows(organizationFacilitiesList: OrganizationFacility[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(organizationFacilitiesList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );

  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    const filteredFacilities = this.organizationFacilities.filter((facility) => {
      return (
        (facility.cpsEntityName && facility.cpsEntityName.toLowerCase().indexOf(filterValue) !==
          -1) ||
        (facility.city && facility.city
          .toLowerCase()
          .indexOf(filterValue) !== -1) ||
        (facility.state && facility.state.toLowerCase().indexOf(filterValue) !== -1) ||
        (facility.facilityDescription && facility.facilityDescription
          .toLowerCase()
          .indexOf(filterValue) !== -1)
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredFacilities);
  }
}

export class FacilitiesDialogModel {
  constructor(public title: string, public orgId: number) { }
}
