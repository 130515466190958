import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import _ from 'lodash';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { FacilityContract } from 'src/app/shared/models/facility-contract';
import { AssignedContractsService } from './services/assigned-contracts.service';

@Component({
  selector: 'app-admin-facility-assigned-contracts',
  templateUrl: './admin-facility-assigned-contracts.component.html',
  styleUrls: ['./admin-facility-assigned-contracts.component.scss']
})
export class AdminFacilityAssignedContractsComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() facilityIdInput: number;

  private userSessionSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<FacilityContract[]>;
  public totalRows$: Observable<number>;
  public sortedData = [];
  public facilityContractList: Array<FacilityContract> = [];
  displayedColumns: string[] = ['logo', 'contractID', 'contractName', 'startDate', 'endDate', 'serviceProvidedName', 'action'];

  constructor(
    private b2cService: B2cService,
    private assignedContractsService: AssignedContractsService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.getFacilityContractList(this.facilityIdInput);
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  getFacilityContractList(facilityId: number): void {
    this.assignedContractsService.getFacilityContracts(facilityId).subscribe(
      (response) => {
        this.facilityContractList = response;
        this.setDisplayRows(this.facilityContractList);
        this.typeOfSpinner='none';
      },
      (error: HttpErrorResponse) => {console.log(error); });
  }

  setDisplayRows(facilityContractList: FacilityContract[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(facilityContractList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    const filteredContracts = this.facilityContractList.filter((contract) => {
      return (
        (contract.contractID && contract.contractID.toString().indexOf(filterValue) !== -1) ||
        (contract.contractName && contract.contractName.toLowerCase().indexOf(filterValue) !==-1)||
        (contract.serviceProvidedName && contract.serviceProvidedName.toLowerCase().indexOf(filterValue) !==-1)
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredContracts);
  }

}
