import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { NewRelicService } from 'cps-menu';
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(@Inject(Injector) private readonly injector: Injector) {}

  private get newRelicService() {
    return this.injector.get(NewRelicService);
  }

  handleError(error) {
    this.newRelicService.handleError(error);
  }
}
