import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { successMsgs } from '../../shared/constants/constants';
import { errorMsgs } from '../../shared/constants/constants';

@Component({
  selector: 'app-organization-domain-list-dialog',
  templateUrl: './organization-domain-list-dialog.component.html',
  styleUrls: ['./organization-domain-list-dialog.component.scss']
})
export class OrganizationDomainListDialogComponent implements OnInit {

  title: string;
  message: string;
  domainName: string;
  entityId: number;

  constructor(private router: Router,
    private dialog: MatDialog,
    private snackBar: SnackBarModule,
    public dialogRef: MatDialogRef<OrganizationDomainListDialogComponent>,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    @Inject(MAT_DIALOG_DATA) public data: InformationDialogModel) {
      this.title = data.title;
      this.message = data.message;
      this.domainName = data.domainName;
     }

  ngOnInit(): void {
      this.message = this.message.replace(/,/g, ' ');
  }

  onConfirm(): void {
    this.dialogRef.close();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  showSnackBarMessageError(error: HttpErrorResponse): void {
    this.snackBar.errorMessage(error);
  }

  onDismiss(): void {
    this.dialogRef.close();
  }
}
export class InformationDialogModel {
  constructor(public title: string, public message: string, public domainName: string) { }
}
