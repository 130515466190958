import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { OrganizationFacility } from 'src/app/shared/models/organization-facility.model';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { fromMatPaginator, fromMatSort, paginateRows, sortRows } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-assign-org-facility',
  templateUrl: './assign-org-facility.component.html',
  styleUrls: ['./assign-org-facility.component.scss']
})
export class AssignOrgFacilityComponent implements OnInit {

  orgId: number;
  organizationFacilities: Array<OrganizationFacility> = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<OrganizationFacility[]>;
  public totalRows$: Observable<number>;

  @Input() organizationIdInput: number;
  @Input() orgNameInput : string;
  displayedColumns: string[] = ['cpsEntityName', 'facilityId', 'entityId', 'unitNumber', 'isActive', 'action'];
  routeParamSubscription: Subscription;

  constructor(private appSecurityManagementService: ApplicationSecurityManagementService
  ) { }

  ngOnInit() {
    this.getOrganizationFacilities();
  }

  getOrganizationFacilities(): void {
    if (this.organizationIdInput !== 0) {
      this.appSecurityManagementService
        .getOrganizationFacilities(this.organizationIdInput.toString())
        .subscribe({
         next: (organizationFacilities) => {
            this.organizationFacilities = organizationFacilities;
            this.setDisplayRows(this.organizationFacilities);
            this.typeOfSpinner = 'none';
          },
         error: (error: HttpErrorResponse) => {
            this.typeOfSpinner = 'none';
            // this.customErrorHandler.handleError(error);
          }
        });
    }
  }

  setDisplayRows(organizationFacilitiesList: OrganizationFacility[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(organizationFacilitiesList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );

  }

  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

  isAdmin(): boolean {
    return this.appSecurityManagementService.getAdminMode();
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    const filteredFacilities = this.organizationFacilities.filter((facility) => {
      return (
        (facility.cpsEntityName && facility.cpsEntityName.toLowerCase().indexOf(filterValue) !==
          -1) ||
        (facility.facilityId && facility.facilityId.toString().indexOf(filterValue) !== -1) ||
        (facility.entityId && facility.entityId.toString().indexOf(filterValue) !== -1) ||
        (facility.unitNumber && facility.unitNumber.toString().indexOf(filterValue) !== -1)
      );
    });
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredFacilities);
  }
}

