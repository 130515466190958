// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.padding-left-30 {
  padding-left: 30px;
}

.box {
  margin-top: 15px;
}

.page-name {
  color: #1f5488;
  font-weight: 500;
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content-sec-wrap {
  padding-left: 30px;
  padding-right: 30px;
  line-height: 20px;
  height: 800px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/overview/overview.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;AACF","sourcesContent":[".padding-left-30 {\r\n padding-left: 30px;\r\n}\r\n\r\n.box {\r\n  margin-top: 15px;\r\n}\r\n\r\n.page-name {\r\n  color: #1f5488;\r\n  font-weight: 500;\r\n  font-size: 18px;\r\n  padding-top: 20px;\r\n  padding-bottom: 20px;\r\n}\r\n\r\n.content-sec-wrap {\r\n  padding-left: 30px;\r\n  padding-right:30px;\r\n  line-height: 20px;\r\n  height: 800px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
