<footer class="newtheme">
  <p>Confidential And Proprietary: This work contains valuable confidential and proprietary information. Disclosure, use or reproduction without the written authorization of CPS Solutions is prohibited. All information transmitted to and from apps.RxResourceSolutions.com is encrypted via industry standard third party Secure Socket Layers.</p>
  <p>Copyright {{ year }} CPS Solutions, LLC.
    <span>All rights reserved. </span> <a (click)="RedirectUrl()" class="tandc" target="_blank" >Terms & Conditions</a>.
  </p>
  <div class="footer-logo">
    <svg width="61" height="34" viewBox="0 0 66 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.2739 31.7053C63.1936 31.7053 63.1178 31.6896 63.0464 31.6583C62.9773 31.627 62.9126 31.5845 62.8569 31.5264L62.9795 31.39C63.0241 31.4325 63.071 31.466 63.1223 31.4928C63.1735 31.5197 63.2293 31.5308 63.2895 31.5308C63.3453 31.5308 63.3899 31.5174 63.4233 31.4928C63.4545 31.466 63.4701 31.4325 63.4701 31.3877C63.4701 31.3385 63.4568 31.305 63.4322 31.2871C63.4055 31.2692 63.3676 31.2469 63.3118 31.2178L63.1379 31.1328C63.0821 31.106 63.0308 31.068 62.984 31.0188C62.9372 30.9696 62.9149 30.9025 62.9149 30.8153C62.9149 30.7192 62.9505 30.6409 63.0219 30.5805C63.0933 30.5202 63.1869 30.4889 63.3029 30.4889C63.372 30.4889 63.4367 30.5023 63.4991 30.5291C63.5616 30.5559 63.6129 30.5895 63.653 30.6297L63.546 30.7751C63.5058 30.746 63.4657 30.7192 63.4211 30.6968C63.3765 30.6744 63.3341 30.6633 63.2917 30.6633C63.236 30.6633 63.1914 30.6767 63.1624 30.7058C63.1334 30.7348 63.1178 30.7661 63.1178 30.8041C63.1178 30.8422 63.1312 30.8712 63.1579 30.8958C63.1847 30.9182 63.2226 30.9428 63.2717 30.9651L63.4412 31.0501C63.5125 31.0814 63.5683 31.1217 63.6106 31.1708C63.653 31.2178 63.6731 31.2871 63.6731 31.3721C63.6731 31.4168 63.6642 31.4593 63.6463 31.4973C63.6285 31.5376 63.6017 31.5733 63.566 31.6046C63.5304 31.6359 63.488 31.6628 63.4389 31.6807C63.3899 31.6985 63.3341 31.7075 63.2739 31.7075V31.7053ZM63.9005 31.6605V30.5135H64.1704L64.3443 30.9428L64.4469 31.2312H64.4625L64.5651 30.9428L64.7346 30.5135H65V31.6605H64.797V31.153L64.8238 30.7639H64.8082L64.5383 31.4817H64.3644L64.0946 30.7639H64.0789L64.1057 31.153V31.6605H63.8983H63.9005Z" fill="#041930"/>
      <path d="M51.4589 6.70203L48.7181 6.46054C48.6602 6.45607 48.6044 6.47619 48.5643 6.51868L45.6005 9.61558C45.4912 9.72961 45.5604 9.91967 45.7165 9.93756L55.2456 10.9684L53.9075 8.66079L51.4589 6.6998V6.70203Z" fill="url(#paint0_linear_0_4)"/>
      <path d="M51.3229 0.0588092L47.2352 4.32738C47.1259 4.44142 47.1951 4.63372 47.3534 4.64937L60.1496 5.8926L59.3423 1.88564L55.2434 0.324896L51.4768 0.000672551C51.4188 -0.0037995 51.3631 0.0163247 51.3229 0.0588092Z" fill="url(#paint1_linear_0_4)"/>
      <path d="M55.1408 0.315948C55.1408 0.315948 58.4971 1.97284 59.1215 6.05806L60.852 17.3522C60.8766 17.5088 61.0683 17.5691 61.1776 17.4551L65.3166 13.1329C65.359 13.0904 65.3768 13.03 65.3679 12.9696L63.4523 1.18353C63.4389 1.09632 63.3675 1.03148 63.2806 1.02477L55.1408 0.315948Z" fill="url(#paint2_linear_0_4)"/>
      <path d="M51.3318 6.69087L57.576 7.23869L58.9364 16.1112C58.9453 16.1694 58.9274 16.2298 58.8851 16.2722L55.9815 19.3065C55.8722 19.4206 55.6782 19.358 55.6559 19.1992L54.7238 12.2653C54.3335 9.3182 52.5405 7.51372 51.334 6.68863L51.3318 6.69087Z" fill="url(#paint3_linear_0_4)"/>
      <path d="M43.2456 21.3861C40.2483 20.8718 39.8447 20.2882 39.8447 19.7873C39.8447 19.188 40.3019 18.3898 42.9467 18.3898C44.122 18.3898 45.1746 18.7475 45.9283 19.1053C46.0532 19.1657 46.2049 19.1344 46.3008 19.0338L48.3636 16.8805C48.5108 16.7262 48.4773 16.4735 48.2922 16.3684C47.0233 15.635 45.1657 14.868 42.9467 14.868C38.4977 14.868 36.2409 17.4462 36.2409 19.6531C36.2409 23.6825 39.7912 24.3376 42.6434 24.8653C46.2695 25.5339 46.5416 26.0996 46.5416 26.8867C46.5416 28.3781 43.7897 28.4944 42.9467 28.4944C41.2318 28.4944 39.4567 27.4837 38.8456 27.1013C38.7141 27.0186 38.5446 27.041 38.4375 27.155L36.4171 29.277C36.2833 29.4179 36.2989 29.6459 36.4528 29.7644C37.2556 30.3794 39.6998 32.0206 42.9467 32.0206C47.3333 32.0206 50.0584 30.0551 50.0584 26.8889C50.0584 22.551 45.6273 21.7975 43.2478 21.395L43.2456 21.3861Z" fill="#001F3F"/>
      <path d="M8.57909 18.3607C9.85023 18.3607 11.0099 18.8302 11.8974 19.6061C11.9956 19.6911 12.1427 19.6889 12.2319 19.595L14.3327 17.3902C14.4263 17.2919 14.4219 17.1353 14.3215 17.0437C12.8006 15.6663 10.7869 14.8278 8.57686 14.8278C6.36686 14.8278 4.19254 15.7356 2.65156 17.2114C1.01914 18.7788 0 20.9858 0 23.432C0 25.8782 1.01914 28.0852 2.65602 29.6504C4.197 31.1262 6.28211 32.034 8.58132 32.034C10.8805 32.034 12.8051 31.1955 14.326 29.8181C14.4263 29.7286 14.4286 29.5699 14.3371 29.4715L12.2364 27.2668C12.1472 27.1729 12 27.1684 11.9019 27.2556C11.0143 28.0315 9.85246 28.5011 8.58355 28.5011C7.2299 28.5011 5.9989 27.9667 5.09126 27.0968C4.12786 26.1734 3.52575 24.8742 3.52575 23.432C3.52575 21.9898 4.12786 20.6906 5.09126 19.7671C5.9989 18.8973 7.22767 18.3629 8.58355 18.3629L8.57909 18.3607Z" fill="#001F3F"/>
      <path d="M26.2345 14.8412C24.165 14.7294 22.2427 15.36 20.704 16.4869V15.3309C20.704 15.2526 20.6415 15.19 20.5635 15.19H17.3232C17.2451 15.19 17.1827 15.2526 17.1827 15.3309V36.6022H20.7062V30.3749C22.1245 31.4169 23.8729 32.034 25.764 32.034C30.5364 32.034 34.41 28.1075 34.3431 23.3068C34.2806 18.8146 30.7103 15.0827 26.2368 14.8412H26.2345ZM25.7618 28.5011C22.9742 28.5011 20.704 26.227 20.704 23.4298C20.704 20.6325 22.9719 18.3585 25.7618 18.3585C28.5516 18.3585 30.8196 20.6325 30.8196 23.4298C30.8196 26.227 28.5516 28.5011 25.7618 28.5011Z" fill="#001F3F"/>
      <defs>
      <linearGradient id="paint0_linear_0_4" x1="48.1093" y1="10.7984" x2="55.3926" y2="6.86587" gradientUnits="userSpaceOnUse">
      <stop offset="0.11" stop-color="#0074D9"/>
      <stop offset="0.79" stop-color="#001F3F"/>
      </linearGradient>
      <linearGradient id="paint1_linear_0_4" x1="51.9072" y1="6.33757" x2="58.5592" y2="0.532536" gradientUnits="userSpaceOnUse">
      <stop offset="0.11" stop-color="#0074D9"/>
      <stop offset="0.79" stop-color="#001F3F"/>
      </linearGradient>
      <linearGradient id="paint2_linear_0_4" x1="65.5998" y1="13.7187" x2="58.1759" y2="4.19004" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0074D9"/>
      <stop offset="0.14" stop-color="#087ADB"/>
      <stop offset="0.37" stop-color="#1F8DE2"/>
      <stop offset="0.64" stop-color="#45ACED"/>
      <stop offset="0.97" stop-color="#79D6FD"/>
      <stop offset="1" stop-color="#7FDBFF"/>
      </linearGradient>
      <linearGradient id="paint3_linear_0_4" x1="60.2231" y1="17.8978" x2="52.8081" y2="8.3781" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0074D9"/>
      <stop offset="0.14" stop-color="#087ADB"/>
      <stop offset="0.37" stop-color="#1F8DE2"/>
      <stop offset="0.64" stop-color="#45ACED"/>
      <stop offset="0.97" stop-color="#79D6FD"/>
      <stop offset="1" stop-color="#7FDBFF"/>
      </linearGradient>
      </defs>
    </svg>
  </div>
</footer>
