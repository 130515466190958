import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { UserSecurityGroup } from '../security-group/models/user-security-group';

@Component({
  selector: 'app-approles-securitygroup-dialog',
  templateUrl: './approles-securitygroup-dialog.component.html',
  styleUrls: ['./approles-securitygroup-dialog.component.scss']
})
export class ApprolesSecuritygroupDialogComponent implements OnInit {
  private securityGroupsList: UserSecurityGroup[];
  public appRoleId: number;
  public appRoleName: string;
  dataSource: MatTableDataSource<UserSecurityGroup>;
  private routeParamSubscription: Subscription;


  constructor(
    public dialogRef: MatDialogRef<ApprolesSecuritygroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public rowData: SecurityGroupConfirmDialogModel,
    private activatedRoute: ActivatedRoute,
    private appSecurityManagementService: ApplicationSecurityManagementService
  ) {
    this.dataSource = new MatTableDataSource<UserSecurityGroup>(this.securityGroupsList);
  }

  ngOnInit() {
    this.appRoleId = this.rowData.appRoleId;
    this.appRoleName = this.rowData.appRoleName;

    this.routeParamSubscription = this.activatedRoute.params.subscribe((params) => {
       if (params.appRoleId) {
        this.appRoleId = params.appRoleId;
      }
    });
  }


  isAdminReadOnly(): boolean {
    return this.appSecurityManagementService.getAdminReadOnlyMode();
  }

}
export class SecurityGroupConfirmDialogModel {
  constructor(public title: string, public appRoleId: number, public appRoleName: string) { }
}
