// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-error-message {
  font-size: 20px;
  color: red;
  padding-left: 25px;
  font-weight: bold;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/no-organization/no-organization.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,UAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AACF","sourcesContent":[".show-error-message {\r\n  font-size: 20px;\r\n  color: red;\r\n  padding-left: 25px;\r\n  font-weight: bold;\r\n  margin-top: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
