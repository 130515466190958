import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, Subscription, of } from 'rxjs';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { ActivatedRoute, Router } from '@angular/router';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import _ from 'lodash';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { FacilityLOBList } from 'src/app/shared/models/facility-lob-list-model';
import { AdminFacilityService } from './services/admin-facility.service';
import { MatRadioChange } from '@angular/material/radio';
import { HttpErrorResponse } from '@angular/common/http';
import { MatRipple } from '@angular/material/core';
import { ChildFacilityLobComponent } from '../child-facility-lob/child-facility-lob.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-view-admin-facility-user-assignment',
  templateUrl: './view-admin-facility-user-assignment.component.html',
  styleUrls: ['./view-admin-facility-user-assignment.component.scss']
})
export class ViewAdminFacilityUserAssignmentComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<FacilityLOBList[]>;
  public totalRows$: Observable<number>;
  public pageName: string;
  private unsubscribeList: any[] = [];
  public userSession: UserSession = new UserSession();
  userSessionSubscription : Subscription;
  private adminFaciliitesList: FacilityLOBList[];
  selectedOption = true;
  filterValue = "";
  displayedColumns: string[] = ['expand', 'facilityName', 'lineOfBusinessType', 'serviceCategory', 'unitNumber', 'entityId',
  'startDate','endDate', 'action'];
  expandedElement: any;
  lastChild = 0;

  constructor(
    private customErrorHandler: CustomErrorHandler,
    private adminFacilityService: AdminFacilityService,
    private activatedRoute: ActivatedRoute,
    private b2cService: B2cService,
    private router: Router) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session;
      this.getAdminFacilities();});
    this.b2cService.getUserSesssion();
    this.pageName = !_.isNil(this.activatedRoute.snapshot.data.pageDetails) ? this.activatedRoute.snapshot.data.pageDetails.title : 'Facility User Assignments';
  }

  ngOnDestroy(): void {
    this.unsubscribeList.forEach((observable) => {
      observable.unsubscribe();
    });
  }

  getAdminFacilities(): void {
    this.adminFacilityService.getFacilityLOBListByOnPermId(this.userSession.onPremisesSecurityIdentifier).subscribe(
      (response) => {
        this.adminFaciliitesList = response;
        this.adminFaciliitesList = this.adminFaciliitesList.sort((a, b) => {
          return (a.facilityName && b.facilityName) ? (a.facilityName.trim().toLocaleLowerCase() < b.facilityName.trim().toLocaleLowerCase()) ? -1 : 1: null;
        });
        this.adminFaciliitesList.forEach(element => {
          element.isParentEntityString= String(element.isParentEntity);
        });
        this.getAndDisplayFilteredRecords();
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });

  }

  setDisplayRows(adminFacilitiesList: FacilityLOBList[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(adminFacilitiesList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );

  }

  applyFilter(filterValue: string): void {
    this.filterValue = filterValue.trim().toLowerCase();
    this.getAndDisplayFilteredRecords();
  }

  onConditionChange(conditionChange: MatRadioChange){
    this.getAndDisplayFilteredRecords();
  }

  getAndDisplayFilteredRecords(){
    this.typeOfSpinner = 'loading';
    let filteredRecords = this.adminFaciliitesList.filter(f => this.selectedOption == null || f.isActive === this.selectedOption);

    //appply search filter
    filteredRecords= filteredRecords.filter((facility) => {
      return (
        (facility.facilityName && facility.facilityName.toLowerCase().indexOf(this.filterValue) !== -1) ||
        (facility.lineOfBusinessType && facility.lineOfBusinessType.toLowerCase().indexOf(this.filterValue) !== -1) ||
        (facility.serviceCategory && facility.serviceCategory.toLowerCase().indexOf(this.filterValue) !== -1) ||
        (facility.entityId && facility.entityId.toString().indexOf(this.filterValue) !== -1) ||
        (facility.salesforceLineofBusiness && facility.salesforceLineofBusiness.toLowerCase().indexOf(this.filterValue) !==-1) ||
        (facility.unitNumber && facility.unitNumber.toLowerCase().indexOf(this.filterValue) !== -1) ||
        (facility.organization && facility.organization.toLowerCase().indexOf(this.filterValue) !== -1) ||
        (facility.salesforceLineofBusiness && facility.salesforceLineofBusiness.toLowerCase().indexOf(this.filterValue) !== -1)
      )});
    this.expandedElement = null;
    this.paginator.pageIndex = 0;
    this.setDisplayRows([]);
    setTimeout(()=>{
      this.setDisplayRows(filteredRecords);
      this.typeOfSpinner = 'none';   
    },1);      
  }

  onEdit(item): void{
    this.router.navigate(['/', item.facilityContractId ,'detailedadminfacilityuserassignment'], { queryParams:  {orgName: item.organization} });
  }

  showCaret(element: any){
    return (element.isParentEntity === true && this.selectedOption === null) || (element.isParentOfActiveChildEntity === true && this.selectedOption === true) || (element.isParentOfInActiveChildEntity === true && this.selectedOption === false)
  }

  onExpand(element: any){
    this.expandedElement = element;
  }

  onCollaspe(){
    this.expandedElement = undefined;
  }


  applyChildFilter(children: Array<FacilityLOBList>){
    children = this.selectedOption == null ? children : children.filter(p => p.isActive === this.selectedOption);
    this.lastChild = children[children.length - 1].facilityContractId;
    return children;
  }
      
}
