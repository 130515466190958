import { Component, OnInit, AfterViewInit, ViewChild, Input, OnDestroy } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {  B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { SnackBarModule } from '../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AppRoleService } from '../../shared/services/app-role.service';
import { SharedUserAppDetailService } from '../../shared/services/shared-user-app-detail.service';
import _ from 'lodash';
import { CossaService } from '../../shared/services/cossa.service';
import { Facility } from '../../shared/models/facility.model';
import { ApplicationsService } from '../../shared/services/applications.service';
import { UserSecurityFacility } from '../../shared/models/user-security-facility.model';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { FacilityAssignUserComponent } from '../../components/facility-assign-user/facility-assign-user.component';
import { OrgAdminFacility } from '../../shared/models/org-admin-facility.model';
import { Users } from '../../shared/models/users.model';
import { DeleteConfirmDialogModel } from 'src/app/components/facility-user-delete-dialog/facility-user-delete-dialog.component';
import { FacilityUserDeleteDialogComponent } from 'src/app/components/facility-user-delete-dialog/facility-user-delete-dialog.component';
import { OrganizationContract } from 'src/app/shared/models/organization-contract.model';
import { Organization } from 'src/app/shared/models/organization.model';

@Component({
  selector: 'app-organization-contract',
  templateUrl: './organization-contract.component.html',
  styleUrls: ['./organization-contract.component.scss']
})
export class OrganizationContractComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatSort, {static: false}) set content(sort: MatSort) {
        this.sort = sort;
  }
  @ViewChild(MatPaginator, {static: false}) set contents(paginator: MatPaginator) {
    this.paginator = paginator;
  }

  @Input() entityIdInput: number;

  private userId: string;
  private successMessage: string;
  private userSessionSubscription: Subscription;
  private routeParamSubscription: Subscription;
  public  userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedRows$: Observable<OrganizationContract[]>;
  public totalRows$: Observable<number>;
  public facilityDetail: Facility;
  public organizationContractInEntity: OrganizationContract[];
  public sortedData = [];
  public selectedOrganization: OrgAdminFacility;
  private organization: Organization[];
  public selectedOrganizations: Organization;
  entityId=0;
  displayedColumns: string[] = ['contractId', 'startDate', 'endDate', 'serviceProviderName', 'action'];

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private activatedRoute: ActivatedRoute,
    private appRoleService: AppRoleService,
    private sharedUserAppDetailService: SharedUserAppDetailService,
    private cossaService: CossaService,
    private applicationsService: ApplicationsService,
    public dialog: MatDialog,
    private b2cService: B2cService,
  ) {}

  addOrganizationContract() {
    const dialogRef = this.dialog.open(FacilityAssignUserComponent, {
      data: {
        dataKey: this.entityIdInput
      },
      backdropClass: 'lg_panel-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.selectedOrganization){
        this.getOrganizationContract(this.entityId);
       }
    });
  }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    this.getContract();
  }

  getContract(){
    this.organization = this.appSecurityManagementService.getOrganization();
    if(this.organization){

        this.organization.forEach(organization => {
            if (organization.selected === true) {
              this.selectedOrganizations = organization;
            }
          });

       if(this.selectedOrganizations) {
        if(this.selectedOrganizations.entityId != undefined && this.selectedOrganizations.entityId != null && this.entityId == 0){
          this.entityId = this.selectedOrganizations.entityId;
          this.getOrganizationContract(this.entityId);
        }
      }else{
         this.getContract();
      }
    }
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

getOrganizationContract(entityId:any): void {
    this.appSecurityManagementService.getOrganizationContract(entityId).subscribe(
    (organizationContract) => {
      this.organizationContractInEntity = organizationContract;
      if(!_.isNil(this.organizationContractInEntity)){
        this.setDisplayRows(this.organizationContractInEntity);
      }
      this.typeOfSpinner = 'none';
    },
    (error: HttpErrorResponse) => {
      this.typeOfSpinner = 'none';
      this.customErrorHandler.handleError(error);
    });
}

onDeleteClick(userId: number, entityId: number): void {
  this.deleteConfirmDialog(userId, entityId);
}

deleteConfirmDialog(userId: number, entityId: number): void {
  this.typeOfSpinner = 'loading';
  const message = 'Are you sure that you want to delete this user from the entity? This cannot be undone.';
  const dialogData = new DeleteConfirmDialogModel(
    'Delete User', message,  userId,  entityId, 0);
  const dialogRef = this.dialog.open(FacilityUserDeleteDialogComponent, {
    // panelClass: 'delete-assessment-modal',
    data: dialogData,
    backdropClass: 'sm_panel-dialog'
  }).afterClosed().subscribe(r => {
    this.typeOfSpinner = 'loading';
     if(this.selectedOrganization){
       this.getOrganizationContract(this.entityId);

      }
  });

}

setDisplayRows(userSecurityFacilityList: OrganizationContract[]): void {
  const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
  const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
  const rows$ = of(userSecurityFacilityList);
  this.totalRows$ = rows$.pipe(map((rows) => rows.length));
  this.displayedRows$ = rows$.pipe(
     sortRows(sortEvents$),
     paginateRows(pageEvents$)
  );
}

applyFilter(filterValue: string): void {
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  const filteredUserSecurityFacility = this.organizationContractInEntity.filter((userSecurityFacility) => {
    return (
      (userSecurityFacility.contractId && userSecurityFacility.contractId.toString().toLowerCase().indexOf(filterValue) !==
        -1) ||
      (userSecurityFacility.contractName && userSecurityFacility.contractName
        .toLowerCase()
        .indexOf(filterValue) !== -1) ||
        (userSecurityFacility.serviceProvidedID && userSecurityFacility.serviceProvidedID
          .toString()
          .indexOf(filterValue) !== -1)
       );
  });
  this.paginator.pageIndex = 0;
  this.setDisplayRows(filteredUserSecurityFacility);
}
}
