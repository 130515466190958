
export class ApplicationRoles{
    appId: number;
    appName: string;
    appRoleID: number;
    appRoleName: string;
    appRoleDescription: string;
    displayName: string;
    isSelected : boolean;
    hideForExtranet: boolean;
}
