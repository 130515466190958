import { Injectable } from '@angular/core';
import { SessionStorageModel } from '../models/session-storage.model';


@Injectable({
    providedIn: 'root'
})
export class SessionService {
    sessionStorgaeModel: SessionStorageModel = new SessionStorageModel();

    public setUserSession(value: any) {
        this.sessionStorgaeModel.userSession = value;
    }
    getUserSession(): any {
        return this.sessionStorgaeModel.userSession;
    }
    removeUserSession() {
        this.sessionStorgaeModel.userSession = [];
    }

    clear() {
        this.sessionStorgaeModel = new SessionStorageModel();
    }
}
