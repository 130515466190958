// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-table-wrap {
  padding: 20px 0;
}

.mat-column-facilityname,
.mat-column-role {
  min-width: 30%;
  max-width: 30%;
  width: 30%;
}

.mat-column-description {
  min-width: 40%;
  max-width: 40%;
  width: 40%;
}

.prev-next {
  width: 100%;
}

.prev-next .btn {
  min-width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/assign-application/assign-application.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;;EAEE,cAAA;EACA,cAAA;EACA,UAAA;AACF;;AAEA;EACE,cAAA;EACA,cAAA;EACA,UAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".mat-mdc-table-wrap {\r\n  padding: 20px 0;\r\n}\r\n\r\n.mat-column-facilityname,\r\n.mat-column-role {\r\n  min-width: 30%;\r\n  max-width: 30%;\r\n  width: 30%;;\r\n}\r\n\r\n.mat-column-description {\r\n  min-width: 40%;\r\n  max-width: 40%;\r\n  width: 40%;\r\n}\r\n\r\n.prev-next {\r\n  width: 100%;\r\n}\r\n\r\n.prev-next .btn {\r\n  min-width: 120px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
