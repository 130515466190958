import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VerifyUserComponent } from '../verify-user/verify-user.component';


@Component({
  selector: 'app-org-manager-home',
  templateUrl: './org-manager-home.component.html',
  styleUrls: ['./org-manager-home.component.scss']
})
export class OrgManagerHomeComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  userVerification() {
    const dialogRef = this.dialog.open(VerifyUserComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit(): void {
    const element: HTMLInputElement = document.querySelector('#roleMode') as HTMLInputElement;
    if(element !=null  && element.value !== 'OrganizationAdmin'){
      element.value = 'OrganizationAdmin';
      element.dispatchEvent(new Event('change'));
    }
  }


}
