import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { FacilityContract } from 'src/app/shared/models/facility-contract';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssignedContractsService {

  constructor(public httpClient : HttpClient) { }

  public getFacilityContracts(facilityid : number): Observable<Array<FacilityContract>> {
    return this.httpClient.get<Array<FacilityContract>>(`${environment.appConfig.apiServer.appSecService}facilityContract/${facilityid}`)
      .pipe(
        map((data) => {
          data.sort((a, b) => {
            return (a.contractName && b.contractName) ? (a.contractName.trim().toLocaleLowerCase() < b.contractName.trim().toLocaleLowerCase()) ? -1 : 1: null;
          });
          return data;
        })
      );
  }
}
