import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { B2cService,  UserSession } from "cps-b2clibrary";
import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subscription, Observable, of } from "rxjs";
import { SnackBarModule } from "../../shared/modules/snackbar/snack-bar.module";
import { ApplicationSecurityManagementService } from "../../shared/services/application-security-management.service";
import { ActivatedRoute} from "@angular/router";
import _ from "lodash";
import { Facility } from "../../shared/models/facility.model";
import {
  fromMatSort,
  fromMatPaginator,
  sortRows,
  paginateRows,
} from "src/app/shared/utils/utils";
import { map } from "rxjs";
import { CustomErrorHandler } from "../../shared/utils/custom-error-handler";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { errorMsgs  } from "../../shared/constants/constants";
import { Organization } from "src/app/shared/models/organization.model";
import { UserEntitySecurityGroup } from "src/app/shared/models/user-entity-security-group.model";
import { FacilityContractTeamMember } from "../../shared/models/facility-contract-team-member.models";
import { TeamMemberRole } from "../../shared/models/team-member-role.models";

@Component({
  selector: "app-facility-lob-assign-team-member",
  templateUrl: "./facility-lob-assign-team-member.component.html",
  styleUrls: ["./facility-lob-assign-team-member.component.scss"],
})
export class FacilityLOBAssignTeamMemberComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private userSessionSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = "loading";
  public displayedRows$: Observable<FacilityContractTeamMember[]>;
  public totalRows$: Observable<number>;
  public facilityDetail: Facility;
  public sortedData = [];
  public selectedOrganization: Organization;
  public userNotInFacilityContract: FacilityContractTeamMember[];
  public selectUserTeamMemberList: FacilityContractTeamMember[];
  public entityId: number;
  public isSuperAdminPage = false;
  teamMemberRoleData: TeamMemberRole[];
  UserEntitySecurityGroup: UserEntitySecurityGroup[];
  displayedColumns: string[] = ['logo', 'firstName', 'lastName', 'email', 'roleDisplay', 'action'];

  constructor(
    private snackBar: SnackBarModule,
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FacilityLOBAssignTeamMemberComponent>
  ) {}

  ngOnInit(): void {
    const pagename = this.activatedRoute.snapshot["_routerState"].url;
    this.isSuperAdminPage =
      pagename.indexOf("detailedadminfacility") !== -1 ? true : false;
    this.b2cService.userSession$.subscribe((session) => {
      this.userSession = session;
    });
    this.b2cService.getUserSesssion();
    this.typeOfSpinner = "none";
    this.getTeamMemberRoleDetails();
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  AssignUser(userUserEntitySecurityGroup: FacilityContractTeamMember) {
    if ((this.data.isEditMode == "0" && userUserEntitySecurityGroup.salesforceCustomTeamRole=="None") ||(this.data.editUser !== null && this.data.editUser.salesforceCustomTeamRole==userUserEntitySecurityGroup.salesforceCustomTeamRole)) {
      this.snackBar.errMessage(errorMsgs.team_member_role_map);
    }
    else{
    if (!this.selectUserTeamMemberList) {
      this.selectUserTeamMemberList = [];
    }
    let selectUserList = [];
    if (
      this.selectUserTeamMemberList &&
      this.selectUserTeamMemberList.length > 0
    ) {
      selectUserList = this.selectUserTeamMemberList.filter(
        (i) => i.userID === userUserEntitySecurityGroup.userID
      );
    }
    if (selectUserList.length == 0) {
      this.selectUserTeamMemberList.push(userUserEntitySecurityGroup);
    }
    this.dialogRef.close(this.selectUserTeamMemberList);
  }
}

  getAssignUsersForSelectedFacilityContract(entityId): void {
    this.appSecurityManagementService
      .getUserNotInFacilityContractTeamMember(entityId, this.data.orgId, this.data.isSaasFacility)
      .subscribe(
        (usersNotinEntity) => {
          this.userNotInFacilityContract = usersNotinEntity;
          if(this.data.selectedAssignedUser)
          {
            const selectedUser =  (this.data.selectedAssignedUser) ? this.data.selectedAssignedUser: null;
            this.userNotInFacilityContract=this.userNotInFacilityContract.filter(item => selectedUser.map(a => a.userID).indexOf(item.userID) === -1);
          }
          this.userNotInFacilityContract.forEach((element) => {
            if(element.isExternal) {
              element.teamMemberRoleData = this.teamMemberRoleData.filter(
                (i) => i.teamMemberRoleCode === 'DOP'
              );
            } else {
              element.teamMemberRoleData = this.teamMemberRoleData;
            }
            element.salesforceCustomTeamRole =
              element.teamMemberRoleData[0].teamMemberRoleCode;
          });
          this.setDisplayRows(this.userNotInFacilityContract);
          this.typeOfSpinner = "none";
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = "none";
          this.customErrorHandler.handleError(error);
        }
      );
  }

  setDisplayRows(userSecurityFacilityList: FacilityContractTeamMember[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(userSecurityFacilityList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.toLowerCase().trim();
    const filteredUserNotInFacility = this.userNotInFacilityContract.filter(
      (userNotInEntity) => {
        return (
          (userNotInEntity.firstName &&
            userNotInEntity.firstName.toLowerCase().indexOf(filterValue) !==
              -1) ||
          (userNotInEntity.lastName &&
            userNotInEntity.lastName.toLowerCase().indexOf(filterValue) !==
              -1) ||
          (userNotInEntity.userEmail &&
            userNotInEntity.userEmail.toLowerCase().indexOf(filterValue) !== -1) ||
            (userNotInEntity.firstName && userNotInEntity.lastName && (userNotInEntity.firstName + ' ' + userNotInEntity.lastName).toLowerCase().indexOf(filterValue) !==
            -1)
        );
      }
    );
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredUserNotInFacility);
  }

  getTeamMemberRoleDetails(): void {
    this.appSecurityManagementService
      .getTeamMemberRoleDetails()
      .subscribe((teamMemberRoles: TeamMemberRole[]) => {
        let result=teamMemberRoles;
        const teamMemberData = {} as TeamMemberRole;
        this.teamMemberRoleData = [] as TeamMemberRole[];
        teamMemberData.teamMemberRoleId = 0;
        teamMemberData.teamMemberRoleCode = "None";
        teamMemberData.teamMemberRoleDescription = "None";
        this.teamMemberRoleData.push(teamMemberData);
        if(this.data.selectedAssignedUser)
        {
          const selectedUser =  (this.data.selectedAssignedUser) ? this.data.selectedAssignedUser: null;
          if (this.data.editUser === null){
            result=result.filter(item => selectedUser.map(a => a.salesforceCustomTeamRole).indexOf(item.teamMemberRoleCode) === -1);
          } else {
            const nonAssignedTeamMemberRoles=result.filter(item => selectedUser.map(a => a.salesforceCustomTeamRole).indexOf(item.teamMemberRoleCode) === -1)
            const assignedTeamMemberRoles=result.filter(item => selectedUser.map(a => a.salesforceCustomTeamRole).indexOf(item.teamMemberRoleCode) !== -1
             && item.teamMemberRoleCode === this.data.editUser.salesforceCustomTeamRole);
            result=nonAssignedTeamMemberRoles;
            result=result.concat(assignedTeamMemberRoles).sort((a,b) => a.teamMemberRoleCode.localeCompare(b.teamMemberRoleCode));
          }
        }
        result.forEach((element) => {
          this.teamMemberRoleData.push(element);
        });

        if (this.data.editUser === null) {
          this.getAssignUsersForSelectedFacilityContract(this.data.dataKey);
        } else {
          const editItemData = {} as FacilityContractTeamMember;
          editItemData.facilityContractTeamMemberID = this.data.editUser.facilityContractTeamMemberID;
          editItemData.userID = this.data.editUser.userID;
          editItemData.firstName = this.data.editUser.firstName;
          editItemData.lastName = this.data.editUser.lastName;
          editItemData.userEmail = this.data.editUser.userEmail;
          if(this.data.editUser.isExternal) {
            editItemData.teamMemberRoleData = this.teamMemberRoleData.filter(
              (i) => i.teamMemberRoleCode === 'None'
            );
          } else {
            editItemData.teamMemberRoleData = this.teamMemberRoleData;
          }
          editItemData.isOrgUser = this.data.editUser.isOrgUser;
          editItemData.salesforceCustomTeamRole =
            this.data.editUser.salesforceCustomTeamRole;
          this.userNotInFacilityContract = [] as FacilityContractTeamMember[];
          this.userNotInFacilityContract.push(editItemData);
          this.setDisplayRows(this.userNotInFacilityContract);
          this.typeOfSpinner = "none";
        }
      });
  }
}
