import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ModuleWithProviders, Inject } from '@angular/core';
import { MenuComponent } from './menu/menu.component';
import { MaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { FooterComponent } from './footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TreeViewHighlightPipe } from './pipes/treeview.highlight.pipe';
import { MatTreeModule } from '@angular/material/tree';
import { HeaderComponent } from './header/header.component';
import { SnackBarModule } from './modules/snackbar/snack-bar.module';
import { CustomErrorHandler } from './modules/custom-error-handler/custom-error-handler';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { NewRelicService } from './services/new-relic.service';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    MenuComponent,
    FooterComponent,
    HeaderComponent,
    TermsAndConditionsComponent,
    TreeViewHighlightPipe
  ],
  imports: [
    MaterialModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatTreeModule,
    SnackBarModule,
    CustomErrorHandler,
    MatSlideToggleModule
  ],
  exports: [
    MenuComponent,
    FooterComponent,
    HeaderComponent,
    TermsAndConditionsComponent,
    MatTreeModule,
    TreeViewHighlightPipe,
    MatSlideToggleModule
  ],
  providers: [MediaMatcher, NewRelicService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MenuModule {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static forRoot(environment: any): ModuleWithProviders<MenuModule> {
    return {
        ngModule: MenuModule,
        providers: [
          MediaMatcher,
            {
                provide: 'environment',
                useValue: environment
            }
        ]
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(@Inject('environment') private config:any) {
    if (!this.config) {
      this.config = {
        environment: {},
      };
    }
  }
}
