import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvgIconService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private httpClient: HttpClient
  ) {}

  public loadIcons(icon: string, iconLabel: string, iconUrl: string): void {
    const svgIconURL = `${iconUrl}/${icon}`;
    if (this.fileExists(svgIconURL)) {
      this.matIconRegistry.addSvgIcon(
        iconLabel,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${icon}`)
      );
    } else {
      this.matIconRegistry.addSvgIcon(
        iconLabel,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${iconUrl}/default.svg`
        )
      );
    }
  }

  private fileExists(url: string): Observable<boolean> {
    return this.httpClient.get(url).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

}
