import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatInput } from "@angular/material/input";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatRadioChange } from "@angular/material/radio";
import { MatSort, Sort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";
import { UserSession } from "cps-b2clibrary";
import { of } from "rxjs";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs";
import { FacilityContract } from "src/app/shared/models/facility-contract";
import { Organization } from "src/app/shared/models/organization.model";
import { ApplicationSecurityManagementService } from "src/app/shared/services/application-security-management.service";
import { ManageContactService } from "src/app/shared/services/manage-contact.service";
import { CustomErrorHandler } from "src/app/shared/utils/custom-error-handler";
import {
  fromMatPaginator,
  fromMatSort,
  paginateRows,
  sortRows,
} from "src/app/shared/utils/utils";

@Component({
  selector: "app-contact-details-dialog",
  templateUrl: "./contact-details-dialog.component.html",
  styleUrls: ["./contact-details-dialog.component.scss"],
})
export class ContactDetailsDialogComponent implements OnInit {
  public customerId: number;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public typeOfSpinner = "loading";
  public displayedRows$: Observable<FacilityContract[]>;
  public totalRows$: Observable<number>;
  public appId: number;
  private organization: Organization[];
  public pageName: string;
  private unsubscribeList: any[] = [];
  public userSession: UserSession = new UserSession();
  private userSessionSubscription: Subscription;
  public selectedOrganizationEntityId = 0;
  public organizationContractList: FacilityContract[];
  public selectedOrganizationName: string;
  selectedOption = true;
  @ViewChild(MatInput) filterInput: MatInput;
  displayedColumns: string[] = ['contractName', 'contractId', 'serviceProviderName', 'startDate', 'endDate', 'action'];

  constructor(
    public dialogRef: MatDialogRef<ContactDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public rowData: ContractDialogModel,
    private activatedRoute: ActivatedRoute,
    private customErrorHandler: CustomErrorHandler,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private manageContractServcie: ManageContactService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.customerId = this.data.dataKey;
    this.getOrganizationContracts();
  }

  getOrganizationContracts(): void {
    if (this.customerId !== 0) {
      this.manageContractServcie
        .getOrganizationContracts(this.customerId)
        .subscribe(
          (organizationContractL) => {
            this.organizationContractList = organizationContractL;
            this.getFilteredContracts(true);
            this.typeOfSpinner = "none";
          },
          (error: HttpErrorResponse) => {
            this.typeOfSpinner = "none";
            this.customErrorHandler.handleError(error);
          }
        );
    }
  }

  setDisplayRows(organizationFacilitiesList: FacilityContract[]): void {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(organizationFacilitiesList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    const filteredContract = this.organizationContractList.filter(
      (contract) => {
        return (
          ((contract.contractName &&
            contract.contractName.toLowerCase().indexOf(filterValue) !== -1) ||
          (contract.startDate &&
            contract.startDate.toLowerCase().indexOf(filterValue) !== -1) ||
          (contract.endDate &&
            contract.endDate.toLowerCase().indexOf(filterValue) !== -1))
          && (this.selectedOption !=null ? contract.isActive == this.selectedOption: true)
        );
      }
    );
    this.paginator.pageIndex = 0;
    this.setDisplayRows(filteredContract);
  }
  closeDiaglog(): void {
    this.dialogRef.close();
  }

  onConditionChange(conditionChange: MatRadioChange) {
    this.selectedOption = conditionChange.value;
    this.getFilteredContracts(conditionChange.value);
  }

  getFilteredContracts(condition?: boolean) {
    this.applyFilter(this.filterInput?.value ?? "");
  }
}

export class ContractDialogModel {
  constructor(public title: string, public customerId: number) {}
}
