import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, of } from 'rxjs';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { SnackBarModule } from '../../../shared/modules/snackbar/snack-bar.module';
import { ApplicationSecurityManagementService } from '../../../shared/services/application-security-management.service';
import {  Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { fromMatSort, sortRows } from 'src/app/shared/utils/utils';
import { fromMatPaginator, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { Users } from '../../../shared/models/users.model';
import { OrganizationUser } from '../../../shared/models/organization-user.model';
import { Organization } from '../../../shared/models/organization.model';
import { roleNames, UserFilter } from '../../../shared/constants/constants';
import { GraphiteRxVendor } from 'src/app/graphiterx/models/graphiteRxVendor.model';
import { GraphiteRxService } from 'src/app/graphiterx/service/graphiterx.sercive';
import { PurchasingVendor } from 'src/app/graphiterx/models/purchasing-Vendor.model';
import { DisplayMessage,ActionMessage } from '../../components/message';
import { ConfirmationDialogComponent, ConfirmDialogModel } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';

const emailAddressValidatedDefaultDate = '1900-01-01T00:00:00';

@Component({
  selector: 'app-grxvendorreconcil-dialog',
  templateUrl: './grxvendorreconcil-dialog.component.html',
  styleUrls: ['./grxvendorreconcil-dialog.component.scss']
})
export class GrxvendorreconcilDialogComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public typeOfSpinner = 'loading';
  public flag:any;
  public displayedRows$: Observable<PurchasingVendor[]>;
  public totalRows$: Observable<number>;
  public entityId: number;
  public pageName: string;
  public userSession: UserSession = new UserSession();
  public allUsers: Users[];
  public selectedOrganization: Organization;
  public distinctAllUser: OrganizationUser[];
  public selectedRole: string;
  public roles: { admin: string; security_manager?: string; organization_admin?: string; };
  selectedCondition: string;
  public inputDialogData:GraphiteRxVendor;
  public correspondingCPSVendors:PurchasingVendor[];

  constructor(private snackBar: SnackBarModule,
    public dialogRef: MatDialogRef<GrxvendorreconcilDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GraphiteRxVendor,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    private b2cService: B2cService,
    private router: Router,
    public dialog: MatDialog,
    private graphiteRxService:GraphiteRxService) { this.selectedCondition = UserFilter.active,
      this.flag=0;
      this.inputDialogData=data;
    }

    ngOnInit(): void {
      this.getCPSVendorsList(this.inputDialogData.vendorName);
      this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
      this.b2cService.getUserSesssion();
      this.selectedRole = this.getRoleMode();
      this.roles = roleNames;
      }

    getRoleMode(): string {
      return this.appSecurityManagementService.getRoleMode();
    }

    getUser(): void {
      this.appSecurityManagementService.getUser().subscribe(
        (allUsersObject) => {
          this.allUsers = allUsersObject.filter(({ isDisabled }) => isDisabled != true);
          this.distinctAllUser = [];
          const map = new Map();
          for (const item of this.allUsers) {
            if (!map.has(item.userId)) {
              map.set(item.userId, true);
              this.distinctAllUser.push({
                userId: item.userId,
                firstName: item.firstName,
                lastName: item.lastName,
                firstNameLastName: item.firstNameLastName,
                email: item.email,
                jobTitle: item.jobTitle,
                isOrgUser: item.isOrgUser,
                securityGroupName: item.securityGroupName,
                assignedEntityName: item.assignedEntityName,
                emailAddressValidatedDate: item.emailAddressValidatedDate,
                isValidUser: item.emailAddressValidatedDate != null && item.emailAddressValidatedDate.toString() !== emailAddressValidatedDefaultDate ? true : false,
                terminationDate: item.terminationDate,
                isExternal: item.isExternal
              });
            }
          }

          this.distinctAllUser.forEach(user => {
            if (user.assignedEntityName === null) {
              user.assignedEntityName = 'UNASSIGNED';
            }
          });

          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.showSnackBarMessageError(error);
        }
      );

    }

    showSnackBarMessageError(error: HttpErrorResponse): void {
      this.snackBar.errorMessage(error);
    }

    setDisplayRows(userList: PurchasingVendor[]): void {
      const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
      const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
      const rows$ = of(userList);
      this.totalRows$ = rows$.pipe(map((rows) => rows.length));
      this.displayedRows$ = rows$.pipe(
        sortRows(sortEvents$),
        paginateRows(pageEvents$)
      );
    }

    getCPSVendorsList(name:string):void{
      this.graphiteRxService
      .GetCPSVendorDetails(name)
      .subscribe(
        (response) => {
          this.correspondingCPSVendors = response;
          this.setDisplayRows(this.correspondingCPSVendors);
          this.typeOfSpinner = 'none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.showSnackBarMessageError(error);
         }
      );
    }

    findEvent(filterValue: PurchasingVendor)
    {
      this.updateBusinessUserConfirmDialog(this.inputDialogData.vendorName,this.inputDialogData.vendorId,filterValue.purchasingVendorId.toString(),filterValue.name.toString());
    }


    updateCPSVendorIDInGrxVendorXref(grxVendorID:string,cpsVendorID:string):void{
      this.graphiteRxService
      .UpdateCPSPurchasingVendorIDInGrxVendorXref(grxVendorID,cpsVendorID)
      .subscribe(
        (response) => {
          this.correspondingCPSVendors = response;
          if (response !== null) {
            this.closeDialog();
            this.snackBar.successMessage(DisplayMessage.updateMessage, ActionMessage.okAction);
            this.router.navigate(['graphiterx']);
          } else {
            this.snackBar.successMessage(DisplayMessage.failureMessage, ActionMessage.okAction);
          }
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner = 'none';
          this.showSnackBarMessageError(error);
        }
      );
    }
    closeDialog() {
                this.dialogRef.close({event:'true'});
        }

        updateBusinessUserConfirmDialog(grxVendorName:string,grxVendorID: string, cpsVendorID: string, cpsVendorName:string): void {
          const message = `CPS Vendor Name :<b>` + cpsVendorName +`</b></br> CPS Vendor ID : <b>`+ cpsVendorID +
          `</b><br/> Grx Vendor Name : <b>`+ grxVendorName +`</b></br> Grx Vendor ID : `+`<b>`+ grxVendorID +
          `</b><br/><br/><br/> Are you sure you want to assign CPS Purchasing VendorID  <b> ` + cpsVendorID  + `</b>  to GraphiteRx Vendor  <b>` + grxVendorName + ' </b> from CPS Vendors list?';

          const dialogData = new ConfirmDialogModel('Assign Vendor', message);
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            // width: '450px',
            data: dialogData,
            backdropClass: 'sm_panel-dialog'
          }).afterClosed().subscribe(response=>{
           if(response){
            this.updateCPSVendorIDInGrxVendorXref(grxVendorID,cpsVendorID.toString());
           }
          });
        }
}
