import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FacilityLOB } from 'src/app/shared/models/facility-lob.model';
import { ValidateResponse } from 'src/app/shared/models/ValidateResponse';
import { ValidateDuplicateFacilityLOBRequest } from 'src/app/shared/models/validate-duplicate-facility-lob.model';
import { FacilityLOBDropdowns } from 'src/app/shared/models/facility-lob-dropdowns.model';

@Injectable({
  providedIn: 'root'
})
export class FacilityLOBService {

  constructor(private http: HttpClient) { }
  

  post(facility: FacilityLOB): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}facilityLOB`;

    return this.http.post<string>(
      apiurl,
      facility
    );
  }

  put(facilityId: number, facility: FacilityLOB): Observable<string> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}facilityLOB/${facilityId}`;    
    return this.http.put<string>(
      apiurl,
      facility
    );
  }

  
  deleteFacilityContract(facilityContractId: number): any {
    const apiurl = `${environment.appConfig.apiServer.appSecService}facilityLOB/${facilityContractId}`;
    return this.http.delete<number>(
      apiurl
    );
  }

  getFacilityLOBDetails(faciityContractId: number): Observable<any> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}facilityLOB/${faciityContractId}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<any>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getFacilityLOBDetails- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  getFacilityLOBDropdowns(): Observable<FacilityLOBDropdowns> {
    const apiUrl = `${environment.appConfig.apiServer.appSecService}FacilityLOBDropdown`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<any>(apiUrl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(() => {'application-security-management.service.service.ts- getFacilityLOBDropdowns- ThrowingError To Caller...'+err}
          );
        }),
        tap((data) => JSON.stringify(data))
      );
  }

  isFacilityLOBExistsAsync(validationRequest:ValidateDuplicateFacilityLOBRequest): Observable<ValidateResponse> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}FacilityLOBValidate`;
    const headers = {
      'Content-Type': 'application/json',
      };

    const params = {
      lineOfBusinessId: validationRequest.lineOfBusinessId,
      facilityName: validationRequest.facilityName,
      serviceCategoryId: validationRequest.serviceCategoryId,
      serviceSubCategoryId: validationRequest.serviceSubCategoryId,
      serviceProvidedId: validationRequest.serviceProvidedId,
      facilityContractId: validationRequest.facilityContractId,
      endDate:  (validationRequest.endDate == null? '': new Date(validationRequest.endDate).toISOString())
    };
    
    return this.http
      .get<ValidateResponse>(apiurl, { headers, params })
      .pipe(
        catchError((err) => {
          return throwError(
            'validateDuplicateFacility - ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }

  allowDeleteFacilityLOBAsync(entityId:number): Observable<ValidateResponse> {
    const apiurl = `${environment.appConfig.apiServer.appSecService}FacilityLOBValidate/${entityId}`;
    const headers = {
      'Content-Type': 'application/json',
      };

    
    return this.http
      .get<ValidateResponse>(apiurl, { headers })
      .pipe(
        catchError((err) => {
          return throwError(
            'allowDeleteFacilityLOBAsync - ThrowingError To Caller...',
            err
          );
        }),
        tap((data) => {
          const s = JSON.stringify(data);
          return s;
        })
      );
  }
}
