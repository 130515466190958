import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { B2cService, UserSession } from 'cps-b2clibrary';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, Observable, of } from 'rxjs';
import { ApplicationSecurityManagementService } from '../../shared/services/application-security-management.service';
import _ from 'lodash';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { map } from 'rxjs';
import { CustomErrorHandler } from '../../shared/utils/custom-error-handler';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Users } from '../../shared/models/users.model';
import { AppUserRolesList } from 'src/app/shared/models/app-user-role.model';


@Component({
  selector: 'app-app-user-role-assign',
  templateUrl: './app-user-role-assign.component.html',
  styleUrls: ['./app-user-role-assign.component.scss']
})


export class AppUserRoleAssignComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public assignSelectedUser: AppUserRolesList;

  private userId: string;
  private successMessage: string;
  private userSessionSubscription: Subscription;
  private routeParamSubscription: Subscription;
  public userSession: UserSession = new UserSession();
  public typeOfSpinner = 'loading';
  public displayedAppUserRolesRows$: Observable<AppUserRolesList[]>;
  public totalRows$: Observable<number>;
  public sortedData = [];
  public allUsers: Users[];
  public distinctAllUser: AppUserRolesList[];
  public appUserRoles: AppUserRolesList[];
  public appId: number;
  public userRoleIdDefault:number;
  public userRoleNameDefault = '';
  displayedColumns: string[] = ['userId', 'firstName', 'lastName', 'userRole', 'action'];

  constructor(
    private customErrorHandler: CustomErrorHandler,
    private b2cService: B2cService,
    private appSecurityManagementService: ApplicationSecurityManagementService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AppUserRoleAssignComponent>
  ) { }

  ngOnInit(): void {
    this.b2cService.userSession$.subscribe(session => { this.userSession = session; });
    this.b2cService.getUserSesssion();
    if (this.data.appId){

      this.appId = this.data.appId;

      this.getUserRoles();
      this.getUser();
   }

  }

  getUserRoles(): void {
    this.appSecurityManagementService.getUserRolesList().subscribe(
      (userAppRoles) => {
        this.appUserRoles = userAppRoles;
        this.userRoleNameDefault = userAppRoles[0].userRoleName.toString();
        this.userRoleIdDefault = userAppRoles[0].userRoleId;
        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      });
  }

  getUser() {
    this.appSecurityManagementService.getUser().subscribe(
      (allUsersObject) => {
        this.allUsers = allUsersObject.filter(({ isDisabled }) => isDisabled != true);
        this.distinctAllUser = [];
        const map = new Map();
        for (const item of this.allUsers) {
          this.typeOfSpinner = 'loading';
          if (!map.has(item.userId)) {
            map.set(item.userId, true);    // set value to Map
            this.distinctAllUser.push({
              userId: item.userId,
              firstName: item.firstName,
              lastName: item.lastName,
              userRoleId:  this.userRoleIdDefault,
              userRoleName:  this.userRoleNameDefault,
              appUserRoleId: 0
            });
          }
          this.typeOfSpinner = 'none';
        }

        this.typeOfSpinner = 'none';
      },
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'none';
        this.customErrorHandler.handleError(error);
      }
    );

  }

  selectUserRole(value: any, item: any) {

    const selectedUserRole = this.appUserRoles.filter((userRole) => {
      return (
        (userRole.userRoleId && userRole.userRoleId.toString() == value)
      );
    });

    item.userRoleId = value;
    if(selectedUserRole.length > 0) {
     item.userRoleName = selectedUserRole[0].userRoleName;
    }

  }

  ngOnDestroy(): void {
    if (!_.isNil(this.userSessionSubscription)) {
      this.userSessionSubscription.unsubscribe();
    }
  }

  assignUserRole(item: AppUserRolesList): void {
      this.dialogRef.close( item);
  }

  setDisplayRows(appUserRolesList: AppUserRolesList[]): void {
    this.typeOfSpinner = 'loading';
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(appUserRolesList);
    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedAppUserRolesRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );
    this.typeOfSpinner = 'none';
  }


  applyAppUserRoleFilter(filterValue: string): void {
    this.typeOfSpinner = 'loading';
    filterValue = filterValue.toLowerCase().trim();
    if(filterValue == "") {
      const filteredUser: AppUserRolesList[] = [];
      this.setDisplayRows(filteredUser);
      return;
    }

    if( this.distinctAllUser != undefined) {
      const filteredUser = this.distinctAllUser.filter((user) => {
        return (
          (user.firstName && user.firstName.toLowerCase().indexOf(filterValue) !== -1) ||
          (user.lastName && user.lastName.toLowerCase().indexOf(filterValue) !== -1) ||
          (user.userId && user.userId.toString().indexOf(filterValue) !== -1)
        );
      });
      this.paginator.pageIndex = 0;
      this.setDisplayRows(filteredUser);
    }
    this.typeOfSpinner = 'none';
  }
}
