import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ProgressSpinnerService {

  typeOfSpinner = 'loading';
  public progressSpinnerBehaviorSubject = new BehaviorSubject(this.typeOfSpinner);
  progressSpinnerObservable = this.progressSpinnerBehaviorSubject.asObservable();

}
