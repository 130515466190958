import { Injectable } from '@angular/core';
import {
    RoleUser, ReportNameOptimizerUsers, RoleReportAdmin, RoleReportViewer, RoleCanExport
  } from '../../reports/constants/constants';
import { Reports } from '../models/report.model';
import { UserSession, B2cService } from 'cps-b2clibrary';
import { combineLatest } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserPermissionsService {
    userSession: UserSession = new UserSession();

    constructor (private b2cService: B2cService) {
      combineLatest([this.b2cService.userSession$, this.b2cService.userAppRoles$, this.b2cService.userUserApps$, this.b2cService.userOrganization$])
      .subscribe(([userSession, userAppRoles, userApps, userOrganizations]) => {
        this.userSession = userSession;
        this.userSession.userAppRoles = userAppRoles;
        this.userSession.userApps = userApps;
        this.userSession.userOrganizations = userOrganizations;
       });
        this.b2cService.getUserSesssion();
    }


    applyReportAccessRole(selectedReport: Reports): boolean {
        let isAllowedRole = false;

        switch (selectedReport.reportName) {
            case ReportNameOptimizerUsers:
                isAllowedRole = this.userAppRolesAllowed([RoleUser, RoleReportAdmin, RoleReportViewer]);
                break;

        }

        return isAllowedRole;
    }

    applyReportCanExport(selectedReport: Reports): boolean {
        let canExport = false;

        switch (selectedReport.reportName) {
            case ReportNameOptimizerUsers:
              canExport = this.userAppRolesAllowed([RoleCanExport]);
              break;
          }

        return canExport;
    }

    userAppRolesAllowed(allowedRoles: string[]): boolean {
      let isAllowedRole = false;
      if (this.userSession.userAppRoles != null && this.userSession.userAppRoles.length > 0) {
        const userAppRoles = this.userSession.userAppRoles;
        if (userAppRoles !== null) {
          userAppRoles.some((role: any) => {
            if (allowedRoles !== undefined && allowedRoles.length > 0) {
              isAllowedRole = allowedRoles.some(
                (allowedRole) => role["appRoleName"].toLowerCase() === allowedRole.toLowerCase()
              );
              return isAllowedRole;
            }
          });
        } else {
          return isAllowedRole;
        }
      }
      return isAllowedRole;
    }
}
