import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSecurityManagementService } from 'src/app/shared/services/application-security-management.service';
import { EntityComposite } from 'src/app/shared/models/entity-composite.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { of } from 'rxjs';
import { fromMatSort, fromMatPaginator, sortRows, paginateRows } from 'src/app/shared/utils/utils';
import { errorMsgs, successMsgs } from 'src/app/shared/constants/constants';
import { SnackBarModule } from 'src/app/shared/modules/snackbar/snack-bar.module';
import { UserUnassignEntityDialogComponent } from '../user-unassign-entity-dialog/user-unassign-entity-dialog.component';


@Component({
  selector: 'app-child-entity',
  templateUrl: './child-entity.component.html',
  styleUrls: ['./child-entity.component.scss']
})
export class ChildEntityComponent implements OnInit {
  displayedColumns: string[] = ['expand','entityId', 'cpsEntityName', 'entityTypeName', 'unitNumber', 'parentEntityName', 'isActive', 'action'];

  constructor(private appSecurityManagementService: ApplicationSecurityManagementService,
    private snackBar: SnackBarModule,public dialogRef: MatDialogRef<UserUnassignEntityDialogComponent>) { }

  @Input() assignedEntityIdInput: number;
  @Input() userIdInput: any;
  userAssignFaclitiesData: EntityComposite[] = [];

  public displayedRows$: Observable<EntityComposite[]>;
  public totalRows$: Observable<number>;

  dataSource = new MatTableDataSource<EntityComposite>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  typeOfSpinner: string;

  ngOnInit() {
    if (!_.isNil(this.assignedEntityIdInput))
    {
      this.typeOfSpinner ='loading';
      this.getUserAssignFaclities(this.assignedEntityIdInput);
    }

  }


  getUserAssignFaclities(entityRootId: number) {
    this.appSecurityManagementService
      .GetChildFacilities(entityRootId)
      .subscribe(
        (response) => {
          this.userAssignFaclitiesData = response;
          this.setDisplayRows(this.userAssignFaclitiesData);
          this.typeOfSpinner ='none';
        },
        (error: HttpErrorResponse) => {
          this.typeOfSpinner ='none';
        });
  }

  setDisplayRows(selectedUserEntityList: EntityComposite[]): void {
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);
    const rows$ = of(selectedUserEntityList);
    this.displayedRows$ = rows$.pipe(
      paginateRows(pageEvents$)
    );
  }

  insertUserEntity(entityId: number) {
    this.typeOfSpinner = 'loading';
    this.appSecurityManagementService.addUserToFacility(this.userIdInput, entityId).subscribe(
      (entityUser) => {
        if (!_.isNil(entityUser)) {
          this.snackBar.successMessage(successMsgs.user_entity_assign);
          this.dialogRef.close(true);
          this.typeOfSpinner = 'none';
        }
        else {
          this.snackBar.errMessage(errorMsgs.user_entity_record_not_inserted);
        }
      }
      ,
      (error: HttpErrorResponse) => {
        this.typeOfSpinner = 'loading';
        this.snackBar.errMessage(errorMsgs.user_entity_record_not_inserted);
      }
    );
  }

}
